import React from "react";

const TrendDown = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.424 18.0959L12.639 9.39878L9.2924 14.3685L2.59912 7.74219"
        stroke="#EB5757"
        strokeWidth="2.07073"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.6592 18.097H21.4242V14.332"
        stroke="#EB5757"
        strokeWidth="2.07073"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TrendDown;
