import React, { useEffect } from "react";
import { useState } from "react";
import {
  Container,
  Header,
  Footer,
  Label,
  Reminder,
  ReText,
  DivIcon,
  Subtitle,
} from "./styles";

import Exclamation from "../../assets/icons/Exclamation";
import Close from "../../assets/icons/Close";
import Modal from "../../components/general/Modal";
import Button from "../../components/general/Button";

//isOpen -> Controlador se o modal está aberto ou não
//onClose -> Função executada ao cancelar/fechar/clicar fora do modal
//onConfirm -> Função executada ao confirmar no modal
//selector -> Local onde o modal sera renderizado(root -> Toda a tela)
//className -> nodrag = modal não pode ser movido

const SwitchVersion = ({
  title = "",
  version,
  setVersion,
  allVersions,
  isOpen,
  onClose,
  selector,
  className,
  item,
  setCritical,
}) => {
  const [projectTitle, setProjectTitle] = useState("");

  //Create Flow create version

  useEffect(() => {
    if (version === "default") {
      setProjectTitle(title);
    } else {
      setProjectTitle(version ? version : title);
    }
  }, [version, title]);

  async function clearCriticalState() {
    setCritical(false);
  }

  const handleClick = () => {
    setVersion(item);
    clearCriticalState().then(() => onClose());
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      selector={selector}
      className={className}
    >
      <Container>
        <Header>
          <Label>Trocar versão</Label>
          <Reminder>
            <Exclamation style={{ height: "1.3125rem", width: "1.3125rem" }} />
            <ReText>
              Lembrete: ao trocar a versão do fluxo as alterações não salvas
              serão perdidas!
            </ReText>
          </Reminder>
          <Subtitle>
            Deseja trocar para a versão{" "}
            <span style={{ color: "#118052", fontWeight: "bold" }}>{item}</span>
            ?
          </Subtitle>
        </Header>
        <Footer>
          <Button
            outline
            content="Cancelar"
            width={"11.3125rem"}
            height={"2.625rem"}
            onClick={onClose}
          />
          <Button
            content="Confirmar"
            width={"11.3125rem"}
            height={"2.625rem"}
            onClick={handleClick}
          />
        </Footer>
        <DivIcon>
          <Close onClick={onClose} />
        </DivIcon>
      </Container>
    </Modal>
  );
};

export default SwitchVersion;
