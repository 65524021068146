import { useDispatch, useSelector } from "react-redux";
import { findFlow } from "../../state/flows/selectors";
import moment from "moment";
import "moment/min/locales";
import React, { useRef, useEffect, useState } from "react";
import {
  Controls,
  ReactFlowProvider,
  Background,
  MiniMap,
  useStoreState,
  useZoomPanHelper,
} from "react-flow-renderer";
import ConditionalNodeForView from "../../components/ConditionalNodeForView";
import ConditionalEndNode from "../../components/ConditionalEndNode";
import ParallelNode from "../../components/ParallelNode";
import ParallelEndNode from "../../components/ParallelEndNode";
import TaskNodeForView from "../../components/TaskNodeForView";
import EventStartNode from "../../components/EventStartNode";
import EventEndNode from "../../components/EventEndNode";
import CustomEdgeForView from "../../components/CustomEdgeForView";
import { useHistory } from "react-router-dom";
import CustomEdgeWithoutLabel from "../../components/CustomEdgeWithoutLabel";
import ModelTaskModal from "./ModelTaskModal/index";
import ModelTimerModal from "./ModelTimerModal/index";

import {
  Button,
  Confirm,
  Icon,
  Label,
  Segment,
  Sidebar,
  Dropdown,
} from "semantic-ui-react";
import {
  handleAddModelFlow,
  handleFetchSingleFlow,
  handleNewDefaultVersionFlow,
  handleRemoveModelFlow,
  handleRemoveVersion,
  newElementsVersion,
  showCriticalPath,
} from "../../state/flows/actions";
import TimerEventNodeForView from "../../components/TimerEventNodeForView";
import { ReactFlowWrapper, ButtonTest, ReactFlowBoard } from "./styles";
import Loading from "../../components/LoadingTemplate/index";
import CustomMarkForView from "../../components/CustomMarkForView";
import CustomTextForView from "../../components/CustomTextForView";
import { useKey } from "rooks"; //Arrows

const ChangePosition = ({ off, xy }) => {
  const { transform } = useZoomPanHelper();

  const state = useStoreState((state) => state);
  const x = state.transform[0];
  const y = state.transform[1];
  const z = state.transform[2];

  useEffect(() => {
    transform({ x: x + xy.x, y: y + xy.y, zoom: z + xy.z });
    off();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

///////FUNÇÕES DO GRÁFICO
function checkBranch(
  elements,
  item = {},
  branchTimeStart = 0,
  doneTimeStart = 0,
  donePreventTimeStart = 0,
  statsStart = [],
  advancedStats = [{ sad: 22 }],
  task = {}
) {
  let stats = statsStart;
  let branchTime = branchTimeStart;
  let donePreventTime = donePreventTimeStart;
  let doneTime = doneTimeStart;

  ////SE FOR ARESTA
  if (item.source) {
    return checkBranch(
      elements,
      elements.find((el) => el.id === item.target),
      branchTime,
      doneTime,
      donePreventTime,
      stats,
      advancedStats,
      task
    );
  }
  ///////SE FOR NO
  else {
    if (item.type === "task" || item.type === "timerEvent") {
      if (item.type === "timerEvent") {
        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });
        stats.push(
          stats.length > 0
            ? {
                taskTitle: "TEMPORIZADOR",
                taskId: item.id,
                time: item.data.expiration.number / 24,
              }
            : {
                taskTitle: "TEMPORIZADOR",
                time: item.data.expiration.number / 24,
                taskId: item.id,
              }
        );
      }
      if (item.type === "task") {
        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        stats.push(
          stats.length > 0
            ? {
                taskTitle: item.data.label,
                time: item.data.expiration.number / 24,
                taskId: item.id,
              }
            : {
                taskTitle: item.data.label,
                time: item.data.expiration.number / 24,
                taskId: item.id,
              }
        );
      }

      branchTime += item.data.expiration.number;

      return checkBranch(
        elements,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        stats,
        advancedStats,
        task
      );
    } else if (item.type === "parallel" || item.type === "conditional") {
      const branchCountt = branchCount(
        elements,
        item,
        stats.length === 0 ? advancedStats : stats
      );

      branchTime += branchCountt.max;
      doneTime += branchCountt.maxDone;
      donePreventTime += branchCountt.maxPreventDone;

      branchCountt.stats.forEach((item) => {
        stats.push(item);
      });

      stats.sort(function (a, b) {
        return Number(a.x) - Number(b.x);
      });

      return checkBranch(
        elements,
        elements.find((e) => e.source === branchCountt.lastItem.id),
        branchTime,
        doneTime,
        donePreventTime,
        stats,
        advancedStats,
        branchCountt.task
      );
    } else if (item.type === "parallelEnd" || item.type === "conditionalEnd") {
      return {
        branchTime,
        lastItem: item,
        doneTime,
        donePreventTime,
        stats,
        task,
      };
    }
    /// SE NAO FOR NADA DISSO SÓ CONTINUA ANDANDO
    else
      return checkBranch(
        elements,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        stats,
        advancedStats,
        task
      );
  }
}

function branchCount(elements, item = {}, stat = [{ jurubebe: 12 }]) {
  let max = 0;
  let maxDone = 0;
  let task;
  let maxPreventDone = 0;
  const allBranchs = elements.filter((e) => e.source === item.id);

  const times = allBranchs.map((e) => {
    const obj = checkBranch(elements, e, 0, 0, 0, [], stat, []);

    return obj;
  });

  let bestBranch = [];

  times.forEach((el, index) => {
    if (index + 1 <= times.length) {
      if (el.branchTime >= max) {
        task = el.task;
      }

      max = Math.max(el.branchTime, max);
      maxDone = Math.max(el.doneTime, maxDone);
      maxPreventDone = Math.max(el.donePreventTime, maxPreventDone);

      if (max === el.branchTime) {
        bestBranch = el.stats;
      }
    }
  });

  return {
    max,
    lastItem: times[0].lastItem,
    maxDone,
    maxPreventDone,
    stats: bestBranch,
    task,
  };
}

function count(
  elements,
  item = { type: "eventEnd" },
  branchTimeStart = 0,
  doneTimeStart = 0,
  donePreventTimeStart = 0,
  statsStart = [],
  task = {}
) {
  let stats = statsStart;
  let branchTime = branchTimeStart;
  let donePreventTime = donePreventTimeStart;
  let doneTime = doneTimeStart;

  //////////SE FOR ARESTA
  if (item.source) {
    return count(
      elements,
      elements.find((el) => el.id === item.target),
      branchTime,
      doneTime,
      donePreventTime,
      stats,
      task
    );
  }
  //////////SE FOR NÓ
  else {
    if (item.type === "task" || item.type === "timerEvent") {
      if (item.type === "timerEvent") {
        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        stats.push({
          taskTitle: "TEMPORIZADOR",
          time: item.data.expiration.number / 24,
          taskId: item.id,
        });
      }

      if (item.type === "task") {
        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        stats.push({
          taskTitle: item.data.label,
          time: item.data.expiration.number / 24,
          taskId: item.id,
        });
      }

      branchTime += item.data.expiration.number;

      return count(
        elements,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        stats,
        task
      );
    } else {
      if (item.type === "eventEnd") {
        return { branchTime, doneTime, donePreventTime, stats };
      } else if (item.type === "parallel" || item.type === "conditional") {
        /////VERIFICA O MAIOR CAMINHO E RETORNA O ID FINAL
        const finishBranchItem = branchCount(elements, item, stats);

        branchTime += finishBranchItem.max;
        doneTime += finishBranchItem.maxDone;
        donePreventTime += finishBranchItem.maxPreventDone;

        finishBranchItem.stats.forEach((item) => {
          stats.push(item);
        });

        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        return count(
          elements,
          elements.find((e) => e.source === finishBranchItem.lastItem.id),
          branchTime,
          doneTime,
          donePreventTime,
          stats,
          finishBranchItem.task
        );
      } else {
        return count(
          elements,
          elements.find((e) =>
            item.type === "conditional"
              ? e.source === item.id && e.data.status === "done"
              : e.source === item.id
          ),
          branchTime,
          doneTime,
          donePreventTime,
          stats,
          task
        );
      }
    }
  }
}

function recursionCritical(itemId, elements) {
  let newElements = JSON.parse(JSON.stringify(elements));

  newElements.forEach(function (it, index) {
    if (itemId === it.id) {
      this[index].data.criticalPath = true;

      if (!it.source) {
        newElements.forEach(function (el, index) {
          if (
            el.source === itemId &&
            !newElements.find(
              (item) =>
                item.source === itemId && item.data.criticalPath === true
            )
          ) {
            if (
              newElements.filter((item) => item.source === itemId).length > 1
            ) {
              if (
                newElements.find(
                  (item) =>
                    el.target === item.id && el.data.criticalPath === true
                )
              ) {
                this[index].data.criticalPath = true;
                return recursionCritical(el.id, newElements);
              }
            } else {
              this[index].data.criticalPath = true;
              return recursionCritical(el.id, newElements);
            }
          } else if (
            el.target === itemId &&
            !newElements.find(
              (item) =>
                item.target === itemId && item.data.criticalPath === true
            )
          ) {
            if (
              newElements.filter((item) => item.target === itemId).length > 1
            ) {
              if (
                newElements.find(
                  (item) =>
                    el.source === item.id && el.data.criticalPath === true
                )
              ) {
                this[index].data.criticalPath = true;
                return recursionCritical(el.id, newElements);
              }
            } else {
              this[index].data.criticalPath = true;
              return recursionCritical(el.id, newElements);
            }
          }
        }, newElements);
      } else {
        newElements.forEach(function (el, index) {
          if (el.id === it.source || el.id === it.target) {
            if (el.data.criticalPath !== true) {
              this[index].data.criticalPath = true;
              return recursionCritical(el.id, newElements);
            }
          }
        }, newElements);
      }
    }
  }, newElements);

  return newElements;
}

function newTry(elements, oldElementsLength = 0) {
  let oldElementsLength_var = oldElementsLength;
  let elements_var = elements;

  elements_var.forEach((item) => {
    if (item.source) {
      if (
        !!elements_var.find(
          (it) =>
            (it.id === item.source || it.id === item.target) &&
            it.data.criticalPath !== true
        )
      )
        if (item.data.criticalPath === true)
          elements_var = recursionCritical(item.id, elements_var);
    } else {
      if (
        (!elements_var.find(
          (it) => it.target === item.id && it.data.criticalPath === true
        ) ||
          !elements_var.find(
            (it) => it.source === item.id && it.data.criticalPath === true
          )) &&
        !(
          !elements_var.find(
            (it) => it.target === item.id && it.data.criticalPath === true
          ) &&
          !elements_var.find(
            (it) => it.source === item.id && it.data.criticalPath === true
          )
        )
      )
        if (item.data.criticalPath === true)
          elements_var = recursionCritical(item.id, elements_var);
    }
  });

  if (
    elements_var.filter((item) => item.data.criticalPath).length !==
    oldElementsLength
  ) {
    oldElementsLength_var = elements_var.filter(
      (item) => item.data.criticalPath
    ).length;

    return newTry(elements_var, oldElementsLength_var);
  }

  return elements_var;
}

const FlowViewerModelPage = ({ match }) => {
  const flow = useSelector((state) =>
    findFlow(state.flows.entities, match.params.id)
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const reactFlowWrapper = useRef(null);
  const elements = flow && flow.elements;
  const flowStatus = useSelector((state) => state.flows.status);
  const [actualTask, setActualTask] = useState(null);
  const [open, setOpen] = useState(false);
  const [timerOpen, setTimerOpen] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmCopyOpen, setConfirmCopyOpen] = useState(false);
  const [visible, setVisible] = useState(true);
  const [options, setOptions] = useState([
    { key: "default", value: "default", text: "Original" },
  ]);
  const [version, setVersion] = useState(false);
  const [confirmOpenVersion, setConfirmOpenVersion] = useState(false);
  const [criticalPath, setCriticalPath] = useState(false);
  const [taskUpdate, setTaskUpdate] = useState(false);
  const [loading, setLoading] = useState(false);
  //Arrow Keys
  const [arrowFlag, setArrowFlag] = useState(false);
  const [coordinates, setCoordinates] = useState(false);

  console.log(flow);

  useEffect(() => {
    if (!token) history.push("/login");
  }, [history, token]);

  useEffect(() => {
    if (criticalPath === true && flow) {
      setLoading(true);
      const start = flow && flow.elements.find((e) => e.type === "eventStart");
      let stats = flow && count(flow.elements, start).stats;

      let elements_copy = JSON.parse(JSON.stringify(flow.elements));

      elements_copy.forEach((item) => {
        item.data.criticalPath = false;
      });

      elements_copy.forEach((el) => {
        if (!!stats.find((item) => item.taskId === el.id)) {
          el.data.criticalPath = true;
        }
        if (
          el.source &&
          !!stats.find(
            (item) => item.taskId === el.source || item.taskId === el.target
          )
        ) {
          el.data.criticalPath = true;
        }
      });

      elements_copy.sort(function (a, b) {
        if (a.data.criticalPath === true && b.data.criticalPath === false)
          return 1;
        else return -1;
      });

      let newElements = recursionCritical(
        elements.find((item) => item.type === "eventStart").id,
        elements_copy
      );

      const nextElements = newTry(newElements);

      dispatch(
        showCriticalPath({ elements: nextElements, flowId: match.params.id })
      );

      setLoading(false);
    } else if (criticalPath === false && flow) {
      setLoading(true);
      let emptyElements = JSON.parse(JSON.stringify(elements));

      emptyElements.forEach((item) => {
        item.data.criticalPath = false;
      });

      dispatch(
        showCriticalPath({ elements: emptyElements, flowId: match.params.id })
      );
      setLoading(false);
    }
  }, [criticalPath, taskUpdate]);

  useEffect(() => {
    if (flowStatus === "idle") {
      dispatch(
        handleFetchSingleFlow({
          enterpriseId: !user.enterpriseId ? user?._id : user.enterpriseId,
          flowId: match.params.id,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleConfirm = () => {
    setLoading(true);
    dispatch(handleRemoveModelFlow(flow?._id)).then(() => {
      history.push("/");
      setLoading(false);
    });
  };
  const handleCancel = () => setConfirmOpen(false);

  const handleConfirmVersion = () => {
    setLoading(true);
    dispatch(
      handleRemoveVersion({ originalId: flow._id, versionNumber: version })
    ).then(() => {
      setConfirmOpenVersion(false);
      setVersion(flow?.defaultVersion);
      setLoading(false);
    });
  };
  const handleCancelVersion = () => setConfirmOpenVersion(false);

  const calculateCriticalPath = (elements) => {
    let sum = 0;

    elements.forEach((item) => {
      if (
        (item.type === "task" || item.type === "timerEvent") &&
        item.data.criticalPath === true
      ) {
        sum += item.data.expiration.number;
      }
    });

    return sum;
  };

  useEffect(() => {
    if (flow && !version) {
      setVersion(
        flow.defaultVersion === "default"
          ? "default"
          : flow.versions.find((item) => item._id === flow.defaultVersion)
              ?.versionNumber
      );

      setOptions([{ key: "default", value: "default", text: "Original" }]);

      flow.versions?.forEach((item) => {
        setOptions((prev) => [
          ...prev,
          {
            key: item.versionNumber,
            value: item.versionNumber,
            text: item.versionNumber,
          },
        ]);
      });
    }
  }, [flow]);

  const handleCopyConfirm = () => {
    let elements = JSON.parse(JSON.stringify(flow.elements));

    elements.forEach(function (v) {
      delete v._id;
    });
    setLoading(true);
    dispatch(
      handleAddModelFlow(
        elements,
        `${flow.title.toUpperCase()} CÓPIA`,
        !user.enterpriseId ? user?._id : user.enterpriseId
      )
    ).then(() => {
      setConfirmCopyOpen(false);
      history.push("/");
      setLoading(false);
    });
  };

  const handleCopyCancel = () => setConfirmCopyOpen(false);

  moment.locale("pt-br");

  const onLoad = (reactFlowapi) => {
    reactFlowapi.fitView();
  };

  const nodeTypes = {
    conditional: ConditionalNodeForView,
    conditionalEnd: ConditionalEndNode,
    parallel: ParallelNode,
    parallelEnd: ParallelEndNode,
    task: TaskNodeForView,
    eventStart: EventStartNode,
    eventEnd: EventEndNode,
    timerEvent: TimerEventNodeForView,
    customMark: CustomMarkForView,
    customText: CustomTextForView,
  };

  const edgeTypes = {
    customEdge: CustomEdgeForView,
    customEdgeWithoutLabel: CustomEdgeWithoutLabel,
  };

  useEffect(() => {
    if (version) {
      if (criticalPath === true) {
        const oldFlow =
          version === "default"
            ? flow?.versions?.find((item) => item.versionNumber === "default")
              ? flow?.versions?.find((item) => item.versionNumber === "default")
                  .elements
              : flow.elements
            : flow?.versions
                ?.find((item) => item.versionNumber === version)
                ?.elements?.map((item) => {
                  let newItem = JSON.parse(JSON.stringify(item));
                  newItem.data.criticalPath = false;
                  return newItem;
                });

        setLoading(true);
        const start = flow && oldFlow.find((e) => e.type === "eventStart");
        let stats = flow && count(oldFlow, start).stats;

        let elements_copy = JSON.parse(JSON.stringify(oldFlow));

        elements_copy.forEach((item) => {
          item.data.criticalPath = false;
        });

        elements_copy.forEach((el) => {
          if (!!stats.find((item) => item.taskId === el.id)) {
            el.data.criticalPath = true;
          }
          if (
            el.source &&
            !!stats.find(
              (item) => item.taskId === el.source || item.taskId === el.target
            )
          ) {
            el.data.criticalPath = true;
          }
        });

        let newElements = recursionCritical(
          elements.find((item) => item.type === "eventStart").id,
          elements_copy
        );

        const nextElements = newTry(newElements);

        dispatch(
          newElementsVersion({
            elements: nextElements,
            flowId: match.params.id,
          })
        );

        setLoading(false);
      } else {
        dispatch(
          newElementsVersion({
            elements:
              version === "default"
                ? flow?.versions?.find(
                    (item) => item.versionNumber === "default"
                  )
                  ? flow?.versions?.find(
                      (item) => item.versionNumber === "default"
                    ).elements
                  : flow.elements
                : flow?.versions
                    ?.find((item) => item.versionNumber === version)
                    ?.elements?.map((item) => {
                      let newItem = JSON.parse(JSON.stringify(item));
                      newItem.data.criticalPath = false;
                      return newItem;
                    }),
            flowId: match.params.id,
          })
        );
      }
    }
  }, [version]);

  //? Implementando comandos por Teclado
  function ArrowLeftEnter(e) {
    setCoordinates({ x: 10, y: 0, z: 0 });
    setArrowFlag(true);
  }
  function ArrowRightEntered(e) {
    setCoordinates({ x: -10, y: 0, z: 0 });
    setArrowFlag(true);
  }
  function ArrowUpEntered(e) {
    setCoordinates({ x: 0, y: 10, z: 0 });
    setArrowFlag(true);
  }
  function ArrowDownEntered(e) {
    setCoordinates({ x: 0, y: -10, z: 0 });
    setArrowFlag(true);
  }
  useKey(["ArrowLeft"], ArrowLeftEnter);
  useKey(["ArrowRight"], ArrowRightEntered);
  useKey(["ArrowUp"], ArrowUpEntered);
  useKey(["ArrowDown"], ArrowDownEntered);

  return flowStatus === "succeeded" && flow !== undefined && !loading ? (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar
          as={Segment}
          animation="push"
          vertical
          visible={visible}
          style={{ textAlign: "center", padding: 0, overflowX: "hidden" }}
        >
          <Label corner="right" style={{ margin: 0, padding: 0 }}>
            <Icon
              name="cancel"
              style={{
                background: "none",
                padding: 0,
                cursor: "pointer",
              }}
              onClick={() => setVisible(false)}
            />
          </Label>
          <h3
            style={{
              backgroundColor: "#F0F0F0",
              padding: "12px",
              marginTop: "0px",
            }}
          >
            {flow.title.toUpperCase()} <br></br>
            Versão: {version === "default" ? "Original" : version}
          </h3>
          <Label size="large">
            <Icon name="calendar alternate outline" />
            Criado: {moment(flow.createdAt).format("L, LT")}
          </Label>

          <Button
            content="Copiar Fluxo"
            icon="copy"
            labelPosition="left"
            onClick={() => setConfirmCopyOpen(true)}
            style={{ minWidth: "210px", marginTop: "10px" }}
          />
          <Confirm
            open={confirmCopyOpen}
            content={`Copiar o fluxo modelo: ${flow?.title.toUpperCase()} ?`}
            onCancel={handleCopyCancel}
            onConfirm={() => handleCopyConfirm()}
            confirmButton="Copiar"
            cancelButton="Cancelar"
          />
          {version === "default" && criticalPath === false ? (
            <Button
              style={{ minWidth: "210px", marginTop: "10px" }}
              content="Editar fluxo"
              icon={<Icon name="edit" style={{ paddingLeft: 2 }} />}
              labelPosition="left"
              onClick={() => {
                setCriticalPath(false);
                history.push({
                  pathname: `/editflow/`,
                  search:
                    "?" +
                    new URLSearchParams({
                      flowId: flow._id,
                    }).toString(),
                });
              }}
            />
          ) : criticalPath === false ? (
            <Button
              style={{ minWidth: "210px", marginTop: "10px" }}
              content="Editar versão do fluxo"
              icon={<Icon name="edit" style={{ paddingLeft: 2 }} />}
              labelPosition="left"
              onClick={() =>
                history.push({
                  pathname: `/editversionflow/`,
                  search:
                    "?" +
                    new URLSearchParams({
                      flowId: flow._id,
                      version: version,
                    }).toString(),
                })
              }
            />
          ) : null}

          <Button
            color="red"
            content={
              criticalPath === true
                ? "Ocultar caminho crítico"
                : "Mostrar caminho crítico"
            }
            style={{ minWidth: "210px", marginTop: "10px" }}
            icon={<Icon name="bug" />}
            onClick={() => {
              setCriticalPath(!criticalPath);
            }}
          />

          {version === "default" ? (
            <Button
              content="Deletar fluxo modelo"
              icon="trash alternate"
              labelPosition="left"
              onClick={() => setConfirmOpen(true)}
              style={{
                backgroundColor: "#ffa3a3",
                marginTop: "10px",
              }}
            />
          ) : (
            <Button
              content="Deletar versão atual"
              icon="trash alternate"
              labelPosition="left"
              onClick={() => setConfirmOpenVersion(true)}
              style={{
                backgroundColor: "#ffa3a3",
                marginTop: "10px",
              }}
            />
          )}
          <Confirm
            open={confirmOpen}
            content="Tem certeza que deseja excluir esse fluxo modelo?"
            onCancel={handleCancel}
            onConfirm={handleConfirm}
            confirmButton="Excluir"
            cancelButton="Cancelar"
          />
          <Confirm
            open={confirmOpenVersion}
            content="Tem certeza que deseja excluir essa versão do fluxo modelo?"
            onCancel={handleCancelVersion}
            onConfirm={handleConfirmVersion}
            confirmButton="Excluir"
            cancelButton="Cancelar"
          />
          <div
            style={{
              margin: 10,
              display: "flex",
              flexDirection: "column",
            }}
          >
            <button
              style={{ margin: 10 }}
              onClick={() =>
                history.push({
                  pathname: `/newversionflow/`,
                  search:
                    "?" +
                    new URLSearchParams({
                      flowId: flow._id,
                      version,
                    }).toString(),
                })
              }
            >
              Cadastrar Nova versao
            </button>

            <Dropdown
              label="Versão atual"
              labeled
              fluid
              selection
              options={options}
              value={version}
              onChange={(e, data) => {
                setVersion(data.value);
              }}
            />

            {flow.defaultVersion === "default"
              ? version !== flow.defaultVersion && (
                  <button
                    style={{ margin: 10 }}
                    onClick={() => {
                      setLoading(true);
                      dispatch(
                        handleNewDefaultVersionFlow({
                          flowId: flow._id,
                          defaultVersion:
                            version === "default"
                              ? version
                              : flow.versions.find(
                                  (item) => item.versionNumber === version
                                )._id,
                        })
                      );
                      setLoading(false);
                    }}
                  >
                    Salvar versão como nova versão padrão
                  </button>
                )
              : version !==
                  flow.versions.find((item) => item._id === flow.defaultVersion)
                    ?.versionNumber && (
                  <button
                    style={{ margin: 10 }}
                    onClick={() => {
                      setLoading(true);
                      dispatch(
                        handleNewDefaultVersionFlow({
                          flowId: flow._id,
                          defaultVersion:
                            version === "default"
                              ? version
                              : flow.versions.find(
                                  (item) => item.versionNumber === version
                                )._id,
                        })
                      );
                      setLoading(false);
                    }}
                  >
                    Salvar versão como nova versão padrão
                  </button>
                )}
          </div>
        </Sidebar>
        <Sidebar.Pusher
          style={{
            display: "flex",
            height: "calc(100vh - (74px))",
            overflow: "hidden",
          }}
        >
          <ReactFlowProvider>
            <ButtonTest>
              <Button
                style={{ width: "180px" }}
                icon="info"
                labelPosition="left"
                content="Dados do fluxo"
                onClick={() => setVisible(!visible)}
              />
            </ButtonTest>
            {criticalPath && (
              <Label
                size="large"
                color="red"
                style={{
                  marginTop: "10px",
                  position: "absolute",
                  zIndex: 10,
                  top: "100px",
                  left: "70px",
                }}
              >
                <Icon name="bug" />
                Total de dias do caminho crítico:{" "}
                {calculateCriticalPath(flow.elements) / 24} dias
              </Label>
            )}
            <ReactFlowWrapper ref={reactFlowWrapper}>
              {open && (
                <ModelTaskModal
                  setTaskUpdate={() => {
                    setTaskUpdate(!taskUpdate);
                  }}
                  flowId={flow._id}
                  taskId={actualTask}
                  open={open}
                  change={(e) => setOpen(e)}
                  version={version}
                />
              )}
              {timerOpen && (
                <ModelTimerModal
                  setTaskUpdate={() => {
                    setTaskUpdate(!taskUpdate);
                  }}
                  flowId={flow._id}
                  taskId={actualTask}
                  open={timerOpen}
                  change={(e) => setTimerOpen(e)}
                  version={version}
                />
              )}
              {arrowFlag && (
                <ChangePosition
                  off={() => setArrowFlag(false)}
                  xy={coordinates}
                />
              )}
              <ReactFlowBoard
                minZoom={0}
                elements={elements}
                onLoad={onLoad}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                nodesDraggable={false}
                nodesConnectable={false}
                elementsSelectable={false}
                onElementClick={(i, e) => {
                  if (
                    e !== null &&
                    (e.type === "task" || e.type === "timerEvent")
                  ) {
                    setActualTask(e.id);

                    if (e.type === "timerEvent") {
                      return setTimerOpen(true);
                    } else return setOpen(true);
                  } else return null;
                }}
              >
                <MiniMap
                  style={{
                    background: "#aaa",
                  }}
                  nodeColor={(node) => {
                    if (node.type === "customMark") {
                      return node.data.hexColor;
                    } else {
                      return "#fff";
                    }
                  }}
                />

                <Controls showInteractive={false} />
                <Background />
              </ReactFlowBoard>
            </ReactFlowWrapper>
          </ReactFlowProvider>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

export default FlowViewerModelPage;
