import React from "react";

import { LogoAnimation, NameAnimation } from "./styles";
import LoadingBar from "./LoadingBar";
import LogoZaivu from "../../../assets/Logo-Zaivu.svg";
import NameLogo from "../../../assets/Name.svg";

const LoginAnimation = ({ animation, animationEnd }) => {
  return (
    <>
      <LogoAnimation
        onAnimationEnd={() => animationEnd()}
        animation={animation}
        src={LogoZaivu}
      />
      <NameAnimation animation={animation} src={NameLogo} />
      <LoadingBar animation={animation} />
    </>
  );
};
export default LoginAnimation;
