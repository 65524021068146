import React, { useEffect, useState } from "react";
import {
  Container,
  ContainerLogin,
  Header,
  SubHeader,
  ContentLogin,
  Logo,
  Footer,
  ContainerBanner,
  ContainerCheck,
  CallIcon,
  ContentBanner,
  Logomark,
  Title,
  Flow,
  Subtitle,
  BannerDiv,
  FlowDiv,
} from "./styles";
import Button from "../../components/general/Button";
import Link from "../../components/general/Link";
import Input from "../../components/general/Input/index";
import LogoZaivu from "../../assets/Logo-Zaivu.svg";
import GlobalStyle from "../../styles/globalStyles";
import Checkbox from "../../components/general/Checkbox";
import Call from "../../assets/call-outgoing.svg";
import Banner from "../../assets/icons/Banner";
import BlackLogo from "../../assets/LogoBlack.svg";
import LoginAnimation from "./LoginAnimation";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { handleSignIn } from "../../state/auth/actions";

const LoginPage = () => {
  //Checkbox e animação
  const [check, setCheck] = useState(true);
  const [animation, setAnimation] = useState(false);
  const [confirmAni, setConfirmAni] = useState(false);

  //Login Page
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [errorLogin, setErrorLogin] = useState(false);

  //Redux
  const history = useHistory();
  const dispatch = useDispatch();

  // const error = useSelector((state) => state.auth.errorMessage);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);

  useEffect(() => {
    if (token !== null) {
      setAnimation(true);
      if (confirmAni) {
        user.rank === "Gerente"
          ? history.push("/")
          : history.push({
              pathname: "/",
              search:
                "?" +
                new URLSearchParams({
                  title: "",
                  flowTitle: "",
                  client: "",
                  status: "undefined",
                  flowType: "undefined",
                }).toString(),
            });
      }
    }
  }, [history, token, confirmAni]);

  //LoginValidation
  const handleLogin = () => {
    dispatch(
      handleSignIn({ username: login, password: password, check: check })
    ).then((res) => {
      res.type === "ADD_ERROR" ? setErrorLogin(true) : setErrorLogin(false);
    });
  };

  //Enter
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleLogin();
    }
  };

  return (
    <>
      <GlobalStyle />
      <LoginAnimation
        animation={animation}
        animationEnd={() => {
          setConfirmAni(true);
        }}
      />

      <Container animation={animation}>
        <ContainerLogin>
          <ContentLogin>
            <Logo style={{ opacity: 0 }} src={LogoZaivu} />
            <Header>Bem-vindo ao Zaivu!</Header>
            <SubHeader>Faça login para continuar</SubHeader>
            <Input
              value={login}
              setValue={(e) => {
                setLogin(e);
              }}
              error={errorLogin}
              label="Endereço de E-mail"
              placeholder="Digite seu e-mail..."
              type="text"
              onKeyDown={handleKeyDown}
            />
            <Input
              value={password}
              setValue={(e) => {
                setPassword(e);
              }}
              error={errorLogin}
              label="Senha"
              placeholder="Digite sua senha..."
              errorMsg="Seu usuário ou senha estão incorretos"
              type="password"
              onKeyDown={handleKeyDown}
            />
            <ContainerCheck>
              <Checkbox
                checked={check}
                onChange={() => setCheck((prev) => !prev)}
                label="Manter conectado"
              />
              <Link
                content="Esqueceu a senha"
                onClick={() => history.push("/forgotpassword")}
              />
            </ContainerCheck>
            <Button
              height="auto"
              content="Acessar agora"
              onClick={() => handleLogin()}
            />
          </ContentLogin>
          <Footer>
            <CallIcon src={Call} />
            Não possui acesso?{" "}
            <Link
              theme
              content="Entre em contato conosco"
              onClick={() => window.open("https://zaivu.com")}
            />
          </Footer>
        </ContainerLogin>
        <ContainerBanner>
          <BannerDiv>
            <Banner />
          </BannerDiv>
          <ContentBanner>
            <Logomark src={BlackLogo} />
            <FlowDiv>
              <Flow />
            </FlowDiv>
            <Title>Organize a sua empresa e acompanhe a produtividade.</Title>
            <Subtitle>
              Modela, analisar, executar e melhorar seus fluxos de trabalho,
              tudo isso na mesma plataforma.
            </Subtitle>
          </ContentBanner>
        </ContainerBanner>
      </Container>
    </>
  );
};

export default LoginPage;
