import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Confirm, Dropdown, Modal } from "semantic-ui-react";
import {
  handleAssignAllTasks,
  handleNewFlowTitle,
  handleUndoTask,
} from "../../../state/activedFlows/actions";
import { fetchUsers } from "../../../state/users/reducers";
import { IContainer, InputContainer, InputTitle } from "./styles";
import Loading from "../../../components/LoadingTemplate/index";

const FlowOptionsModal = ({ open, change, flow }) => {
  const dispatch = useDispatch();
  const [confirm, setConfirm] = useState(false);
  const [secondConfirm, setSecondConfirm] = useState(false);
  const [thirdConfirm, setThirdConfirm] = useState(false);
  const user = useSelector((state) => state.auth.user);
  const users = useSelector((state) => state.users.entities);
  const usersStatus = useSelector((state) => state.users.status);
  const [employeer, setEmployeer] = useState(user?.username);
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const accountable = [
    { key: "Ninguém", value: "Ninguém", text: user?.username },
  ];

  users.forEach((item, index) => {
    accountable.push({
      key: index,
      value: item.email,
      text: item.username,
    });
  });

  const handleConfirm = (item) => {
    setLoading(true);
    dispatch(handleUndoTask({ flowId: item })).then(() => {
      setConfirm(false);
      change(false);
      setLoading(false);
    });
  };
  const handleCancel = () => setConfirm(false);

  const handleSecondConfirm = (item) => {
    setLoading(true);
    dispatch(handleAssignAllTasks(item, employeer)).then(() => {
      setSecondConfirm(false);
      change(false);
      setLoading(false);
    });
  };

  const handleThirdConfirm = (flowId, title) => {
    setLoading(true);
    dispatch(handleNewFlowTitle(flowId, title)).then(() => {
      setThirdConfirm(false);
      change(false);
      setLoading(false);
    });
  };
  const handleSecondCancel = () => setSecondConfirm(false);

  const handleThirdCancel = () => setThirdConfirm(false);

  useEffect(() => {
    if (usersStatus === "idle")
      dispatch(fetchUsers(!user.enterpriseId ? user?._id : user.enterpriseId));
  }, []);

  return !loading ? (
    <Modal
      onClose={() => change(false)}
      onOpen={() => change(true)}
      open={open}
    >
      <Confirm
        open={confirm}
        content={`Tem certeza que deseja desfazer a última ação?`}
        onCancel={handleCancel}
        onConfirm={() => handleConfirm(flow?._id)}
        confirmButton="Confirmar"
        cancelButton="Cancelar"
      />
      <Confirm
        open={secondConfirm}
        content={`Tem certeza que deseja delegar todas as tarefas do fluxo para ${
          employeer === "Ninguém" ? user?.username : employeer
        }?`}
        onCancel={handleSecondCancel}
        onConfirm={() => handleSecondConfirm(flow?._id)}
        confirmButton="Confirmar"
        cancelButton="Cancelar"
      />
      <Confirm
        open={thirdConfirm}
        content={`Tem certeza que deseja alterar o nome do fluxo para ${title}?`}
        onCancel={handleThirdCancel}
        onConfirm={() => handleThirdConfirm(flow?._id, title)}
        confirmButton="Confirmar"
        cancelButton="Cancelar"
      />

      <Modal.Header>OPÇÕES DO FLUXO</Modal.Header>
      <Modal.Content>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <Button
            content="Desfazer ação"
            disabled={!(flow?.lastState?.length > 0)}
            color="yellow"
            icon="undo"
            labelPosition="left"
            onClick={() => setConfirm(true)}
          />
          {!(flow?.lastState?.length > 0) && (
            <p style={{ color: "red", marginLeft: "10px" }}>
              Você não fez nenhuma ação recentemente para ser desfeita
            </p>
          )}
        </div>

        <div>
          <h4>Delegar todas as tarefas do fluxo para: </h4>
          <div style={{ display: "flex" }}>
            <Dropdown
              placeholder={employeer ? employeer : "Funcionário"}
              fluid
              search
              selection
              onChange={(e, data) => {
                setEmployeer(data.value);
              }}
              options={accountable}
              defaultValue={employeer}
            />
            <Button
              style={{ marginLeft: "10px" }}
              content="Delegar"
              color="blue"
              icon="write"
              labelPosition="left"
              onClick={() => setSecondConfirm(true)}
            />
          </div>
        </div>

        <IContainer>
          <h4>Nome do fluxo:</h4>
          <InputContainer>
            <InputTitle
              value={title}
              placeholder={flow.title}
              onChange={(e) => {
                const pointer = e.target.selectionStart;

                window.requestAnimationFrame(() => {
                  e.target.selectionStart = pointer;
                  e.target.selectionEnd = pointer;
                });
                setTitle(e.target.value);
              }}
            />
            <Button
              style={{ marginLeft: "12px" }}
              content="Alterar"
              color="green"
              icon="check"
              labelPosition="left"
              onClick={() => {
                if (title.length !== 0) setThirdConfirm(true);
              }}
            />
          </InputContainer>
        </IContainer>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => change(false)} content="Voltar" />
      </Modal.Actions>
    </Modal>
  ) : (
    <Modal
      onClose={() => change(false)}
      onOpen={() => change(true)}
      open={open}
    >
      <Loading />
    </Modal>
  );
};

export default FlowOptionsModal;
