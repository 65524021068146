import { Popover } from "@material-ui/core";
import React, { memo, useEffect } from "react";
import { useState } from "react";
import { useStoreActions } from "react-flow-renderer";
import { useDispatch } from "react-redux";
import { Input, Form, TextArea } from "semantic-ui-react";
import { updateCustomTextStyles } from "../../state/flows/actions";
import { updateCustomTextFORM } from "../../state/undoFlow/actions";

import {
  Content,
  ContentText,
  PopoverContent,
  Lock,
  Unlock,
  ColorDiv,
  ConfirmButton,
} from "./styles";

const CustomText = ({ selected, data, id }) => {
  const style = {
    color: selected ? "blue" : "#111",
  };

  const [open, setOpen] = useState(false);

  const idOpa = open ? "simple-popover" : undefined;
  const [anchorEl, setAnchorEl] = useState(null);
  const [text, setText] = useState(data.label);
  const [fontSize, setFontSize] = useState(data.fontSize);
  const [drag, setDrag] = useState(data.draggable);
  const [hex, setHex] = useState(data.hexColor);

  const setSelectedElements = useStoreActions(
    (actions) => actions.setSelectedElements
  );

  const formValidation = (text, fontSize, hex, drag) => {
    if (
      data.label === text &&
      data.fontSize === fontSize &&
      data.hexColor === hex &&
      data.draggable === drag
    ) {
      return false;
    }
    return true;
  };
  const dispatch = useDispatch();

  useEffect(() => {
    if (!open) {
      if (formValidation(text, fontSize, hex, drag)) {
        setText(data.label);
        setFontSize(data.fontSize);
        setHex(data.hexColor);
        setDrag(data.draggable);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, data]);

  useEffect(() => {
    if (formValidation(text, fontSize, hex, drag)) {
      dispatch(
        updateCustomTextFORM({
          id,
          text,
          fontSize,
          hexColor: hex,
          draggable: drag,
        })
      );
    }
  }, [open, drag]);

  useEffect(() => {
    if (!open && text === "") {
      setText("CAIXA DE TEXTO");
    }
  }, [text, open]);

  useEffect(() => {
    if (!drag) setSelectedElements([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drag]);

  const handleLock = () => setDrag((prev) => !prev);
  return (
    <>
      <Content
        className={drag ? "" : "nodrag"}
        onDoubleClick={(event) => {
          setAnchorEl(event.currentTarget);
          setOpen(true);
        }}
      >
        <ContentText
          style={{
            color: hex,
            fontSize: fontSize + "px",
          }}
        >
          {text}
          {drag ? (
            <Unlock onClick={handleLock} />
          ) : (
            <Lock onClick={handleLock} />
          )}
        </ContentText>
      </Content>
      <Popover
        className="nodrag"
        open={open}
        id={idOpa}
        anchorEl={anchorEl}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <PopoverContent className="nodrag">
          <Form>
            <TextArea
              type="text"
              onChange={(event) => {
                event.preventDefault();

                const pointer = event.target.selectionStart;

                window.requestAnimationFrame(() => {
                  event.target.selectionStart = pointer;
                  event.target.selectionEnd = pointer;
                });

                setText(event.target.value.toUpperCase());
              }}
              value={text}
            />
          </Form>
          <Input
            type="number"
            onChange={(e) => {
              setFontSize(e.target.value);
            }}
            min="10"
            max="72"
            value={fontSize}
          ></Input>
          <ColorDiv>
            <h5>Cor</h5>
            <input
              style={{ marginRight: "2px" }}
              type="color"
              value={hex}
              onChange={(e) => setHex(e.currentTarget.value)}
            ></input>
          </ColorDiv>
        </PopoverContent>
      </Popover>
    </>
  );
};

export default memo(CustomText);
