import { Loader } from "semantic-ui-react";
import styled from "styled-components";

export const StyledButton = styled.button`
  color: ${({ load, background, colorText }) =>
    !load ? colorText : background};
  background-color: ${({ background }) => background};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  font-size: ${({ fontSize }) => fontSize};
  font-weight: ${({ fontWeight }) => fontWeight};
  line-height: ${({ lineHeight }) => lineHeight};
  cursor: ${({ load }) => (!load ? `pointer` : `auto`)};
  user-select: none;
  border-radius: ${({ borderRadius }) => borderRadius};
  padding: ${({ padding }) => padding};
  margin: ${({ margin }) => margin};
  align-items: center;
  align-content: center;
  border: none;
  border: ${({ outline, border }) => (!outline ? 0 : `1.5px solid ${border}`)};
  position: relative;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center;
  path {
    stroke: ${({ load, background, colorText }) =>
      !load ? colorText : background};
  }
  svg {
    width: ${({ iconSize }) => iconSize};
    height: ${({ iconSize }) => iconSize};
  }
  &:disabled,
  &[disabled] {
    opacity: 0.5;
    cursor: default;
  }

  &:active:enabled {
    background-color: ${({ activeBackground }) => activeBackground};
    color: ${({ load, activeBackground, colorText }) =>
      !load ? colorText : activeBackground};
    border-color: ${({ activeBorder }) => activeBorder};

    path {
      stroke: ${({ load, activeBackground, colorText }) =>
        !load ? colorText : activeBackground};
    }
  }

  &:hover:enabled {
    background-color: ${({ activeBackground }) => activeBackground};
    color: ${({ load, activeBackground, colorText }) =>
      !load ? colorText : activeBackground};
    border-color: ${({ activeBorder }) => activeBorder};

    path {
      stroke: ${({ load, activeBackground, colorText }) =>
        !load ? colorText : activeBackground};
    }
  }
`;

export const StyledText = styled.div`
  font-family: Sora;
  font-style: normal;
  font-weight: bold;
  font-size: 0.75rem;
  line-height: 0.9375rem;

  align-self: center;
`;

export const LoaderComponent = styled(Loader)`
  padding: ${({ padding }) => padding};
`;
