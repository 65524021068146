import React, { useState } from "react";
import Checkbox from "../../../../../Checkbox";
import { Item, DivIcon, Title, Input } from "./styles";
import TripleVector from "../../../../../../../assets/icons/TripleVector";
import Close from "../../../../../../../assets/icons/Close";

const ListItem = ({ item, setSubtasks }) => {
  const [editMode, setEditMode] = useState(false);

  const handleDelete = () =>
    setSubtasks((prev) => prev.filter((e) => e !== item));

  const handleAssign = () =>
    setSubtasks((els) =>
      els.map((e) => (e === item ? { ...e, checked: !item.checked } : e))
    );

  const handleInputValue = (e) =>
    setSubtasks((els) =>
      els.map((el) => (el === item ? { ...el, title: e.target.value } : el))
    );

  const handleEditMode = () => setEditMode((prev) => !prev);

  const handleKeyDown = (e) => {
    return e.key === "Enter" && setEditMode(false);
  };

  return (
    <>
      <Item>
        <DivIcon>
          <TripleVector onClick={handleEditMode} />
        </DivIcon>
        <Checkbox checked={item.checked} onChange={handleAssign} />
        {editMode ? (
          <Input
            maxLength={35}
            value={item.title}
            type="text"
            onChange={handleInputValue}
            onKeyDown={handleKeyDown}
          />
        ) : (
          <Title>{item.title}</Title>
        )}

        <Close onClick={handleDelete} />
      </Item>
    </>
  );
};

export default ListItem;
