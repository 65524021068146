import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  width: 5.274375rem;
  height: 2.470625rem;
  display: flex;
  align-items: center;
  justify-content: space-around;

  box-shadow: 5px 1.88249px 7.52994px rgba(88, 94, 102, 0.25);
  border-radius: 2.82373px;
  background: #fff;

  z-index: 5;
  bottom: 1.25rem;
  left: calc(50% - 2.6371875rem);

  svg {
    width: 1.17625rem;
    height: 1.17625rem;
    cursor: pointer;
  }
`;
