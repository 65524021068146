import styled from "styled-components";

export const EventStart = styled.div`
  width: 50px;
  height: 50px;
  background: #2da919;
  box-shadow: ${({ selected }) =>
    selected === true ? "0 0 0 1px blue" : " 0 0 0 0.5px #1a192b"};
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: ${({ status, criticalPath }) =>
    status === "done"
      ? "#beefbe"
      : status === "doing"
      ? "#f2f0a6"
      : criticalPath === true
      ? "red"
      : "white"};
`;
