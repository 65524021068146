import React from "react";

const Add = ({ style }) => (
  <svg
    style={style}
    width="13"
    height="14"
    viewBox="2.9 2.5 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.63379 6.35474H9.93763"
      stroke="#7E858F"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.78589 9.50672V3.20288"
      stroke="#7E858F"
      strokeWidth="0.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default Add;
