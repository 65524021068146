import React, { memo } from "react";
import { Handle, useStoreState, Position } from "react-flow-renderer";

import { HandleStyle, ParallelEnd, ParallelEndInside } from "./styles.js";
import Add from "../../assets/icons/Add";

const ParallelEndNode = ({
  id,
  data,
  targetPosition = Position.Left,
  sourcePosition = Position.Right,
  selected,
}) => {
  const edges = useStoreState((state) => state.edges);
  const nodes = useStoreState((state) => state.nodes);

  const AddStyle = {
    transform: "rotate(45deg)",
    alignSelf: "center",
    width: "22px",
    height: "22px",
  };

  return (
    <ParallelEnd
      status={data.status}
      selected={selected}
      className="flow_elements_node"
      data-id={id}
    >
      <ParallelEndInside selected={selected}>
        <Add style={AddStyle} />
      </ParallelEndInside>

      <HandleStyle
        id="b"
        type="target"
        position={targetPosition}
        style={{ top: 0 }}
        selected={selected}
        isValidConnection={(e) =>
          nodes.find((item) => item.id === e.source).type === "parallel" ||
          nodes.find((item) => item.id === e.source).type === "conditional"
            ? true
            : !edges.find((item) => item.source === e.source)
        }
      />

      <HandleStyle
        type="source"
        position={sourcePosition}
        selected={selected}
        id="a"
        style={{
          top: "100%",
        }}
        isValidConnection={(e) =>
          nodes.find((item) => item.id === e.target).type === "parallelEnd" ||
          nodes.find((item) => item.id === e.target).type === "conditionalEnd"
            ? !edges.find((item) => item.source === e.source)
            : !edges.find(
                (item) => item.source === e.source || item.target === e.target
              )
        }
      />
    </ParallelEnd>
  );
};

export default memo(ParallelEndNode);
