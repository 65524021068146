import React, { useState } from "react";
import { Content, CriticalButton, IconDiv } from "./styles";
import TrendDown from "../../../assets/icons/TrendDown";
import Help from "./HelpDialog";
import CriticalDialog from "./CriticalDialog";
import Alert from "../Alert";
import { handleValidation } from "../../../utils";

const Critical = ({
  elements,
  version,
  critical,
  setCritical = () => console.log("critical"),
  value,
  selectFlow,
  setIsSelectable,
}) => {
  const [help, setHelp] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [typeAlert, setTypeAlert] = useState("error");
  const [errorMsg, setErrorMsg] = useState("");

  async function clearSelectable() {
    await selectFlow([]);
    await setIsSelectable(true);
  }

  const validation = () =>
    handleValidation(elements, setOpenAlert, setErrorMsg, setTypeAlert);

  return (
    <>
      <Content>
        <CriticalButton
          critical={critical}
          onClick={() => {
            clearSelectable().then(() => {
              if (!critical) {
                if (validation()) setCritical();
              } else {
                setCritical();
              }
            });
          }}
        >
          <IconDiv critical={critical}>
            <TrendDown />
          </IconDiv>
        </CriticalButton>
        {help && <Help setHelp={() => setHelp(false)} />}
        {critical && value && (
          <CriticalDialog value={value} setCritical={setCritical} />
        )}
      </Content>
      <Alert
        open={openAlert}
        setOpen={(e) => setOpenAlert(e)}
        version="none"
        type="error"
        errorMsg={errorMsg}
        selector="#root"
      />
    </>
  );
};

export default Critical;
