import React, { useEffect, useRef, useState } from "react";
import EditIcon from "../../../assets/icons/EditIcon";

import {
  Container,
  IconDiv,
  LogoZaivu,
  ProjectName,
  VerticalLine,
  Input,
} from "./styles";
import LogoZaivuWhite from "../../../assets/Logo-Zaivu-White.svg";
import DropSelect from "./DropSelect";

import { useHistory } from "react-router-dom";
import PushBackIcon from "../../../assets/icons/PushBackIcon";
import { useOutsideDrop } from "../../hooks/useOutsideDrop";

const DropSelectVersion = ({
  List = [],
  version = "Versão",
  setVersion,
  selector,
  title = "Projeto",
  setTitle,
  setCritical,
}) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  const clearInput = () => setOpen(false);
  const history = useHistory();

  const handleKeyDown = (event) => {
    if (event.key === "Enter") setOpen(false);
  };
  useOutsideDrop(wrapperRef, clearInput);

  const pushBackStyle = {
    height: "1.75rem",
    width: "1.75rem",
    marginLeft: "1.2rem",
    cursor: "pointer",
  };

  return (
    <>
      <Container>
        <PushBackIcon style={pushBackStyle} />
        <LogoZaivu src={LogoZaivuWhite} style={{ cursor: "pointer" }} />

        <ProjectName openInput={open}>
          {!open ? (
            <>
              <span>{title}</span>
              <IconDiv
                onClick={(event) => {
                  event.stopPropagation();
                  setOpen(true);
                }}
              >
                <EditIcon />
              </IconDiv>
            </>
          ) : (
            <Input
              ref={wrapperRef}
              maxLength="40"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onKeyPress={handleKeyDown}
            ></Input>
          )}
        </ProjectName>

        {selector && (
          <>
            <VerticalLine />
            <DropSelect
              List={List}
              version={version}
              setVersion={setVersion}
              setCritical={setCritical}
            />
          </>
        )}
      </Container>
    </>
  );
};

export default DropSelectVersion;
