import React from "react";

const EyeSlash = ({ color = "#a9aeb4", className, style }) => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    style={style}
  >
    <path
      d="M10.8976 7.10244L7.10256 10.8974C6.61506 10.4099 6.31506 9.74244 6.31506 8.99994C6.31506 7.51494 7.51506 6.31494 9.00006 6.31494C9.74256 6.31494 10.4101 6.61494 10.8976 7.10244Z"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.365 4.32761C12.0525 3.33761 10.5525 2.79761 9 2.79761C6.3525 2.79761 3.885 4.35761 2.1675 7.05761C1.4925 8.11511 1.4925 9.89261 2.1675 10.9501C2.76 11.8801 3.45 12.6826 4.2 13.3276"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.31506 14.6475C7.17006 15.0075 8.07756 15.2025 9.00006 15.2025C11.6476 15.2025 14.1151 13.6425 15.8326 10.9425C16.5076 9.88501 16.5076 8.10751 15.8326 7.05001C15.5851 6.66001 15.3151 6.29251 15.0376 5.94751"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11.6325 9.5249C11.4375 10.5824 10.575 11.4449 9.51746 11.6399"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.1025 10.8975L1.5 16.5"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.5001 1.5L10.8976 7.1025"
      stroke={color}
      strokeWidth="1.2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default EyeSlash;
