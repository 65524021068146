import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Form, Input } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleNewUserVerification } from "../../state/auth/actions";
import { handleSignUp } from "../../state/users/actions";
import { BoxFormWithHeader, LoginContainer } from "./styles";
import Loading from "../../components/LoadingTemplate/index";

const NewAccountPage = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const [userError, setUserError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [rePasswordError, setRePasswordError] = useState(false);
  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const resetToken = match.params.token;
  const [validToken, setValidToken] = useState("loading");
  const [enterpriseName, setEnterpriseName] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token !== null)
      user.rank === "Gerente"
        ? history.push("/")
        : history.push({
            pathname: "/",
            search:
              "?" +
              new URLSearchParams({
                title: "",
                flowTitle: "",
                client: "",
                status: "all",
                flowType: "all",
              }).toString(),
          });
  }, [history, token]);

  useEffect(() => {
    dispatch(handleNewUserVerification({ token: resetToken })).then((res) => {
      if (res.payload.error) setValidToken(false);
      else {
        formik.setFieldValue("email", res.payload.email);
        formik.setFieldValue("username", res.payload.username);
        setEnterpriseName(res.payload.enterpriseName);

        setValidToken(true);
      }
    });
  }, []);

  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
      rePassword: "",
    },
    onSubmit: (values) => {
      if (
        values.username === "" ||
        values.password === "" ||
        values.rePassword === "" ||
        values.rePassword !== values.password ||
        values.email === ""
      ) {
        if (values.username === "") setUserError(true);
        if (values.password === "") setPasswordError(true);
        if (values.rePassword === "") setRePasswordError(true);
        if (values.email === "") setEmailError(true);
        if (values.rePassword !== values.password)
          setError("Senhas não coincidem");
      } else {
        setLoading(true);
        dispatch(
          handleSignUp({
            username: values.username,
            password: values.password,
            email: values.email,
          })
        ).then((res) => {
          if (res.type === "ADD_ERROR") {
            setError("Apelido já existente");
          } else {
            history.push("/login");
          }
          setLoading(false);
        });
      }
    },
  });

  return validToken === true && !loading ? (
    <BoxFormWithHeader>
      <div>
        <Form onSubmit={formik.handleSubmit}>
          <LoginContainer>
            <h3>Bem vindo à {enterpriseName}, finalize seu cadastro aqui!</h3>

            <div
              style={{
                display: "flex",
                flexDirection: "column",

                padding: "10px",
                borderRadius: "5px",
                width: "100%",
              }}
            >
              <label>Email (já escolhido)</label>
              <Input
                icon="mail"
                iconPosition="left"
                error={emailError}
                type="email"
                name="email"
                style={{ marginBottom: "10px" }}
                placeholder="Email"
                onChange={formik.handleChange}
                value={formik.values.email}
                disabled
              />

              <label>Nome de usuário</label>
              <Input
                icon="user"
                iconPosition="left"
                type="text"
                style={{ marginBottom: "10px" }}
                name="username"
                error={userError}
                placeholder="Nome de usuário"
                onChange={formik.handleChange}
                value={formik.values.username}
              />

              <label>Senha</label>
              <Input
                icon="lock"
                iconPosition="left"
                error={passwordError}
                type="password"
                style={{ marginBottom: "10px" }}
                name="password"
                placeholder="Senha"
                onChange={formik.handleChange}
                value={formik.values.password}
              />

              <label>Confirmar senha</label>
              <Input
                icon="lock"
                iconPosition="left"
                error={rePasswordError}
                type="password"
                name="rePassword"
                style={{ marginBottom: "10px" }}
                placeholder="Confirmar senha"
                onChange={formik.handleChange}
                value={formik.values.rePassword}
              />

              {error && (
                <p
                  style={{
                    color: "red",
                    alignSelf: "center",
                    marginBottom: "10px",
                  }}
                >
                  {error}
                </p>
              )}

              <button className="ui button" type="submit">
                Cadastrar
              </button>
            </div>
          </LoginContainer>
        </Form>
      </div>
    </BoxFormWithHeader>
  ) : validToken === false && !loading ? (
    <BoxFormWithHeader>
      <div>Convite inválido ou expirado</div>
    </BoxFormWithHeader>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

export default NewAccountPage;
