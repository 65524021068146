import React from "react";

const TextIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7527 0.619346V5.66221H16.258C15.9649 4.49882 15.6397 3.66522 15.2824 3.16139C14.9251 2.6484 14.4351 2.24076 13.8121 1.93846C13.464 1.77357 12.8549 1.69113 11.9846 1.69113H10.5968V16.064C10.5968 17.0167 10.6472 17.6121 10.748 17.8503C10.8579 18.0884 11.064 18.2991 11.3663 18.4823C11.6777 18.6564 12.0991 18.7434 12.6304 18.7434H13.2488V19.2518H3.49283V18.7434H4.11116C4.65163 18.7434 5.08676 18.6472 5.41653 18.4549C5.65471 18.3266 5.8425 18.1068 5.97991 17.7953C6.08067 17.5755 6.13105 16.9983 6.13105 16.064V1.69113H4.78446C3.52947 1.69113 2.618 1.95678 2.05005 2.48809C1.25308 3.23009 0.749253 4.28813 0.538561 5.66221H0.0164117V0.619346H16.7527Z"
        fill="#7E858F"
      />
    </svg>
  );
};

export default TextIcon;
