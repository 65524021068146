import { createAction } from "@reduxjs/toolkit";
import api from "../../services/api";

export const signIn = createAction("SIGN_IN");
export const signOut = createAction("SIGN_OUT");
export const addError = createAction("ADD_ERROR");
export const updateToken = createAction("UPDATE_TOKEN");
export const changeUser = createAction("CHANGE_USER");
export const resetPassword = createAction("RESET_PASSWORD");
export const newPassword = createAction("NEW_PASSWORD");
export const editUsername = createAction("EDIT_USERNAME");
export const editPassword = createAction("EDIT_PASSWORD");
export const editEmail = createAction("EDIT_EMAIL");
export const newUserVerification = createAction("NEW_USER_VERIFICATION");
export const editEnterpriseName = createAction("EDIT_ENTERPRISE_NAME");

export const handleSignIn = ({ username, password }) => {
  return (dispatch) =>
    api
      .post("/auth/sign-in", { login: username, password })
      .then((res) => dispatch(signInSucess(res.data)))
      .catch((err) => dispatch(signInFailure(err.message)));
};

const signInSucess = (payload) => signIn(payload);

const signInFailure = (error) => addError(error);

export const handleResetPassword = (email) => {
  return (dispatch) =>
    api
      .put("/auth/reset-password-email", { email })
      .then((res) => dispatch(resetPasswordSucess(res.data)))
      .catch((err) => dispatch(resetPasswordFailure(err.message)));
};

const resetPasswordSucess = (payload) => resetPassword(payload);

const resetPasswordFailure = (error) => resetPassword(error);

export const handleNewPassword = ({ password, resetToken }) => {
  return (dispatch) =>
    api
      .put("/auth/new-password", { password, resetToken })
      .then((res) => dispatch(newPasswordSucess(res.data)))
      .catch((err) => dispatch(newPasswordFailure(err.message)));
};

const newPasswordSucess = (payload) => newPassword(payload);

const newPasswordFailure = (error) => newPassword(error);

export const handleEditUsername = ({ username, id }) => {
  return (dispatch) =>
    api
      .put("/auth/edit-username", { username, id })
      .then((res) => dispatch(editUsernameSucess(res.data, username)))
      .catch((err) => dispatch(editUsernameFailure(err.message)));
};

const editUsernameSucess = (payload) => editUsername(payload);

const editUsernameFailure = (error) => editUsername(error);

export const handleEditEnterpriseName = ({ enterpriseName, id }) => {
  return (dispatch) =>
    api
      .put("/auth/edit-enterprise-name", { enterpriseName, id })
      .then((res) =>
        dispatch(editEnterpriseNameSucess(res.data, enterpriseName))
      )
      .catch((err) => dispatch(editEnterpriseNameFailure(err.message)));
};

const editEnterpriseNameSucess = (payload) => editEnterpriseName(payload);

const editEnterpriseNameFailure = (error) => editEnterpriseName(error);

export const handleEditPassword = ({ oldPass, newPass, id }) => {
  return (dispatch) =>
    api
      .put("/auth/edit-password", { oldPass, newPass, id })
      .then((res) => dispatch(editPasswordSucess(res.data)))
      .catch((err) => dispatch(editPasswordFailure(err.message)));
};

const editPasswordSucess = (payload) => editPassword(payload);

const editPasswordFailure = (error) => editPassword(error);

export const handleEditEmail = ({ email, id }) => {
  return (dispatch) =>
    api
      .put("/auth/edit-email", { email, id })
      .then((res) => dispatch(editEmailSucess(res.data)))
      .catch((err) => dispatch(editEmailFailure(err.message)));
};

const editEmailSucess = (payload) => editEmail(payload);

const editEmailFailure = (error) => editEmail(error);

export const handleNewUserVerification = ({ token }) => {
  return (dispatch) =>
    api
      .get("/auth/validate-token/" + token)
      .then((res) => dispatch(newUserVerificationSucess(res.data)))
      .catch((err) => dispatch(newUserVerificationFailure(err.message)));
};
const newUserVerificationSucess = (payload) => newUserVerification(payload);
const newUserVerificationFailure = (error) => newUserVerification(error);
