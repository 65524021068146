import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchActivedFlows } from "../../state/activedFlows/reducers";
import {
  Button,
  Confirm,
  Icon,
  Input,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import moment from "moment";
import { ContainerCenter, Dashboard } from "./styles";
import NewActivedFlowModal from "./NewActivedFlowModal/index";
import {
  handleRemoveActivedFlow,
  handleSearch,
} from "../../state/activedFlows/actions";
import Loading from "../../components/LoadingTemplate";

let highTask = {};
let advancedStats = [];
///////FUNÇÕES DO GRÁFICO

//? Bloco 1

//Ramificaçao Principal
function count(
  elements,
  item = { type: "eventEnd" },
  branchTimeStart = 0,
  doneTimeStart = 0,
  donePreventTimeStart = 0,
  statsStart = [advancedStats[0]],
  realStatsStart = [advancedStats[0]],
  task = {}
) {
  let realStats = realStatsStart;
  let stats = statsStart;
  let branchTime = branchTimeStart;
  let donePreventTime = donePreventTimeStart;
  let doneTime = doneTimeStart;

  const nowLocal = moment().utcOffset(-180);

  //////////SE FOR ARESTA
  if (item.source) {
    return count(
      elements,
      elements.find((el) => el.id === item.target),
      branchTime,
      doneTime,
      donePreventTime,
      stats,
      realStats,
      task
    );
  }
  //////////SE FOR NÓ
  else {
    if (item.type === "task" || item.type === "timerEvent") {
      if (item.type === "timerEvent") {
        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        stats.push({
          x: stats[stats.length - 1].x + item.data.expiration.number / 24,
          y: stats[stats.length - 1].y - item.data.expiration.number / 24,
          taskTitle: "TEMPORIZADOR",
          start: moment(stats[stats.length - 1].finish),
          finish: moment(stats[stats.length - 1].finish).add(
            item.data.expiration.number,
            "hours",
            true
          ),
          time: item.data.expiration.number / 24,
          taskId: item._id,
          initialResource: stats[stats.length - 1].y,
        });
      }

      if (item.type === "task") {
        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        stats.push({
          x: stats[stats.length - 1].x + item.data.expiration.number / 24,
          y: stats[stats.length - 1].y - item.data.expiration.number / 24,
          taskTitle: item.data.label,
          start: moment(stats[stats.length - 1].finish),
          finish: moment(stats[stats.length - 1].finish).add(
            item.data.expiration.number,
            "hours",
            true
          ),
          time: item.data.expiration.number / 24,
          taskId: item._id,
          initialResource: stats[stats.length - 1].y,
        });
      }

      if (item.data.status === "done") {
        if (item.type === "timerEvent") {
          donePreventTime += item.data.expiration.number;
          doneTime += item.data.expiration.number;
        } else {
          realStats.sort(function (a, b) {
            return Number(a.x) - Number(b.x);
          });

          realStats.push({
            x:
              realStats[realStats.length - 1].x +
              parseFloat(
                Number(
                  moment(item.data.finishedAt).diff(
                    moment(item.data.startedAt),
                    "days",
                    true
                  )
                )
              ),
            y:
              realStats[realStats.length - 1].y -
              item.data.expiration.number / 24,
            taskTitle: item.data.label,
            taskId: item._id,
            start: item.data.startedAt,
            finish: item.data.finishedAt,
          });

          donePreventTime += item.data.expiration.number;
          doneTime += moment(item.data.finishedAt).diff(
            moment(item.data.startedAt),
            "hours",
            true
          );
        }
      } else if (item.data.status === "doing") {
        if (
          moment(item.data.startedAt)
            .add(item.data.expiration.number, "hours")
            .diff(nowLocal) < 0 &&
          item.type === "task"
        ) {
          branchTime += moment(nowLocal).diff(
            moment(item.data.startedAt),
            "hours",
            true
          );
          doneTime += moment(nowLocal).diff(
            moment(item.data.startedAt),
            "hours",
            true
          );
          donePreventTime += item.data.expiration.number;
        } else {
          branchTime += item.data.expiration.number;
        }

        return count(
          elements,
          elements.find((e) => e.source === item.id),
          branchTime,
          doneTime,
          donePreventTime,
          stats,
          realStats,
          item
        );
      } else if (item.data.status === "pending") {
        branchTime += item.data.expiration.number;
      }
      return count(
        elements,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        stats,
        realStats,
        task
      );
    } else {
      if (item.type === "eventEnd") {
        highTask = task;
        return { branchTime, doneTime, donePreventTime, stats, realStats };
      } else if (
        item.type === "parallel" ||
        (item.type === "conditional" && item.data.status !== "done")
      ) {
        /////VERIFICA O MAIOR CAMINHO E RETORNA O ID FINAL
        const finishBranchItem = branchCount(elements, item, stats, realStats);

        branchTime += finishBranchItem.max;
        doneTime += finishBranchItem.maxDone;
        donePreventTime += finishBranchItem.maxPreventDone;

        finishBranchItem.stats.forEach((item) => {
          stats.push(item);
        });

        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        finishBranchItem.realStats.forEach((item) => {
          realStats.push(item);
        });

        realStats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        return count(
          elements,
          elements.find((e) => e.source === finishBranchItem.lastItem.id),
          branchTime,
          doneTime,
          donePreventTime,
          stats,
          realStats,
          finishBranchItem.task
        );
      } else {
        return count(
          elements,
          elements.find((e) =>
            item.type === "conditional"
              ? e.source === item.id && e.data.status === "done"
              : e.source === item.id
          ),
          branchTime,
          doneTime,
          donePreventTime,
          stats,
          realStats,
          task
        );
      }
    }
  }
}
//Contagem das ramificações
function branchCount(elements, item = {}, stat = [{ jurubebe: 12 }], realStat) {
  let max = 0;
  let maxDone = 0;
  let task;
  let maxPreventDone = 0;
  const allBranchs = elements.filter((e) => e.source === item.id);

  const times = allBranchs.map((e) => {
    const obj = checkBranch(elements, e, 0, 0, 0, [], stat, [], realStat);

    return obj;
  });

  let bestBranch = [];
  let bestRealBranch = [];

  times.forEach((el, index) => {
    if (index + 1 <= times.length) {
      if (el.branchTime >= max) {
        task = el.task;
      }

      max = Math.max(el.branchTime, max);
      maxDone = Math.max(el.doneTime, maxDone);
      maxPreventDone = Math.max(el.donePreventTime, maxPreventDone);

      if (item.type === "conditional") {
        if (max === el.branchTime) {
          bestBranch = el.stats;
          bestRealBranch = el.realStats;
        }
      } else {
        el.stats.forEach((item) => {
          bestBranch.push(item);
        });
        el.realStats.forEach((item) => {
          bestRealBranch.push(item);
        });
      }
    }
  });

  return {
    max,
    lastItem: times[0].lastItem,
    maxDone,
    maxPreventDone,
    stats: bestBranch,
    realStats: bestRealBranch,
    task,
  };
}
//Ramificações
function checkBranch(
  elements,
  item = {},
  branchTimeStart = 0,
  doneTimeStart = 0,
  donePreventTimeStart = 0,
  statsStart = [],
  advancedStats = [{ sad: 22 }],
  realStatsStart = [],
  realStat,
  task = {}
) {
  let realStats = realStatsStart;
  let stats = statsStart;
  let branchTime = branchTimeStart;
  let donePreventTime = donePreventTimeStart;
  let doneTime = doneTimeStart;
  const nowLocal = moment().utcOffset(-180);

  ////SE FOR ARESTA
  if (item.source) {
    return checkBranch(
      elements,
      elements.find((el) => el.id === item.target),
      branchTime,
      doneTime,
      donePreventTime,
      stats,
      advancedStats,
      realStats,
      realStat,
      task
    );
  }
  ///////SE FOR NO
  else {
    if (item.type === "task" || item.type === "timerEvent") {
      if (item.type === "timerEvent") {
        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });
        stats.push(
          stats.length > 0
            ? {
                x: stats[stats.length - 1].x + item.data.expiration.number / 24,
                y: stats[stats.length - 1].y - item.data.expiration.number / 24,
                taskTitle: "TEMPORIZADOR",
                start: moment(stats[stats.length - 1].finish),
                finish: moment(stats[stats.length - 1].finish).add(
                  item.data.expiration.number,
                  "hours",
                  true
                ),
                time: item.data.expiration.number / 24,
                taskId: item._id,
                initialResource: stats[stats.length - 1].y,
              }
            : {
                x:
                  advancedStats[advancedStats.length - 1]?.x +
                  item.data.expiration.number / 24,
                y:
                  advancedStats[advancedStats.length - 1]?.y -
                  item.data.expiration.number / 24,
                taskTitle: "TEMPORIZADOR",
                start: moment(advancedStats[advancedStats.length - 1]?.finish),
                finish: moment(
                  advancedStats[advancedStats.length - 1]?.finish
                ).add(item.data.expiration.number, "hours", true),
                time: item.data.expiration.number / 24,
                taskId: item._id,
                initialResource: advancedStats[advancedStats.length - 1].y,
              }
        );
      }
      if (item.type === "task") {
        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        stats.push(
          stats.length > 0
            ? {
                x: stats[stats.length - 1].x + item.data.expiration.number / 24,
                y: stats[stats.length - 1].y - item.data.expiration.number / 24,
                taskTitle: item.data.label,
                start: moment(stats[stats.length - 1].finish),
                finish: moment(stats[stats.length - 1].finish).add(
                  item.data.expiration.number,
                  "hours",
                  true
                ),
                time: item.data.expiration.number / 24,
                taskId: item._id,
                initialResource: stats[stats.length - 1].y,
              }
            : {
                x:
                  advancedStats[advancedStats.length - 1]?.x +
                  item.data.expiration.number / 24,
                y:
                  advancedStats[advancedStats.length - 1]?.y -
                  item.data.expiration.number / 24,
                taskTitle: item.data.label,
                start: moment(advancedStats[advancedStats.length - 1]?.finish),
                finish: moment(
                  advancedStats[advancedStats.length - 1]?.finish
                ).add(item.data.expiration.number, "hours", true),
                time: item.data.expiration.number / 24,
                taskId: item._id,
                initialResource: advancedStats[advancedStats.length - 1]?.y,
              }
        );
      }
      if (item.data.status === "done") {
        if (item.type === "timerEvent") {
          donePreventTime += item.data.expiration.number;
          doneTime += item.data.expiration.number;
        } else {
          realStats.sort(function (a, b) {
            return Number(a.x) - Number(b.x);
          });

          realStats.push(
            realStats.length > 0
              ? {
                  x:
                    realStats[realStats.length - 1].x +
                    parseFloat(
                      Number(
                        moment(item.data.finishedAt).diff(
                          moment(item.data.startedAt),
                          "days",
                          true
                        )
                      )
                    ),
                  y:
                    realStats[realStats.length - 1].y -
                    item.data.expiration.number / 24,
                  taskTitle: item.data.label,
                  taskId: item._id,
                  start: item.data.startedAt,
                  finish: item.data.finishedAt,
                }
              : {
                  x:
                    realStat[realStat.length - 1].x +
                    parseFloat(
                      Number(
                        moment(item.data.finishedAt).diff(
                          moment(item.data.startedAt),
                          "days",
                          true
                        )
                      )
                    ),
                  y:
                    realStat[realStat.length - 1].y -
                    item.data.expiration.number / 24,
                  taskTitle: item.data.label,
                  taskId: item._id,
                  start: item.data.startedAt,
                  finish: item.data.finishedAt,
                }
          );

          donePreventTime += item.data.expiration.number;
          doneTime += moment(item.data.finishedAt).diff(
            moment(item.data.startedAt),
            "hours",
            true
          );
        }
      }
      if (item.data.status === "pending") {
        branchTime += item.data.expiration.number;
      }
      if (item.data.status === "doing") {
        if (
          moment(item.data.startedAt)
            .add(item.data.expiration.number, "hours", true)
            .diff(nowLocal, "hours", true) < 0 &&
          item.type === "task"
        ) {
          branchTime += moment(nowLocal).diff(
            moment(item.data.startedAt),
            "hours",
            true
          );
          doneTime += moment(nowLocal).diff(
            moment(item.data.startedAt),
            "hours",
            true
          );
          donePreventTime += item.data.expiration.number;
        } else {
          branchTime += item.data.expiration.number;
        }

        return checkBranch(
          elements,
          elements.find((e) => e.source === item.id),
          branchTime,
          doneTime,
          donePreventTime,
          stats,
          advancedStats,
          realStats,
          realStat,
          item
        );
      }

      return checkBranch(
        elements,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        stats,
        advancedStats,
        realStats,
        realStat,
        task
      );
    } else if (item.type === "parallel" || item.type === "conditional") {
      const branchCountt = branchCount(
        elements,
        item,
        stats.length === 0 ? advancedStats : stats,
        realStat
      );

      branchTime += branchCountt.max;
      doneTime += branchCountt.maxDone;
      donePreventTime += branchCountt.maxPreventDone;

      branchCountt.stats.forEach((item) => {
        stats.push(item);
      });

      stats.sort(function (a, b) {
        return Number(a.x) - Number(b.x);
      });

      branchCountt.realStats.forEach((item) => {
        realStats.push(item);
      });

      realStats.sort(function (a, b) {
        return Number(a.x) - Number(b.x);
      });

      return checkBranch(
        elements,
        elements.find((e) => e.source === branchCountt.lastItem.id),
        branchTime,
        doneTime,
        donePreventTime,
        stats,
        advancedStats,
        realStats,
        realStat,
        branchCountt.task
      );
    } else if (item.type === "parallelEnd" || item.type === "conditionalEnd") {
      return {
        branchTime,
        lastItem: item,
        doneTime,
        donePreventTime,
        stats,
        realStats,
        task,
      };
    }
    /// SE NAO FOR NADA DISSO SÓ CONTINUA ANDANDO
    else
      return checkBranch(
        elements,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        stats,
        advancedStats,
        realStats,
        realStat,
        task
      );
  }
}
//? Bloco 2
//Ramificação Principal
function flowTime(elements, item = { type: "eventEnd" }, branchTimeStart = 0) {
  let branchTime = branchTimeStart;

  //////////SE FOR ARESTA
  if (item.source) {
    return flowTime(
      elements,
      elements.find((el) => el.id === item.target),
      branchTime
    );
  }
  //////////SE FOR NÓ
  else {
    if (item.type === "task" || item.type === "timerEvent") {
      branchTime += item.data.expiration.number;

      if (item.data.status === "doing") {
        return flowTime(
          elements,
          elements.find((e) => e.source === item.id),
          branchTime
        );
      }
      return flowTime(
        elements,
        elements.find((e) => e.source === item.id),
        branchTime
      );
    } else {
      if (item.type === "eventEnd") {
        return { branchTime };
      } else if (
        item.type === "parallel" ||
        (item.type === "conditional" && item.data.status !== "done")
      ) {
        /////VERIFICA O MAIOR CAMINHO E RETORNA O ID FINAL
        const finishBranchItem = branchCountEntireTime(elements, item);

        branchTime += finishBranchItem.max;
        return flowTime(
          elements,
          elements.find((e) => e.source === finishBranchItem.lastItem.id),
          branchTime
        );
      } else {
        //eventStart...
        return flowTime(
          elements,
          elements.find((e) =>
            item.type === "conditional"
              ? e.source === item.id && e.data.status === "done"
              : e.source === item.id
          ),
          branchTime
        );
      }
    }
  }
}
//Contagem das ramificações
function branchCountEntireTime(elements, item = {}) {
  let max = 0;
  const allBranchs = elements.filter((e) => e.source === item.id);
  const times = allBranchs.map((e) => {
    const obj = checkBranchEntireTime(elements, e);

    return obj;
  });
  times.forEach((el, index) => {
    if (index + 1 <= times.length) {
      max = Math.max(el.branchTime, max);
    }
  });

  return { max, lastItem: times[0].lastItem };
}
//Ramificações
function checkBranchEntireTime(elements, item = {}, branchTimeStart = 0) {
  let branchTime = branchTimeStart;
  ////SE FOR ARESTA
  if (item.source) {
    return checkBranchEntireTime(
      elements,
      elements.find((el) => el.id === item.target),
      branchTime
    );
  }
  ///////SE FOR NO
  else {
    if (item.type === "task" || item.type === "timerEvent") {
      branchTime += item.data.expiration.number;

      if (item.data.status === "doing") {
        return checkBranchEntireTime(
          elements,
          elements.find((e) => e.source === item.id),
          branchTime
        );
      } else
        return checkBranchEntireTime(
          elements,
          elements.find((e) => e.source === item.id),
          branchTime
        );
    } else if (item.type === "parallel" || item.type === "conditional") {
      const branchCount = branchCountEntireTime(elements, item);

      branchTime += branchCount.max;

      return checkBranchEntireTime(
        elements,
        elements.find((e) => e.source === branchCount.lastItem.id),
        branchTime
      );
    } else if (item.type === "parallelEnd" || item.type === "conditionalEnd") {
      return { branchTime, lastItem: item };
    }
    /// SE NAO FOR NADA DISSO SÓ CONTINUA ANDANDO
    else
      return checkBranchEntireTime(
        elements,
        elements.find((e) => e.source === item.id),
        branchTime
      );
  }
}

//? Bloco 3
//Ramificação principal
function graphCountTime(
  elements,
  createdAt,
  actualDay,
  item = { type: "eventEnd" },
  branchTimeStart = 0,
  doneTimeStart = 0,
  donePreventTimeStart = 0,
  task = {}
) {
  let branchTime = branchTimeStart;
  let donePreventTime = donePreventTimeStart;
  let doneTime = doneTimeStart;

  if (item.source) {
    return graphCountTime(
      elements,
      createdAt,
      actualDay,
      elements.find((el) => el.id === item.target),
      branchTime,
      doneTime,
      donePreventTime,
      task
    );
  } else {
    if (item.type === "task" || item.type === "timerEvent") {
      if (item.data.status === "done") {
        const verificationDone = moment(item.data.finishedAt)
          .startOf("day")
          .isSameOrBefore(
            moment(createdAt).add(actualDay, "days").startOf("day")
          );
        if (item.type === "timerEvent") {
          if (verificationDone) {
            donePreventTime += item.data.expiration.number;
            doneTime += item.data.expiration.number;
          } else branchTime += item.data.expiration.number;
        } else {
          if (verificationDone) {
            donePreventTime += item.data.expiration.number;
            doneTime += moment(item.data.finishedAt).diff(
              moment(item.data.startedAt),
              "hours",
              true
            );
          } else {
            branchTime += item.data.expiration.number;
            if (Object.keys(task).length === 0) {
              return graphCountTime(
                elements,
                createdAt,
                actualDay,
                elements.find((e) => e.source === item.id),
                branchTime,
                doneTime,
                donePreventTime,
                item
              );
            }
          }
        }
      } else if (item.data.status === "doing") {
        branchTime += item.data.expiration.number;

        if (
          moment(item.data.startedAt)
            .startOf("day")
            .isSameOrBefore(
              moment(createdAt).add(actualDay, "days").startOf("day")
            )
        ) {
          return graphCountTime(
            elements,
            createdAt,
            actualDay,
            elements.find((e) => e.source === item.id),
            branchTime,
            doneTime,
            donePreventTime,
            item
          );
        }
      } else if (item.data.status === "pending") {
        branchTime += item.data.expiration.number;
      }
      return graphCountTime(
        elements,
        createdAt,
        actualDay,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        task
      );
    } else {
      if (item.type === "eventEnd") {
        highTask = task;

        return { branchTime, doneTime, donePreventTime };
      } else if (
        item.type === "parallel" ||
        (item.type === "conditional" && item.data.status !== "done")
      ) {
        /////VERIFICA O MAIOR CAMINHO E RETORNA O ID FINAL
        const finishBranchItem = graphBranchCountTime(
          elements,
          createdAt,
          actualDay,
          item
        );
        branchTime += finishBranchItem.max;
        doneTime += finishBranchItem.maxDone;
        donePreventTime += finishBranchItem.maxPreventDone;

        return graphCountTime(
          elements,
          createdAt,
          actualDay,
          elements.find((e) => e.source === finishBranchItem.lastItem.id),
          branchTime,
          doneTime,
          donePreventTime,
          finishBranchItem.task
        );
      } else {
        return graphCountTime(
          elements,
          createdAt,
          actualDay,
          elements.find((e) =>
            item.type === "conditional"
              ? e.source === item.id && e.data.status === "done"
              : e.source === item.id
          ),
          branchTime,
          doneTime,
          donePreventTime,
          task
        );
      }
    }
  }
}
//Contagem das ramificações
function graphBranchCountTime(elements, createdAt, actualDay, item = {}) {
  let max = 0;
  let maxDone = 0;
  let task;
  let maxPreventDone = 0;

  const allBranchs = elements.filter((e) => e.source === item.id);

  const times = allBranchs.map((e) => {
    const obj = graphCheckBranchTime(elements, createdAt, actualDay, e);

    return obj;
  });

  times.forEach((el, index) => {
    if (index + 1 <= times.length) {
      if (el.branchTime >= max) {
        task = el.task;
      }

      max = Math.max(el.branchTime, max);
      maxDone = Math.max(el.doneTime, maxDone);
      maxPreventDone = Math.max(el.donePreventTime, maxPreventDone);
    }
  });

  return { max, lastItem: times[0].lastItem, maxDone, maxPreventDone, task };
}
//Ramificações
function graphCheckBranchTime(
  elements,
  createdAt,
  actualDay,
  item = {},
  branchTimeStart = 0,
  doneTimeStart = 0,
  donePreventTimeStart = 0,
  task = {}
) {
  let branchTime = branchTimeStart;
  let donePreventTime = donePreventTimeStart;
  let doneTime = doneTimeStart;

  ////SE FOR ARESTA
  if (item.source) {
    return graphCheckBranchTime(
      elements,
      createdAt,
      actualDay,
      elements.find((el) => el.id === item.target),
      branchTime,
      doneTime,
      donePreventTime,
      task
    );
  }
  ///////SE FOR NO
  else {
    if (item.type === "task" || item.type === "timerEvent") {
      if (item.data.status === "done") {
        const verificationDone = moment(item.data.finishedAt)
          .startOf("day")
          .isSameOrBefore(
            moment(createdAt).add(actualDay, "days").startOf("day")
          );

        if (item.type === "timerEvent") {
          if (verificationDone) {
            donePreventTime += item.data.expiration.number;
            doneTime += item.data.expiration.number;
          } else branchTime += item.data.expiration.number;
        } else {
          if (verificationDone) {
            donePreventTime += item.data.expiration.number;
            doneTime += moment(item.data.finishedAt).diff(
              moment(item.data.startedAt),
              "hours",
              true
            );
          } else {
            branchTime += item.data.expiration.number;
            return graphCheckBranchTime(
              elements,
              createdAt,
              actualDay,
              elements.find((e) => e.source === item.id),
              branchTime,
              doneTime,
              donePreventTime,
              item
            );
          }
        }
      }
      if (item.data.status === "pending") {
        branchTime += item.data.expiration.number;
      }
      if (item.data.status === "doing") {
        branchTime += item.data.expiration.number;

        if (
          moment(item.data.startedAt)
            .startOf("day")
            .isSameOrBefore(
              moment(createdAt).add(actualDay, "days").startOf("day")
            )
        ) {
          return graphCheckBranchTime(
            elements,
            createdAt,
            actualDay,
            elements.find((e) => e.source === item.id),
            branchTime,
            doneTime,
            donePreventTime,
            item
          );
        }
      }

      return graphCheckBranchTime(
        elements,
        createdAt,
        actualDay,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        task
      );
    } else if (item.type === "parallel" || item.type === "conditional") {
      const branchCount = graphBranchCountTime(
        elements,
        createdAt,
        actualDay,
        item
      );

      branchTime += branchCount.max;
      doneTime += branchCount.maxDone;
      donePreventTime += branchCount.maxPreventDone;

      return graphCheckBranchTime(
        elements,
        createdAt,
        actualDay,
        elements.find((e) => e.source === branchCount.lastItem.id),
        branchTime,
        doneTime,
        donePreventTime,
        branchCount.task
      );
    } else if (item.type === "parallelEnd" || item.type === "conditionalEnd") {
      return {
        branchTime,
        lastItem: item,
        doneTime,
        donePreventTime,
        task,
      };
    }
    /// SE NAO FOR NADA DISSO SÓ CONTINUA ANDANDO
    else
      return graphCheckBranchTime(
        elements,
        createdAt,
        actualDay,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        item
      );
  }
}

const ActivedFlowsPage = ({ history }) => {
  const dispatch = useDispatch();
  const [titleSearch, setTitleSearch] = useState("");
  const [clientSearch, setClientSearch] = useState("");

  const pages = useSelector((state) => state.activedFlows.pages);
  const activedFlows = useSelector((state) => state.activedFlows.entities);

  const activedFlowsStatus = useSelector((state) => state.activedFlows.status);

  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const [open, setOpen] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [stats, setStats] = useState(false);
  const nowLocal = moment().utcOffset(-180);

  const [actualFlow, setActualFlow] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (activedFlowsStatus === "succeeded") {
      //&& stats === false
      setStats([]);
      activedFlows.forEach((flow) => {
        const start =
          flow && flow.elements.find((e) => e.type === "eventStart");

        const flowFullTime = flow && flowTime(flow.elements, start); //branch
        advancedStats = [
          {
            x: 0,
            y: flowFullTime?.branchTime / 24,
            taskTitle: "INÍCIO",
            start: flow?.createdAt,
            finish: flow?.createdAt,
            time: 0,
          },
        ];
        let stats = flow && count(flow.elements, start);
        stats.stats.push({
          x: flowFullTime.branchTime / 24,
          y: 0.00001,
          taskTitle: "FIM",
          start: stats.stats[stats.stats.length - 1].finished,
          finish: stats.stats[stats.stats.length - 1].finished,
          time: 0,
        });

        if (flow?.status[0] === "finished") {
          stats.realStats.push({
            x: stats.realStats[stats.realStats.length - 1].x,
            y: 0,
            taskTitle: "FIM",
            finished: flow?.finishedAt,
            start: flow?.finishedAt,
            time: 0,
          });
        }
        const actualDay = moment(nowLocal)
          .startOf("day")
          .diff(moment(flow.createdAt).utcOffset(-180).startOf("day"), "days");

        let realStats = [
          {
            x: 0,
            y: flowFullTime.branchTime / 24,
            doneTasks: [{ data: { label: "INÍCIO" } }],
          },
        ];
        let value = 0;
        let timeB = 0;
        for (let i = 0; i < actualDay; i++) {
          const graphTime = graphCountTime(
            flow.elements,
            flow.createdAt,
            i,
            start
          );
          if (i === 0) timeB = graphTime.branchTime;

          const actualDate = moment(flow.createdAt) //data atual
            .utcOffset(-180)
            .startOf("day")
            .add(i, "days");

          const doneTasks = flow.elements.filter(
            (it) =>
              it.data.status === "done" &&
              (it.type === "task" || it.type === "timerEvent") &&
              moment(it.data.finishedAt).startOf("day").format("lll") ===
                moment(actualDate).startOf("day").format("lll")
          );

          if (flow.status[0] !== "finished") {
            if (
              highTask !== {} &&
              moment(highTask?.data?.startedAt)
                .add(highTask?.data?.expiration?.number, "hours")
                .diff(
                  moment(flow.createdAt)
                    .utcOffset(-180)
                    .startOf("day")
                    .add(i, "days"),
                  "days"
                ) > 1
            ) {
              if (i !== 0 || (i === 0 && doneTasks.length > 0))
                realStats.push({
                  x: i,
                  y: (timeB - 24 * value) / 24,
                  doneTasks,
                });

              value += 1;
            } else {
              realStats.push({
                x: i,
                y: (timeB - 24 * value) / 24,
                doneTasks,
              });
            }
          } else {
            if (doneTasks.length > 0)
              realStats.push({
                x: i,
                y: (timeB - 24 * value) / 24,
                doneTasks,
              });
          }
        }
        setStats((prev) => [...prev, { ...stats, graphStats: realStats }]);
        setLoading(false);
      });
    }
  }, [activePage, activedFlowsStatus]);

  const handleConfirm = (flowId) => {
    dispatch(handleRemoveActivedFlow(flowId)).then(() => setConfirmOpen(false));
  };

  const handleCancel = () => setConfirmOpen(false);

  useEffect(() => {
    if (!token) history.push("/login");
  }, [history, token]);

  useEffect(() => {
    dispatch(
      fetchActivedFlows({
        enterpriseId: !user.enterpriseId ? user?._id : user.enterpriseId,
        page: 1,
        type: "actived",
      })
    );
  }, [open]);

  return activedFlowsStatus === "succeeded" &&
    stats !== false &&
    loading === false ? (
    <ContainerCenter>
      <Segment compact>
        <NewActivedFlowModal
          open={open}
          change={(e) => setOpen(e)}
          setLoading={setLoading}
        />
        {user?.rank === "Gerente" && (
          <Confirm
            open={confirmOpen}
            content={`Tem certeza que deseja excluir o fluxo ativo: 
            ${actualFlow?.title.toUpperCase()} ?`}
            onCancel={handleCancel}
            onConfirm={() => handleConfirm(actualFlow?._id)}
            confirmButton="Excluir"
            cancelButton="Cancelar"
          />
        )}
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "10px",
          }}
        >
          <p
            style={{
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            SEUS FLUXOS ATIVOS DISPONÍVEIS
          </p>

          <Button
            color="yellow"
            style={{ marginLeft: "10px" }}
            icon="add"
            labelPosition="right"
            onClick={() => setOpen(true)}
            content="Ativar fluxo"
          />
        </div>

        <Input
          style={{ marginBottom: "10px" }}
          label="Título"
          value={titleSearch}
          onChange={(e) => {
            const pointer = e.target.selectionStart;

            window.requestAnimationFrame(() => {
              e.target.selectionStart = pointer;
              e.target.selectionEnd = pointer;
            });

            setTitleSearch(e.target.value.toUpperCase());
            setActivePage(1);
          }}
        />
        <Input
          style={{ marginBottom: "10px", marginLeft: "10px" }}
          label="Cliente"
          value={clientSearch}
          onChange={(e) => {
            const pointer = e.target.selectionStart;

            window.requestAnimationFrame(() => {
              e.target.selectionStart = pointer;
              e.target.selectionEnd = pointer;
            });

            setClientSearch(e.target.value.toUpperCase());
            setActivePage(1);
          }}
        />

        <Button
          style={{ margin: "10px" }}
          content="Filtrar"
          icon="search"
          labelPosition="right"
          color="blue"
          onClick={() => {
            setActivePage(1);
            setLoading(true);
            if ((clientSearch || titleSearch) !== "")
              dispatch(
                handleSearch({
                  title: titleSearch === "" ? "undefined" : titleSearch,
                  client: clientSearch === "" ? "undefined" : clientSearch,
                  enterpriseId: !user.enterpriseId
                    ? user?._id
                    : user.enterpriseId,
                  page: 1,
                  type: "actived",
                })
              );
            else
              dispatch(
                fetchActivedFlows({
                  enterpriseId: !user.enterpriseId
                    ? user?._id
                    : user.enterpriseId,
                  page: 1,
                  type: "actived",
                })
              );

            setLoading(false);
          }}
        />

        <Dashboard>
          {activedFlows.length > 0 ? (
            <div>
              <Table celled padded color="yellow" selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell singleLine>
                      Título do fluxo
                    </Table.HeaderCell>
                    <Table.HeaderCell singleLine>Cliente</Table.HeaderCell>
                    <Table.HeaderCell singleLine>Tarefa atual</Table.HeaderCell>
                    <Table.HeaderCell singleLine>
                      Status do fluxo
                    </Table.HeaderCell>
                    <Table.HeaderCell>Data de início do fluxo</Table.HeaderCell>
                    <Table.HeaderCell singleLine>
                      Deletar fluxo
                    </Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {activedFlows.map((item, index) => (
                    <Table.Row key={index} style={{ cursor: "pointer" }}>
                      <Table.Cell
                        onClick={() =>
                          history.push(`/activedviewer/${item._id}/full`)
                        }
                        style={{ padding: "10px" }}
                      >
                        {item.title.toUpperCase()}
                      </Table.Cell>
                      <Table.Cell
                        onClick={() =>
                          history.push(`/activedviewer/${item._id}/full`)
                        }
                        style={{ padding: "10px" }}
                      >
                        {item.client.toUpperCase()}
                      </Table.Cell>
                      <Table.Cell
                        onClick={() =>
                          history.push(`/activedviewer/${item._id}/full`)
                        }
                        style={{ padding: "10px" }}
                      >
                        {item.status.map((e, iterator, array) =>
                          iterator === array.length - 1
                            ? item.elements
                                ?.find((i) => i.id === e)
                                ?.data?.label?.toUpperCase() + "."
                            : item.elements
                                ?.find((i) => i.id === e)
                                ?.data?.label?.toUpperCase() + ", "
                        )}
                      </Table.Cell>
                      <Table.Cell
                        onClick={() =>
                          history.push(`/activedviewer/${item._id}/full`)
                        }
                        style={{ padding: "10px" }}
                      >
                        {item?.status[0] !== "finished"
                          ? stats[index]?.graphStats[
                              stats[index]?.graphStats.length - 1
                            ].x === 0 ||
                            !stats[index]?.graphStats[
                              stats[index]?.graphStats.length - 1
                            ].x
                            ? "Iniciado"
                            : stats[index]?.stats[0].y -
                                stats[index]?.graphStats[
                                  stats[index]?.graphStats.length - 1
                                ].y >
                              stats[index]?.graphStats[
                                stats[index]?.graphStats.length - 1
                              ].x
                            ? "Adiantado"
                            : stats[index]?.stats[0].y -
                                stats[index]?.graphStats[
                                  stats[index]?.graphStats.length - 1
                                ].y ===
                              stats[index]?.graphStats[
                                stats[index]?.graphStats.length - 1
                              ].x
                            ? "Em dia"
                            : "Atrasado"
                          : "Finalizado"}
                      </Table.Cell>
                      <Table.Cell
                        onClick={() =>
                          history.push(`/activedviewer/${item._id}/full`)
                        }
                        style={{ padding: "10px" }}
                      >
                        {moment(item.createdAt).format("lll").toUpperCase()}
                      </Table.Cell>
                      <Table.Cell
                        onClick={() => {
                          setActualFlow(item);
                          setConfirmOpen(true);
                        }}
                        negative
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Icon name="trash alternate" />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <Pagination
                style={{ alignSelf: "center" }}
                activePage={activePage}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                onPageChange={(e, pageInfo) => {
                  setActivePage(pageInfo.activePage);
                  setLoading(true);

                  if ((clientSearch || titleSearch) !== "")
                    dispatch(
                      handleSearch({
                        title: titleSearch === "" ? "undefined" : titleSearch,
                        client:
                          clientSearch === "" ? "undefined" : clientSearch,
                        enterpriseId: !user.enterpriseId
                          ? user?._id
                          : user.enterpriseId,
                        page: pageInfo.activePage,
                        type: "actived",
                      })
                    );
                  else
                    dispatch(
                      fetchActivedFlows({
                        enterpriseId: !user.enterpriseId
                          ? user?._id
                          : user.enterpriseId,
                        page: pageInfo.activePage,
                        type: "actived",
                      })
                    );

                  setLoading(false);
                }}
                totalPages={pages}
              />
            </div>
          ) : (
            "VOCÊ NÃO POSSUI FLUXOS ATIVOS, PARA ATIVAR APERTE NO BOTÃO ATIVAR FLUXO E SELECIONE ALGUM MODELO!"
          )}
        </Dashboard>
      </Segment>
    </ContainerCenter>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

export default ActivedFlowsPage;
