import React from "react";
import MarkIcon from "../../../../assets/SideBar/MarkIcon";
import TaskIcon from "../../../../assets/SideBar/TaskIcon";
import { Container, Title, Draggable, ActivityNode, IconDiv } from "./styles";

const Activities = ({ Label, onDrag }) => {
  return (
    <Container>
      <Title>{Label}</Title>
      <Draggable>
        <ActivityNode style={{ marginRight: "0.125rem" }}>
          <IconDiv draggable onDragStart={(event) => onDrag(event, "task")}>
            <TaskIcon />
          </IconDiv>
          <span>Tarefa</span>
        </ActivityNode>
        <ActivityNode>
          <IconDiv
            draggable
            onDragStart={(event) => onDrag(event, "customMark")}
          >
            <MarkIcon />
          </IconDiv>
          <span>Marcador</span>
        </ActivityNode>
      </Draggable>
    </Container>
  );
};

export default Activities;
