import styled from "styled-components";

export const MainDiv = styled.div`
  background: #eff1f5;
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const BigText = styled.div`
  position: absolute;
  font-weight: bold;
  color: #34bf85;
  font-size: 2.116954167rem;
  line-height: 1.666666667rem;
  top: 4.39rem;
`;

export const SmallText = styled.div`
  position: absolute;
  font-weight: 600;
  color: #42464c;
  font-size: 1.058475rem;
  line-height: 1.666666667rem;
  top: 6.91rem;
`;
