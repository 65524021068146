import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import {
  Button,
  Header,
  Image,
  Input,
  Label,
  Segment,
} from "semantic-ui-react";
import api from "../../services/api";
import {
  changeUser,
  handleEditEnterpriseName,
  signOut,
} from "../../state/auth/actions";
import {
  handleEditEmail,
  handleEditPassword,
  handleEditUsername,
} from "../../state/auth/actions";
import ProfilePictureModal from "./ProfilePictureModal";
import { ContainerCenter } from "./styles";
import Loading from "../../components/LoadingTemplate/index";

const ProfilePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const [newName, setNewName] = useState("");
  const [newNameConfirm, setNewNameConfirm] = useState("");
  const [newPassword, setNewPasssword] = useState("");
  const [newPasswordConfirm, setNewPassswordConfirm] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [newEmailConfirm, setNewEmailConfirm] = useState("");
  const [password, setPassword] = useState("");
  const [errorUser, setErrorUser] = useState("");
  const [errorPassword, setErrorPassword] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [enterpriseName, setEnterpriseName] = useState("");
  const [enterpriseError, setEnterpriseError] = useState("");
  const token = useSelector((state) => state.auth.token);
  const [open, setOpen] = useState(false);
  const [picture, setPicture] = useState();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!token) history.push("/login");
  }, [history, token]);

  const handleEnterpriseName = () => {
    if (enterpriseName === "")
      setEnterpriseError("Necessários preencher o campo");
    else {
      setEnterpriseError("");
      setLoading(true);
      dispatch(handleEditEnterpriseName({ enterpriseName, id: user._id })).then(
        (res) => {
          localStorage.setItem(
            "user",
            JSON.stringify({
              ...res.payload,
            })
          );

          dispatch(
            changeUser({
              ...res.payload,
            })
          );
          setEnterpriseName("");
          setLoading(false);
        }
      );
    }
  };

  const handleUser = () => {
    if (newName === "" || newNameConfirm === "")
      setErrorUser("Necessários preencher os campos");
    else if (newName !== newNameConfirm) setErrorUser("Nomes não coincidem");
    else {
      setErrorUser("");
      setLoading(true);
      dispatch(handleEditUsername({ username: newName, id: user._id })).then(
        (res) => {
          if (res.payload === "Nome de usuário já existe") {
            setErrorUser(res.payload);
          } else {
            localStorage.setItem(
              "user",
              JSON.stringify({
                ...res.payload,
                enterpriseName: user.enterpriseName,
              })
            );

            dispatch(
              changeUser({
                ...res.payload,
                enterpriseName: user.enterpriseName,
              })
            );
          }
          setNewName("");
          setNewNameConfirm("");
          setLoading(false);
        }
      );
    }
  };

  const handlePassword = () => {
    if (newPassword === "" || newPasswordConfirm === "" || password === "")
      setErrorPassword("Necessário preencher os campos");
    else if (newPassword !== newPasswordConfirm)
      setErrorPassword("Senhas não coincidem");
    else if (password === newPassword)
      setErrorPassword("A Nova senha não pode ser igual a sua senha atual");
    else {
      setErrorPassword("");
      setLoading(true);
      dispatch(
        handleEditPassword({
          oldPass: password,
          newPass: newPassword,
          id: user._id,
        })
      ).then((res) => {
        if (res.payload === "Request failed with status code 422") {
          setErrorPassword("Senha não confere");
        } else {
          dispatch(signOut());
        }
        setLoading(false);
      });
    }
  };

  const handleEmail = () => {
    if (newEmail === "" || newEmailConfirm === "")
      setErrorEmail("Necessário preencher os campos");
    else if (newEmail !== newEmailConfirm)
      setErrorEmail("Emails não coincidem");
    else if (user.email === newEmail)
      setErrorEmail("Por favor atualize para um novo e-mail");
    else {
      setErrorEmail("");
      setLoading(true);
      dispatch(handleEditEmail({ email: newEmail, id: user._id })).then(() => {
        dispatch(signOut());
        setLoading(false);
      });
    }
  };

  const handleDeletePicture = async (id) => {
    setLoading(true);

    const response = await api.delete(`users/profile/picture/delete/${id}`);

    setPicture(response.data.url);
    setLoading(false);
  };

  useEffect(() => {
    async function fetchPicture() {
      const response = await api.get(`/users/profile/picture/${user._id}`);

      setPicture(response.data.url);
    }

    fetchPicture();
  }, []);

  return token && !loading ? (
    <ContainerCenter>
      <Segment compact style={{ minWidth: "500px" }}>
        <Image src={picture} style={{ maxWidth: "200px" }} />

        <ProfilePictureModal
          open={open}
          change={(e) => setOpen(e)}
          user={user}
          setPicture={setPicture}
          setLoading={setLoading}
        />

        <Button
          content="Trocar imagem de perfil"
          onClick={() => setOpen(true)}
        />
        <Button
          content="Excluir imagem de perfil"
          color="red"
          onClick={() => handleDeletePicture(user._id)}
        />

        <Header
          style={{
            backgroundColor: "white",
            alignSelf: "center",
            display: "flex",
            justifyContent: "center",
          }}
          textAlign="center"
        >
          PERFIL
        </Header>
        <div
          style={{
            display: "flex",
            justifyContent: "space-around",
            margin: "30px",
          }}
        >
          <Label
            content={
              user.enterpriseName !== "none"
                ? user.enterpriseName
                : "Empresa sem nome"
            }
            icon="user"
            size="big"
          />
          <Label content={user.username} icon="user" size="big" />
          <Label
            content={
              user.rank === "Gerente" && !user.enterpriseId
                ? "Admnistrador"
                : user.rank
            }
            icon="star"
            size="big"
          />
        </div>
        {!user.enterpriseId && (
          <Segment style={{ display: "flex", flexDirection: "column" }}>
            <Header style={{ backgroundColor: "white" }}>
              Alterar nome da empresa
            </Header>

            <Input
              value={enterpriseName}
              onChange={(data) => {
                const pointer = data.target.selectionStart;

                window.requestAnimationFrame(() => {
                  data.target.selectionStart = pointer;
                  data.target.selectionEnd = pointer;
                });

                setEnterpriseName(data.target.value);
              }}
              placeholder="Novo nome da empresa"
              style={{ margin: "10px" }}
            />

            {enterpriseError !== "" && (
              <p style={{ color: "red", alignSelf: "center", margin: "10px" }}>
                {enterpriseError}
              </p>
            )}
            <Button
              content="Alterar nome da empresa"
              onClick={() => handleEnterpriseName()}
              icon="send"
              style={{ margin: "10px" }}
              color="blue"
              labelPosition="right"
            />
          </Segment>
        )}
        <Segment style={{ display: "flex", flexDirection: "column" }}>
          <Header style={{ backgroundColor: "white" }}>
            Alterar nome de usuário
          </Header>
          <Input
            value={newName}
            onChange={(data) => {
              const pointer = data.target.selectionStart;

              window.requestAnimationFrame(() => {
                data.target.selectionStart = pointer;
                data.target.selectionEnd = pointer;
              });

              setNewName(data.target.value);
            }}
            placeholder="Novo nome de usuário"
            style={{ margin: "10px" }}
          />
          <Input
            value={newNameConfirm}
            onChange={(data) => {
              const pointer = data.target.selectionStart;

              window.requestAnimationFrame(() => {
                data.target.selectionStart = pointer;
                data.target.selectionEnd = pointer;
              });

              setNewNameConfirm(data.target.value);
            }}
            placeholder="Confirmar novo nome de usuário"
            style={{ margin: "10px" }}
          />
          {errorUser !== "" && (
            <p style={{ color: "red", alignSelf: "center", margin: "10px" }}>
              {errorUser}
            </p>
          )}
          <Button
            content="Alterar nome de usuário"
            onClick={() => handleUser()}
            icon="send"
            style={{ margin: "10px" }}
            color="blue"
            labelPosition="right"
          />
        </Segment>
        <Segment style={{ display: "flex", flexDirection: "column" }}>
          <Header style={{ backgroundColor: "white" }}>Alterar senha</Header>

          <Input
            value={password}
            onChange={(data) => {
              const pointer = data.target.selectionStart;

              window.requestAnimationFrame(() => {
                data.target.selectionStart = pointer;
                data.target.selectionEnd = pointer;
              });

              setPassword(data.target.value);
            }}
            type="password"
            placeholder="Senha atual"
            style={{ margin: "10px" }}
          />
          <Input
            onChange={(data) => {
              const pointer = data.target.selectionStart;

              window.requestAnimationFrame(() => {
                data.target.selectionStart = pointer;
                data.target.selectionEnd = pointer;
              });

              setNewPasssword(data.target.value);
            }}
            value={newPassword}
            type="password"
            placeholder="Nova senha"
            style={{ margin: "10px" }}
          />
          <Input
            onChange={(data) => {
              const pointer = data.target.selectionStart;

              window.requestAnimationFrame(() => {
                data.target.selectionStart = pointer;
                data.target.selectionEnd = pointer;
              });

              setNewPassswordConfirm(data.target.value);
            }}
            value={newPasswordConfirm}
            type="password"
            placeholder="Confirmar nova senha"
            style={{ margin: "10px" }}
          />

          {errorPassword !== "" && (
            <p style={{ color: "red", alignSelf: "center", margin: "10px" }}>
              {errorPassword}
            </p>
          )}

          <Button
            onClick={() => handlePassword()}
            content="Alterar senha"
            icon="send"
            style={{ margin: "10px" }}
            color="blue"
            labelPosition="right"
          />
        </Segment>
        <Segment style={{ display: "flex", flexDirection: "column" }}>
          <Header style={{ backgroundColor: "white" }}>Alterar email</Header>

          <Label content={`Email atual: ${user.email}`} />
          <Input
            onChange={(data) => setNewEmail(data.target.value)}
            value={newEmail}
            type="email"
            placeholder="Novo email"
            style={{ margin: "10px" }}
          />
          <Input
            onChange={(data) => setNewEmailConfirm(data.target.value)}
            value={newEmailConfirm}
            type="email"
            placeholder="Confirmar novo email"
            style={{ margin: "10px" }}
          />

          {errorEmail !== "" && (
            <p style={{ color: "red", alignSelf: "center", margin: "10px" }}>
              {errorEmail}
            </p>
          )}

          <Button
            onClick={() => handleEmail()}
            content="Alterar email"
            icon="send"
            style={{ margin: "10px" }}
            color="blue"
            labelPosition="right"
          />
        </Segment>
      </Segment>
    </ContainerCenter>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

export default ProfilePage;
