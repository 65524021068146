import React, { useState } from "react";
import {
  Container,
  Label,
  CustomInput,
  ErrorLabel,
  EyeSlashIconStyled,
  EyeIconStyled,
} from "./styles";

const Input = ({
  label,
  placeholder,
  error,
  errorMsg,
  type = "text",
  onKeyDown = {},
  value,
  setValue,
}) => {
  const [visible, setVisible] = useState(false);

  return (
    <Container>
      <Label>{label}</Label>
      {type === "password" &&
        (!visible ? (
          <div onClick={() => setVisible((prev) => !prev)}>
            <EyeSlashIconStyled />
          </div>
        ) : (
          <div onClick={() => setVisible((prev) => !prev)}>
            <EyeIconStyled />
          </div>
        ))}
      <CustomInput
        onKeyDown={onKeyDown}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        visible={visible}
        error={error}
        placeholder={placeholder}
        type={
          type === "password" && !visible
            ? type
            : type === "password"
            ? "text"
            : type
        }
      />
      {error && <ErrorLabel>{errorMsg}</ErrorLabel>}
    </Container>
  );
};

export default Input;
