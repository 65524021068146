import React, { memo } from "react";
import { Handle, useStoreState, Position } from "react-flow-renderer";

import { EventStart } from "./styles.js";
import { Square } from "react-feather";

const EventEndNode = ({ data, targetPosition = Position.Left, selected }) => {
  const edges = useStoreState((state) => state.edges);
  const nodes = useStoreState((state) => state.nodes);

  return (
    <EventStart
      criticalPath={data.criticalPath}
      status={data.status}
      selected={selected}
    >
      <Square size={20} />
      <Handle
        type="target"
        position={targetPosition}
        id="a"
        style={{ width: "8px", height: "8px" }}
        isValidConnection={(e) =>
          nodes.find((item) => item.id === e.source).type === "parallel" ||
          nodes.find((item) => item.id === e.source).type === "conditional"
            ? true
            : !edges.find(
                (item) => item.target === e.target || item.source === e.source
              )
        }
      />
    </EventStart>
  );
};

export default memo(EventEndNode);
