import { Handle } from "react-flow-renderer";
import styled, { css } from "styled-components";

export const EventStart = styled.div`
  width: 50.81px;
  height: 50.81px;
  box-shadow: 0 0 0 1.2px #42464c;
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: #fff;

  path {
    stroke: #42464c;
    stroke-width: 1px;
  }

  &:hover {
    background: #cff8e7;
    box-shadow: 0 0 0 1.2px #40e5a0;
    path {
      stroke: #40e5a0;
    }
  }

  &.critical {
    background: #d93333 !important;
    border: none !important;
    box-shadow: none !important;
    path {
      stroke: #eff1f5;
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      background: #fff !important;
      box-shadow: 0 0 3px 2px #40e5a0 !important;
      path {
        stroke: #42464c !important;
      }
    `}
`;

export const HandleStyle = styled(Handle)`
  width: 8px;
  height: 8px;
  background: #42464c;
`;
