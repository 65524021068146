/**
 * ? findFlow
 *  * Utilizado em FlowEdition e FlowViewerModel. O seu prósito é retornar um
 *  * fluxo especifico em meio as entidades do estado global, seus parametros são
 *  * basicamente o estado atual e um _id do fluxo selecionado.
 */

export const findFlow = (flows, _id) => {
  if (flows !== undefined && _id !== undefined)
    return flows?.find((item) => item?._id === _id);
};

export const findFlowAndVersion = (flows, _id, version) => {
  if (flows && _id && version) {
    if (version === "default") return flows?.find((item) => item?._id === _id);
    else {
      return flows
        ?.find((item) => item?._id === _id)
        ?.versions.find((it) => it.versionNumber === version);
    }
  }
};

/**
 * ? findTaskOneFlow
 * * Utilizado em ModelTaskModal, ModelTimerModal. O seu propósito é retornar
 * * um elemento do tipo tarefa (ou do tipo timer como usado em ModelTimerModal)
 * * Os seus parâmetros são o id do elemento(interno), o flowId e as entidades do
 * * estado global.
 */

export const findTaskOneFlow = (taskId, flowId, flows, version) => {
  const flow = flows?.find((item) => item?._id === flowId);

  if (flow !== undefined && version !== undefined) {
    if (version === "default") {
      const task = flow?.elements?.find((item) => item?.id === taskId);
      return task;
    } else {
      let task;

      flow.versions.forEach((el) => {
        if (el.versionNumber === version) {
          task = el?.elements?.find((item) => item?.id === taskId);
        }
      });

      return task;
    }
  }

  return false;
};

/**
 * ? filterFlow
 * * Utilizado em DashboardModel. O seu propósito é filtrar a lista de
 * * fluxos modelos e exibir a nova lista conforme o campo título for
 * * digitado. Os seus parâmetros é a entidade global de fluxos modelo
 * * e o título a ser pesquisado
 */
export const filterFlows = (flows, title) => {
  if (title !== undefined && flows !== undefined) {
    return flows?.filter((e) => e?.title?.match(title));
  }
};
