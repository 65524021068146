import React from "react";

const EndIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="14.85"
        cy="15.0668"
        r="13.7981"
        stroke="#7E858F"
        strokeWidth="1.96988"
      />
      <rect
        x="10.2759"
        y="10.8466"
        width="8.65969"
        height="8.65969"
        rx="1.12564"
        stroke="#7E858F"
        strokeWidth="1.68847"
      />
    </svg>
  );
};

export default EndIcon;
