import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  border-radius: 0.17648rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  background: #fff;
  width: 25.875rem;
  font-family: Sora;
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0 1.75rem 1.82375rem;

  input {
    width: 22.354375rem;
    border-radius: 0.35296625rem;
  }
`;

export const Header = styled.div`
  margin-top: 1.91875rem;
  margin-bottom: 1.803125rem;

  display: flex;
  align-items: center;

  svg {
    width: 1.411875rem;
    height: 1.411875rem;
  }

  span {
    font-size: 1.1rem;
    font-weight: 600;
    line-height: 1.3125rem;
    margin-left: 0.52rem;
  }
`;

export const Button = styled.button`
  font-family: Sora;
  text-align: center;
  width: 6.95625rem;
  height: 2.461875rem;
  padding: 0;
  line-height: 0.875rem;
  font-size: 0.70625rem;
  font-weight: bold;
  color: #7e858f;
  border-radius: 0.353rem;
  border: 1.41186px solid #d4d6da;
  align-self: flex-end;
  margin: 1.8rem 1.75rem 0 0;
  cursor: pointer;

  &:hover {
    background: #d4d6da;
  }
`;

export const DivIcon = styled.div`
  position: absolute;
  top: 1.0875rem;
  right: 0.875rem;
  cursor: pointer;

  svg {
    width: 0.75625rem;
    height: 0.75625rem;
    &:hover {
      path,
      rect {
        stroke: #40e5a0;
      }
    }
  }
`;
