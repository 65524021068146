import React, { memo } from "react";
import { useStoreState, Position } from "react-flow-renderer";

import { EventStart, HandleStyle } from "./styles.js";
import Play from "../../assets/icons/Play";

const EventStartNode = ({
  data,
  sourcePosition = Position.Right,
  selected,
  id,
}) => {
  const edges = useStoreState((state) => state.edges);
  const nodes = useStoreState((state) => state.nodes);

  return (
    <EventStart selected={selected} className="flow_elements_node" data-id={id}>
      <Play style={{ height: "38px", width: "32px" }} />
      <HandleStyle
        type="source"
        position={sourcePosition}
        selected={selected}
        id="a"
        isValidConnection={(e) =>
          nodes.find((item) => item.id === e.target).type === "parallelEnd" ||
          nodes.find((item) => item.id === e.target).type === "conditionalEnd"
            ? !edges.find((item) => item.source === e.source)
            : !edges.find(
                (item) => item.source === e.source || item.target === e.target
              )
        }
      />
    </EventStart>
  );
};

export default memo(EventStartNode);
