import { useEffect } from "react";
import { isEdge, getConnectedEdges, useStoreState } from "react-flow-renderer";
import { useDispatch } from "react-redux";
import { updateFlow, updateRemove } from "../../undoFlow/actions";
import { v4 as uuid } from "uuid";

export function getSelectedGraph(selectedNodes, elements) {
  const allEdges = [];
  const allNodeMap = {};

  elements.forEach((node) => {
    if (isEdge(node)) {
      allEdges.push(node);
    } else {
      allNodeMap[node.id] = node;
    }
  });
  const edgeMap = {};
  const selectedNodeIds = [];
  const nodes = [];
  selectedNodes.forEach((node) => {
    if (!isEdge(node)) {
      selectedNodeIds.push(node.id);
      const connectedEdges = getConnectedEdges([node], allEdges);
      connectedEdges.forEach((edge) => (edgeMap[edge.id] = edge));
      nodes.push(allNodeMap[node.id]);
    }
  });
  // pick edges which has both nodes present in selectedNodes
  Object.values(edgeMap).forEach((edge) => {
    if (
      selectedNodeIds.includes(edge.source) &&
      selectedNodeIds.includes(edge.target)
    ) {
      nodes.push(edge);
    }
  });

  let filteredElements = JSON.parse(JSON.stringify(nodes));

  filteredElements.forEach((el) => {
    if (el._id) {
      delete el._id;
      delete el.flowId;
    }
  });
  return filteredElements;
}

const concatIdEdge = (id1, id2, sourceH, targetH) => {
  return "reactflow__edge-" + id1 + sourceH + "-" + id2 + targetH;
};

const randomPosition = (min, max) => {
  return Math.random() * (max - min) + min;
};

const Format = "application/react-flow-format";

export function useCutCopyPaste(
  elements,
  selectedElements,
  selectableFlow,
  isSelectable
) {
  const dispatch = useDispatch();

  useEffect(() => {
    function cut(event) {
      const isModalOpen = document.getElementById("modalContainer");
      if (selectedElements && selectedElements.length && !isModalOpen) {
        const data = JSON.stringify(
          getSelectedGraph(selectedElements, elements)
        );

        event.clipboardData.setData(Format, data);
        event.preventDefault();

        dispatch(updateRemove(selectedElements));
        selectableFlow([]);
        isSelectable(true);
      }
    }
    function copy(event) {
      const isModalOpen = document.getElementById("modalContainer");
      if (selectedElements && selectedElements.length && !isModalOpen) {
        const data = JSON.stringify(
          getSelectedGraph(selectedElements, elements)
        );
        event.clipboardData.setData(Format, data);
        event.preventDefault();
      }
    }
    function paste(event) {
      const isModalOpen = document.getElementById("modalContainer");

      if (!event.clipboardData.getData(Format) || isModalOpen) return false;

      try {
        const finalFlow = JSON.parse(event.clipboardData.getData(Format));
        const initialFlow = JSON.parse(event.clipboardData.getData(Format));
        const APIelements = JSON.parse(JSON.stringify(elements));

        finalFlow.forEach((item) => (item.lastId = item.id));

        const max = 150;
        const min = 50;
        let x = randomPosition(min, max);
        let y = randomPosition(min, max);

        initialFlow.forEach((el, i) => {
          let id_source = uuid();
          let id_target = uuid();
          //Se for aresta

          if (el.source && !el.visited) {
            //Caso o encontrou o nó correspondente ao source / target da aresta
            let node_source = initialFlow.find((item) => item.id === el.source);
            let node_target = initialFlow.find((item) => item.id === el.target);

            // Caso não tenha sido visitado e o nó source exista
            if (!node_source.isVisited) {
              //Setando true no nó source
              initialFlow.find(
                (item) => item.id === el.source
              ).isVisited = true;

              //Trocando os valores dos ids
              finalFlow.find((item) => item.id === el.source).id = id_source;
              finalFlow[i].source = id_source;
            } else {
              //Caso encontre um nó source ja visitado
              finalFlow[i].source = finalFlow.find(
                (item) => item.lastId === el.source
              ).id;
            }
            if (!node_target.isVisited) {
              //Setando true no nó target
              initialFlow.find(
                (item) => item.id === el.target
              ).isVisited = true;

              //Trocando os valores dos ids
              finalFlow.find((item) => item.id === el.target).id = id_target;
              finalFlow[i].target = id_target;
            } else {
              //Caso encontre um nó target ja visitado
              finalFlow[i].target = finalFlow.find(
                (item) => item.lastId === el.target
              ).id;
            }
            //No fim gera um novo id baseado na concatenação do source e target
            finalFlow[i].id = concatIdEdge(
              finalFlow[i].source,
              finalFlow[i].target,
              finalFlow[i].sourceHandle,
              finalFlow[i].targetHandle
            );
            el.isVisited = true;
          }
        });
        //Caso haja nós soltos, conditional edges e deletando o lastId..
        finalFlow.forEach((el) => {
          if (!el.source) {
            el.position.x += x;
            el.position.y += y;
          }
          if (el.lastId === el.id) el.id = uuid();
          delete el.lastId;
        });

        //Somente Marcadores
        let markElements = finalFlow.filter(
          (item) => item.type === "customMark"
        );

        //Se houver Marcadores
        if (markElements.length > 0) {
          markElements.forEach((item) => (item.data.draggable = true));

          //Elementos sem marcadores
          let Elements = finalFlow.filter((item) => item.type !== "customMark");

          //API Elements
          let actualElements = JSON.parse(JSON.stringify(elements));

          let insertIndex = actualElements.findIndex(
            (item) => item.type !== "customMark"
          );

          //insertIndex = -1 -> não há nós comuns entre os elements
          //insertIndex > 0  -> posição que reflete um nó comum de elements
          if (insertIndex < 0) {
            actualElements.splice(actualElements.length, 0, ...markElements);
          } else {
            actualElements.splice(insertIndex, 0, ...markElements);
          }
          let elementsWithMark = actualElements;
          let totalFlow = elementsWithMark.concat(Elements);
          dispatch(updateFlow(totalFlow));
        } else {
          dispatch(updateFlow(APIelements.concat(finalFlow)));
        }
        //Nodes Selectable
        selectableFlow(finalFlow);
        isSelectable(true);
        event.preventDefault();

        return finalFlow;
      } catch (error) {
        console.error(error);
      }
    }

    document.addEventListener("cut", cut);
    document.addEventListener("copy", copy);
    document.addEventListener("paste", paste);
    return () => {
      document.removeEventListener("cut", cut);
      document.removeEventListener("copy", copy);
      document.removeEventListener("paste", paste);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elements, selectedElements]);
}
