import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import { Button, Dropdown, Segment, Table } from "semantic-ui-react";
import { fetchUsers } from "../../state/users/reducers";
import { ContainerCenter } from "./styles";
import { DateRangePicker } from "react-dates";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import moment from "moment";
import { TableCell } from "@material-ui/core";
import Loading from "../../components/LoadingTemplate";
import { handleEmployeerStats } from "../../state/activedTasks/actions";

const EmployeerStatsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);
  const token = useSelector((state) => state.auth.token);
  const users = useSelector((state) => state.users.entities);
  const usersStatus = useSelector((state) => state.users.status);

  const [state, setState] = useState({
    startDate: null,
    endDate: null,
    focusedInput: null,
  });
  const [employeer, setEmployeer] = useState(user.email);
  const accountable = [];
  const [hours, setHours] = useState({ doneHours: 0, doingHours: 0 });
  const [productivity, setProductivity] = useState(false);
  const [actual, setActual] = useState(
    employeer === "Ninguém" ? user?.email : employeer
  );
  const [stats, setStats] = useState({});
  const [loading, setLoading] = useState(false);

  moment.locale("pt-br");

  users.forEach((item, index) => {
    accountable.push({
      key: index,
      value: item.email,
      text: item.username,
    });
  });

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleTasks(
        user.rank === "Gerente" ? employeer : user?.email,
        state.startDate,
        state.endDate
      );
    }
  };

  const handleTasks = (name, start, end) => {
    setActual(user.rank === "Gerente" ? employeer : user?.email);
    setLoading(true);
    dispatch(
      handleEmployeerStats({
        employeer: name,
        startDate: moment(start).format("YYYY-MM-DD"),
        endDate: moment(end).format("YYYY-MM-DD"),
      })
    ).then((res) => {
      setHours(res.payload.hours);
      setProductivity(res.payload.productivity);
      setStats(res.payload.stats);
      setLoading(false);
    });
  };

  useEffect(() => {
    if (token === null) history.push("/login");

    if (usersStatus === "idle")
      dispatch(fetchUsers(!user.enterpriseId ? user?._id : user.enterpriseId));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return usersStatus === "succeeded" && !loading ? (
    <ContainerCenter>
      <Segment compact>
        {user.rank === "Gerente" && (
          <div>
            <h4>Funcionário</h4>
            <Dropdown
              placeholder={employeer ? employeer : "Funcionário"}
              fluid
              search
              selection
              onChange={(e, data) => {
                setEmployeer(data.value);
              }}
              options={accountable}
              defaultValue={employeer}
              onKeyDown={handleKeyDown}
            />
          </div>
        )}
        <DateRangePicker
          startDate={state.startDate} // momentPropTypes.momentObj or null,
          startDateId="1" // PropTypes.string.isRequired,
          endDate={state.endDate} // momentPropTypes.momentObj or null,
          endDateId="2" // PropTypes.string.isRequired,
          onDatesChange={({ startDate, endDate }) => {
            setState((prev) => ({
              ...prev,
              startDate,
              endDate,
            }));
          }} // PropTypes.func.isRequired,
          focusedInput={state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={(focusedInput) =>
            setState((prev) => ({ ...prev, focusedInput }))
          } // PropTypes.func.isRequired,
          startDatePlaceholderText="Início"
          endDatePlaceholderText="Fim"
          isOutsideRange={() => false}
        />
        <Button
          color="blue"
          icon="search"
          labelPosition="right"
          content="Filtrar"
          onClick={() =>
            handleTasks(
              user.rank === "Gerente" ? employeer : user?.email,
              state.startDate,
              state.endDate
            )
          }
        />

        {productivity && (
          <Table celled padded color="yellow">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Funcionário</Table.HeaderCell>
                <Table.HeaderCell>Tarefas em andamento</Table.HeaderCell>
                <Table.HeaderCell>
                  Tarefas em andamento expiradas
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Tempo total das tarefas em andamento
                </Table.HeaderCell>

                <Table.HeaderCell>
                  Total das tarefas em andamento
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{actual}</Table.Cell>
                <Table.Cell>{stats.doingTasks}</Table.Cell>
                <Table.Cell>{stats.expiredTasks}</Table.Cell>
                <Table.Cell>{hours.doingHours / 24} Dias</Table.Cell>
                <TableCell>{stats.doingTasks + stats.expiredTasks}</TableCell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}

        {productivity && (
          <Table celled padded color="green">
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Funcionário</Table.HeaderCell>
                <Table.HeaderCell>
                  Tarefas concluídas com sucesso
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Tarefas concluídas com atraso
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Tempo total das tarefas concluídas
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Produtividade das tarefas concluídas
                </Table.HeaderCell>
                <Table.HeaderCell>
                  Total das tarefas concluídas
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              <Table.Row>
                <Table.Cell>{actual}</Table.Cell>
                <Table.Cell>{stats.doneTasks}</Table.Cell>
                <Table.Cell>{stats.expiredDoneTasks}</Table.Cell>
                <Table.Cell>{hours.doneHours / 24} Dias</Table.Cell>
                <Table.Cell>
                  {isNaN(productivity)
                    ? "--"
                    : productivity === Infinity
                    ? "Altíssima"
                    : `${Math.round(productivity)}%`}
                </Table.Cell>
                <Table.Cell>
                  {stats.doneTasks + stats.expiredDoneTasks}{" "}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        )}
      </Segment>
    </ContainerCenter>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

export default EmployeerStatsPage;
