import styled from "styled-components";

export const TaskStyle = styled.div`
  padding: 10px;
  width: 150px;
  display: flex;
  font-family: jetbrains-mono;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: ${({ status, expired }) =>
    expired === true && status !== "done"
      ? "#ff6666"
      : status === "done"
      ? "#beefbe"
      : status === "doing"
      ? "#f2f0a6"
      : "white"};
  box-shadow: ${({ status, expiredTask, selected }) =>
    selected === true
      ? "0 0 0 1px blue"
      : expiredTask === true && status === "done"
      ? " 0 0 0 1px red"
      : " 0 0 0 0.5px #1a192b"};
`;
