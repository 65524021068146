import React from "react";

const TimerIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 29 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28.0151 15.5914C28.0151 23.2306 21.95 29.3896 14.5098 29.3896C7.06948 29.3896 1.00447 23.2306 1.00447 15.5914C1.00447 7.95226 7.06948 1.79329 14.5098 1.79329C21.95 1.79329 28.0151 7.95226 28.0151 15.5914Z"
        stroke="#7E858F"
        strokeWidth="1.96988"
      />
      <path
        d="M19.6774 16.9415C19.6774 19.7831 17.3712 22.0893 14.5296 22.0893C11.688 22.0893 9.38184 19.7831 9.38184 16.9415C9.38184 14.0999 11.688 11.7937 14.5296 11.7937C17.3712 11.7937 19.6774 14.0999 19.6774 16.9415Z"
        stroke="#7E858F"
        strokeWidth="1.68847"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.5298 13.8528V16.7944"
        stroke="#7E858F"
        strokeWidth="1.12564"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.7646 10.323H16.2946"
        stroke="#7E858F"
        strokeWidth="1.12564"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default TimerIcon;
