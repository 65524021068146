import { Height } from "@material-ui/icons";
import React from "react";
import { LoaderComponent, StyledButton, StyledText } from "./style";

//? Creating Button React component
//* 4 Bases foram criadas para o Zaivu
// nameless => background/active = #585E66/#42464C ; colorText/active = #f4f4f4/#BFC2C7 ; no border.
// greenBase => background/active = #40E5A0/#23AB72 ; colorText/active = #585E66/#42464C ; no border.
// outline => background/active = transparent/#D4D6DA ; colorText/active = #7E858F/#7E858F ; solidborder 1,5px #D4D6DA.
// noBackGround => background/active = transparent/transparent ; colorText/active = #BFC2c7/#7E858F; no border
//* Flags implementadas greenBase,outline,noBackGround,iconPosition,round,square,load.
// iconPosition => controla o local do icone com default:left
// round => sera nescessario passar o tamanho esperado do icone como width, o default = 1,5rem
// square => renderizara apenas o Icone.tera size = ao icone exceto modificado!
// load => renderiza o load component, torna o texto do botao invisivel,desabilita onClick,nao modifica o cursor on hover
//* por default:fontSize = 0.64684rem fontWeight = 700; lineHeight = 0.833rem and borderRadius = 0.3528rem

const Button = ({
  style,
  content,
  onClick = () => console.log(content),
  load = false,
  greenBase = false,
  outline = false,
  noBackground = false,
  disabled = false,
  round = false,
  square = false,
  border = "#D4D6DA",
  colorText = !noBackground
    ? !outline
      ? !greenBase
        ? "#f4f4f4"
        : "#585E66"
      : "#7E858F"
    : "#BFC2c7",
  background = !noBackground
    ? !outline
      ? !greenBase
        ? "#585E66"
        : "#40E5A0"
      : "transparent"
    : "transparent",
  activeBackground = !noBackground
    ? !outline
      ? !greenBase
        ? "#42464C"
        : "#23AB72"
      : border
    : "transparent",
  activeColorText = !noBackground
    ? !outline
      ? !greenBase
        ? "#BFC2C7"
        : "#42464C"
      : "#7E858F"
    : "#7E858F",
  Icon = false,
  iconPosition = "left",
  fontSize = `0.70583rem`,
  fontWeight = 700,
  lineHeight = `0.833rem`,
  size = `1.5rem`,
  iconSize = !(round || square) ? `auto` : `1.0rem`,
  padding = !(round || square) ? `0.82rem 0.2rem 0.7646rem 0.2rem;` : `0 0 0 0`,
  width = !(round || square) ? (!noBackground ? `100%` : `auto`) : size,
  height = !(round || square) ? (!noBackground ? `100%` : `auto`) : size,
}) => {
  return (
    <StyledButton
      style={style}
      colorText={colorText}
      onClick={() => (!load ? onClick() : null)}
      greenBase={greenBase}
      outline={outline}
      disabled={disabled}
      background={background}
      border={border}
      activeBackground={activeBackground}
      activeColorText={activeColorText}
      fontSize={fontSize}
      fontWeight={fontWeight}
      lineHeight={lineHeight}
      borderRadius={!round ? `0.3528rem` : `100%`}
      width={width}
      height={height}
      padding={padding}
      iconSize={iconSize}
      load={load}
    >
      {load ? <LoaderComponent active /> : null}
      {iconPosition === "left" && Icon && <Icon colorText={colorText} />}
      {!!content === true && !square && !round && (
        <StyledText>{content}</StyledText>
      )}
      {iconPosition === "right" && Icon && <Icon colorText={colorText} />}
    </StyledButton>
  );
};
export default Button;
