import React from "react";

const TaskIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.54608"
        y="1.14129"
        width="18.4604"
        height="12.527"
        rx="1.22362"
        stroke="#7E858F"
        strokeWidth="1.31774"
      />
    </svg>
  );
};
export default TaskIcon;
