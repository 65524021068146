import React from "react";

const PenIcon = ({ width, height, onClick }) => (
  <svg
    width={width}
    height={height}
    onClick={onClick}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M20.285 21.572H3.34265C2.95674 21.572 2.63672 21.252 2.63672 20.8661C2.63672 20.4802 2.95674 20.1602 3.34265 20.1602H20.285C20.6709 20.1602 20.991 20.4802 20.991 20.8661C20.991 21.252 20.6709 21.572 20.285 21.572Z"
      fill="#40E5A0"
    />
    <path
      d="M18.4214 4.14112C16.5954 2.31511 14.8071 2.26805 12.934 4.14112L11.7951 5.28002C11.701 5.37415 11.6633 5.52475 11.701 5.65652C12.4163 8.15081 14.4117 10.1462 16.906 10.8616C16.9437 10.871 16.9813 10.8804 17.019 10.8804C17.1225 10.8804 17.2166 10.8428 17.2919 10.7675L18.4214 9.62856C19.3533 8.70615 19.8051 7.81197 19.8051 6.90837C19.8145 5.97654 19.3627 5.07295 18.4214 4.14112Z"
      fill="#40E5A0"
    />
    <path
      d="M15.2119 11.7186C14.9389 11.5868 14.6754 11.455 14.4212 11.3044C14.2142 11.182 14.0165 11.0503 13.8188 10.9091C13.6588 10.8056 13.4706 10.655 13.2917 10.5044C13.2729 10.4949 13.207 10.4385 13.1317 10.3632C12.8211 10.0996 12.4729 9.76077 12.1623 9.38427C12.134 9.36545 12.087 9.29956 12.0211 9.21485C11.9269 9.1019 11.7669 8.91365 11.6257 8.69717C11.5128 8.55598 11.381 8.34891 11.2587 8.14183C11.1081 7.8877 10.9763 7.63356 10.8445 7.37002C10.8246 7.32729 10.8053 7.28478 10.7866 7.24251C10.6477 6.92878 10.2386 6.83706 9.99596 7.07967L4.60408 12.4716C4.48171 12.5939 4.36877 12.8292 4.34053 12.9892L3.83226 16.5942C3.73813 17.2342 3.91697 17.8366 4.31229 18.2414C4.65114 18.5708 5.12176 18.7496 5.63003 18.7496C5.74298 18.7496 5.85593 18.7402 5.96888 18.7214L9.58325 18.2131C9.75267 18.1849 9.98798 18.0719 10.1009 17.9496L15.4861 12.5644C15.7304 12.3201 15.6382 11.9008 15.3204 11.7653C15.2847 11.75 15.2485 11.7344 15.2119 11.7186Z"
      fill="#40E5A0"
    />
  </svg>
);

export default PenIcon;
