import { useDispatch, useSelector } from "react-redux";
import {
  findActivedFlow,
  findActivedTaskOneFlow,
} from "../../state/activedFlows/selectors";
import moment from "moment";
import "moment/min/locales";
import React, { useState, useRef, useEffect } from "react";
import {
  Controls,
  ReactFlowProvider,
  Background,
  MiniMap,
  useStoreState,
  useZoomPanHelper,
} from "react-flow-renderer";

import ConditionalNodeForView from "../../components/ConditionalNodeForView";
import ConditionalEndNode from "../../components/ConditionalEndNode";
import ParallelNode from "../../components/ParallelNode";
import ParallelEndNode from "../../components/ParallelEndNode";
import TaskNodeForView from "../../components/TaskNodeForView";
import EventStartNode from "../../components/EventStartNode";
import EventEndNode from "../../components/EventEndNode";
import CustomEdgeForView from "../../components/CustomEdgeForView";
import { useHistory } from "react-router-dom";
import CustomEdgeWithoutLabel from "../../components/CustomEdgeWithoutLabel";
import ActivedTaskModal from "../../components/ActivedTaskModal/index";
import {
  Button,
  Comment,
  Form,
  Header,
  Segment,
  Sidebar,
  Icon,
  Label,
  Confirm,
} from "semantic-ui-react";
import {
  handleEditComment,
  handleFetchSingleActivedFlow,
  handleNewFlowPost,
  handleRemoveActivedFlow,
  handleSendTask,
  sortActivedFlow,
} from "../../state/activedFlows/actions";
import {
  ButtonTest,
  ButtonTest2,
  ButtonTest3,
  ReactFlowBoard,
  ReactFlowWrapper,
  StickyNotes,
  StickyTitle,
} from "./styles.js";
import StatsModal from "../../components/StatsModal/index";
import { Popover } from "@material-ui/core";
import FlowOptionsModal from "./FlowOptionsModal/index";
import TimerEventNodeForView from "../../components/TimerEventNodeForView";
import TimerModal from "../../components/ActivedTimerModal/index";
import ZoomNode from "../../components/ZoomNodeComponent/index";
import Loading from "../../components/LoadingTemplate";
import CustomMarkForView from "../../components/CustomMarkForView";
import CustomTextForView from "../../components/CustomTextForView";
import { useKey } from "rooks"; //Arrows

const ChangePosition = ({ off, xy }) => {
  const { transform } = useZoomPanHelper();

  const state = useStoreState((state) => state);
  const x = state.transform[0];
  const y = state.transform[1];
  const z = state.transform[2];

  useEffect(() => {
    transform({ x: x + xy.x, y: y + xy.y, zoom: z + xy.z });
    off();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

const FlowViewerActivedPage = ({ match }) => {
  const flow = useSelector((state) =>
    findActivedFlow(state.activedFlows.entities, match.params.id)
  );
  const flowStatus = useSelector((state) => state.activedFlows.status);
  const dispatch = useDispatch();
  const history = useHistory();
  const reactFlowWrapper = useRef(null);
  const [load, setLoad] = useState(false);
  const [actualTask, setActualTask] = useState(null);
  const [open, setOpen] = useState(false);
  const [timerOpen, setTimerOpen] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const [commentEdition, setCommentEdition] = useState(false);
  const [state, setState] = useState("");
  const [visible, setVisible] = useState(true);
  const [visibleRight, setVisibleRight] = useState(false);
  const [optionsModal, setOptionsModal] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmOpenDelete, setConfirmOpenDelete] = useState(false);
  const [actualOption, setActualOption] = useState(null);
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? "simple-popover" : undefined;
  const ifDone = [];
  const nowLocal = moment().utcOffset(-180);
  const [comments, setComments] = useState("");
  const task = useSelector((state) =>
    findActivedTaskOneFlow(actualTask, flow?._id, state.activedFlows.entities)
  );
  const counterRef = useRef(false);
  const [loading, setLoading] = useState(false);
  //Arrow Keys
  const [arrowFlag, setArrowFlag] = useState(false);
  const [coordinates, setCoordinates] = useState(false);

  moment.locale("pt-br");

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmit = () => {
    const nowLocal = moment().utcOffset(-180);

    if (state !== "") {
      setLoading(true);
      dispatch(
        handleNewFlowPost(flow?._id, {
          username: user?.username,
          text: state,
          time: nowLocal,
        })
      ).then(() => {
        setState("");
        setLoading(false);
      });
    }
  };

  useEffect(() => {
    if (!token) history.push("/login");
    if (flowStatus === "idle") {
      setLoading(true);
      dispatch(
        handleFetchSingleActivedFlow({
          enterpriseId: !user.enterpriseId ? user?.id : user.enterpriseId,
          flowId: match.params.id,
        })
      );
      setLoading(false);
    }
  }, [dispatch]);

  useEffect(() => {
    if (!commentEdition && flowStatus === "succeeded")
      setComments(flow?.comments);
    if (flow?.status[0] === "finished") history.push("/finishedflows");
  }, [flow]);

  useEffect(() => {
    if (flowStatus === "succeeded") {
      setComments(flow?.comments);
      let elementsState = [...flow.elements];

      flow.elements.forEach((item, index, array) => {
        if (item.type === "conditionalEnd" && item.data.status === "done") {
          const conditionalEdgesEnd = array.filter((e) => e.target === item.id);
          let doneId;
          let doneElement;

          conditionalEdgesEnd.forEach((d, interator) => {
            if (d.data.status === "done") {
              doneId = d._id;
            }
          });

          flow.elements?.forEach((i, j) => {
            if (i._id === doneId) {
              doneElement = elementsState?.splice(j, 1);

              elementsState?.push(doneElement[0]);
            }
          });
        }

        if (item.type === "conditional" && item.data.status === "done") {
          const conditionalEdges = array.filter((e) => e.source === item.id);
          let doneId;
          let doneElement;

          conditionalEdges.forEach((d, interator) => {
            if (d.data.status === "done") {
              doneId = d._id;
            }
          });

          flow.elements?.forEach((i, j) => {
            if (i._id === doneId) {
              doneElement = elementsState?.splice(j, 1);

              elementsState?.push(doneElement[0]);
            }
          });
          elementsState.sort(function (a, b) {
            if (a.data.status === "done" && b.data.status !== "done") return 1;
            else if (
              (a.data.status === "done" && b.data.status === "done") ||
              (a.data.status !== "done" && b.data.status !== "done")
            )
              return 0;
            else return -1;
          });
        }
      });

      dispatch(sortActivedFlow({ flowId: flow?._id, elements: elementsState }));
      console.log("correu");
    }
  }, [flowStatus]);

  const onLoad = (reactFlowapi) => {
    setLoad(true);
    reactFlowapi.fitView();
  };

  const nodeTypes = {
    conditional: ConditionalNodeForView,
    conditionalEnd: ConditionalEndNode,
    parallel: ParallelNode,
    parallelEnd: ParallelEndNode,
    task: TaskNodeForView,
    eventStart: EventStartNode,
    eventEnd: EventEndNode,
    timerEvent: TimerEventNodeForView,
    customMark: CustomMarkForView,
    customText: CustomTextForView,
  };

  const edgeTypes = {
    customEdge: CustomEdgeForView,
    customEdgeWithoutLabel: CustomEdgeWithoutLabel,
  };

  const handleConfirm = (item) => {
    setLoading(true);
    dispatch(handleSendTask(flow?._id, task?._id, item?._id)).then(() => {
      setConfirmOpen(false);
      setLoading(false);
    });
  };
  const handleConfirmDelete = () => {
    setLoading(true);
    dispatch(handleRemoveActivedFlow(flow?._id)).then(() => {
      history.push("/activedflows");
      setLoading(false);
    });

    setConfirmOpenDelete(false);
  };
  const handleCancel = () => setConfirmOpen(false);
  const handleCancelDelete = () => setConfirmOpenDelete(false);

  //? Implementando comandos por Teclado
  function ArrowLeftEnter(e) {
    setCoordinates({ x: 10, y: 0, z: 0 });
    setArrowFlag(true);
  }
  function ArrowRightEntered(e) {
    setCoordinates({ x: -10, y: 0, z: 0 });
    setArrowFlag(true);
  }
  function ArrowUpEntered(e) {
    setCoordinates({ x: 0, y: 10, z: 0 });
    setArrowFlag(true);
  }
  function ArrowDownEntered(e) {
    setCoordinates({ x: 0, y: -10, z: 0 });
    setArrowFlag(true);
  }
  useKey(["ArrowLeft"], ArrowLeftEnter);
  useKey(["ArrowRight"], ArrowRightEntered);
  useKey(["ArrowUp"], ArrowUpEntered);
  useKey(["ArrowDown"], ArrowDownEntered);

  return flowStatus === "succeeded" && flow !== undefined && !loading ? (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar
          as={Segment}
          animation="push"
          vertical
          visible={visible}
          style={{ textAlign: "center", padding: 0, overflowX: "hidden" }}
        >
          <Confirm
            open={confirmOpen}
            content={`Tem certeza que deseja selecionar a opção: ${actualOption?.data?.text?.toUpperCase()} ?`}
            onCancel={handleCancel}
            onConfirm={() => handleConfirm(actualOption)}
            confirmButton="Confirmar"
            cancelButton="Cancelar"
          />
          <Label corner="right" style={{ margin: 0, padding: 0 }}>
            <Icon
              name="cancel"
              style={{
                background: "none",
                padding: 0,
                cursor: "pointer",
              }}
              onClick={() => setVisible(false)}
            />
          </Label>

          <h3
            style={{
              backgroundColor: "#F0F0F0",
              padding: "12px",
              marginTop: "0px",
            }}
          >
            {flow.title.toUpperCase()}
          </h3>
          <h3
            style={{
              backgroundColor: "#F0F0F0",
              padding: "12px",
              marginTop: "-30px",
            }}
          >
            {flow.client.toUpperCase()}
          </h3>

          <Label size="large">
            <Icon name="calendar alternate outline" />
            Início: {moment(flow.createdAt).format("L, LT")}
          </Label>
          <Segment style={{ margin: "10px" }} color="yellow">
            <h4>
              Atualmente em:{" "}
              {flow.status.map((item, index) => (
                <div key={index}>
                  •{flow.elements?.find((e) => e.id === item)?.data?.label}
                </div>
              ))}
            </h4>
          </Segment>

          <StickyNotes>
            <StickyTitle>
              <div>Anotações</div>

              {flow.status[0] !== "finished" ? (
                commentEdition === false ? (
                  <Icon
                    style={{
                      position: "absolute",
                      background: "none",
                      padding: 0,
                      cursor: "pointer",
                      right: "20px",
                    }}
                    name="edit"
                    onClick={() => {
                      setCommentEdition(true);
                    }}
                  />
                ) : (
                  <>
                    <Icon
                      style={{
                        position: "absolute",
                        background: "none",
                        padding: 0,
                        cursor: "pointer",
                        left: "20px",
                      }}
                      name="cancel"
                      onClick={() => {
                        setCommentEdition(false);
                      }}
                    />
                    <Icon
                      style={{
                        position: "absolute",
                        background: "none",
                        padding: 0,
                        cursor: "pointer",
                        right: "20px",
                      }}
                      name="check"
                      onClick={() => {
                        setLoading(true);
                        dispatch(
                          handleEditComment(
                            "flow",
                            flow?._id,
                            null,
                            comments,
                            !user.enterpriseId ? user?._id : user.enterpriseId
                          )
                        ).then(() => {
                          setCommentEdition(false);
                          setLoading(false);
                        });
                      }}
                    />
                  </>
                )
              ) : null}
            </StickyTitle>

            {commentEdition ? (
              <Form style={{ marginBottom: "10px" }}>
                <Form.TextArea
                  rows={6}
                  disabled={!commentEdition}
                  name="post"
                  value={comments}
                  onChange={(e) => setComments(e.target.value)}
                />
              </Form>
            ) : (
              <h5
                style={{
                  margin: "10px",
                  overflowWrap: "break-word",
                  textAlign: "left",
                  whiteSpace: "pre-wrap",
                }}
              >
                {flow?.comments}
              </h5>
            )}
          </StickyNotes>

          <Comment.Group
            style={{
              textAlign: "left",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            <Header as="h3" dividing style={{ backgroundColor: "white" }}>
              Comentários
            </Header>

            {flow.posts.map((item, index) => (
              <Comment key={index}>
                <Comment.Content>
                  <Comment.Author as="a">{item.username}</Comment.Author>
                  <Comment.Metadata>
                    <div>{`${moment(item.time).calendar()} | ${moment(
                      item.time
                    ).from(nowLocal)}`}</div>
                  </Comment.Metadata>
                  <Comment.Text>{item.text}</Comment.Text>
                </Comment.Content>
              </Comment>
            ))}

            {flow.status[0] !== "finished" ? (
              <Form onSubmit={handleSubmit}>
                <Form.TextArea
                  name="post"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
                <Button
                  content="Responder"
                  labelPosition="left"
                  icon={<Icon name="edit" style={{ paddingLeft: 2 }} />}
                  primary
                  type="submit"
                />
              </Form>
            ) : null}
          </Comment.Group>
        </Sidebar>

        <StatsModal
          open={visibleRight}
          change={(e) => setVisibleRight(e)}
          flow={flow}
        />
        <FlowOptionsModal
          open={optionsModal}
          change={(e) => setOptionsModal(e)}
          flow={flow}
        />

        <Sidebar.Pusher
          style={{
            display: "flex",
            height: "calc(100vh - (74px))",
            overflow: "hidden",
          }}
        >
          <ReactFlowProvider>
            {match.params.taskId !== "full" && (
              <ZoomNode
                id={match.params.taskId}
                nodes={flow.elements}
                load={load}
              />
            )}
            <Popover
              id={id}
              open={openPopover}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
            >
              {flow.elements.filter((item) => {
                if (
                  item.source === task?.id &&
                  item.data.status === "pending"
                ) {
                  ifDone.push(item);
                }

                return item.source === task?.id && item.data.status === "done";
              }).length === 0 ? (
                <Segment color="yellow">
                  <Header style={{ backgroundColor: "white" }}>
                    <p>ESCOLHA UMA OPÇÃO</p>
                  </Header>
                  {ifDone.map((item, index) => (
                    <button
                      className="ui button"
                      key={index}
                      onClick={() => {
                        setAnchorEl(null);
                        setActualOption(item);
                        setConfirmOpen(true);
                      }}
                    >
                      {item?.data?.text?.toUpperCase()}
                    </button>
                  ))}
                </Segment>
              ) : null}
            </Popover>
            <ButtonTest>
              <Button
                style={{ width: "180px", backgroundColor: "#9e9eff" }}
                icon="info"
                labelPosition="left"
                content="Dados do fluxo"
                onClick={() => setVisible(!visible)}
              />
            </ButtonTest>
            <ButtonTest2>
              <Button
                style={{ width: "180px", backgroundColor: "#beefbe" }}
                icon="line graph"
                labelPosition="left"
                content="Estatísticas"
                onClick={() => setVisibleRight(!visibleRight)}
              />
            </ButtonTest2>
            {user?.rank === "Gerente" && (
              <ButtonTest3>
                <Button
                  style={{ width: "180px" }}
                  icon="settings"
                  labelPosition="left"
                  content="Opções do fluxo"
                  onClick={() => setOptionsModal(true)}
                />
                <div className="buttonTest4">
                  <Button
                    content="Deletar fluxo"
                    icon="trash alternate"
                    labelPosition="left"
                    onClick={() => setConfirmOpenDelete(true)}
                    style={{
                      backgroundColor: "#ffa3a3",
                      marginTop: "5px",
                    }}
                  />
                </div>
                <Confirm
                  open={confirmOpenDelete}
                  content="Tem certeza que deseja excluir este fluxo ativo?"
                  onCancel={handleCancelDelete}
                  onConfirm={handleConfirmDelete}
                  confirmButton="Excluir"
                  cancelButton="Cancelar"
                />
              </ButtonTest3>
            )}

            <ReactFlowWrapper ref={reactFlowWrapper}>
              <ActivedTaskModal
                counterRef={counterRef}
                flow={flow}
                taskId={actualTask}
                open={open}
                change={(e) => setOpen(e)}
              />
              {
                arrowFlag && (
                  <ChangePosition
                    off={() => setArrowFlag(false)}
                    xy={coordinates}
                  />
                ) // ponte entre as funcions e state do ReactFlowProvider
              }
              <TimerModal
                flow={flow}
                taskId={actualTask}
                open={timerOpen}
                change={(e) => setTimerOpen(e)}
              />
              <ReactFlowBoard
                minZoom={0}
                elements={flow.elements}
                onLoad={onLoad}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                nodesDraggable={false}
                nodesConnectable={false}
                elementsSelectable={false}
                onElementClick={(i, e) => {
                  if (
                    e !== null &&
                    (e.type === "task" ||
                      (e.type === "conditional" && e.data.status === "doing") ||
                      e.type === "timerEvent")
                  ) {
                    if (e.type === "timerEvent") {
                      setActualTask(e.id);
                      return setTimerOpen(true);
                    } else if (e.type === "conditional") {
                      setActualTask(e.id);
                      return setAnchorEl(i.currentTarget);
                    } else {
                      setActualTask(e.id);
                      return setOpen(true);
                    }
                  } else return null;
                }}
              >
                <MiniMap
                  nodeStrokeColor={(node) =>
                    node.data.expired === true ? "red" : "black"
                  }
                  style={{ background: "#aaa" }}
                  nodeColor={(node) => {
                    const nowLocal = moment().utcOffset(-180);

                    if (node.type === "customMark") {
                      return node.data.hexColor;
                    } else {
                      if (
                        node.type !== "customText" &&
                        moment(node.data.startedAt)
                          .add(node.data.expiration.number, "hours")
                          .diff(nowLocal, "hours", true) < 0 &&
                        node.data.status !== "done"
                      )
                        return "#ff6666";

                      switch (node.data.status) {
                        case "done":
                          return "#beefbe";
                        case "doing":
                          return "#f2f0a6";
                        default:
                          return "white";
                      }
                    }
                  }}
                />
                <Controls showInteractive={false} />
                <Background />
              </ReactFlowBoard>
            </ReactFlowWrapper>
          </ReactFlowProvider>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

export default FlowViewerActivedPage;

<MiniMap />;
