import React from "react";

const EditIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3195 6.01758H10.2213C7.47605 6.01758 6.37793 7.11569 6.37793 9.86099V13.1553C6.37793 15.9006 7.47605 16.9987 10.2213 16.9987H13.5157C16.261 16.9987 17.3591 15.9006 17.3591 13.1553V12.0572"
        stroke="#A9AEB4"
        strokeWidth="1.12949"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.087 6.57689L9.76042 10.9035C9.5957 11.0682 9.43099 11.3921 9.39804 11.6282L9.16195 13.2809C9.0741 13.8794 9.49687 14.2966 10.0953 14.2143L11.748 13.9782C11.9786 13.9452 12.3026 13.7805 12.4728 13.6158L16.7993 9.28923C17.5461 8.54251 17.8975 7.675 16.7993 6.57689C15.7012 5.47877 14.8337 5.83017 14.087 6.57689Z"
        stroke="#A9AEB4"
        strokeWidth="1.12949"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4668 7.19727C13.8347 8.50951 14.8614 9.53625 16.1791 9.90961"
        stroke="#A9AEB4"
        strokeWidth="1.12949"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default EditIcon;
