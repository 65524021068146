import styled from "styled-components";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

export const Content = styled.div``;

export const ContentText = styled.span``;

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
`;
export const Lock = styled(LockIcon)`
  position: absolute;
  top: -2px;
  right: -25px;
  color: black;
  z-index: 500;
  font-size: 16px;
`;
export const Unlock = styled(LockOpenIcon)`
  position: absolute;
  top: -2px;
  right: -25px;
  color: black;
  z-index: 500;
  font-size: 16px;
`;

export const ContainerInput = styled.div`
  display: flex;
`;

export const ColorDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  h5 {
    margin-left: 15px;
    margin-top: 10px;
  }
  input {
    padding: 2px;
  }
`;

export const ConfirmButton = styled.button`
  display: flex;
  width: 100%;
  height: 20px;
  align-self: center;
  background-color: #44c767;
  border: 1px solid #18ab29;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;

  text-decoration: none;
  text-shadow: 0px 1px 26px #2f6627;

  &:hover {
    background-color: #5cbf2a;
  }
`;
