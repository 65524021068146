import React, { memo } from "react";
import { Handle, useStoreState, Position } from "react-flow-renderer";
import { EventTimer } from "./styles.js";
import TimerNodeIcon from "../../assets/icons/TimerNode.js";
import { useState } from "react";
import TimerModal from "./TimerModal/index.js";
import { Popover } from "@material-ui/core";
import { useRef } from "react";
import TimerPopover from "./TimerPopover/index.js";

const TimerEventNode = ({
  data,
  id,
  targetPosition = Position.Left,
  sourcePosition = Position.Right,
  selected,
}) => {
  const edges = useStoreState((state) => state.edges);
  const nodes = useStoreState((state) => state.nodes);

  const ref = useRef(null);

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const IconStyle = {
    width: "27px",
    height: "27px",
  };

  const HandleStyle = {
    width: "7px",
    height: "7px",
  };

  return (
    <>
      <EventTimer
        ref={ref}
        onDoubleClick={handleOpen}
        className="flow_elements_node"
        data-id={id}
        status={data.status}
        selected={selected}
      >
        <TimerNodeIcon style={IconStyle} />

        <Handle
          type="source"
          position={sourcePosition}
          id="a"
          style={HandleStyle}
          isValidConnection={(e) =>
            nodes.find((item) => item.id === e.target).type === "parallelEnd" ||
            nodes.find((item) => item.id === e.target).type === "conditionalEnd"
              ? !edges.find((item) => item.source === e.source)
              : !edges.find(
                  (item) => item.source === e.source || item.target === e.target
                )
          }
        />
        <Handle
          type="target"
          position={targetPosition}
          id="a"
          style={HandleStyle}
          isValidConnection={(e) =>
            nodes.find((item) => item.id === e.source).type === "parallel" ||
            nodes.find((item) => item.id === e.source).type === "conditional"
              ? true
              : !edges.find(
                  (item) => item.target === e.target || item.source === e.source
                )
          }
        />
        {/* <TimerPopover
          className="nodrag"
          id={id}
          data={data}
          useRef={ref}
          isModalOpen={isModalOpen}
          setModalOpen={setModalOpen}
        /> */}

        <TimerModal
          selector="#root"
          id={id}
          data={data}
          isOpen={isModalOpen}
          onClose={handleClose}
          className="nodrag"
        />
      </EventTimer>
    </>
  );
};

export default memo(TimerEventNode);
