import moment from "moment";
import "moment/min/locales";
import React from "react";
import { useSelector } from "react-redux";
import { findActivedTaskOneFlow } from "../../state/activedFlows/selectors";
import { Button, Header, Progress } from "semantic-ui-react";
import { Modal } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { HeaderModal } from "../ActivedTaskModal/styles";

const TimerModal = ({ flow, taskId, open, change }) => {
  const task = useSelector((state) =>
    findActivedTaskOneFlow(taskId, flow._id, state.activedFlows.entities)
  );
  const nowLocal = moment().utcOffset(-180);

  moment.locale("pt-br");

  return task !== null && task !== undefined ? (
    <Modal
      onClose={() => change(false)}
      onOpen={() => change(true)}
      open={open}
    >
      <Modal.Header>
        <HeaderModal>
          <div>{task.data.label.toUpperCase()}</div>
          <div>
            {task.data.status === "done"
              ? "Temporizador concluído"
              : task.data.status === "doing"
              ? "Temporizador em andamento"
              : "Temporizador pendente"}
          </div>
        </HeaderModal>
      </Modal.Header>
      <Modal.Content scrolling className="content">
        <Progress
          percent={
            task.data.status !== "done"
              ? Math.round(
                  (moment(nowLocal).diff(
                    moment(task.data.startedAt),
                    "hours",
                    true
                  ) /
                    moment(task.data.startedAt)
                      .add(task.data.expiration.number, "hours")
                      .diff(moment(task.data.startedAt), "hours", true)) *
                    100
                )
              : 100
          }
          indicating
          progress
        >
          Andamento da tarefa
        </Progress>

        <HeaderModal>
          {task.data.status === "doing" && task.type === "task" ? (
            moment(task.data.startedAt)
              .add(task.data.expiration.number, "hours")
              .diff(nowLocal, "hours", true) >= 0 ? (
              <h3 style={{ color: "red", marginRight: "50px" }}>
                A tarefa irá expirar em{" "}
                {task.data.expiration.time === "days"
                  ? (
                      moment(task.data.startedAt)
                        .add(task.data.expiration.number, "hours")
                        .diff(nowLocal, "hours", true) / 24
                    ).toFixed(2)
                  : task.data.expiration.time === "hours"
                  ? moment(task.data.startedAt)
                      .add(task.data.expiration.number, "hours")
                      .diff(nowLocal, "hours", true)
                      .toFixed(2)
                  : task.data.expiration.time === "minutes"
                  ? (
                      moment(task.data.startedAt)
                        .add(task.data.expiration.number, "hours")
                        .diff(nowLocal, "hours", true) * 60
                    ).toFixed(2)
                  : "indefinido"}{" "}
                {task.data.expiration.time === "days"
                  ? "dias"
                  : task.data.expiration.time === "hours"
                  ? "horas"
                  : task.data.expiration.time === "minutes"
                  ? "minutos"
                  : "indefinido"}
              </h3>
            ) : (
              <h3 style={{ color: "red", marginRight: "50px" }}>
                TAREFA EXPIRADA HÁ{" "}
                {Math.round(
                  nowLocal.diff(
                    moment(task?.data?.startedAt).add(
                      task.data.expiration.number,
                      "hours"
                    ),
                    "hours",
                    true
                  ) / 24
                )}{" "}
                DIAS
              </h3>
            )
          ) : null}

          {task.data.startedAt ? (
            <div style={{ marginBottom: "20px" }}>
              <h3 style={{ marginRight: "50px" }}>
                Início: {moment(task.data.startedAt).format("LLL")}
              </h3>
            </div>
          ) : null}
          {task.data.finishedAt ? (
            <div style={{ marginBottom: "20px" }}>
              <h3 style={{ marginRight: "50px" }}>
                Fim: {moment(task.data.finishedAt).format("LLL")}
              </h3>
            </div>
          ) : null}
        </HeaderModal>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => change(false)} content="Voltar" />
      </Modal.Actions>
    </Modal>
  ) : (
    <Modal
      onClose={() => change(false)}
      onOpen={() => change(true)}
      open={open}
    >
      <div>wait</div>
    </Modal>
  );
};

export default TimerModal;
