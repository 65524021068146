import React, { memo, useCallback, useEffect, useState } from "react";
import { useZoomPanHelper } from "react-flow-renderer";
import FitViewIcon from "../../../assets/icons/FitViewCurved";
import LockGreen from "../../../assets/icons/LockGreen";
import MinusIcon from "../../../assets/icons/MinusIcon";
import PlusIcon from "../../../assets/icons/PlusIcon";
import UnlockGray from "../../../assets/icons/UnlockGray";
import { Button, Container } from "./styles";

export const ControlButton = ({ children, className, ...rest }) => (
  <Button {...rest}>{children}</Button>
);

const Controls = ({
  style,
  fitViewParams,
  onZoomIn,
  onZoomOut,
  onFitView,
  lock = false,
  setUnlock,
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const { zoomIn, zoomOut, fitView } = useZoomPanHelper();

  const onZoomInHandler = useCallback(() => {
    zoomIn?.();
    onZoomIn?.();
  }, [zoomIn, onZoomIn]);

  const onZoomOutHandler = useCallback(() => {
    zoomOut?.();
    onZoomOut?.();
  }, [zoomOut, onZoomOut]);

  const onFitViewHandler = useCallback(() => {
    fitView?.(fitViewParams);
    onFitView?.();
  }, [fitView, fitViewParams, onFitView]);

  useEffect(() => {
    setIsVisible(true);
  }, []);

  if (!isVisible) {
    return null;
  }

  return (
    <Container>
      <ControlButton onClick={onZoomInHandler}>
        <PlusIcon />
      </ControlButton>
      <ControlButton onClick={onZoomOutHandler}>
        <MinusIcon />
      </ControlButton>
      <ControlButton onClick={onFitViewHandler}>
        <FitViewIcon />
      </ControlButton>
      <ControlButton onClick={() => setUnlock((prev) => !prev)}>
        <LockGreen color={!lock ? "#D4D6DA" : "#40e5A0"} />
      </ControlButton>
    </Container>
  );
};

Controls.displayName = "Controls";

export default memo(Controls);
