import { Popover } from "@material-ui/core";
import React, { memo, useEffect, useState } from "react";
import { Handle, useStoreState, Position } from "react-flow-renderer";
import { useDispatch } from "react-redux";
import { Input } from "semantic-ui-react";
import { updateTaskTitle } from "../../state/flows/actions.js";
import { updateTaskNodeLABEL } from "../../state/undoFlow/actions.js";
import { TaskStyle } from "./styles.js";

const TaskNode = ({
  data,
  targetPosition = Position.Left,
  sourcePosition = Position.Right,
  id,
  selected,
}) => {
  const edges = useStoreState((state) => state.edges);
  const nodes = useStoreState((state) => state.nodes);

  // const flowId = useStoreState(
  //   (state) => state.nodes.find((item) => item.flowId)?.flowId
  // );

  const [anchor, setAnchor] = useState(null);
  const [text, setText] = useState(data.label);
  const [open, setOpen] = useState(false);
  const idOpa = open ? "simple-popover" : undefined;

  const textValidation = (text) => {
    if (data.label === text) {
      return false;
    } else {
      return true;
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (!open) {
      if (textValidation(text)) setText(data.label);
    }
  }, [open, data]);

  useEffect(() => {
    if (textValidation(text)) {
      dispatch(updateTaskNodeLABEL({ id, text }));
    }
  }, [open]);

  return (
    <>
      <TaskStyle
        status={data.status}
        selected={selected}
        onDoubleClick={(event) => {
          setAnchor(event.target);
          setOpen(true);
        }}
      >
        <Handle
          type="target"
          id="c"
          position={targetPosition}
          style={{ width: "8px", height: "8px" }}
          isValidConnection={(e) =>
            nodes.find((item) => item.id === e.source).type === "parallel" ||
            nodes.find((item) => item.id === e.source).type === "conditional"
              ? true
              : !edges.find(
                  (item) => item.target === e.target || item.source === e.source
                )
          }
        />
        <div style={{ wordBreak: "break-word" }}>{text.toUpperCase()}</div>
        <Handle
          type="source"
          position={sourcePosition}
          id="a"
          style={{ width: "8px", height: "8px" }}
          isValidConnection={(e) =>
            nodes.find((item) => item.id === e.target).type === "parallelEnd" ||
            nodes.find((item) => item.id === e.target).type === "conditionalEnd"
              ? !edges.find((item) => item.source === e.source)
              : !edges.find(
                  (item) => item.source === e.source || item.target === e.target
                )
          }
        />
      </TaskStyle>
      <Popover
        className="nodrag"
        id={idOpa}
        open={open}
        anchorEl={anchor}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Input
          type="text"
          value={text.toUpperCase()}
          onChange={(e) => {
            e.preventDefault();
            const pointer = e.target.selectionStart;

            window.requestAnimationFrame(() => {
              e.target.selectionStart = pointer;
              e.target.selectionEnd = pointer;
            });
            setText(e.target.value.toUpperCase());
          }}
        />
      </Popover>
    </>
  );
};

export default memo(TaskNode);
