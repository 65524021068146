import React from "react";

import {
  EventStart,
  DragStyle,
  ConditionalStyle,
  TaskStyle,
  ConditionalEndStyle,
  ConditionalEndStyleInside,
  SidebarStyle,
  TopicStyle,
} from "./styles.js";

import { Play, Square, Plus, Clock } from "react-feather";

const Sidebar = () => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };
  return (
    <SidebarStyle>
      <div style={{ marginBottom: "10px" }}>
        <h5 style={{ userSelect: "none", cursor: "default" }}>
          Dica: Você pode arrastar os componentes para a mesa de criação!
        </h5>
      </div>

      <h3 style={{ userSelect: "none", cursor: "default" }}>EVENTOS</h3>
      <TopicStyle>
        <DragStyle>
          <h4 style={{ userSelect: "none", cursor: "default" }}>Início</h4>
          <div style={{ cursor: "move" }}>
            <EventStart
              onDragStart={(event) => onDragStart(event, "eventStart")}
              draggable
            >
              <Play size={20} />
            </EventStart>
          </div>
        </DragStyle>
        <DragStyle>
          <h4 style={{ userSelect: "none", cursor: "default" }}>Fim</h4>
          <EventStart
            style={{ cursor: "move" }}
            onDragStart={(event) => onDragStart(event, "eventEnd")}
            draggable
          >
            <Square size={20} />
          </EventStart>
        </DragStyle>
      </TopicStyle>

      <DragStyle>
        <h4 style={{ userSelect: "none", cursor: "default" }}>Temporizador</h4>
        <EventStart
          style={{ cursor: "move" }}
          onDragStart={(event) => onDragStart(event, "timerEvent")}
          draggable
        >
          <Clock size={20} />
        </EventStart>
      </DragStyle>

      <h3 style={{ userSelect: "none", cursor: "default" }}>ATIVIDADES</h3>
      <TopicStyle>
        <DragStyle>
          <TaskStyle
            style={{ cursor: "move" }}
            onDragStart={(event) => onDragStart(event, "task")}
            draggable
          >
            Tarefa
          </TaskStyle>
        </DragStyle>
      </TopicStyle>

      <h3 style={{ userSelect: "none", cursor: "default" }}>CONDICIONAIS</h3>
      <TopicStyle>
        <DragStyle>
          <h4 style={{ userSelect: "none", cursor: "default" }}>Início</h4>
          <ConditionalStyle
            style={{ cursor: "move" }}
            onDragStart={(event) => onDragStart(event, "conditional")}
            draggable
          ></ConditionalStyle>
        </DragStyle>
        <DragStyle>
          <h4 style={{ userSelect: "none", cursor: "default" }}>Fim</h4>
          <ConditionalEndStyle
            style={{ cursor: "move" }}
            onDragStart={(event) => onDragStart(event, "conditionalEnd")}
            draggable
          >
            <ConditionalEndStyleInside />
          </ConditionalEndStyle>
        </DragStyle>
      </TopicStyle>

      <h3 style={{ userSelect: "none", cursor: "default" }}>PARALELOS</h3>
      <TopicStyle>
        <DragStyle>
          <h4 style={{ userSelect: "none", cursor: "default" }}>Início</h4>
          <ConditionalStyle
            style={{ cursor: "move" }}
            onDragStart={(event) => onDragStart(event, "parallel")}
            draggable
          >
            <Plus style={{ transform: "rotate(45deg)" }} size={50} />
          </ConditionalStyle>
        </DragStyle>
        <DragStyle>
          <h4 style={{ userSelect: "none", cursor: "default" }}>Fim</h4>
          <ConditionalEndStyle
            style={{ cursor: "move" }}
            onDragStart={(event) => onDragStart(event, "parallelEnd")}
            draggable
          >
            <ConditionalEndStyleInside>
              <Plus style={{ transform: "rotate(45deg)" }} size={50} />
            </ConditionalEndStyleInside>
          </ConditionalEndStyle>
        </DragStyle>
      </TopicStyle>
      <h3 style={{ userSelect: "none", cursor: "default" }}>MARCAÇÃO</h3>
      <TopicStyle>
        <DragStyle>
          <TaskStyle
            style={{ cursor: "move" }}
            onDragStart={(event) => onDragStart(event, "customMark")}
            draggable
          ></TaskStyle>
        </DragStyle>
      </TopicStyle>

      <TopicStyle>
        <DragStyle>
          <TaskStyle
            style={{ cursor: "move" }}
            onDragStart={(event) => onDragStart(event, "customText")}
            draggable
          >
            <h4>TEXTO</h4>
          </TaskStyle>
        </DragStyle>
      </TopicStyle>
    </SidebarStyle>
  );
};
export default Sidebar;
