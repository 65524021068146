import styled from "styled-components";

export const ConditionalStyle = styled.div`
  width: 50px;
  height: 50px;
  box-shadow: ${({ selected }) =>
    selected === true ? "0 0 0 1px blue" : " 0 0 0 0.5px #1a192b"};
  box-sizing: border-box;
  border-radius: 8px;
  transform: rotate(-45deg);
  background-color: ${({ status }) =>
    status === "done" ? "#beefbe" : status === "doing" ? "#f2f0a6" : "white"};
`;

export const Condi = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;
