import React from "react";

const Exclamation = ({ style }) => (
  <svg
    style={style}
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 18.3333C15.5834 18.3333 19.3334 14.5833 19.3334 9.99996C19.3334 5.41663 15.5834 1.66663 11 1.66663C6.41669 1.66663 2.66669 5.41663 2.66669 9.99996C2.66669 14.5833 6.41669 18.3333 11 18.3333Z"
      stroke="#118052"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M11 6.66663V10.8333"
      stroke="#118052"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.9955 13.3334H11.003"
      stroke="#118052"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Exclamation;
