import { createAction } from "@reduxjs/toolkit";
import api from "../../services/api";

export const deleteUser = createAction("DELETE_USER");
export const signUp = createAction("SIGN_UP");
export const addError = createAction("ADD_ERROR");
export const clearUsers = createAction("CLEAR_USERS");
export const sendEmail = createAction("SEND_EMAIL");
export const resendEmail = createAction("RESEND_EMAIL");

export const handleDeleteUser = ({ username, enterpriseId }) => {
  return (dispatch) =>
    api
      .delete(`/employees/employee/delete/${username}/${enterpriseId}`)
      .then((res) => dispatch(deleteUserSucess(res.data)))
      .catch((err) => dispatch(deleteUserFailure(err.message)));
};

const deleteUserSucess = (payload) => deleteUser(payload);

const deleteUserFailure = (error) => deleteUser(error);

export const handleSignUp = ({ username, password, email }) => {
  return (dispatch) =>
    api
      .post("/auth/sign-up", {
        username,
        password,
        email,
      })
      .then((res) => dispatch(signUpSucess(res.data)))
      .catch((err) => dispatch(signUpFailure(err.message)));
};
const signUpSucess = (payload) => signUp(payload);
const signUpFailure = (error) => addError(error);

export const handleSendEmail = ({ name, enterpriseId, email, rank }) => {
  return (dispatch) =>
    api
      .put("/users/send-register-link", {
        name,
        enterpriseId,
        email,
        rank,
      })
      .then((res) => dispatch(sendEmailSucess(res.data)))
      .catch((err) => dispatch(sendEmailFailure(err.message)));
};
const sendEmailSucess = (payload) => sendEmail(payload);
const sendEmailFailure = (error) => sendEmail(error);

export const handleResendEmail = ({ id }) => {
  return (dispatch) =>
    api
      .put("/users/resend-email", {
        id,
      })
      .then((res) => dispatch(resendEmailSucess(res.data)))
      .catch((err) => dispatch(resendEmailFailure(err.message)));
};
const resendEmailSucess = (payload) => resendEmail(payload);
const resendEmailFailure = (error) => resendEmail(error);
