import React from "react";

const LockGreen = ({ width, height, style, onClick, color = "#40E5A0" }) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      onClick={onClick}
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.5 8.83329V7.16663C4.5 4.40829 5.25 2.16663 9 2.16663C12.75 2.16663 13.5 4.40829 13.5 7.16663V8.83329"
        stroke={color}
        strokeWidth="1.96988"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.37497 15.9168C10.6176 15.9168 11.625 14.984 11.625 13.8335C11.625 12.6829 10.6176 11.7501 9.37497 11.7501C8.13233 11.7501 7.12497 12.6829 7.12497 13.8335C7.12497 14.984 8.13233 15.9168 9.37497 15.9168Z"
        stroke={color}
        strokeWidth="1.96988"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.75 18.8333H5.24997C2.24997 18.8333 1.49997 17.9999 1.49997 14.6666V12.9999C1.49997 9.66659 2.24997 8.83325 5.24997 8.83325H12.75C15.75 8.83325 16.5 9.66659 16.5 12.9999V14.6666C16.5 17.9999 15.75 18.8333 12.75 18.8333Z"
        fill={color}
      />
    </svg>
  );
};

export default LockGreen;
