import React, { useRef, useState } from "react";
import { DropDown, Label, Button, List, ListItem } from "./styles";
import ArrowDown from "../../../assets/icons/DropArrowdown";

import Alert from "../Alert";

import { useDispatch } from "react-redux";

import { useOutsideDrop } from "../../hooks/useOutsideDrop";
import { handleValidation } from "../../../utils";

const SaveButton = ({
  state,
  title,
  version,
  setVersion,
  allVersions,
  elements,
  user,
  flowId = false,
  originalId,
  defaultVersion,
}) => {
  const [drop, setDrop] = useState(false);
  const wrapperRef = useRef(null);
  const clearDropdown = () => setDrop(false);

  useOutsideDrop(wrapperRef, clearDropdown);

  //Modals
  const [showSave, setShowSave] = useState(false);
  const [showNewVersion, setShowNewVersion] = useState(false);
  const [showRename, setShowRename] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  //Alert
  const [openAlert, setOpenAlert] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);

  const [typeAlert, setTypeAlert] = useState("error");

  const dispatch = useDispatch();

  //Validation
  const validationFlow = (showModal) => {
    handleValidation(
      elements,
      setOpenAlert,
      setErrorMsg,
      setTypeAlert,
      showModal
    );
  };

  const handleSave = (showModal) => {
    setDrop(false);
    validationFlow(showModal);
  };

  const handleOpen = (showModal, typeAlert) => {
    setDrop(false);
    showModal(true);
  };

  const handleNewDefault = () => {};

  return (
    <>
      <DropDown drop={drop} ref={wrapperRef}>
        <Label onClick={() => setShowSave(true)} drop={drop}>
          <p>Salvar Modelo</p>
        </Label>
        <Button drop={drop} onClick={() => setDrop((prev) => !prev)}>
          <ArrowDown />
        </Button>

        <List drop={drop}>
          <ListItem disabled={state === "creation"}>Salvar</ListItem>
          <ListItem
            disabled={state === "creation"}
            onClick={() =>
              state !== "creation" ? handleSave(setShowNewVersion) : undefined
            }
          >
            Salvar como nova versão
          </ListItem>
          <ListItem
            disabled={state === "creation"}
            onClick={state !== "creation" ? handleNewDefault : undefined}
          >
            Definir como modelo padrão
          </ListItem>
          <ListItem
            disabled={state === "creation"}
            onClick={() =>
              state !== "creation"
                ? handleOpen(setShowRename, "rename")
                : undefined
            }
          >
            Renomear atual versão
          </ListItem>
          <ListItem
            disabled={state === "creation"}
            onClick={() =>
              state !== "creation"
                ? handleOpen(setShowDelete, "delete")
                : undefined
            }
          >
            Excluir atual versão
          </ListItem>
        </List>
      </DropDown>

      {/* <Save
        title={title}
        version={version}
        state={state}
        setVersion={setVersion}
        allVersions={allVersions}
        elements={elements}
        user={user}
        flowId={flowId}
        originalId={originalId}
        selector="#root"
        className="nodrag"
        isOpen={showSave}
        onClose={() => setShowSave(false)}
        setTypeAlert={setTypeAlert}
        setOpenAlert={setOpenAlert}
        setErrorMsg={setErrorMsg}
      /> */}

      {/* <SaveNewVersion
        title={title}
        version={version}
        setVersion={setVersion}
        allVersions={allVersions}
        state={state}
        elements={elements}
        user={user}
        originalId={originalId}
        selector="#root"
        className="nodrag"
        isOpen={showNewVersion}
        onClose={() => setShowNewVersion(false)}
        setTypeAlert={setTypeAlert}
        setOpenAlert={setOpenAlert}
        setErrorMsg={setErrorMsg}
      />
      <RenameVersion
        title={title}
        version={version}
        setVersion={setVersion}
        allVersions={allVersions}
        state={state}
        elements={elements}
        user={user}
        flowId={flowId}
        originalId={originalId}
        selector="#root"
        className="nodrag"
        isOpen={showRename}
        onClose={() => setShowRename(false)}
        setTypeAlert={setTypeAlert}
        setOpenAlert={setOpenAlert}
        setErrorMsg={setErrorMsg}
      />

      <DeleteVersion
        title={title}
        version={version}
        setVersion={setVersion}
        defaultVersion={defaultVersion}
        state={state}
        elements={elements}
        user={user}
        flowId={flowId}
        originalId={originalId}
        selector="#root"
        className="nodrag"
        isOpen={showDelete}
        onClose={() => setShowDelete(false)}
        setTypeAlert={setTypeAlert}
        setOpenAlert={setOpenAlert}
        setErrorMsg={setErrorMsg}
      /> */}

      <Alert
        open={openAlert}
        setOpen={(e) => setOpenAlert(e)}
        version={version}
        type={typeAlert}
        errorMsg={errorMsg}
        selector="#root"
      />
    </>
  );
};

export default SaveButton;
