import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Dimmer, Loader, Segment } from "semantic-ui-react";
import EmployeeModal from "./EmployeeModal/index";
import { fetchUsers } from "../../state/users/reducers";
import { ContainerCenter, Dashboard } from "./styles";
import Loading from "../../components/LoadingTemplate";
import { filterUsers } from "../../state/users/selectors";

const DashboardEmployeesPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const users = useSelector((state) =>
    filterUsers(state.users.entities, state.auth.user._id)
  );
  const usersStatus = useSelector((state) => state.users.status);
  const [username, setUsername] = useState("none");
  const [userEmail, setUserEmail] = useState("none");
  const [open, setOpen] = useState(false);
  const token = useSelector((state) => state.auth?.token);
  const enterpriseUser = useSelector((state) => state.auth.user);
  const enterpriseId = !enterpriseUser.enterpriseId
    ? enterpriseUser._id
    : enterpriseUser.enterpriseId;
  const [user, setUser] = useState();

  useEffect(() => {
    dispatch(fetchUsers(enterpriseId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (!token) history.push("/login");
  }, [history, token]);

  return usersStatus === "succeeded" ? (
    <ContainerCenter>
      <Segment compact>
        <EmployeeModal
          enterpriseId={enterpriseId}
          username={username}
          email={userEmail}
          open={open}
          change={(e) => setOpen(e)}
          user={user}
        />
        <Button
          style={{ marginLeft: "50px", marginBottom: 30 }}
          labelPosition="right"
          icon="plus"
          content="Novo funcionário"
          onClick={() => history.push("/newaccountsend")}
        />
        <Dashboard style={{ marginBottom: 30 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <h3>USUÁRIOS AGUARDANDO CONFIRMAÇÃO</h3>
            </div>
          </div>
          <Segment
            color="yellow"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {users?.length === 0 ? (
              <p>Não existe funcionários cadastrados</p>
            ) : (
              users.map(
                (e, index) =>
                  e?.status === "pending" && (
                    <Button
                      key={index}
                      style={{ marginTop: "10px" }}
                      onClick={() => {
                        setUsername(e.username);
                        setOpen(true);
                        setUserEmail(e.email);
                        setUser(e);
                      }}
                    >
                      {e.username}
                    </Button>
                  )
              )
            )}
          </Segment>
        </Dashboard>
        <Dashboard style={{ marginBottom: 30 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <h3>GERENTES</h3>
            </div>
          </div>
          <Segment
            color="yellow"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {users?.length === 0 ? (
              <p>Não existe funcionários cadastrados</p>
            ) : (
              users.map(
                (e, index) =>
                  e.rank === "Gerente" &&
                  e.status !== "pending" && (
                    <Button
                      key={index}
                      style={{ marginTop: "10px" }}
                      onClick={() => {
                        setUsername(e.username);
                        setOpen(true);
                        setUserEmail(e.email);
                      }}
                    >
                      {e.username}
                    </Button>
                  )
              )
            )}
          </Segment>
        </Dashboard>
        <Dashboard style={{ marginBottom: 30 }}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <div>
              <h3>COLABORADORES</h3>
            </div>
          </div>
          <Segment
            color="yellow"
            style={{ display: "flex", flexDirection: "column" }}
          >
            {users?.length === 0 ? (
              <p>Não existe funcionários cadastrados</p>
            ) : (
              users.map(
                (e, index) =>
                  e.rank === "Funcionário" &&
                  e.status !== "pending" && (
                    <Button
                      key={index}
                      style={{ marginTop: "10px" }}
                      onClick={() => {
                        setUsername(e.username);
                        setOpen(true);
                        setUserEmail(e.email);
                      }}
                    >
                      {e.username}
                    </Button>
                  )
              )
            )}
          </Segment>
        </Dashboard>
      </Segment>
    </ContainerCenter>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

export default DashboardEmployeesPage;
