import styled from "styled-components";

export const DropDown = styled.div`
  display: flex;
  position: relative;
  user-select: none;
  font-family: Sora;
  width: 10.176875rem;
  height: 2.470625rem;
  z-index: 99;

  p {
    font-weight: 600;
  }

  &:hover {
    div,
    label {
      background: ${({ drop }) => !drop && "#42464c"};
    }
    p {
      color: ${({ drop }) => !drop && "#d4d6da"};
    }
    div {
      path {
        stroke: ${({ drop }) => !drop && "#7e858f"};
      }
    }
  }
`;

export const Label = styled.label`
  display: flex;
  font-size: 0.705625rem; //11.29px;
  width: 8.6875rem; //139px
  height: 2.470625rem; //39.53px;
  background: ${({ drop }) => (drop ? "#42464C" : "#585e66")};
  border-radius: 0.25rem 0 0 0.25rem; //6px 0px 0px 6px;
  align-items: center;
  justify-content: center;
  color: #fff;
  cursor: pointer;
`;

export const Button = styled.div`
  background: ${({ drop }) => (drop ? "#2D2F32" : "#585e66")};
  box-shadow: none;
  margin-left: 1px;
  border-radius: 0 0.25rem 0.25rem 0; //0px 6px 6px 0px;
  width: 1.411875rem; //22.59px;
  height: 2.470625rem; //39.53px;

  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  path:first-child {
    stroke: ${({ drop }) => (drop ? "#7e858f" : "#EFF1F5")};
  }
`;

export const List = styled.ul`
  position: absolute;
  display: ${({ drop }) => (drop ? "flex" : "none")};
  flex-direction: column;
  top: 100%;
  width: 12.648125rem; //202.37px

  background: #2d2f32;
  border: 0.8px solid #d4d6da;
  border-radius: 0.25rem; //6px;
  color: #eff1f5;
  list-style-type: none;
  margin-top: 0.25rem;
  border: 1px solid #ccc;
  padding: 0;
  font-size: 0.705625rem; //11.29px;
  height: 9.706875rem;

  li:first-child {
    margin-top: 0.3125rem;
  }
`;
export const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 0.404375rem 0 0.426875rem 0.82375rem;
  height: 1.70625rem; //27.3x;
  width: 100%;
  line-height: 0.625rem; //15px;
  margin-top: 2px;
  color: ${({ disabled }) => (disabled ? "gray" : "#fff")};

  cursor: pointer;

  &:hover {
    background: #212326;

    div:first-child {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Test = styled.div`
  display: none;
  position: absolute;
  right: -6.25rem;
  top: 7.5rem;
  background: lightgray;
  width: 6.25rem; //100px
  height: 6.25rem;
  color: #111;

  span {
    &:hover {
      color: red;
    }
  }
`;
