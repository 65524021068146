import { createAction } from "@reduxjs/toolkit";

// ? Flow Page
export const clearUndoFlow = createAction("CLEAR_UNDO_FLOW");
export const updateFlow = createAction("UPDATE_FLOW");
export const updateDrop = createAction("UPDATE_DROP");
export const updateRemove = createAction("UPDATE_REMOVE");
export const updateAddEdge = createAction("UPDATE_ADD_EDGE");

// ? CustomMark
export const updateCustomMark = createAction("CUSTOM_MARK_UPDATE");

// ? CustomText
export const updateCustomTextFORM = createAction("CUSTOM_TEXT_FORM");

// ? TaskNode
export const updateTaskNodeLABEL = createAction("TASKNODE_LABEL");

// ? ConditionalNode
export const updateConditionalNodeLABEL = createAction("COND_NODE_LABEL");

// ? CustomEdge
export const updateCustomEdgeLABEL = createAction("CUSTOM_EDGE_LABEL");
