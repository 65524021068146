import React from "react";
import ParallelEndIcon from "../../../../assets/SideBar/ParallelEndIcon";
import ParallelStartIcon from "../../../../assets/SideBar/ParallelStartIcon";
import { Container, Title, Draggable, ParallelNode, IconDiv } from "./styles";

const Parallels = ({ Label, onDrag }) => {
  return (
    <Container>
      <Title>{Label}</Title>
      <Draggable>
        <ParallelNode style={{ marginRight: "0.125rem" }}>
          <IconDiv draggable onDragStart={(event) => onDrag(event, "parallel")}>
            <ParallelStartIcon />
          </IconDiv>
          <span>Início</span>
        </ParallelNode>
        <ParallelNode>
          <IconDiv
            draggable
            onDragStart={(event) => onDrag(event, "parallelEnd")}
          >
            <ParallelEndIcon />
          </IconDiv>
          <span>Fim</span>
        </ParallelNode>
      </Draggable>
    </Container>
  );
};

export default Parallels;
