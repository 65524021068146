import styled from "styled-components";
import theme from "../../styles/theme";
import Fluxo from "../../assets/icons/Fluxo.js";

export const Container = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;

  opacity: ${({ animation }) => (animation ? "0" : "1")};

  pointer-events: ${({ animation }) => (animation ? "none" : "auto")};
  user-select: ${({ animation }) => (animation ? "none" : "auto")};
  transition: opacity 1s linear 0.5s;
  position: relative;
  overflow-y: hidden;
`;
export const ContainerLogin = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background: #fff;
  min-width: 34.16rem;
  height: 100%;
  align-items: center;
`;
export const Logo = styled.img`
  width: 2.11875rem; //50.85px;
  z-index: 5;
`;

export const ContentLogin = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 16.481rem; //48.24%; //395,56px
  height: 25.9rem; //57.5509%; //621,6px
  align-items: center;
  //margin-bottom: 7rem; //7.93875rem; //190.47px
  margin-top: 5rem;
`;

export const Header = styled.p`
  font-size: 1.4167rem; //34px
  font-weight: 600;
  line-height: 1.125rem;
  margin: 1.7654rem 0 0 0;
  color: ${theme.defaultGray};
`;
export const SubHeader = styled.p`
  color: #7e858f;
  margin: 1.2358rem 0 3.655rem 0;
  font-size: 0.825rem; //19.8px
  line-height: 1.05917rem; //25.42px
`;

export const ContainerCheck = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 1.08rem; //50px-25px(padding do input)
  margin-bottom: 1.33rem;
`;

export const Footer = styled.div`
  font-size: 0.64583rem;
  margin-top: 15%;
  color: #585e66;

  span {
    text-decoration: underline;
  }
`;

export const CallIcon = styled.img`
  margin-right: 0.70833rem;
`;

export const ContainerBanner = styled.div`
  position: relative;
  background: linear-gradient(
    179.67deg,
    rgba(64, 229, 160, 0.94) 0.31%,
    rgba(64, 229, 160, 0.47) 75.74%,
    rgba(64, 229, 160, 0) 91.98%
  );
  width: 100%; //45.833rem; //57.29%;
`;

export const BannerDiv = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
`;
export const FlowDiv = styled.div`
  margin-top: 2.208rem;
  width: 100%;
  height: 18.667rem;
  display: flex;
  justify-content: center;
  z-index: 1;
`;
export const ContentBanner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;
export const Logomark = styled.img`
  width: 9.408rem;
  height: 2.4708rem;
  margin-top: 4.41rem;
  z-index: 1;
`;
export const Flow = styled(Fluxo)``;
export const Title = styled.div`
  font-size: 46px; //2.117rem;
  color: #42464c;
  font-weight: 700;
  line-height: 3.06rem;
  text-align: center;
  width: 36.282rem; //870.77px
  margin-bottom: 0.8829rem;
  z-index: 1;
`;
export const Subtitle = styled.p`
  font-size: 1.0416rem;
  color: #585e66;
  text-align: center;
  width: 31.46rem; //755.05px
  z-index: 1;
`;
