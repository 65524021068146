import ConditionalEndNode from "./components/ConditionalEndNode";
import ConditionalNode from "./components/ConditionalNode";
import CustomEdge from "./components/CustomEdge";
import CustomEdgeWithoutLabel from "./components/CustomEdgeWithoutLabel";
import CustomMark from "./components/CustomMark";
import CustomText from "./components/CustomText";
import EventEndNode from "./components/EventEndNode";
import EventStartNode from "./components/EventStartNode";
import ParallelEndNode from "./components/ParallelEndNode";
import ParallelNode from "./components/ParallelNode";
import TaskNode from "./components/general/FlowElements/Task";
import TimerEventNode from "./components/TimerEventNode";
import { v4 as uuid } from "uuid";
import { ItemMeta } from "semantic-ui-react";

export const edgeTypes = {
  customEdge: CustomEdge,
  customEdgeWithoutLabel: CustomEdgeWithoutLabel,
};

export const nodeTypes = {
  conditional: ConditionalNode,
  conditionalEnd: ConditionalEndNode,
  parallel: ParallelNode,
  parallelEnd: ParallelEndNode,
  task: TaskNode,
  eventStart: EventStartNode,
  eventEnd: EventEndNode,
  timerEvent: TimerEventNode,
  customMark: CustomMark,
  customText: CustomText,
};

export const lockStyles = {
  width: "2rem",
  height: "2rem",
  zIndex: "50",
  cursor: "pointer",
};

export const handleValidation = (
  elements,
  setOpenAlert,
  setErrorMsg,
  setTypeAlert,
  showModal = () => null
) => {
  let validation = false;

  ///SE TIVER VAZIO
  if (elements.length === 0) validation = "Fluxo vazio";
  ////SE TEM EVENTO DE INÍCIO E EVENTO DE FIM
  else if (!elements.find((e) => e.type === "eventStart"))
    validation = "Falta evento de início";
  else if (!elements.find((e) => e.type === "eventEnd"))
    validation = "Falta evento de fim";
  ///SE EXISTE O MESMO NÚMERO DE INÍCIOS E FINS DE PARALELOS
  else {
    let condStart = 0;
    let condEnd = 0;
    let paraStart = 0;
    let paraEnd = 0;
    let eventStart = 0;
    let eventEnd = 0;

    elements.forEach((e) => {
      if (e.type === "conditional") condStart += 1;
      if (e.type === "conditionalEnd") condEnd += 1;
      if (e.type === "parallel") paraStart += 1;
      if (e.type === "parallelEnd") paraEnd += 1;
      if (e.type === "eventStart") eventStart += 1;
      if (e.type === "eventEnd") eventEnd += 1;
    });

    if (eventStart > 1)
      validation = "Somente é permitido 1 (um) evento de inicio.";
    if (eventEnd > 1) validation = "Somente é permitido 1(um) evento de fim";
    if (condStart !== condEnd)
      if (condStart > condEnd) validation = "Falta fechar condicional";
      else validation = "Falta abrir condicional";
    else if (paraStart !== paraEnd)
      if (paraStart > paraEnd) validation = "Falta fechar paralelo";
      else validation = "Falta abrir paralelo";
  }
  //////VERIFICAR SE TODOS OS NÓS ESTÃO LIGADOS
  if (!validation)
    elements.forEach((item) => {
      if (
        !item.source &&
        item.type !== "customMark" &&
        item.type !== "customText"
      ) {
        if (item.type === "eventStart") {
          if (!elements.find((e) => e.source === item.id))
            validation = "Falta ligar componentes";
        } else if (item.type === "eventEnd") {
          if (!elements.find((e) => e.target === item.id))
            validation = "Falta ligar componentes";
        } else {
          console.log(item);
          if (!elements.find((e) => e.target === item.id)) {
            validation = "Falta ligar componentes";
          }
          if (!elements.find((e) => e.source === item.id)) {
            validation = "Falta ligar componentes";
          }
        }
      }
    });

  if (validation) {
    setTypeAlert("error");
    setErrorMsg(validation);
    setOpenAlert(true);
    return false;
  } else {
    showModal(true);
    return true;
  }
};

export const getNodeType = (position, type) => {
  let newNode = {};

  if (type === "") {
    return false;
  }

  switch (type) {
    case "customText":
      newNode = {
        id: uuid(),
        type,
        position,
        data: {
          label: "CAIXA DE TEXTO",
          status: "model",
          hexColor: "#111111",
          fontSize: 16,
          draggable: true,
        },
      };
      break;
    case "customMark":
      newNode = {
        id: uuid(),
        type,
        position,
        data: {
          label: "Marcador",
          status: "model",
          hexColor: "#47977f6f",
          draggable: true,
          refData: {
            width: 300,
            height: 100,
          },
        },
      };
      break;
    case "task":
      newNode = {
        id: uuid(),
        type,
        position,
        data: {
          label: "Tarefa",
          status: "model",
          expiration: {
            number: 5 * 24,
            time: "days",
          },
          attachFile: false,
          lockTask: false,
          subtasks: [],
        },
        targetPosition: "left",
        sourcePosition: "right",
      };
      break;

    case "timerEvent":
      newNode = {
        id: uuid(),
        type,
        position,
        data: {
          status: "model",
          expiration: {
            number: 5 * 24,
            time: "days",
          },
        },
      };
      break;

    default:
      newNode = {
        id: uuid(),
        type,
        position,
        data: {
          label:
            type === "conditional"
              ? "Início da condicional"
              : type === "eventEnd"
              ? "Evento de fim"
              : type === "eventStart"
              ? "Evento de início"
              : type === "conditionalEnd"
              ? "Fim da condicional"
              : type === "parallel"
              ? "Paralelo"
              : type === "parallelEnd"
              ? "Fim do paralelo"
              : type === "timerEvent"
              ? "Temporizador"
              : null,
          status: "model",
        },
        targetPosition: "left",
        sourcePosition: "right",
      };
  }

  return newNode;
};

// Implementando comandos por Teclado
export function ArrowLeftEnter(setCoordinates, setArrow) {
  setCoordinates({ x: 15, y: 0, z: 0 });
  setArrow(true);
}
export function ArrowRightEntered(setCoordinates, setArrow) {
  setCoordinates({ x: -15, y: 0, z: 0 });
  setArrow(true);
}
export function ArrowUpEntered(setCoordinates, setArrow) {
  setCoordinates({ x: 0, y: 15, z: 0 });
  setArrow(true);
}
export function ArrowDownEntered(setCoordinates, setArrow) {
  setCoordinates({ x: 0, y: -15, z: 0 });
  setArrow(true);
}
