import React from "react";
import Portal from "./Portal";
import { Overlay, Background, Container } from "./styles";
import { useHotkeys } from "react-hotkeys-hook";

const Modal = ({
  children,
  onClose,
  isOpen,
  selector = undefined,
  className = "modal",
}) => {
  useHotkeys("esc", onClose, { keydown: true }, [isOpen]);

  return (
    <>
      {isOpen && (
        <Portal selector={selector}>
          <Overlay id="modalContainer" className={className} isOpen={isOpen}>
            <Background onClick={onClose} />
            <Container>{children}</Container>
          </Overlay>
        </Portal>
      )}
    </>
  );
};

export default Modal;
