import { createAction } from "@reduxjs/toolkit";
import api from "../../services/api";

export const employeerStats = createAction("EMPLOYEER_STATS");

export const handleEmployeerStats = ({ employeer, startDate, endDate }) => {
  return (dispatch) =>
    api
      .get(
        "/actived-tasks/stats/" + employeer + "/" + startDate + "/" + endDate
      )
      .then((res) => dispatch(employeerStatsSucess(res.data)))
      .catch((err) => dispatch(employeerStatsFailure(err.message)));
};

const employeerStatsSucess = (payload) => employeerStats(payload);

const employeerStatsFailure = (error) => employeerStats(error);
