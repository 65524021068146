import styled, { css, keyframes } from "styled-components";

const fadeOut = keyframes`
    0%{
        opacity: 1;
        bottom: 9rem;
    }
    50%{
        opacity: 0.8;
        bottom: 8.5rem
    }

    60%{
        opacity: 0.5;
        bottom:8.3rem
    }
    80%{
        opacity: 0.4;
        bottom: 8.15rem
    }
    90%{
        opacity: 0.2;
        bottom: 8rem;
    }
    100%{
        opacity: 0;
        bottom: 8rem
      
    }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background: #42464c;
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  border-radius: 0.1764125rem;
  z-index: 100;
  padding: 0.3rem 1.35rem 0.3rem 1.6rem;
  user-select: none;
  visibility: ${({ open }) => (open ? "visible" : "hidden")};

  bottom: 9rem;
  animation: ${({ open }) =>
    open &&
    css`
      ${fadeOut} 0.5s ease-in forwards 3s
    `};
`;

export const Text = styled.p`
  font-family: Sora;
  font-size: 0.6467rem;
  line-height: 0.815rem;
  color: #fff;
  span {
    color: ${({ type }) => (type === "error" ? "#F86464" : "#40E5A0")};
  }
`;
