import styled from "styled-components";

export const Content = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  width: 12.11875rem;
  height: 7.175rem;
  background: #fde2dc;
  user-select: none;
  clip-path: polygon(100% 100%, 0 100%, 0 8%, 45% 8%, 50% 0, 54% 8%, 100% 8%);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 0.3875rem;
  box-shadow: 0px 3.76497px 7.52994px rgba(77, 68, 68, 0.18);
  border-radius: 2.82373px;
  z-index: 1;

  p {
    color: #aa3e3e;
    font-size: 0.82375rem;
    text-align: center;
    font-weight: 600;
    margin-top: 0.470625rem;
    line-height: 1rem;
    span {
      font-weight: 700;
    }
  }
  svg {
    width: 1.4375rem;
    height: 1.4375rem;
    margin-top: 1.25rem;
  }
`;

export const IconDiv = styled.div`
  display: flex;
  align-items: center;

  width: 1.529375rem;
`;

export const IconCloseDiv = styled.div`
  position: absolute;
  top: 1.25rem;
  right: 0.5rem;
  margin: 0;

  display: flex;
  align-items: center;
  cursor: pointer;

  svg {
    margin: 0;
    width: 0.5531rem;
    height: 0.5531rem;
  }
`;
