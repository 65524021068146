// eslint-disable-next-line import/no-anonymous-default-export
export default {
  //Brand Colors
  brandColor: "#0ce597",
  brandColorDarker: "#138658",
  brandColorDarkest: "#0b5b3b",
  brandColorLigher: "#d5faeb",
  brandColorEvenLigther: "#eafcf5",
  brandColorLightest: "#d5faeb",

  //System Colors
  defaultColor: "#575e66",
  defaultGray: "#585E66",
  defaultColorLighter: "#cfd2d4",
  linkColor: "#087dad",
  dangerColor: "#de2443",
  errorColor: "#de24431a",
  warningColor: "#f7b228",
  sucessColor: "#00875a",

  //Typography & Spacing
  big: "5rem", //80px
  xxxxLarge: "4.5rem", //72px
  xxxLarge: "3rem", //48px
  xxLarge: "2.25rem", //36px
  xLarge: "1.5rem", //24px
  large: "1.25rem", //20px
  medium: "1rem", //16px
  small: "0.875rem", //14px
  xSmall: "0.75rem", //12px
  xxSmall: "0.5rem", //8px
  xxxSmall: "0.25rem", //4px
  xxxxSmall: "0.125rem", //2px
  tiny: "0.0625rem", //1px
};
