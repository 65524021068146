import styled from "styled-components";

export const ConditionalStyle = styled.div`
  width: 50px;
  height: 50px;
  box-shadow: ${({ selected }) =>
    selected === true ? "0 0 0 1px blue" : " 0 0 0 0.5px #1a192b"};
  box-sizing: border-box;
  border-radius: 8px;
  transform: rotate(-45deg);
  background-color: ${({ status }) =>
    status === "done" ? "#beefbe" : status === "doing" ? "#f2f0a6" : "white"};
`;

export const TaskStyle = styled.div`
  padding: 10px;
  width: 150px;
  display: flex;
  font-family: jetbrains-mono;
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background-color: ${({ status, expired }) =>
    expired === true && status !== "done"
      ? "#ff6666"
      : status === "done"
      ? "#beefbe"
      : status === "doing"
      ? "#f2f0a6"
      : "white"};
  box-shadow: ${({ status, expiredTask, selected }) =>
    selected === true
      ? "0 0 0 1px blue"
      : expiredTask === true && status === "done"
      ? " 0 0 0 1px red"
      : " 0 0 0 0.5px #1a192b"};
`;

export const ConditionalEndStyle = styled.div`
  width: 50px;
  height: 50px;
  box-shadow: ${({ selected }) =>
    selected === true ? "0 0 0 1px blue" : " 0 0 0 0.5px #1a192b"};
  box-sizing: border-box;
  border-radius: 8px;
  transform: rotate(-45deg);
  background-color: ${({ status }) =>
    status === "done" ? "#beefbe" : status === "doing" ? "#f2f0a6" : "white"};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ConditionalEndStyleInside = styled.div`
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0 0 0 0.5px #1a192b;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const EventStart = styled.div`
  width: 50px;
  height: 50px;
  background: #2da919;
  box-shadow: ${({ selected }) =>
    selected === true ? "0 0 0 1px blue" : " 0 0 0 0.5px #1a192b"};
  border-radius: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  background-color: ${({ status }) =>
    status === "done" ? "#beefbe" : status === "doing" ? "#f2f0a6" : "white"};
`;

export const SidebarStyle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  padding: 10px;
  width: clamp(195px, 19%, 280px);
  overflow-y: scroll;

  h5 {
    margin: 10px 10px 0 10px;
  }
`;

export const TopicStyle = styled.div`
  display: flex;
`;
export const DragStyle = styled.div`
  cursor: default;
  background-color: #f0f0f0;
  padding: 10px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 15px;
  box-shadow: 0 0 0 0.5px #1a192b;
  border-radius: 3px;
`;
