import React, { memo } from "react";
import { useStoreState, Position } from "react-flow-renderer";
import {
  ConditionalEndStyle,
  ConditionalEndStyleInside,
  HandleStyle,
} from "./styles";

const ConditionalEndNode = ({
  data,
  targetPosition = Position.Left,
  sourcePosition = Position.Right,
  selected,
  id,
}) => {
  const edges = useStoreState((state) => state.edges);
  const nodes = useStoreState((state) => state.nodes);

  return (
    <ConditionalEndStyle
      criticalPath={data.criticalPath}
      status={data.status}
      selected={selected}
      className="flow_elements_node"
      data-id={id}
    >
      <ConditionalEndStyleInside selected={selected} />
      <HandleStyle
        type="target"
        id="c"
        position={targetPosition}
        selected={selected}
        style={{ top: 0 }}
        isValidConnection={(e) =>
          nodes.find((item) => item.id === e.source).type === "parallel" ||
          nodes.find((item) => item.id === e.source).type === "conditional"
            ? true
            : !edges.find((item) => item.source === e.source)
        }
      />
      <HandleStyle
        type="source"
        position={sourcePosition}
        id="a"
        selected={selected}
        style={{
          top: "100%",
        }}
        isValidConnection={(e) =>
          nodes.find((item) => item.id === e.target).type === "parallelEnd" ||
          nodes.find((item) => item.id === e.target).type === "conditionalEnd"
            ? !edges.find((item) => item.source === e.source)
            : !edges.find(
                (item) => item.source === e.source || item.target === e.target
              )
        }
      />
    </ConditionalEndStyle>
  );
};

export default memo(ConditionalEndNode);
