import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  background: #585e66;
  border: ${({ check }) =>
    check ? "0.752994px solid #40e5a0" : " 0.752994px solid #D4D6DA"};
  box-sizing: border-box;
  box-shadow: ${({ check }) =>
    check && "0px 0px 0.4706215rem rgba(64, 229, 160, 0.3)"};

  border-radius: 0.352375rem; //5.64746px;
  width: 11.765rem; //188.25px;
  height: 2rem; //32px;
  margin-left: 1.111875rem; //17.79px;
  user-select: none;
  align-items: center;
  cursor: pointer;

  transition: box-shadow 0.3s ease-in-out;
  transition: border 0.3s ease-in-out;
  &:hover {
    background: ${({ check }) => !check && "#35383d"};
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;

  justify-content: space-between;
  svg {
    margin-right: 0.705625rem; //11.29px;
  }
`;

export const Version = styled.span`
  font-size: 0.705625rem; //11.29px;
  color: #eff1f5;
  font-weight: 600;
  margin-left: 0.8575rem; //13.72px;
`;

export const UList = styled.ul`
  position: absolute;
  top: 100%;
  width: 100%;
  margin-top: 0.34375rem;
  display: flex;
  flex-direction: column;
  padding: 0;
  background: #42464c;
  border-radius: 0.353125rem;
  max-height: 10rem;
  overflow-y: ${({ len }) => (len > 5 ? "scroll" : "none")};

  ::-webkit-scrollbar {
    width: 5px;
  }

  li:last-child {
    margin-bottom: 0.875rem;
  }
`;

export const ListItem = styled.li`
  position: relative;
  display: flex;
  font-size: 0.705625rem; //11.29px;
  font-weight: 400;
  line-height: 0.885rem;
  color: ${({ select }) => (select ? "#40E5A0" : "#a9aeb4")};

  //padding-left: 0.8575rem;
  margin-top: 0.5rem;
  padding: 0.235rem 0 0.2425rem 0.7675rem;

  list-style: none;
  overflow-x: none;

  &:hover {
    background: #212326;
  }

  svg {
    position: absolute;
    width: 0.75rem;
    right: calc(0.82375rem - 0.3531rem);
    top: 50%;
    transform: translateY(-50%);
  }
`;
