import React, { useRef, useState } from "react";
import { Container, Content, Version, UList, ListItem } from "./styles";
import DropArrowdown from "../../../../assets/icons/DropArrowdown";
import TickCicle from "../../../../assets/icons/TickCircle";
import { useOutsideDrop } from "../../../../components/hooks/useOutsideDrop";
import SwitchVersion from "../../../../Modals/SwitchVersion";

const DropSelect = ({ List = [], version, setVersion, setCritical }) => {
  const [check, setCheck] = useState(false); // abrir/fechar drop

  const wrapperRef = useRef(null);
  const clearDropdown = () => setCheck(false);

  useOutsideDrop(wrapperRef, clearDropdown);
  const [showModal, setShowModal] = useState(false);
  const [item, setItem] = useState(false);

  return (
    <>
      <Container ref={wrapperRef} check={check}>
        <Content onClick={() => List.length > 0 && setCheck((prev) => !prev)}>
          <Version>{version}</Version>
          <DropArrowdown />
        </Content>

        {check && (
          <UList len={List.length}>
            {List.map((item, i) => (
              <ListItem
                select={version === item}
                onClick={() => {
                  clearDropdown();
                  setItem(item);
                  if (item !== version) setShowModal(true);
                }}
                key={i}
              >
                {item}
                {version === item && <TickCicle />}
              </ListItem>
            ))}
          </UList>
        )}
      </Container>
      <SwitchVersion
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        item={item}
        version={version}
        setVersion={setVersion}
        setCritical={setCritical}
        selector="#root"
        className="nodrag"
      />
    </>
  );
};

export default DropSelect;
