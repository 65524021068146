import styled, { keyframes, css } from "styled-components";

const fadeIn = keyframes`
    0%{
        opacity: 0;
    }
    100%{
        opacity: 1;
    }
`;

export const Overlay = styled.div`
  position: fixed;
  z-index: 99;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  animation: ${({ isOpen }) =>
    isOpen &&
    css`
      ${fadeIn} 0.15s ease-in forwards
    `};
`;

export const Background = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
`;

export const Container = styled.div`
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%);
`;
