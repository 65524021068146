import styled from "styled-components";

export const Container = styled.div`
  font-family: system-ui;
  display: flex;
  align-items: center;
  user-select: none;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  vertical-align: middle;
  margin-right: 0.333rem;
`;

export const Icon = styled.svg`
  position: absolute;
  fill: none;
  stroke: white;
  stroke-width: 2px;
`;

export const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  clip-path: inset(50%);
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

export const StyledCheckbox = styled.div`
  position: relative;
  display: inline-block;
  width: 0.94125rem;
  height: 0.941rem;
  border: ${(props) => (props.checked ? "none" : "1px solid #d4d6da")};
  background: ${(props) => (props.checked ? "#40e5a0" : "#EFF1F5")};
  border-radius: 4px;
  transition: all 150ms;

  ${Icon} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
  }
`;

export const Label = styled.label`
  display: flex;
  font-size: 0.7083rem;
  color: #585e66;
`;
