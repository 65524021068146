import styled from "styled-components";

export const Container = styled.div`
  font-family: Sora;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 1.7625rem;
`;

export const Title = styled.div`
  font-size: 0.646875rem;
  color: #212326;
  font-weight: 600;
  line-height: 0.815625rem;
  margin-left: 0.8825rem;
  margin-bottom: 0.70625rem;
`;
export const Draggable = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  flex-wrap: wrap;
`;
export const ConditionalNode = styled.div`
  width: 5.43875rem;
  height: 4.079375rem;

  border-radius: 2.82px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  span {
    font-size: 0.646875rem;
    color: #42464c;
    margin-top: 0.4875rem;
    line-height: 0.74125rem;
  }
`;

export const IconDiv = styled.div`
  width: 1.43rem;
  height: 1.43rem;
  cursor: move;

  svg {
    width: 1.43rem;
    height: 1.43rem;
    //margin-top: 0.9375rem;
    z-index: 1;
  }

  &:hover {
    path,
    circle,
    rect {
      stroke: #118052;
    }
  }
`;
