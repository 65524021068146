import React from "react";

const LoadingInput = ({ style }) => (
  <svg
    style={style}
    width="1.2rem"
    height="1.2rem"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7051 12.6294C12.2254 14.4722 10.0043 15.3693 7.80846 15.2337C6.41583 15.1477 5.03624 14.6461 3.86417 13.705C0.849666 11.2845 0.36811 6.87858 2.78858 3.86408C5.20905 0.849578 9.61497 0.368021 12.6295 2.78849C15.644 5.20896 16.1255 9.61488 13.7051 12.6294Z"
      stroke="url(#paint0_angular_1062_22048)"
      strokeWidth="2"
    />
    <defs>
      <radialGradient
        id="paint0_angular_1062_22048"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(8.24682 8.24673) rotate(-163.61) scale(8.86002 17.057)"
      >
        <stop stopColor="#40E5A0" />
        <stop offset="1" stopColor="#40E5A0" stopOpacity="0" />
      </radialGradient>
    </defs>
  </svg>
);

export default LoadingInput;
