import styled from "styled-components";

export const Container = styled.div`
  width: 27.5rem;
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 0.17648rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
`;

export const Header = styled.div`
  padding-left: 1.875rem;
  padding-top: 1.875rem;

  input {
    width: 23.75rem;
    border-radius: 0.35296625rem;
  }
`;

export const Label = styled.span`
  font-family: Sora;
  font-style: normal;
  font-weight: 600;
  font-size: 1.125rem;
  line-height: 1.4375rem;
`;

export const Reminder = styled.div`
  display: flex;
  background: #cff8e7;
  width: 23.75rem;
  height: 2.875rem;
  align-items: center;
  margin-top: 0.8125rem;
  padding-left: 0.5625rem;
  margin-bottom: 0.9375rem;
`;

export const ReText = styled.span`
  font-family: Sora;
  font-style: normal;
  font-weight: normal;
  font-size: 0.6875rem;
  line-height: 1rem;
  width: 20.8125rem;
  margin-left: 0.5625rem;
  color: #118052;
`;
export const Subtitle = styled.span`
  font-family: Sora;
  color: #111;
  line-height: 1rem;
  font-size: 0.9rem;
`;
export const Footer = styled.div`
  display: flex;
  padding: 0.8125rem 2.0625rem 1.875rem 2.0625rem;
  justify-content: space-between;
`;

export const DivIcon = styled.div`
position: absolute;
top: 1.0875rem;
right: 0.875rem;
cursor: pointer;

  svg{
    width:0.75625rem;
    height:0.75625rem;
    &:hover {
      path,
      rect {
        stroke: #40e5a0;
      }
  }
`;
