import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  z-index: 5;
`;

export const Button = styled.button`
  background: #40e5a0;
  border-radius: 50%;
  width: 2.470625rem;
  height: 2.470625rem;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 0.765rem;
    height: 1.646875rem;
  }
`;
