import React, { useRef } from "react";
import { Button, Modal } from "semantic-ui-react";
import ProfilePicturePicker from "../ProfilePicturePicker";

const ProfilePictureModal = ({
  open,
  change,
  user,
  setPicture,
  setLoading,
}) => {
  const childRef = useRef();

  return (
    <Modal
      onClose={() => change(false)}
      onOpen={() => change(true)}
      open={open}
    >
      <Modal.Header style={{ textAlign: "center" }}>UPAR IMAGEM</Modal.Header>
      <Modal.Content>
        <ProfilePicturePicker
          ref={childRef}
          user={user}
          change={change}
          setPicture={setPicture}
          setLoading={setLoading}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Voltar" color="black" onClick={() => change(false)} />
        <Button
          content="Upar Imagem"
          positive
          icon="check"
          labelPosition="right"
          onClick={() => childRef.current.handleUpload()}
        />
      </Modal.Actions>
    </Modal>
  );
};

export default ProfilePictureModal;
