import styled from "styled-components";

export const IContainer = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const InputTitle = styled.input`
  width: 100%;
  padding: 0.67857143em 2.1em 0.67857143em 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  &:focus {
    outline: none !important;
    transition: box-shadow 0.1s ease, border-color 0.1s ease;
    border-color: #85b7d9;
    box-shadow: none;
  }
`;
