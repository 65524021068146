import React from "react";
import { useHistory } from "react-router-dom";
import Page404 from "../../assets/icons/page404";
import GlobalStyle from "../../styles/globalStyles";
import { BigText, MainDiv, SmallText } from "./styles";
import Button from "../../components/general/Button";

const NotFoundPage = () => {
  const history = useHistory();

  return (
    <>
      <GlobalStyle />
      <MainDiv>
        <BigText>Ops...</BigText>
        <SmallText>
          desculpe, não encontramos a página, volte para o ínicio
        </SmallText>
        <Button
          content="Voltar ao início"
          width="12.94rem"
          height="2.46rem"
          style={{ marginBottom: "6rem" }}
          onClick={() => history.push("/")}
        />
        <Page404 />
      </MainDiv>
    </>
  );
};
export default NotFoundPage;
