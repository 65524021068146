import React from "react";

const TripleVector = ({ widht, height, onClick }) => (
  <svg
    width={widht}
    height={height}
    onClick={onClick}
    viewBox="0 0 17 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.04785 5.58984H15.6371"
      stroke="#A9AEB4"
      strokeWidth="1.12949"
      strokeLinecap="round"
    />
    <path
      d="M1.04785 5.58984H15.6371"
      stroke="#A9AEB4"
      strokeWidth="1.12949"
      strokeLinecap="round"
    />
    <path
      d="M1.04785 5.58984H15.6371"
      stroke="#A9AEB4"
      strokeWidth="1.12949"
      strokeLinecap="round"
    />
    <path
      d="M6.69531 0.882812H13.7546"
      stroke="#A9AEB4"
      strokeWidth="1.12949"
      strokeLinecap="round"
    />
    <path
      d="M2.93018 10.2949H9.9895"
      stroke="#A9AEB4"
      strokeWidth="1.12949"
      strokeLinecap="round"
    />
  </svg>
);

export default TripleVector;
