import React, { memo, useEffect, useState } from "react";

import { Resizable } from "re-resizable";
import {
  Content,
  Lock,
  Unlock,
  ColorPickButton,
  IndexPosition,
} from "./styles";
import { useDispatch } from "react-redux";
import Popover from "@material-ui/core/Popover";
import Sketch from "@uiw/react-color-sketch";
import { useStoreActions, useStoreState } from "react-flow-renderer";
import { updateCustomMark } from "../../state/undoFlow/actions";

const CustomMark = ({ selected, data, id }) => {
  const dispatch = useDispatch();
  const [height, setHeight] = useState(data.refData.height);
  const [width, setWidth] = useState(data.refData.width);
  const [drag, setDrag] = useState(data.draggable);
  const [hex, setHex] = useState(data.hexColor);
  const [openHex, setOpenHex] = useState(false);
  const [area, setArea] = useState(data.refData.height * data.refData.width);
  const [changeResize, setChangeResize] = useState(false);

  const idHex = openHex ? "simple-popover" : undefined;
  const [anchorEl, setAnchorEl] = useState(null);

  const setSelectedElements = useStoreActions(
    (actions) => actions.setSelectedElements
  );

  const markValidation = (width, height, hex, drag) => {
    if (
      data.refData.width === width &&
      data.refData.height === height &&
      data.hexColor === hex &&
      data.draggable === drag
    ) {
      return false;
    }
    return true;
  };

  const indexNode = useStoreState((state) =>
    state.nodes.findIndex((el) => el.id === id)
  );

  const onResizeStop = (e, direction, ref, d) => {
    setHeight((prev) => prev + d.height);
    setWidth((prev) => prev + d.width);
    setChangeResize(true);
  };

  useEffect(() => {
    setArea(() => width * height);
    setChangeResize(false);
  }, [changeResize]);

  useEffect(() => {
    if (!openHex) {
      if (markValidation(width, height, hex, drag)) {
        setWidth(data.refData.width);
        setHeight(data.refData.height);
        setHex(data.hexColor);
        setDrag(data.draggable);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openHex, data]);

  useEffect(() => {
    if (markValidation(width, height, hex, drag)) {
      dispatch(
        updateCustomMark({
          id,
          width,
          height,
          style: {
            hex,
          },
          draggable: drag,
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openHex, area, drag]);

  useEffect(() => {
    if (!drag) setSelectedElements([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [drag]);

  const handleStyles = {
    top: {
      marginTop: -3,
      marginLeft: -5,
      top: 0,
      left: "50%",
      cursor: "ns-resize",
      border: "3px solid #999",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "none",
      borderWidth: 5,
      borderColor: selected ? "#1a192b" : "lightgray",
      width: 10,
      height: 10,
      boxSizing: "border-box",
      zIndex: 1,
    },
    topLeft: {
      marginTop: -2,
      marginLeft: -2,
      top: 0,
      left: 0,
      cursor: "nwse-resize",
      border: "3px solid #999",
      borderRight: "none",
      borderBottom: "none",
      borderColor: selected ? "#1a192b" : "lightgray",
      width: 10,
      height: 10,
      boxSizing: "border-box",
      zIndex: 1,
    },
    left: {
      marginTop: -5,
      marginLeft: -3,
      top: "50%",
      left: 0,
      cursor: "ew-resize",
      border: "3px solid #999",
      borderTop: "none",
      borderRight: "none",
      borderBottom: "none",
      borderWidth: 5,
      borderColor: selected ? "#1a192b" : "lightgray",
      width: 10,
      height: 10,
      boxSizing: "border-box",
      zIndex: 1,
    },
    bottomLeft: {
      marginTop: -7,
      marginLeft: -2,
      top: "100%",
      left: 0,
      cursor: "nesw-resize",
      border: "3px solid #999",
      borderRight: "none",
      borderTop: "none",
      borderColor: selected ? "#1a192b" : "lightgray",
      width: 10,
      height: 10,
      boxSizing: "border-box",
      zIndex: 1,
    },
    bottom: {
      marginTop: -7,
      marginLeft: -5,
      top: "100%",
      left: "50%",
      cursor: "ns-resize",
      border: "3px solid #999",
      borderLeft: "none",
      borderRight: "none",
      borderTop: "none",
      borderWidth: 5,
      borderColor: selected ? "#1a192b" : "lightgray",
      width: 10,
      height: 10,
      boxSizing: "border-box",
      zIndex: 1,
    },
    bottomRight: {
      marginTop: -7,
      marginLeft: -7,
      top: "100%",
      left: "100%",
      cursor: "nwse-resize",
      border: "3px solid #999",
      borderLeft: "none",
      borderTop: "none",
      borderColor: selected ? "#1a192b" : "lightgray",
      width: 10,
      height: 10,
      boxSizing: "border-box",
      zIndex: 1,
    },
    right: {
      marginTop: -5,
      marginLeft: -7,
      top: "50%",
      left: "100%",
      cursor: "ew-resize",
      border: "3px solid #999",
      borderTop: "none",
      borderLeft: "none",
      borderBottom: "none",
      borderWidth: 5,
      borderColor: selected ? "#1a192b" : "lightgray",
      width: 10,
      height: 10,
      boxSizing: "border-box",
      zIndex: 1,
    },
    topRight: {
      marginTop: -2,
      marginLeft: -7,
      top: 0,
      left: "100%",
      cursor: "nesw-resize",
      border: "3px solid #999",
      borderLeft: "none",
      borderBottom: "none",
      borderColor: selected ? "#1a192b" : "lightgray",
      width: 10,
      height: 10,
      boxSizing: "border-box",
      zIndex: 1,
    },
  };

  const handleLock = () => setDrag((prev) => !prev);

  return (
    <>
      <Resizable
        style={{ userSelect: "none" }}
        draggable={false}
        size={{ width, height }}
        onResizeStop={onResizeStop}
        handleClasses={{
          top: "nodrag",
          right: "nodrag",
          bottom: "nodrag",
          left: "nodrag",
          topRight: "nodrag",
          bottomRight: "nodrag",
          bottomLeft: "nodrag",
          topLeft: "nodrag",
        }}
        handleStyles={handleStyles}
      >
        <Content
          className={drag ? "" : "nodrag"}
          style={{
            background: hex,
            userSelect: "none",
          }}
        ></Content>
        <ColorPickButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setOpenHex(true);
          }}
        ></ColorPickButton>
        {drag ? <Unlock onClick={handleLock} /> : <Lock onClick={handleLock} />}
        <Popover
          className="nodrag"
          id={idHex}
          open={openHex}
          anchorEl={anchorEl}
          onClose={() => setOpenHex(false)}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div>
            <Sketch
              color={hex}
              onChange={(color) => {
                setHex(color.hexa);
              }}
            />
          </div>
        </Popover>
        <IndexPosition draggable={false}>
          <span>{indexNode}</span>
        </IndexPosition>
      </Resizable>
    </>
  );
};

export default memo(CustomMark);
