import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle` 
  body {
    font-size: 100%;
    font-family: Sora;
  }
  
  html {
    font-size: 16px;//16px para resoluções abaixo de 1080p
    
    @media screen and (min-width: 1920px) {
      font-size: 1.35rem;//24px para 1080p  

    }
  }


`;

export default GlobalStyle;
