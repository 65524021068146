import React from "react";

import {
  Container,
  CheckboxContainer,
  HiddenCheckbox,
  Icon,
  StyledCheckbox,
  Label,
} from "./styles";

const Checkbox = ({ checked, label = false, onChange }) => {
  return (
    <Container>
      <label>
        <CheckboxContainer>
          <HiddenCheckbox checked={checked} onChange={() => onChange()} />
          <StyledCheckbox checked={checked}>
            <Icon viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </Icon>
          </StyledCheckbox>
        </CheckboxContainer>
      </label>
      <Label checked={checked} onClick={() => onChange()}>
        {label}
      </Label>
    </Container>
  );
};
export default Checkbox;
