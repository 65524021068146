import React from "react";

const ConditionalEndIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.665608"
        width="15.24"
        height="15.24"
        rx="1.41186"
        transform="matrix(0.707159 -0.707054 0.707159 0.707054 1.08457 12.6777)"
        stroke="#7E858F"
        strokeWidth="0.941243"
      />
      <rect
        x="0.665608"
        width="12.0037"
        height="12.0037"
        rx="1.41186"
        transform="matrix(0.707159 -0.707054 0.707159 0.707054 3.51181 12.6777)"
        stroke="#7E858F"
        strokeWidth="0.941243"
      />
    </svg>
  );
};

export default ConditionalEndIcon;
