import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Input, Button } from "semantic-ui-react";
import { Formik, Form } from "formik";
import { handleNewPassword } from "../../state/auth/actions";
import { BoxForm, FormContainer } from "./styles";
import { LoginContainer } from "../NewAccount/styles";
import Loading from "../../components/LoadingTemplate/index";

const ResetPasswordPage = ({ match }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const resetToken = match.params.token;
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const [passwordError, setPasswordError] = useState(false);
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token !== null)
      user.rank === "Gerente"
        ? history.push("/")
        : history.push({
            pathname: "/",
            search:
              "?" +
              new URLSearchParams({
                title: "",
                flowTitle: "",
                client: "",
                status: "all",
                flowType: "all",
              }).toString(),
          });
  }, [history, token]);

  const handleSubmit = (values) => {
    if (
      values.newPassword !== values.password ||
      values.newPassword === "" ||
      values.password === ""
    ) {
      setPasswordError(true);
    } else {
      setLoading(true);
      dispatch(
        handleNewPassword({ password: values.password, resetToken })
      ).then(() => {
        setPasswordError(false);
        setLoading(false);
        setMessage("Senha redefinida com sucesso!");
      });
    }
  };

  return !loading ? (
    <BoxForm>
      <Formik
        initialValues={{
          password: "",
          newPassword: "",
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values, handleChange }) => (
          <Form onSubmit={handleSubmit}>
            <LoginContainer>
              <h2>Redefinir senha</h2>
              <FormContainer>
                <label>Nova senha</label>
                <Input
                  disabled={message}
                  icon="lock"
                  iconPosition="left"
                  style={{ marginBottom: "10px" }}
                  type="password"
                  id="password"
                  name="password"
                  placeholder="Senha"
                  value={values.password}
                  onChange={handleChange}
                />
                <label>Confirmar nova senha</label>
                <Input
                  disabled={message}
                  icon="lock"
                  iconPosition="left"
                  style={{ marginBottom: "10px" }}
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  placeholder="Confirmar senha"
                  value={values.newPassword}
                  onChange={handleChange}
                />
                {passwordError && (
                  <p
                    style={{
                      color: "red",
                      alignSelf: "center",
                      marginBottom: "10px",
                    }}
                  >
                    Campos não conferem
                  </p>
                )}
                {message && <p style={{ color: "green" }}>{message}</p>}
                {!message && <Button type="submit">Redefinir senha</Button>}
              </FormContainer>
            </LoginContainer>
          </Form>
        )}
      </Formik>
    </BoxForm>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

export default ResetPasswordPage;
