import styled from "styled-components";

export const Item = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-around;
  min-height: 1.75rem;

  svg {
    :nth-child(4) {
      // Close Icon
      opacity: 0;
      width: 0.9125rem;
      height: 0.588125rem;
    }

    &:hover {
      path {
        stroke: #40e5a0;
      }
    }
    cursor: pointer;
  }
  &:hover {
    svg {
      :nth-child(4) {
        opacity: 1;
      }
    }
  }
`;

export const Input = styled.input`
  outline: none;
  width: 11.875rem !important;
  padding: 2px 0 2px 2px;
  font-size: 0.70625rem;
  font-family: Sora;
  font-weight: 600;
  color: #585e66;
  line-height: 0.889375rem;
  border-radius: 0.25rem !important;
  border: 1px solid #a9aeb4;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.87);
  }

  &:focus {
    border: 1px solid #40e5a0;
  }
`;

export const DivIcon = styled.div`
  svg {
    width: 0.9125rem;
    height: 0.588125rem;

    &:hover {
      path {
        stroke: #40e5a0;
      }
    }
    cursor: pointer;
  }
`;

export const Title = styled.span`
  display: flex;
  flex-wrap: wrap;

  width: 11.875rem;
  font-family: Sora;
  font-size: 0.70625rem;
  font-weight: 600;
  color: #585e66;
  line-height: 0.889375rem;
`;
