import { createAsyncThunk, createReducer, current } from "@reduxjs/toolkit";
import {
  addFlow,
  addFlowInVersion,
  addHandleClick,
  addModelFlow,
  addTaskSubtasks,
  clearFlows,
  connectElement,
  dropElement,
  connectElementInVersion,
  dropElementInVersion,
  editFlowTitle,
  editModelFlow,
  editTask,
  editTaskExpirationTime,
  editTaskSubtasks,
  editTaskTitle,
  editTimer,
  newDefaultVersionFlow,
  newVersionFlow,
  removeElement,
  removeElementInVersion,
  removeModelFlow,
  removeTaskSubtasks,
  updateCondTitle,
  updateCustomTextStyles,
  updateEdgeTitle,
  removeVersion,
  updateElement,
  updateElementInVersion,
  updateFlowElements,
  updateMarkDimensions,
  updateMarkStyles,
  updateTaskTitle,
  updateFlowElementsInVersion,
  editVersionFlow,
  showCriticalPath,
  newElements,
  newElementsVersion,
  fetchSingleFlow,
  searchModel,
} from "../flows/actions";
import { addEdge, removeElements } from "react-flow-renderer";
import api from "../../services/api";

export const fetchFlows = createAsyncThunk(
  "flows/fetchFlows",
  async ({ enterpriseId, page }) => {
    const response = await api.get("/flow-models/" + enterpriseId + "/" + page);
    return response.data;
  }
);

const flowsReducer = createReducer(
  { entities: [], status: "idle", pages: 1 },
  {
    /**
     * ? addModelFlow
     * * Cria uma cópia do estado atual e adiciona o novo fluxo
     * * a lista de entidades. No final retorna o objeto para o
     * * estado atual
     */
    [addModelFlow]: (state, action) => {
      let newState = JSON.parse(JSON.stringify(state));
      newState.entities.push(action.payload.flow);
      return newState;
    },

    /**
     * ? editModelFlow
     * * Cria uma cópia do estado atual e adiciona o novo fluxo
     * * no lugar do previamente selecionado na lista de entidades
     * * pelo index. No final retorna o objeto para o
     * * estado atual.
     */
    [editModelFlow]: (state, action) => {
      let newState = JSON.parse(JSON.stringify(state));

      newState.entities.forEach((item, index) => {
        if (item._id === action.payload.flow._id) {
          newState.entities[index] = action.payload.flow;
        }
      });

      return newState;
    },

    [editVersionFlow]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.flow.originalId) {
          els.versions.forEach((el) => {
            if (el._id === action.payload.flow._id) el = action.payload.flow;
          });
        }
      });
    },

    /**
     * ? editTask
     * * Atualiza as propriedades de uma tarefa em uma página
     * * de criação de fluxos. É chamada no botão [Salvar tarefa]
     * * em ModelTaskModal. Localmente atualiza os campos de data
     * * de uma tarefa (subtarefas, tempo de expiração etc..)
     */
    [editTask]: (state, action) => {
      const clone = JSON.parse(JSON.stringify(current(state)));

      clone.entities.forEach((els) => {
        if (els._id === action.payload.flowId) {
          if (action.payload.version === "default") {
            els.elements.forEach((el) => {
              if (el.id === action.payload.newTask.id) {
                el.data = action.payload.newTask.data;
              }
            });
          } else {
            els.versions.forEach((el) => {
              if (el.versionNumber === action.payload.version) {
                el.elements.forEach((it) => {
                  if (it.id === action.payload.newTask.id) {
                    it.data = action.payload.newTask.data;
                  }
                });
              }
            });
            els.elements.forEach((it) => {
              if (it.id === action.payload.newTask.id) {
                it.data = action.payload.newTask.data;
              }
            });
          }
        }
      });

      return clone;
    },
    [fetchFlows.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchFlows.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.entities = action.payload.flows;
      state.pages = action.payload.pages;
    },

    /**
     * ? updateElement
     * * Ação para atualizar o rótulo (texto) de elementos
     * * do fluxo modelo (arestas ou nós). É chamada
     * * na página de edição de fluxo (FlowEditionPage).
     * * Localmente atualiza o elemento baseando-se no
     * * id do nó(node) ou aresta(edge).
     * *
     */

    [updateElement]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload._id) {
          els.elements.forEach((el) => {
            if (el.id === action.payload.edgeId) {
              el.data.text = action.payload.text;
            } else if (el.id === action.payload.nodeId) {
              el.data.label = action.payload.text;
            }
          });
        }
      });
    },

    [updateElementInVersion]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload._id) {
          els.versions.forEach((el) => {
            if (el.versionNumber === action.payload.version) {
              el.elements.forEach((item) => {
                if (item.id === action.payload.edgeId) {
                  item.data.text = action.payload.text;
                } else if (item.id === action.payload.nodeId) {
                  item.data.label = action.payload.text;
                }
              });
            }
          });
        }
      });
    },

    /**
     * ? removeElement
     * * Remove um elemento do fluxo selecionado e as arestas
     * * adjacentes conectadas a ele (elementsToRemove) caso o
     * * elemento seja um nó. Utiliza o metodo removeElements
     * * da biblioteca React Flow.
     *
     */

    [removeElement]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload._id) {
          els.elements = removeElements(
            action.payload.elementsToRemove,
            els.elements
          );
        }
      });
    },
    [removeElementInVersion]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload._id) {
          els.versions.forEach((el) => {
            if (el.versionNumber === action.payload.version) {
              el.elements = removeElements(
                action.payload.elementsToRemove,
                el.elements
              );
            }
          });
        }
      });
    },

    /**
     * ! addhandleClick
     * * Seleciona o elemento pelo click do mouse. Dos parametros,
     * * seleciona um handleClick e _id do fluxo. No handleClick
     * * estarão as informações do elemento clicado
     */

    [addHandleClick]: (state, action) => {
      const clone = JSON.parse(JSON.stringify(current(state)));

      if (state.status === "succeeded") {
        clone.entities.forEach((els, index) => {
          if (els._id === action.payload._id) {
            els.elements.forEach((el, i) => {
              if (el.type === "customEdge")
                el.data.handleClick = action.payload.handleClick;
            });
          }
        });
      }

      return clone;
    },

    /**
     * ? dropElement
     * * Seleciona um elemento da tabela e libera na mesa
     * * de criação (Local), configurando um novo nó do fluxo
     */

    [dropElement]: (state, action) => {
      if (action.payload.newNode.type === "customMark") {
        let newArray = JSON.parse(JSON.stringify(action.payload.elements));

        let indexInsert = newArray.findIndex(
          (item) => item.type !== "customMark"
        );
        if (indexInsert < 0)
          newArray.splice(newArray.length, 0, action.payload.newNode);
        else if (indexInsert > 0)
          newArray.splice(indexInsert, 0, action.payload.newNode);
        else newArray.unshift(action.payload.newNode);

        state.entities.forEach((els) => {
          if (els._id === action.payload._id) {
            els.elements = newArray;
          }
        });
      } else {
        state.entities.forEach((els) => {
          if (els._id === action.payload._id) {
            els.elements.push(action.payload.newNode);
          }
        });
      }
    },

    [updateTaskTitle]: (state, action) => {
      state.entities
        .find((item) => item._id === action.payload.flowId)
        .elements.forEach((el) => {
          if (el.id === action.payload.id) {
            el.data = {
              ...el.data,
              label: action.payload.text,
            };
          }
        });
    },
    [updateCondTitle]: (state, action) => {
      state.entities
        .find((item) => item._id === action.payload.flowId)
        .elements.forEach((el) => {
          if (el.id === action.payload.id) {
            el.data = {
              ...el.data,
              label: action.payload.text,
            };
          }
        });
    },

    [updateEdgeTitle]: (state, action) => {
      state.entities
        .find((item) => item._id === action.payload.flowId)
        .elements.forEach((el) => {
          if (el.id === action.payload.id) {
            el.data = {
              ...el.data,
              text: action.payload.text,
            };
          }
        });
    },

    [updateMarkDimensions]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.flowId) {
          els.elements.forEach((el) => {
            if (el.id === action.payload.id) {
              el.data.refData = {
                ...el.data.refData,
                width: action.payload.width,
                height: action.payload.height,
              };
            }
          });
        }
      });
    },

    [updateMarkStyles]: (state, action) => {
      state.entities
        .find((item) => item._id === action.payload.flowId)
        .elements.forEach((el) => {
          if (el.id === action.payload.id) {
            el.data = {
              ...el.data,
              hexColor: action.payload.style.hex,
              draggable: action.payload.drag,
            };
          }
          return el;
        });
    },

    [updateCustomTextStyles]: (state, action) => {
      state.entities
        .find((item) => item._id === action.payload.flowId)
        .elements.forEach((el) => {
          if (el.id === action.payload.id) {
            el.data = {
              ...el.data,
              label: action.payload.text,
              fontSize: action.payload.fontSize,
              draggable: action.payload.draggable,
              hexColor: action.payload.hexColor,
            };
          }
          return el;
        });
    },

    [dropElementInVersion]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload._id) {
          els.versions.forEach((el) => {
            if (el.versionNumber === action.payload.version) {
              el.elements.push(action.payload.newNode);
            }
          });
        }
      });
    },
    /**
     * ? updateFlowElements
     * * Atualiza o fluxo de elementos com uma nova relação.
     * * Utilizado em flowEdition para atualizar localmente o fluxo
     */

    [updateFlowElements]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload._id) {
          els.elements = action.payload.newFlow;
        }
      });
    },
    [updateFlowElementsInVersion]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload._id) {
          els.versions.forEach((el) => {
            if (el.versionNumber === action.payload.version) {
              el.elements = action.payload.newFlow;
            }
          });
        }
      });
    },
    /**
     * ? connectElement
     * * Adiciona uma nova aresta a um elemento existente
     * * da mesa de criação. Utiliza o método addEdge da
     * * biblioteca React Flow.
     */

    [connectElement]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload._id) {
          els.elements = addEdge(action.payload.params, els.elements);
        }
      });
    },
    [connectElementInVersion]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload._id) {
          els.versions.forEach((el) => {
            if (el.versionNumber === action.payload.version) {
              el.elements = addEdge(action.payload.params, el.elements);
            }
          });
        }
      });
    },
    /**
     * ? editTaskTitle
     * * Edita o título da tarefa no modal ModelTaskModal na página
     * * de criação de fluxo
     */

    [editTaskTitle]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.flowId) {
          els.elements.forEach((el) => {
            if (el.id === action.payload.taskId) {
              el.data.label = action.payload.label;
            }
          });
        }
      });
    },

    /**
     * ? editTaskExpiration
     * * Edita o prazo da tarefa no modal ModelTaskModal na página
     * * de criação de fluxo
     */
    [editTaskExpirationTime]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.flowId) {
          if (action.payload.version === "default") {
            els.elements.forEach((el) => {
              if (el.id === action.payload.taskId) {
                el.data.expiration.time = action.payload.time;
              }
            });
          } else {
            els.versions.forEach((el) => {
              if (el.versionNumber === action.payload.version) {
                el.elements.forEach((it) => {
                  if (it.id === action.payload.taskId) {
                    it.data.expiration.time = action.payload.time;
                  }
                });
              }
            });
          }
        }
      });
    },
    /**
     * ? editTaskSubtasks
     * * Edita o nome da subtarefa no modal ModelTaskModal na página
     * * de criação de fluxo
     */
    [editTaskSubtasks]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.flowId) {
          if (action.payload.version === "default") {
            els.elements.forEach((el) => {
              if (el.id === action.payload.taskId) {
                el.data.subtasks[action.payload.index] = action.payload.title;
              }
            });
          } else {
            els.versions.forEach((el) => {
              if (el.versionNumber === action.payload.version) {
                el.elements.forEach((it) => {
                  if (it.id === action.payload.taskId) {
                    it.data.subtasks[action.payload.index] =
                      action.payload.title;
                  }
                });
              }
            });
          }
        }
      });
    },
    /**
     * ? addTaskSubtasks
     * * Adiciona uma subtarefa para uma tarefa no modal
     * * ModelTaskModal na página de criação de fluxo
     */
    [addTaskSubtasks]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.flowId) {
          if (action.payload.version === "default") {
            els.elements.forEach((el) => {
              if (el.id === action.payload.taskId) {
                el.data.subtasks.push("");
              }
            });
          } else {
            els.versions.forEach((el) => {
              if (el.versionNumber === action.payload.version) {
                el.elements.forEach((it) => {
                  if (it.id === action.payload.taskId) {
                    it.data.subtasks.push("");
                  }
                });
              }
            });
          }
        }
      });
    },
    /**
     * ? removeTaskSubtasks
     * * Deleta uma subtarefa no modal ModelTaskModal na página
     * * de criação de fluxo
     */
    [removeTaskSubtasks]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.flowId) {
          if (action.payload.version === "default") {
            els.elements.forEach((el) => {
              if (el.id === action.payload.taskId) {
                el.data.subtasks.splice(action.payload.index, 1);
              }
            });
          } else {
            els.versions.forEach((el) => {
              if (el.versionNumber === action.payload.version) {
                el.elements.forEach((it) => {
                  if (it.id === action.payload.taskId) {
                    it.data.subtasks.splice(action.payload.index, 1);
                  }
                });
              }
            });
          }
        }
      });
    },
    /**
     * ? editFlowTitle
     * * Edita o  nome do fluxo atual no painel interno de edição
     * * de fluxos modelo.
     */
    [editFlowTitle]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.flowId) {
          els.title = action.payload.title;
        }
      });
    },

    /**
     * ? RemoveModelFlow
     * * Filtra o estado atual pelo _id com relação ao
     * * fluxo a ser removido.
     */

    [removeModelFlow]: (state, action) => {
      state.entities = state.entities.filter(
        (item) => item._id !== action.payload.flowId
      );
    },
    /**
     * ? editTimer
     * * Edita as propriedades de um temporizador de um fluxo.
     * * As alterações são despachadas após clicar no botão
     * * [Salvar Tarefa], presente no modal ModelTimerModal.
     */

    [editTimer]: (state, action) => {
      const clone = JSON.parse(JSON.stringify(current(state)));

      clone.entities.forEach((els) => {
        if (els._id === action.payload.flowId) {
          if (action.payload.version === "default") {
            els.elements.forEach((el) => {
              if (el.id === action.payload.newTimer.id) {
                el.data = action.payload.newTimer.data;
              }
            });
          } else {
            els.versions.forEach((el) => {
              if (el.versionNumber === action.payload.version) {
                el.elements.forEach((it) => {
                  if (it.id === action.payload.newTimer.id) {
                    it.data = action.payload.newTimer.data;
                  }
                });
              }
            });
            els.elements.forEach((it) => {
              if (it.id === action.payload.newTimer.id) {
                it.data = action.payload.newTimer.data;
              }
            });
          }
        }
      });

      return clone;
    },

    /**
     * ? clearFlows
     * * Limpa o estado atual localmente de fluxos modelos cadastrados
     */
    [clearFlows]: (state) => {
      state.status = "idle";
      state.entities = [];
    },
    /**
     * ? addFlow
     * * Adiciona o novo fluxo ao clicar em control+z ou em desfazer
     */
    [addFlow]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload._id) {
          els.elements = action.payload.flow;
        }
      });
    },
    [addFlowInVersion]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload._id) {
          els.versions.forEach((el) => {
            if (el.versionNumber === action.payload.version) {
              el.elements = action.payload.flow;
            }
          });
        }
      });
    },

    [newVersionFlow]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.flow.originalId) {
          els.versions.push(action.payload.flow);
        }
      });
    },
    [newDefaultVersionFlow]: (state, action) => {
      const clone = JSON.parse(JSON.stringify(current(state)));

      clone.entities.forEach((els) => {
        if (els._id === action.payload.flowId) {
          els.defaultVersion = action.payload.defaultVersion;
        }
      });

      return clone;
    },
    [removeVersion]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.flowId) {
          els.defaultVersion = action.payload.defaultVersion;
          els.versions = els.versions.filter(
            (item) => item.versionNumber !== action.payload.versionNumber
          );
        }
      });
    },
    [showCriticalPath]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.flowId) {
          els.elements = action.payload.elements;
        }
      });
    },
    [newElements]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.flowId) {
          els.elements = action.payload.elements;
        }
      });
    },
    [newElementsVersion]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.flowId) {
          if (!els.versions.find((item) => item.versionNumber === "default")) {
            els.versions.push({
              versionNumber: "default",
              elements: els.elements,
            });
          }

          els.elements = action.payload.elements;
        }
      });
    },
    [fetchSingleFlow]: (state, action) => {
      state.entities = [action.payload.flow];
      state.pages = 1;
      state.status = "succeeded";
    },
    [searchModel]: (state, action) => {
      state.entities = action.payload.flows;
      state.pages = action.payload.pages;
    },
  }
);

export default flowsReducer;
