import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "semantic-ui-react";
import { Modal } from "semantic-ui-react";
import {
  handleDeleteUser,
  handleResendEmail,
} from "../../../state/users/actions";
import Loading from "../../../components/LoadingTemplate/index";

const EmployeeModal = ({
  enterpriseId,
  username,
  email,
  open,
  change,
  user,
}) => {
  const dispatch = useDispatch();
  const authUser = useSelector((state) => state.auth.user);
  const [loading, setLoading] = useState(false);

  return !loading ? (
    <Modal
      onClose={() => change(false)}
      onOpen={() => change(true)}
      open={open}
    >
      <Modal.Header>Nome do funcionário: {username}</Modal.Header>
      <Modal.Header>Email do funcionário: {email}</Modal.Header>
      <Modal.Actions>
        <Button color="black" onClick={() => change(false)}>
          Voltar
        </Button>
        {user?.status === "pending" && (
          <Button
            color="green"
            onClick={() => {
              setLoading(true);
              dispatch(handleResendEmail({ id: user._id })).then(() =>
                setLoading(false)
              );
              change(false);
            }}
          >
            Reenviar email
          </Button>
        )}
        {!authUser?.enterpriseId && (
          <Button
            content="Deletar usuário"
            labelPosition="right"
            icon="x"
            onClick={() => {
              setLoading(true);
              dispatch(handleDeleteUser({ username, enterpriseId })).then(
                () => {
                  change(false);
                  setLoading(false);
                }
              );
            }}
            negative
          />
        )}
      </Modal.Actions>
    </Modal>
  ) : (
    <Modal
      onClose={() => change(false)}
      onOpen={() => change(true)}
      open={open}
    >
      <div>
        <Loading />
      </div>
    </Modal>
  );
};

export default EmployeeModal;
