import { useDispatch, useSelector } from "react-redux";
import { findActivedFlow } from "../../state/activedFlows/selectors";
import moment from "moment";
import "moment/min/locales";
import React, { useRef, useEffect, useState } from "react";
import ReactFlow, {
  Controls,
  ReactFlowProvider,
  Background,
  MiniMap,
  useStoreState,
  useZoomPanHelper,
} from "react-flow-renderer";

import ConditionalNodeForView from "../../components/ConditionalNodeForView";
import ConditionalEndNode from "../../components/ConditionalEndNode";
import ParallelNode from "../../components/ParallelNode";
import ParallelEndNode from "../../components/ParallelEndNode";
import TaskNodeForView from "../../components/TaskNodeForView";
import EventStartNode from "../../components/EventStartNode";
import EventEndNode from "../../components/EventEndNode";
import CustomEdgeForView from "../../components/CustomEdgeForView";
import { useHistory } from "react-router-dom";
import { fetchActivedFlows } from "../../state/activedFlows/reducers";
import CustomEdgeWithoutLabel from "../../components/CustomEdgeWithoutLabel";
import ActivedTaskModal from "../../components/ActivedTaskModal/index";
import {
  Button,
  Comment,
  Confirm,
  Dimmer,
  Header,
  Icon,
  Loader,
  Segment,
  Label,
  Sidebar,
} from "semantic-ui-react";
import {
  ButtonTest,
  ButtonTest2,
  ButtonTest3,
  ReactFlowBoard,
  ReactFlowWrapper,
  StickyNotes,
  StickyTitle,
} from "./styles";
import StatsModal from "../../components/StatsModal/index";
import TimerEventNodeForView from "../../components/TimerEventNodeForView";
import {
  handleRemoveActivedFlow,
  handleFetchSingleActivedFlow,
} from "../../state/activedFlows/actions";
import ZoomNode from "../../components/ZoomNodeComponent/index";
import Loading from "../../components/LoadingTemplate";
import CustomMarkForView from "../../components/CustomMarkForView";
import CustomTextForView from "../../components/CustomTextForView";
import { useKey } from "rooks"; //Arrows

const ChangePosition = ({ off, xy }) => {
  const { transform } = useZoomPanHelper();

  const state = useStoreState((state) => state);
  const x = state.transform[0];
  const y = state.transform[1];
  const z = state.transform[2];

  useEffect(() => {
    transform({ x: x + xy.x, y: y + xy.y, zoom: z + xy.z });
    off();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

const FlowViewerFinishedPage = ({ match }) => {
  const flow = useSelector((state) =>
    findActivedFlow(state.activedFlows.entities, match.params.id)
  );
  const flowStatus = useSelector((state) => state.activedFlows.status);
  const dispatch = useDispatch();
  const history = useHistory();
  const reactFlowWrapper = useRef(null);
  const [load, setLoad] = useState(false);
  const [actualTask, setActualTask] = useState(null);
  const [open, setOpen] = useState(false);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const nowLocal = moment().utcOffset(-180);
  const [visible, setVisible] = useState(true);
  const [visibleRight, setVisibleRight] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const counterRef = useRef(false);
  const [jumpElement, setJumpElement] = useState([]);
  //Arrow Keys
  const [arrowFlag, setArrowFlag] = useState(false);
  const [coordinates, setCoordinates] = useState(false);

  useEffect(() => {
    if (!token) history.push("/login");
  }, [history, token]);

  moment.locale("pt-br");

  useEffect(() => {
    dispatch(
      handleFetchSingleActivedFlow({
        enterpriseId: !user.enterpriseId ? user?.id : user.enterpriseId,
        flowId: match.params.id,
      })
    );
  }, [dispatch]);

  useEffect(() => {
    if (flowStatus === "succeeded") {
      let elementsState = [...flow.elements];

      flow.elements.forEach((item, index, array) => {
        if (item.type === "conditionalEnd" && item.data.status === "done") {
          const conditionalEdgesEnd = array.filter((e) => e.target === item.id);
          let doneId;
          let doneElement;

          conditionalEdgesEnd.forEach((d, interator) => {
            if (d.data.status === "done") {
              doneId = d._id;
            }
          });

          flow.elements?.forEach((i, j) => {
            if (i._id === doneId) {
              doneElement = elementsState?.splice(j, 1);

              elementsState?.push(doneElement[0]);
            }
          });
        }

        if (item.type === "conditional" && item.data.status === "done") {
          const conditionalEdges = array.filter((e) => e.source === item.id);
          let doneId;
          let doneElement;

          conditionalEdges.forEach((d, interator) => {
            if (d.data.status === "done") {
              doneId = d._id;
            }
          });

          flow.elements?.forEach((i, j) => {
            if (i._id === doneId) {
              doneElement = elementsState?.splice(j, 1);
              elementsState?.push(doneElement[0]);
            }
          });
        }
      });
      elementsState.sort(function (a, b) {
        if (a.data.status === "done" && b.data.status !== "done") return 1;
        else if (
          (a.data.status === "done" && b.data.status === "done") ||
          (a.data.status !== "done" && b.data.status !== "done")
        )
          return 0;
        else return -1;
      });
      setJumpElement(elementsState);
    }
  }, [flowStatus]);

  const onLoad = (reactFlowapi) => {
    setLoad(true);
    reactFlowapi.fitView();
  };

  const nodeTypes = {
    conditional: ConditionalNodeForView,
    conditionalEnd: ConditionalEndNode,
    parallel: ParallelNode,
    parallelEnd: ParallelEndNode,
    task: TaskNodeForView,
    eventStart: EventStartNode,
    eventEnd: EventEndNode,
    timerEvent: TimerEventNodeForView,
    customMark: CustomMarkForView,
    customText: CustomTextForView,
  };

  const edgeTypes = {
    customEdge: CustomEdgeForView,
    customEdgeWithoutLabel: CustomEdgeWithoutLabel,
  };

  const handleConfirmDelete = () => {
    setLoading(true);
    dispatch(handleRemoveActivedFlow(flow?._id)).then(() => {
      history.push("/finishedflows");
      setLoading(false);
    });

    setConfirmOpen(false);
  };
  const handleCancel = () => setConfirmOpen(false);
  //? Implementando comandos por Teclado
  function ArrowLeftEnter(e) {
    setCoordinates({ x: 10, y: 0, z: 0 });
    setArrowFlag(true);
  }
  function ArrowRightEntered(e) {
    setCoordinates({ x: -10, y: 0, z: 0 });
    setArrowFlag(true);
  }
  function ArrowUpEntered(e) {
    setCoordinates({ x: 0, y: 10, z: 0 });
    setArrowFlag(true);
  }
  function ArrowDownEntered(e) {
    setCoordinates({ x: 0, y: -10, z: 0 });
    setArrowFlag(true);
  }
  useKey(["ArrowLeft"], ArrowLeftEnter);
  useKey(["ArrowRight"], ArrowRightEntered);
  useKey(["ArrowUp"], ArrowUpEntered);
  useKey(["ArrowDown"], ArrowDownEntered);

  return flowStatus === "succeeded" && loading === false ? (
    <div>
      <Sidebar.Pushable as={Segment}>
        <Sidebar
          as={Segment}
          animation="push"
          vertical
          visible={visible}
          style={{ textAlign: "center", padding: 0, overflowX: "hidden" }}
        >
          <Label corner="right" style={{ margin: 0, padding: 0 }}>
            <Icon
              name="cancel"
              style={{
                background: "none",
                padding: 0,
                cursor: "pointer",
              }}
              onClick={() => setVisible(false)}
            />
          </Label>

          <h3
            style={{
              backgroundColor: "#F0F0F0",
              padding: "12px",
              marginTop: "0px",
            }}
          >
            {flow?.title?.toUpperCase()}
          </h3>
          <h3
            style={{
              backgroundColor: "#F0F0F0",
              padding: "12px",
              marginTop: "-30px",
            }}
          >
            {flow?.client?.toUpperCase()}
          </h3>

          <Label size="large" style={{ minWidth: "210px" }}>
            <Icon name="calendar alternate outline" />
            Início: {moment(flow?.createdAt).format("L, LT")}
          </Label>
          <Label
            size="large"
            style={{
              margin: "10px",
              minWidth: "210px",
              paddingLeft: 0,
            }}
          >
            <Icon name="calendar alternate outline" />
            Fim: {moment(flow?.finishedAt).format("L, LT")}
          </Label>
          <Segment style={{ margin: "10px" }} color="green">
            <h4>FLUXO FINALIZADO</h4>
          </Segment>
          <StickyNotes>
            <StickyTitle>
              <div>Anotações</div>
            </StickyTitle>

            <h5
              style={{
                margin: "10px",
                overflowWrap: "break-word",
                textAlign: "left",
                whiteSpace: "pre-wrap",
              }}
            >
              {flow?.comments}
            </h5>
          </StickyNotes>

          <Comment.Group
            style={{
              textAlign: "left",
              paddingLeft: "5px",
              paddingRight: "5px",
            }}
          >
            <Header as="h3" dividing style={{ backgroundColor: "white" }}>
              Comentários
            </Header>

            {flow?.posts?.map((item, index) => (
              <Comment key={index}>
                <Comment.Content>
                  <Comment.Author as="a">{item.username}</Comment.Author>
                  <Comment.Metadata>
                    <div>{`${moment(item.time).calendar()} | ${moment(
                      item.time
                    ).from(nowLocal)}`}</div>
                  </Comment.Metadata>
                  <Comment.Text>{item.text}</Comment.Text>
                </Comment.Content>
              </Comment>
            ))}
          </Comment.Group>
        </Sidebar>

        <StatsModal
          open={visibleRight}
          change={(e) => setVisibleRight(e)}
          flow={flow}
        />

        <Sidebar.Pusher
          style={{
            display: "flex",
            height: "calc(100vh - (74px))",
            overflow: "hidden",
          }}
        >
          <ReactFlowProvider>
            {match.params.taskId !== "full" && (
              <ZoomNode
                id={match.params.taskId}
                nodes={flow?.elements}
                load={load}
              />
            )}
            {
              arrowFlag && (
                <ChangePosition
                  off={() => setArrowFlag(false)}
                  xy={coordinates}
                />
              ) // ponte entre as funcions e state do ReactFlowProvider
            }

            <ButtonTest>
              <Button
                style={{ width: "180px", backgroundColor: "#9e9eff" }}
                icon="info"
                labelPosition="left"
                content="Dados do fluxo"
                onClick={() => setVisible(!visible)}
              />
            </ButtonTest>
            <ButtonTest2>
              <Button
                style={{ width: "180px", backgroundColor: "#beefbe" }}
                icon="line graph"
                labelPosition="left"
                content="Estatísticas"
                onClick={() => setVisibleRight(!visibleRight)}
              />
            </ButtonTest2>
            {user?.rank === "Gerente" && (
              <ButtonTest3>
                <Button
                  content="Deletar fluxo"
                  icon="trash alternate"
                  labelPosition="left"
                  onClick={() => setConfirmOpen(true)}
                  style={{
                    backgroundColor: "#ffa3a3",
                  }}
                />
              </ButtonTest3>
            )}

            <Confirm
              open={confirmOpen}
              content="Tem certeza que deseja excluir esse fluxo finalizado?"
              onCancel={handleCancel}
              onConfirm={handleConfirmDelete}
              confirmButton="Excluir"
              cancelButton="Cancelar"
            />

            <ReactFlowWrapper ref={reactFlowWrapper}>
              <ActivedTaskModal
                counterRef={counterRef}
                flow={flow}
                taskId={actualTask}
                open={open}
                change={(e) => setOpen(e)}
              />
              <ReactFlowBoard
                minZoom={0}
                elements={jumpElement}
                onLoad={onLoad}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                nodesDraggable={false}
                nodesConnectable={false}
                elementsSelectable={false}
                onElementClick={(i, e) => {
                  if (
                    e !== null &&
                    (e.type === "task" ||
                      (e.type === "conditional" && e.data.status === "doing"))
                  ) {
                    setActualTask(e.id);
                    return setOpen(true);
                  } else return null;
                }}
              >
                <MiniMap
                  nodeStrokeColor={(node) =>
                    node.data.expired === true ? "red" : "black"
                  }
                  style={{ background: "#aaa" }}
                  nodeColor={(node) => {
                    const nowLocal = moment().utcOffset(-180);

                    if (node.type === "customMark") {
                      return node.data.hexColor;
                    } else {
                      if (
                        node.type !== "customText" &&
                        moment(node.data.startedAt)
                          .add(node.data.expiration.number, "hours")
                          .diff(nowLocal, "hours", true) < 0 &&
                        node.data.status !== "done"
                      )
                        return "#ff6666";

                      switch (node.data.status) {
                        case "done":
                          return "#beefbe";
                        case "doing":
                          return "#f2f0a6";
                        default:
                          return "white";
                      }
                    }
                  }}
                />
                <Controls showInteractive={false} />
                <Background />
              </ReactFlowBoard>
            </ReactFlowWrapper>
          </ReactFlowProvider>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    </div>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

export default FlowViewerFinishedPage;
