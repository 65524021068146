import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle` 
  body {
    font-size: 100%;
    font-family: Sora;
  }
  
  html {
    font-size: 14px;
    @media screen and (min-width: 1420px){
      font-size: 1rem;
    }
    @media screen and (min-width: 1920px) {
      font-size: 1.35rem;//24px para 1080p  
    }
  }
`;

export default GlobalStyle;
