import React from "react";

const Stop = ({ style }) => (
  <svg
    style={style}
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.77598"
      y="1.8007"
      width="15.2427"
      height="15.2427"
      rx="1.97582"
      fill="none"
      stroke="#585E66"
      strokeWidth="1.69356"
    />
  </svg>
);

export default Stop;
