import React from "react";

const Banner = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 1102 1080"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M596.48 -36.8781C596.285 -36.9909 595.968 -36.9909 595.773 -36.8781L560.097 -16.2805C559.901 -16.1677 559.901 -15.9849 560.097 -15.8721L595.773 4.7255C595.968 4.8383 596.285 4.83829 596.48 4.7255L632.156 -15.8721C632.352 -15.9849 632.352 -16.1677 632.156 -16.2805L596.48 -36.8781ZM535.841 -16.2805C535.646 -16.1677 535.646 -15.9849 535.841 -15.8721L595.773 18.7295C595.968 18.8423 596.285 18.8423 596.48 18.7295L656.412 -15.8721C656.607 -15.9849 656.607 -16.1678 656.412 -16.2805L596.48 -50.8821C596.285 -50.9949 595.968 -50.9949 595.773 -50.8821L535.841 -16.2805Z"
        fill="url(#paint0_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M863.292 -36.8782C863.097 -36.991 862.78 -36.991 862.585 -36.8782L826.909 -16.2806C826.714 -16.1679 826.714 -15.985 826.909 -15.8722L862.585 4.72541C862.78 4.8382 863.097 4.83819 863.292 4.7254L898.969 -15.8722C899.164 -15.985 899.164 -16.1678 898.969 -16.2806L863.292 -36.8782ZM802.653 -16.2806C802.458 -16.1678 802.458 -15.985 802.653 -15.8722L862.585 18.7294C862.78 18.8422 863.097 18.8422 863.292 18.7294L923.224 -15.8722C923.42 -15.985 923.42 -16.1679 923.224 -16.2806L863.292 -50.8823C863.097 -50.995 862.78 -50.995 862.585 -50.8822L802.653 -16.2806Z"
        fill="url(#paint1_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M747.724 -82.5954C757.771 -88.3961 757.771 -97.8008 747.724 -103.601C737.677 -109.402 721.388 -109.402 711.341 -103.601C701.294 -97.8008 701.294 -88.3961 711.341 -82.5954C721.388 -76.7948 737.677 -76.7948 747.724 -82.5954ZM759.852 -75.5934C776.597 -85.2612 776.597 -100.936 759.852 -110.603C743.107 -120.271 715.958 -120.271 699.213 -110.603C682.468 -100.936 682.468 -85.2612 699.213 -75.5934C715.958 -65.9257 743.107 -65.9256 759.852 -75.5934Z"
        fill="url(#paint2_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M747.724 71.4487C757.771 65.6481 757.771 56.2433 747.724 50.4427C737.677 44.642 721.388 44.642 711.341 50.4427C701.294 56.2434 701.294 65.6481 711.341 71.4487C721.388 77.2494 737.677 77.2494 747.724 71.4487ZM759.852 78.4507C776.597 68.783 776.597 53.1084 759.852 43.4407C743.107 33.7729 715.958 33.7729 699.213 43.4407C682.468 53.1084 682.468 68.783 699.213 78.4507C715.958 88.1185 743.107 88.1185 759.852 78.4507Z"
        fill="url(#paint3_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M737.915 -74.2546L737.915 42.102L721.15 42.102L721.15 -74.2546L737.915 -74.2546Z"
        fill="url(#paint4_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M711.341 -75.5933L626.446 -26.5793L614.318 -33.5813L699.213 -82.5953L711.341 -75.5933ZM844.747 1.42879L759.852 50.4429L747.725 43.4409L832.619 -5.57321L844.747 1.42879Z"
        fill="url(#paint5_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M839.037 103.162C838.841 103.049 838.525 103.049 838.329 103.162L802.653 123.76C802.458 123.872 802.458 124.055 802.653 124.168L838.329 144.766C838.525 144.879 838.842 144.878 839.037 144.766L874.713 124.168C874.908 124.055 874.908 123.872 874.713 123.76L839.037 103.162ZM778.398 123.76C778.202 123.872 778.202 124.055 778.398 124.168L838.329 158.77C838.525 158.883 838.842 158.883 839.037 158.77L898.969 124.168C899.164 124.055 899.164 123.872 898.969 123.76L839.037 89.1581C838.842 89.0453 838.525 89.0453 838.329 89.1581L778.398 123.76Z"
        fill="url(#paint6_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1105.85 103.162C1105.65 103.049 1105.34 103.049 1105.14 103.162L1069.47 123.76C1069.27 123.872 1069.27 124.055 1069.47 124.168L1105.14 144.766C1105.34 144.878 1105.65 144.878 1105.85 144.766L1141.53 124.168C1141.72 124.055 1141.72 123.872 1141.53 123.76L1105.85 103.162ZM1045.21 123.76C1045.01 123.872 1045.01 124.055 1045.21 124.168L1105.14 158.77C1105.34 158.882 1105.65 158.882 1105.85 158.77L1165.78 124.168C1165.98 124.055 1165.98 123.872 1165.78 123.76L1105.85 89.158C1105.65 89.0452 1105.34 89.0452 1105.14 89.158L1045.21 123.76Z"
        fill="url(#paint7_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M990.281 57.4448C1000.33 51.6441 1000.33 42.2394 990.281 36.4387C980.234 30.6381 963.945 30.6381 953.898 36.4388C943.851 42.2394 943.851 51.6441 953.898 57.4448C963.945 63.2454 980.234 63.2454 990.281 57.4448ZM1002.41 64.4468C1019.15 54.779 1019.15 39.1045 1002.41 29.4367C985.664 19.769 958.515 19.769 941.77 29.4367C925.025 39.1045 925.025 54.779 941.77 64.4468C958.515 74.1146 985.664 74.1145 1002.41 64.4468Z"
        fill="url(#paint8_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M990.281 211.489C1000.33 205.688 1000.33 196.284 990.281 190.483C980.234 184.682 963.945 184.682 953.897 190.483C943.85 196.284 943.85 205.688 953.897 211.489C963.944 217.29 980.234 217.29 990.281 211.489ZM1002.41 218.491C1019.15 208.823 1019.15 193.149 1002.41 183.481C985.664 173.813 958.515 173.813 941.77 183.481C925.025 193.149 925.025 208.823 941.77 218.491C958.515 228.159 985.664 228.159 1002.41 218.491Z"
        fill="url(#paint9_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M980.472 65.7856L980.472 182.142L963.707 182.142L963.707 65.7856L980.472 65.7856Z"
        fill="url(#paint10_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M953.898 64.4469L869.003 113.461L856.875 106.459L941.77 57.4449L953.898 64.4469ZM1087.3 141.469L1002.41 190.483L990.281 183.481L1075.18 134.467L1087.3 141.469Z"
        fill="url(#paint11_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M353.923 -176.918C353.728 -177.031 353.411 -177.031 353.216 -176.918L317.54 -156.321C317.345 -156.208 317.345 -156.025 317.54 -155.912L353.216 -135.315C353.411 -135.202 353.728 -135.202 353.923 -135.315L389.599 -155.912C389.795 -156.025 389.795 -156.208 389.599 -156.321L353.923 -176.918ZM293.284 -156.321C293.089 -156.208 293.089 -156.025 293.284 -155.912L353.216 -121.311C353.411 -121.198 353.728 -121.198 353.923 -121.311L413.855 -155.912C414.05 -156.025 414.05 -156.208 413.855 -156.321L353.923 -190.922C353.728 -191.035 353.411 -191.035 353.216 -190.922L293.284 -156.321Z"
        fill="url(#paint12_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M620.736 -176.918C620.54 -177.031 620.224 -177.031 620.028 -176.918L584.352 -156.321C584.157 -156.208 584.157 -156.025 584.352 -155.912L620.028 -135.315C620.224 -135.202 620.54 -135.202 620.736 -135.315L656.412 -155.912C656.607 -156.025 656.607 -156.208 656.412 -156.321L620.736 -176.918ZM560.096 -156.321C559.901 -156.208 559.901 -156.025 560.096 -155.912L620.028 -121.311C620.224 -121.198 620.54 -121.198 620.736 -121.311L680.667 -155.912C680.863 -156.025 680.863 -156.208 680.667 -156.321L620.736 -190.922C620.54 -191.035 620.224 -191.035 620.028 -190.922L560.096 -156.321Z"
        fill="url(#paint13_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M505.168 -222.636C515.215 -228.436 515.215 -237.841 505.168 -243.642C495.121 -249.442 478.831 -249.442 468.784 -243.642C458.737 -237.841 458.737 -228.436 468.784 -222.636C478.831 -216.835 495.121 -216.835 505.168 -222.636ZM517.296 -215.634C534.041 -225.301 534.041 -240.976 517.296 -250.644C500.55 -260.311 473.401 -260.311 456.656 -250.644C439.911 -240.976 439.911 -225.301 456.656 -215.634C473.401 -205.966 500.55 -205.966 517.296 -215.634Z"
        fill="url(#paint14_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M505.167 -68.5915C515.215 -74.3921 515.215 -83.7969 505.167 -89.5975C495.12 -95.3982 478.831 -95.3982 468.784 -89.5975C458.737 -83.7969 458.737 -74.3921 468.784 -68.5915C478.831 -62.7908 495.12 -62.7908 505.167 -68.5915ZM517.295 -61.5895C534.04 -71.2572 534.04 -86.9318 517.295 -96.5995C500.55 -106.267 473.401 -106.267 456.656 -96.5995C439.911 -86.9318 439.911 -71.2572 456.656 -61.5895C473.401 -51.9217 500.55 -51.9217 517.295 -61.5895Z"
        fill="url(#paint15_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M495.359 -214.295L495.359 -97.9382L478.593 -97.9382L478.593 -214.295L495.359 -214.295Z"
        fill="url(#paint16_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M468.784 -215.634L383.889 -166.619L371.762 -173.621L456.656 -222.636L468.784 -215.634ZM602.19 -138.611L517.296 -89.5973L505.168 -96.5993L590.063 -145.613L602.19 -138.611Z"
        fill="url(#paint17_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M585.018 -43.5069L611.547 -128.26L631.238 -126.206L604.708 -41.4524L585.018 -43.5069Z"
        fill="url(#paint18_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M825.57 95.3129L853.869 9.53821L873.525 11.6999L845.227 97.4745L825.57 95.3129Z"
        fill="url(#paint19_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1497.28 483.78C1497.47 483.893 1497.47 484.076 1497.28 484.189L1461.6 504.786C1461.4 504.899 1461.09 504.899 1460.89 504.786L1425.22 484.189C1425.02 484.076 1425.02 483.893 1425.22 483.78L1460.89 463.183C1461.09 463.07 1461.4 463.07 1461.6 463.183L1497.28 483.78ZM1461.6 518.79C1461.4 518.903 1461.09 518.903 1460.89 518.79L1400.96 484.189C1400.77 484.076 1400.77 483.893 1400.96 483.78L1460.89 449.179C1461.09 449.066 1461.4 449.066 1461.6 449.179L1521.53 483.78C1521.73 483.893 1521.73 484.076 1521.53 484.189L1461.6 518.79Z"
        fill="url(#paint20_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1497.28 329.736C1497.47 329.849 1497.47 330.031 1497.28 330.144L1461.6 350.742C1461.4 350.855 1461.09 350.855 1460.89 350.742L1425.22 330.144C1425.02 330.031 1425.02 329.849 1425.22 329.736L1460.89 309.138C1461.09 309.025 1461.4 309.025 1461.6 309.138L1497.28 329.736ZM1461.6 364.746C1461.4 364.859 1461.09 364.859 1460.89 364.746L1400.96 330.144C1400.77 330.031 1400.77 329.849 1400.96 329.736L1460.89 295.134C1461.09 295.021 1461.4 295.021 1461.6 295.134L1521.53 329.736C1521.73 329.849 1521.73 330.031 1521.53 330.144L1461.6 364.746Z"
        fill="url(#paint21_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1576.46 396.459C1586.51 390.659 1602.8 390.659 1612.84 396.459C1622.89 402.26 1622.89 411.665 1612.84 417.465C1602.8 423.266 1586.51 423.266 1576.46 417.465C1566.41 411.665 1566.41 402.26 1576.46 396.459ZM1564.33 389.457C1581.08 379.79 1608.23 379.789 1624.97 389.457C1641.72 399.125 1641.72 414.8 1624.97 424.467C1608.23 434.135 1581.08 434.135 1564.33 424.467C1547.59 414.8 1547.59 399.125 1564.33 389.457Z"
        fill="url(#paint22_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1309.65 396.459C1319.69 390.658 1335.98 390.658 1346.03 396.459C1356.08 402.26 1356.08 411.665 1346.03 417.465C1335.98 423.266 1319.69 423.266 1309.65 417.465C1299.6 411.664 1299.6 402.26 1309.65 396.459ZM1297.52 389.457C1314.27 379.789 1341.41 379.789 1358.16 389.457C1374.9 399.125 1374.9 414.799 1358.16 424.467C1341.41 434.135 1314.27 434.135 1297.52 424.467C1280.77 414.799 1280.78 399.125 1297.52 389.457Z"
        fill="url(#paint23_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1562.01 402.122L1360.48 402.122L1360.48 411.802L1562.01 411.802L1562.01 402.122Z"
        fill="url(#paint24_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1564.33 417.465L1479.44 466.479L1491.57 473.481L1576.46 424.467L1564.33 417.465ZM1430.93 340.443L1346.03 389.457L1358.16 396.459L1443.05 347.445L1430.93 340.443Z"
        fill="url(#paint25_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1254.72 343.74C1254.91 343.853 1254.91 344.035 1254.72 344.148L1219.04 364.746C1218.85 364.859 1218.53 364.859 1218.34 364.746L1182.66 344.148C1182.46 344.035 1182.46 343.853 1182.66 343.74L1218.34 323.142C1218.53 323.029 1218.85 323.029 1219.04 323.142L1254.72 343.74ZM1219.04 378.75C1218.85 378.863 1218.53 378.863 1218.34 378.75L1158.4 344.148C1158.21 344.035 1158.21 343.853 1158.4 343.74L1218.34 309.138C1218.53 309.025 1218.85 309.025 1219.04 309.138L1278.97 343.74C1279.17 343.853 1279.17 344.035 1278.97 344.148L1219.04 378.75Z"
        fill="url(#paint26_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1254.72 189.695C1254.91 189.808 1254.91 189.991 1254.72 190.104L1219.04 210.701C1218.85 210.814 1218.53 210.814 1218.34 210.701L1182.66 190.104C1182.46 189.991 1182.46 189.808 1182.66 189.695L1218.33 169.098C1218.53 168.985 1218.85 168.985 1219.04 169.098L1254.72 189.695ZM1219.04 224.705C1218.85 224.818 1218.53 224.818 1218.33 224.705L1158.4 190.104C1158.21 189.991 1158.21 189.808 1158.4 189.695L1218.33 155.094C1218.53 154.981 1218.85 154.981 1219.04 155.094L1278.97 189.695C1279.17 189.808 1279.17 189.991 1278.97 190.104L1219.04 224.705Z"
        fill="url(#paint27_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1333.9 256.419C1343.95 250.618 1360.24 250.618 1370.29 256.419C1380.33 262.219 1380.33 271.624 1370.29 277.425C1360.24 283.226 1343.95 283.225 1333.9 277.425C1323.86 271.624 1323.86 262.219 1333.9 256.419ZM1321.78 249.417C1338.52 239.749 1365.67 239.749 1382.41 249.417C1399.16 259.085 1399.16 274.759 1382.41 284.427C1365.67 294.095 1338.52 294.095 1321.78 284.427C1305.03 274.759 1305.03 259.085 1321.78 249.417Z"
        fill="url(#paint28_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1067.09 256.419C1077.14 250.618 1093.43 250.618 1103.47 256.419C1113.52 262.219 1113.52 271.624 1103.47 277.425C1093.43 283.225 1077.14 283.225 1067.09 277.425C1057.04 271.624 1057.04 262.219 1067.09 256.419ZM1054.96 249.417C1071.71 239.749 1098.86 239.749 1115.6 249.417C1132.35 259.084 1132.35 274.759 1115.6 284.427C1098.86 294.094 1071.71 294.094 1054.96 284.427C1038.22 274.759 1038.22 259.084 1054.96 249.417Z"
        fill="url(#paint29_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1319.46 262.082L1117.92 262.082L1117.92 271.762L1319.46 271.762L1319.46 262.082Z"
        fill="url(#paint30_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1321.78 277.425L1236.88 326.439L1249.01 333.441L1333.9 284.427L1321.78 277.425ZM1188.37 200.403L1103.47 249.417L1115.6 256.419L1200.5 207.405L1188.37 200.403Z"
        fill="url(#paint31_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1739.83 623.82C1740.03 623.933 1740.03 624.116 1739.83 624.229L1704.16 644.826C1703.96 644.939 1703.64 644.939 1703.45 644.826L1667.77 624.229C1667.58 624.116 1667.58 623.933 1667.77 623.82L1703.45 603.223C1703.64 603.11 1703.96 603.11 1704.16 603.223L1739.83 623.82ZM1704.16 658.83C1703.96 658.943 1703.64 658.943 1703.45 658.83L1643.52 624.229C1643.32 624.116 1643.32 623.933 1643.52 623.82L1703.45 589.219C1703.64 589.106 1703.96 589.106 1704.16 589.219L1764.09 623.82C1764.28 623.933 1764.28 624.116 1764.09 624.229L1704.16 658.83Z"
        fill="url(#paint32_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1739.83 469.776C1740.03 469.889 1740.03 470.071 1739.83 470.184L1704.16 490.782C1703.96 490.895 1703.64 490.895 1703.45 490.782L1667.77 470.184C1667.58 470.071 1667.58 469.889 1667.77 469.776L1703.45 449.178C1703.64 449.065 1703.96 449.065 1704.16 449.178L1739.83 469.776ZM1704.16 504.786C1703.96 504.899 1703.64 504.899 1703.45 504.786L1643.52 470.184C1643.32 470.071 1643.32 469.889 1643.52 469.776L1703.45 435.174C1703.64 435.061 1703.96 435.061 1704.16 435.174L1764.09 469.776C1764.28 469.889 1764.28 470.071 1764.09 470.184L1704.16 504.786Z"
        fill="url(#paint33_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1819.02 536.499C1829.06 530.699 1845.35 530.699 1855.4 536.499C1865.45 542.3 1865.45 551.705 1855.4 557.505C1845.35 563.306 1829.06 563.306 1819.02 557.505C1808.97 551.705 1808.97 542.3 1819.02 536.499ZM1806.89 529.497C1823.63 519.829 1850.78 519.829 1867.53 529.497C1884.27 539.165 1884.27 554.84 1867.53 564.507C1850.78 574.175 1823.63 574.175 1806.89 564.507C1790.14 554.839 1790.14 539.165 1806.89 529.497Z"
        fill="url(#paint34_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1552.2 536.499C1562.25 530.698 1578.54 530.698 1588.59 536.499C1598.63 542.3 1598.63 551.705 1588.59 557.505C1578.54 563.306 1562.25 563.306 1552.2 557.505C1542.16 551.704 1542.16 542.3 1552.2 536.499ZM1540.08 529.497C1556.82 519.829 1583.97 519.829 1600.72 529.497C1617.46 539.165 1617.46 554.839 1600.72 564.507C1583.97 574.175 1556.82 574.175 1540.08 564.507C1523.33 554.839 1523.33 539.165 1540.08 529.497Z"
        fill="url(#paint35_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1804.57 542.162L1603.03 542.162L1603.03 551.842L1804.57 551.842L1804.57 542.162Z"
        fill="url(#paint36_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1806.89 557.505L1721.99 606.519L1734.12 613.521L1819.02 564.507L1806.89 557.505ZM1673.48 480.483L1588.59 529.497L1600.72 536.499L1685.61 487.485L1673.48 480.483Z"
        fill="url(#paint37_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1508.76 490.398L1655.55 475.081L1652 463.712L1505.2 479.029L1508.76 490.398Z"
        fill="url(#paint38_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1268.31 351.515L1416.88 335.177L1413.14 323.828L1264.57 340.166L1268.31 351.515Z"
        fill="url(#paint39_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M444.766 143.459C444.57 143.346 444.254 143.346 444.058 143.459L408.382 164.056C408.187 164.169 408.187 164.352 408.382 164.465L444.058 185.062C444.254 185.175 444.57 185.175 444.766 185.062L480.442 164.465C480.637 164.352 480.637 164.169 480.442 164.056L444.766 143.459ZM384.127 164.056C383.931 164.169 383.931 164.352 384.127 164.465L444.058 199.066C444.254 199.179 444.57 199.179 444.766 199.066L504.698 164.465C504.893 164.352 504.893 164.169 504.698 164.056L444.766 129.455C444.57 129.342 444.254 129.342 444.058 129.455L384.127 164.056Z"
        fill="url(#paint40_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M711.578 143.459C711.383 143.346 711.066 143.346 710.871 143.459L675.195 164.056C674.999 164.169 674.999 164.352 675.195 164.465L710.871 185.062C711.066 185.175 711.383 185.175 711.578 185.062L747.254 164.465C747.45 164.352 747.45 164.169 747.254 164.056L711.578 143.459ZM650.939 164.056C650.744 164.169 650.744 164.352 650.939 164.465L710.871 199.066C711.066 199.179 711.383 199.179 711.578 199.066L771.51 164.465C771.705 164.352 771.705 164.169 771.51 164.056L711.578 129.455C711.383 129.342 711.066 129.342 710.871 129.455L650.939 164.056Z"
        fill="url(#paint41_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M596.01 97.7415C606.057 91.9408 606.057 82.5361 596.01 76.7354C585.963 70.9348 569.674 70.9348 559.627 76.7355C549.58 82.5361 549.579 91.9408 559.627 97.7415C569.674 103.542 585.963 103.542 596.01 97.7415ZM608.138 104.744C624.883 95.0757 624.883 79.4012 608.138 69.7334C591.393 60.0657 564.244 60.0657 547.499 69.7334C530.754 79.4012 530.754 95.0757 547.499 104.743C564.244 114.411 591.393 114.411 608.138 104.744Z"
        fill="url(#paint42_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M596.01 251.786C606.057 245.985 606.057 236.58 596.01 230.78C585.963 224.979 569.673 224.979 559.626 230.78C549.579 236.58 549.579 245.985 559.626 251.786C569.673 257.586 585.963 257.586 596.01 251.786ZM608.138 258.788C624.883 249.12 624.883 233.445 608.138 223.778C591.393 214.11 564.244 214.11 547.499 223.778C530.753 233.445 530.753 249.12 547.499 258.788C564.244 268.455 591.393 268.455 608.138 258.788Z"
        fill="url(#paint43_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M586.201 106.082L586.201 222.439L569.435 222.439L569.436 106.082L586.201 106.082Z"
        fill="url(#paint44_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M559.627 104.744L474.732 153.758L462.604 146.756L547.499 97.7416L559.627 104.744ZM693.033 181.766L608.138 230.78L596.01 223.778L680.905 174.764L693.033 181.766Z"
        fill="url(#paint45_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M687.322 283.499C687.127 283.386 686.81 283.386 686.615 283.499L650.939 304.097C650.744 304.209 650.744 304.392 650.939 304.505L686.615 325.103C686.81 325.215 687.127 325.215 687.323 325.103L722.999 304.505C723.194 304.392 723.194 304.209 722.999 304.097L687.322 283.499ZM626.683 304.097C626.488 304.209 626.488 304.392 626.683 304.505L686.615 339.107C686.81 339.219 687.127 339.219 687.323 339.107L747.254 304.505C747.45 304.392 747.45 304.209 747.254 304.097L687.323 269.495C687.127 269.382 686.81 269.382 686.615 269.495L626.683 304.097Z"
        fill="url(#paint46_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M954.135 283.499C953.939 283.386 953.623 283.386 953.427 283.499L917.751 304.096C917.556 304.209 917.556 304.392 917.751 304.505L953.427 325.103C953.623 325.215 953.939 325.215 954.135 325.103L989.811 304.505C990.006 304.392 990.006 304.209 989.811 304.096L954.135 283.499ZM893.496 304.096C893.3 304.209 893.3 304.392 893.496 304.505L953.427 339.107C953.623 339.219 953.939 339.219 954.135 339.107L1014.07 304.505C1014.26 304.392 1014.26 304.209 1014.07 304.096L954.135 269.495C953.939 269.382 953.623 269.382 953.427 269.495L893.496 304.096Z"
        fill="url(#paint47_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M838.567 237.782C848.614 231.981 848.614 222.576 838.567 216.776C828.52 210.975 812.23 210.975 802.183 216.776C792.136 222.576 792.136 231.981 802.183 237.782C812.23 243.582 828.52 243.582 838.567 237.782ZM850.695 244.784C867.44 235.116 867.44 219.441 850.695 209.774C833.95 200.106 806.8 200.106 790.055 209.774C773.31 219.441 773.31 235.116 790.055 244.784C806.801 254.451 833.95 254.451 850.695 244.784Z"
        fill="url(#paint48_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M838.567 391.826C848.614 386.025 848.614 376.62 838.567 370.82C828.52 365.019 812.23 365.019 802.183 370.82C792.136 376.62 792.136 386.025 802.183 391.826C812.23 397.627 828.52 397.627 838.567 391.826ZM850.694 398.828C867.44 389.16 867.439 373.486 850.694 363.818C833.949 354.15 806.8 354.15 790.055 363.818C773.31 373.486 773.31 389.16 790.055 398.828C806.8 408.496 833.949 408.496 850.694 398.828Z"
        fill="url(#paint49_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M828.758 246.122L828.758 362.479L811.992 362.479L811.992 246.123L828.758 246.122Z"
        fill="url(#paint50_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M802.183 244.784L717.289 293.798L705.161 286.796L790.056 237.782L802.183 244.784ZM935.59 321.806L850.695 370.82L838.567 363.818L923.462 314.804L935.59 321.806Z"
        fill="url(#paint51_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M202.209 3.41859C202.014 3.3058 201.697 3.3058 201.502 3.41859L165.826 24.0162C165.63 24.129 165.63 24.3118 165.826 24.4246L201.502 45.0222C201.697 45.135 202.014 45.135 202.209 45.0222L237.885 24.4246C238.08 24.3118 238.08 24.129 237.885 24.0162L202.209 3.41859ZM141.57 24.0162C141.374 24.129 141.374 24.3118 141.57 24.4246L201.502 59.0262C201.697 59.139 202.014 59.139 202.209 59.0262L262.141 24.4246C262.336 24.3118 262.336 24.129 262.141 24.0162L202.209 -10.5854C202.014 -10.6982 201.697 -10.6982 201.502 -10.5854L141.57 24.0162Z"
        fill="url(#paint52_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M469.021 3.41848C468.826 3.30569 468.509 3.30569 468.314 3.41848L432.638 24.0161C432.442 24.1289 432.442 24.3117 432.638 24.4245L468.314 45.0221C468.509 45.1349 468.826 45.1349 469.021 45.0221L504.697 24.4245C504.893 24.3117 504.893 24.1289 504.697 24.0161L469.021 3.41848ZM408.382 24.0161C408.187 24.1289 408.187 24.3117 408.382 24.4245L468.314 59.0261C468.509 59.1389 468.826 59.1389 469.021 59.0261L528.953 24.4245C529.148 24.3117 529.148 24.1289 528.953 24.0161L469.021 -10.5855C468.826 -10.6983 468.509 -10.6983 468.314 -10.5855L408.382 24.0161Z"
        fill="url(#paint53_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M353.453 -42.2987C363.5 -48.0994 363.5 -57.5041 353.453 -63.3048C343.406 -69.1054 327.117 -69.1054 317.07 -63.3047C307.023 -57.5041 307.023 -48.0994 317.07 -42.2987C327.117 -36.4981 343.406 -36.4981 353.453 -42.2987ZM365.581 -35.2967C382.326 -44.9645 382.326 -60.639 365.581 -70.3068C348.836 -79.9745 321.687 -79.9745 304.942 -70.3068C288.197 -60.639 288.197 -44.9645 304.942 -35.2967C321.687 -25.6289 348.836 -25.6289 365.581 -35.2967Z"
        fill="url(#paint54_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M353.453 111.745C363.5 105.945 363.5 96.5401 353.453 90.7394C343.406 84.9388 327.117 84.9388 317.07 90.7394C307.023 96.5401 307.023 105.945 317.07 111.745C327.117 117.546 343.406 117.546 353.453 111.745ZM365.581 118.747C382.326 109.08 382.326 93.4052 365.581 83.7374C348.836 74.0696 321.687 74.0696 304.942 83.7374C288.197 93.4052 288.197 109.08 304.942 118.747C321.687 128.415 348.836 128.415 365.581 118.747Z"
        fill="url(#paint55_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M343.644 -33.9579L343.644 82.3987L326.879 82.3987L326.879 -33.9579L343.644 -33.9579Z"
        fill="url(#paint56_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M317.07 -35.2966L232.175 13.7175L220.047 6.71545L304.942 -42.2986L317.07 -35.2966ZM450.476 41.7255L365.581 90.7396L353.453 83.7376L438.348 34.7235L450.476 41.7255Z"
        fill="url(#paint57_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M433.303 136.83L459.833 52.0765L479.524 54.131L452.994 138.885L433.303 136.83Z"
        fill="url(#paint58_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M673.856 275.65L702.154 189.875L721.811 192.037L693.513 277.811L673.856 275.65Z"
        fill="url(#paint59_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1345.56 664.117C1345.76 664.23 1345.76 664.413 1345.56 664.526L1309.89 685.123C1309.69 685.236 1309.37 685.236 1309.18 685.123L1273.5 664.526C1273.31 664.413 1273.31 664.23 1273.5 664.117L1309.18 643.519C1309.37 643.407 1309.69 643.407 1309.89 643.52L1345.56 664.117ZM1309.89 699.127C1309.69 699.24 1309.37 699.24 1309.18 699.127L1249.25 664.526C1249.05 664.413 1249.05 664.23 1249.25 664.117L1309.18 629.516C1309.37 629.403 1309.69 629.403 1309.89 629.516L1369.82 664.117C1370.01 664.23 1370.01 664.413 1369.82 664.526L1309.89 699.127Z"
        fill="url(#paint60_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1345.56 510.073C1345.76 510.186 1345.76 510.368 1345.56 510.481L1309.89 531.079C1309.69 531.192 1309.37 531.192 1309.18 531.079L1273.5 510.481C1273.31 510.368 1273.31 510.186 1273.5 510.073L1309.18 489.475C1309.37 489.362 1309.69 489.362 1309.89 489.475L1345.56 510.073ZM1309.89 545.083C1309.69 545.196 1309.37 545.196 1309.18 545.083L1249.25 510.481C1249.05 510.368 1249.05 510.186 1249.25 510.073L1309.18 475.471C1309.37 475.358 1309.69 475.358 1309.89 475.471L1369.82 510.073C1370.01 510.185 1370.01 510.368 1369.82 510.481L1309.89 545.083Z"
        fill="url(#paint61_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1424.75 576.796C1434.79 570.995 1451.08 570.996 1461.13 576.796C1471.18 582.597 1471.18 592.002 1461.13 597.802C1451.08 603.603 1434.79 603.603 1424.75 597.802C1414.7 592.002 1414.7 582.597 1424.75 576.796ZM1412.62 569.794C1429.36 560.126 1456.51 560.126 1473.26 569.794C1490 579.462 1490 595.137 1473.26 604.804C1456.51 614.472 1429.36 614.472 1412.62 604.804C1395.87 595.136 1395.87 579.462 1412.62 569.794Z"
        fill="url(#paint62_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1157.93 576.796C1167.98 570.995 1184.27 570.995 1194.32 576.796C1204.36 582.597 1204.36 592.001 1194.32 597.802C1184.27 603.603 1167.98 603.603 1157.93 597.802C1147.89 592.001 1147.89 582.597 1157.93 576.796ZM1145.81 569.794C1162.55 560.126 1189.7 560.126 1206.44 569.794C1223.19 579.462 1223.19 595.136 1206.44 604.804C1189.7 614.472 1162.55 614.472 1145.81 604.804C1129.06 595.136 1129.06 579.462 1145.81 569.794Z"
        fill="url(#paint63_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1410.3 582.459L1208.76 582.459L1208.76 592.139L1410.3 592.139L1410.3 582.459Z"
        fill="url(#paint64_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1412.62 597.802L1327.72 646.816L1339.85 653.818L1424.75 604.804L1412.62 597.802ZM1279.21 520.78L1194.32 569.794L1206.45 576.796L1291.34 527.782L1279.21 520.78Z"
        fill="url(#paint65_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1103 524.077C1103.2 524.189 1103.2 524.372 1103 524.485L1067.33 545.083C1067.13 545.195 1066.82 545.195 1066.62 545.083L1030.94 524.485C1030.75 524.372 1030.75 524.189 1030.94 524.077L1066.62 503.479C1066.82 503.366 1067.13 503.366 1067.33 503.479L1103 524.077ZM1067.33 559.087C1067.13 559.2 1066.82 559.2 1066.62 559.087L1006.69 524.485C1006.49 524.372 1006.49 524.189 1006.69 524.077L1066.62 489.475C1066.82 489.362 1067.13 489.362 1067.33 489.475L1127.26 524.077C1127.46 524.189 1127.46 524.372 1127.26 524.485L1067.33 559.087Z"
        fill="url(#paint66_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1103 370.032C1103.2 370.145 1103.2 370.328 1103 370.441L1067.33 391.038C1067.13 391.151 1066.82 391.151 1066.62 391.038L1030.94 370.441C1030.75 370.328 1030.75 370.145 1030.94 370.032L1066.62 349.435C1066.82 349.322 1067.13 349.322 1067.33 349.435L1103 370.032ZM1067.33 405.042C1067.13 405.155 1066.82 405.155 1066.62 405.042L1006.69 370.441C1006.49 370.328 1006.49 370.145 1006.69 370.032L1066.62 335.431C1066.82 335.318 1067.13 335.318 1067.33 335.431L1127.26 370.032C1127.46 370.145 1127.46 370.328 1127.26 370.441L1067.33 405.042Z"
        fill="url(#paint67_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1182.19 436.756C1192.24 430.955 1208.53 430.955 1218.57 436.756C1228.62 442.556 1228.62 451.961 1218.57 457.762C1208.53 463.562 1192.24 463.562 1182.19 457.762C1172.14 451.961 1172.14 442.556 1182.19 436.756ZM1170.06 429.754C1186.81 420.086 1213.96 420.086 1230.7 429.754C1247.45 439.421 1247.45 455.096 1230.7 464.764C1213.96 474.432 1186.81 474.432 1170.06 464.764C1153.32 455.096 1153.32 439.421 1170.06 429.754Z"
        fill="url(#paint68_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M915.376 436.756C925.423 430.955 941.713 430.955 951.76 436.756C961.807 442.556 961.807 451.961 951.76 457.762C941.713 463.562 925.423 463.562 915.376 457.762C905.329 451.961 905.329 442.556 915.376 436.756ZM903.249 429.754C919.994 420.086 947.143 420.086 963.888 429.754C980.633 439.421 980.633 455.096 963.888 464.764C947.143 474.431 919.994 474.431 903.249 464.764C886.503 455.096 886.503 439.421 903.249 429.754Z"
        fill="url(#paint69_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1167.74 442.419L966.207 442.419L966.207 452.099L1167.74 452.099L1167.74 442.419Z"
        fill="url(#paint70_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1170.06 457.762L1085.17 506.776L1097.29 513.778L1182.19 464.764L1170.06 457.762ZM1036.65 380.74L951.76 429.754L963.888 436.756L1048.78 387.742L1036.65 380.74Z"
        fill="url(#paint71_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1588.12 804.157C1588.31 804.27 1588.31 804.453 1588.12 804.566L1552.44 825.163C1552.25 825.276 1551.93 825.276 1551.73 825.163L1516.06 804.565C1515.86 804.453 1515.86 804.27 1516.06 804.157L1551.73 783.559C1551.93 783.447 1552.25 783.447 1552.44 783.559L1588.12 804.157ZM1552.44 839.167C1552.25 839.28 1551.93 839.28 1551.73 839.167L1491.8 804.566C1491.61 804.453 1491.61 804.27 1491.8 804.157L1551.73 769.555C1551.93 769.443 1552.25 769.443 1552.44 769.555L1612.37 804.157C1612.57 804.27 1612.57 804.453 1612.37 804.566L1552.44 839.167Z"
        fill="url(#paint72_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1588.12 650.113C1588.31 650.225 1588.31 650.408 1588.12 650.521L1552.44 671.119C1552.25 671.232 1551.93 671.231 1551.73 671.119L1516.06 650.521C1515.86 650.408 1515.86 650.225 1516.06 650.113L1551.73 629.515C1551.93 629.402 1552.25 629.402 1552.44 629.515L1588.12 650.113ZM1552.44 685.123C1552.25 685.235 1551.93 685.235 1551.73 685.123L1491.8 650.521C1491.61 650.408 1491.61 650.225 1491.8 650.113L1551.73 615.511C1551.93 615.398 1552.25 615.398 1552.44 615.511L1612.37 650.113C1612.57 650.225 1612.57 650.408 1612.37 650.521L1552.44 685.123Z"
        fill="url(#paint73_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1667.3 716.836C1677.35 711.035 1693.64 711.035 1703.69 716.836C1713.73 722.637 1713.73 732.042 1703.69 737.842C1693.64 743.643 1677.35 743.643 1667.3 737.842C1657.26 732.041 1657.26 722.637 1667.3 716.836ZM1655.17 709.834C1671.92 700.166 1699.07 700.166 1715.81 709.834C1732.56 719.502 1732.56 735.176 1715.81 744.844C1699.07 754.512 1671.92 754.512 1655.17 744.844C1638.43 735.176 1638.43 719.502 1655.17 709.834Z"
        fill="url(#paint74_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1400.49 716.836C1410.54 711.035 1426.83 711.035 1436.87 716.836C1446.92 722.637 1446.92 732.041 1436.87 737.842C1426.83 743.643 1410.54 743.643 1400.49 737.842C1390.44 732.041 1390.44 722.637 1400.49 716.836ZM1388.36 709.834C1405.11 700.166 1432.26 700.166 1449 709.834C1465.75 719.502 1465.75 735.176 1449 744.844C1432.26 754.512 1405.11 754.512 1388.36 744.844C1371.62 735.176 1371.62 719.502 1388.36 709.834Z"
        fill="url(#paint75_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1652.86 722.499L1451.32 722.499L1451.32 732.179L1652.86 732.179L1652.86 722.499Z"
        fill="url(#paint76_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1655.17 737.842L1570.28 786.856L1582.41 793.858L1667.3 744.844L1655.17 737.842ZM1521.77 660.82L1436.87 709.834L1449 716.836L1533.9 667.822L1521.77 660.82Z"
        fill="url(#paint77_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1357.04 670.735L1503.84 655.418L1500.28 644.049L1353.48 659.366L1357.04 670.735Z"
        fill="url(#paint78_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1116.6 531.851L1265.17 515.513L1261.42 504.165L1112.86 520.503L1116.6 531.851Z"
        fill="url(#paint79_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M154.413 227.691C154.218 227.578 153.901 227.578 153.706 227.691L118.03 248.289C117.835 248.402 117.835 248.584 118.03 248.697L153.706 269.295C153.901 269.408 154.218 269.408 154.413 269.295L190.09 248.697C190.285 248.584 190.285 248.402 190.09 248.289L154.413 227.691ZM93.7743 248.289C93.5789 248.402 93.5789 248.584 93.7743 248.697L153.706 283.299C153.901 283.412 154.218 283.412 154.413 283.299L214.345 248.697C214.541 248.584 214.541 248.402 214.345 248.289L154.413 213.687C154.218 213.574 153.901 213.574 153.706 213.687L93.7743 248.289Z"
        fill="url(#paint80_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M421.226 227.691C421.03 227.578 420.714 227.578 420.518 227.691L384.842 248.289C384.647 248.401 384.647 248.584 384.842 248.697L420.518 269.295C420.714 269.408 421.03 269.408 421.226 269.295L456.902 248.697C457.097 248.584 457.097 248.401 456.902 248.289L421.226 227.691ZM360.587 248.289C360.391 248.401 360.391 248.584 360.587 248.697L420.518 283.299C420.714 283.412 421.03 283.412 421.226 283.299L481.158 248.697C481.353 248.584 481.353 248.401 481.158 248.289L421.226 213.687C421.03 213.574 420.714 213.574 420.518 213.687L360.587 248.289Z"
        fill="url(#paint81_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M305.658 181.974C315.705 176.173 315.705 166.769 305.658 160.968C295.611 155.167 279.321 155.167 269.274 160.968C259.227 166.769 259.227 176.173 269.274 181.974C279.321 187.775 295.611 187.775 305.658 181.974ZM317.786 188.976C334.531 179.308 334.531 163.634 317.786 153.966C301.041 144.298 273.891 144.298 257.146 153.966C240.401 163.634 240.401 179.308 257.146 188.976C273.891 198.644 301.041 198.644 317.786 188.976Z"
        fill="url(#paint82_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M305.658 336.018C315.705 330.217 315.705 320.813 305.658 315.012C295.611 309.211 279.321 309.211 269.274 315.012C259.227 320.813 259.227 330.217 269.274 336.018C279.321 341.819 295.611 341.819 305.658 336.018ZM317.785 343.02C334.53 333.352 334.53 317.678 317.785 308.01C301.04 298.342 273.891 298.342 257.146 308.01C240.401 317.678 240.401 333.352 257.146 343.02C273.891 352.688 301.04 352.688 317.785 343.02Z"
        fill="url(#paint83_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M295.849 190.315L295.849 306.671L279.083 306.671L279.083 190.315L295.849 190.315Z"
        fill="url(#paint84_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M269.274 188.976L184.379 237.99L172.252 230.988L257.147 181.974L269.274 188.976ZM402.681 265.998L317.786 315.012L305.658 308.01L390.553 258.996L402.681 265.998Z"
        fill="url(#paint85_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M396.97 367.731C396.775 367.619 396.458 367.619 396.263 367.731L360.587 388.329C360.391 388.442 360.391 388.625 360.587 388.737L396.263 409.335C396.458 409.448 396.775 409.448 396.97 409.335L432.646 388.737C432.842 388.625 432.842 388.442 432.646 388.329L396.97 367.731ZM336.331 388.329C336.136 388.442 336.136 388.625 336.331 388.737L396.263 423.339C396.458 423.452 396.775 423.452 396.97 423.339L456.902 388.737C457.097 388.625 457.097 388.442 456.902 388.329L396.97 353.727C396.775 353.615 396.458 353.615 396.263 353.727L336.331 388.329Z"
        fill="url(#paint86_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M663.783 367.731C663.587 367.619 663.27 367.619 663.075 367.731L627.399 388.329C627.204 388.442 627.204 388.625 627.399 388.737L663.075 409.335C663.27 409.448 663.587 409.448 663.783 409.335L699.459 388.737C699.654 388.625 699.654 388.442 699.459 388.329L663.783 367.731ZM603.143 388.329C602.948 388.442 602.948 388.625 603.143 388.737L663.075 423.339C663.27 423.452 663.587 423.452 663.783 423.339L723.714 388.737C723.91 388.625 723.91 388.442 723.714 388.329L663.783 353.727C663.587 353.615 663.27 353.615 663.075 353.727L603.143 388.329Z"
        fill="url(#paint87_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M548.214 322.014C558.261 316.213 558.262 306.809 548.214 301.008C538.167 295.207 521.878 295.207 511.831 301.008C501.784 306.809 501.784 316.213 511.831 322.014C521.878 327.815 538.167 327.815 548.214 322.014ZM560.342 329.016C577.087 319.348 577.087 303.674 560.342 294.006C543.597 284.338 516.448 284.338 499.703 294.006C482.958 303.674 482.958 319.348 499.703 329.016C516.448 338.684 543.597 338.684 560.342 329.016Z"
        fill="url(#paint88_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M548.214 476.058C558.261 470.258 558.261 460.853 548.214 455.052C538.167 449.252 521.878 449.252 511.831 455.052C501.784 460.853 501.784 470.258 511.831 476.058C521.878 481.859 538.167 481.859 548.214 476.058ZM560.342 483.06C577.087 473.393 577.087 457.718 560.342 448.05C543.597 438.382 516.448 438.382 499.703 448.05C482.958 457.718 482.958 473.393 499.703 483.06C516.448 492.728 543.597 492.728 560.342 483.06Z"
        fill="url(#paint89_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M538.406 330.355L538.406 446.712L521.64 446.712L521.64 330.355L538.406 330.355Z"
        fill="url(#paint90_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M511.831 329.016L426.936 378.03L414.808 371.028L499.703 322.014L511.831 329.016ZM645.237 406.038L560.342 455.052L548.215 448.05L633.11 399.036L645.237 406.038Z"
        fill="url(#paint91_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-88.1433 87.651C-88.3386 87.5382 -88.6554 87.5382 -88.8507 87.651L-124.527 108.249C-124.722 108.361 -124.722 108.544 -124.527 108.657L-88.8507 129.255C-88.6554 129.367 -88.3386 129.367 -88.1433 129.255L-52.4672 108.657C-52.2718 108.544 -52.2718 108.361 -52.4672 108.249L-88.1433 87.651ZM-148.782 108.249C-148.978 108.361 -148.978 108.544 -148.782 108.657L-88.8507 143.259C-88.6554 143.371 -88.3386 143.371 -88.1433 143.259L-28.2115 108.657C-28.0162 108.544 -28.0162 108.361 -28.2115 108.249L-88.1433 73.647C-88.3386 73.5342 -88.6554 73.5342 -88.8507 73.647L-148.782 108.249Z"
        fill="url(#paint92_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M178.669 87.6509C178.474 87.5381 178.157 87.5381 177.962 87.6509L142.286 108.248C142.09 108.361 142.09 108.544 142.286 108.657L177.962 129.255C178.157 129.367 178.474 129.367 178.669 129.255L214.345 108.657C214.54 108.544 214.54 108.361 214.345 108.248L178.669 87.6509ZM118.03 108.248C117.834 108.361 117.834 108.544 118.03 108.657L177.962 143.259C178.157 143.371 178.474 143.371 178.669 143.259L238.601 108.657C238.796 108.544 238.796 108.361 238.601 108.248L178.669 73.6469C178.474 73.5341 178.157 73.5341 177.962 73.6469L118.03 108.248Z"
        fill="url(#paint93_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.101 41.9337C73.148 36.1331 73.1481 26.7283 63.101 20.9277C53.054 15.127 36.7645 15.127 26.7175 20.9277C16.6705 26.7283 16.6705 36.1331 26.7175 41.9337C36.7645 47.7344 53.054 47.7344 63.101 41.9337ZM75.2289 48.9357C91.9739 39.268 91.9739 23.5934 75.2289 13.9257C58.4838 4.2579 31.3347 4.25791 14.5897 13.9257C-2.1554 23.5934 -2.1554 39.268 14.5897 48.9357C31.3347 58.6035 58.4838 58.6035 75.2289 48.9357Z"
        fill="url(#paint94_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M63.1008 195.978C73.1479 190.177 73.1479 180.772 63.1008 174.972C53.0538 169.171 36.7643 169.171 26.7173 174.972C16.6703 180.772 16.6703 190.177 26.7173 195.978C36.7643 201.779 53.0538 201.779 63.1008 195.978ZM75.2287 202.98C91.9737 193.312 91.9737 177.638 75.2287 167.97C58.4836 158.302 31.3345 158.302 14.5895 167.97C-2.15558 177.638 -2.15558 193.312 14.5895 202.98C31.3345 212.648 58.4836 212.648 75.2287 202.98Z"
        fill="url(#paint95_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.292 50.2745L53.292 166.631L36.5265 166.631L36.5265 50.2745L53.292 50.2745Z"
        fill="url(#paint96_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M26.7176 48.9358L-58.1773 97.9499L-70.3051 90.9479L14.5898 41.9338L26.7176 48.9358ZM160.124 125.958L75.229 174.972L63.1011 167.97L147.996 118.956L160.124 125.958Z"
        fill="url(#paint97_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.951 221.062L169.481 136.309L189.171 138.363L162.642 223.117L142.951 221.062Z"
        fill="url(#paint98_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M383.504 359.882L411.802 274.108L431.459 276.269L403.16 362.044L383.504 359.882Z"
        fill="url(#paint99_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1055.21 748.35C1055.4 748.462 1055.4 748.645 1055.21 748.758L1019.53 769.356C1019.34 769.468 1019.02 769.468 1018.83 769.356L983.15 748.758C982.954 748.645 982.954 748.462 983.15 748.349L1018.83 727.752C1019.02 727.639 1019.34 727.639 1019.53 727.752L1055.21 748.35ZM1019.53 783.36C1019.34 783.472 1019.02 783.472 1018.83 783.36L958.894 748.758C958.699 748.645 958.699 748.462 958.894 748.35L1018.83 713.748C1019.02 713.635 1019.34 713.635 1019.53 713.748L1079.46 748.349C1079.66 748.462 1079.66 748.645 1079.47 748.758L1019.53 783.36Z"
        fill="url(#paint100_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1055.21 594.305C1055.4 594.418 1055.4 594.601 1055.21 594.714L1019.53 615.311C1019.34 615.424 1019.02 615.424 1018.83 615.311L983.149 594.714C982.954 594.601 982.954 594.418 983.149 594.305L1018.83 573.708C1019.02 573.595 1019.34 573.595 1019.53 573.708L1055.21 594.305ZM1019.53 629.315C1019.34 629.428 1019.02 629.428 1018.83 629.315L958.894 594.714C958.698 594.601 958.698 594.418 958.894 594.305L1018.83 559.704C1019.02 559.591 1019.34 559.591 1019.53 559.704L1079.46 594.305C1079.66 594.418 1079.66 594.601 1079.46 594.714L1019.53 629.315Z"
        fill="url(#paint101_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1134.39 661.029C1144.44 655.228 1160.73 655.228 1170.78 661.029C1180.82 666.829 1180.82 676.234 1170.78 682.035C1160.73 687.835 1144.44 687.835 1134.39 682.035C1124.35 676.234 1124.35 666.829 1134.39 661.029ZM1122.27 654.027C1139.01 644.359 1166.16 644.359 1182.91 654.027C1199.65 663.694 1199.65 679.369 1182.91 689.037C1166.16 698.704 1139.01 698.704 1122.27 689.037C1105.52 679.369 1105.52 663.694 1122.27 654.027Z"
        fill="url(#paint102_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M867.581 661.028C877.628 655.228 893.918 655.228 903.965 661.028C914.012 666.829 914.012 676.234 903.965 682.035C893.918 687.835 877.628 687.835 867.581 682.034C857.534 676.234 857.534 666.829 867.581 661.028ZM855.453 654.026C872.198 644.359 899.347 644.359 916.093 654.026C932.838 663.694 932.838 679.369 916.093 689.037C899.348 698.704 872.198 698.704 855.453 689.036C838.708 679.369 838.708 663.694 855.453 654.026Z"
        fill="url(#paint103_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1119.95 666.692L918.412 666.692L918.412 676.371L1119.95 676.371L1119.95 666.692Z"
        fill="url(#paint104_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1122.27 682.035L1037.37 731.049L1049.5 738.051L1134.39 689.037L1122.27 682.035ZM988.86 605.012L903.965 654.027L916.093 661.029L1000.99 612.014L988.86 605.012Z"
        fill="url(#paint105_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M812.652 608.309C812.847 608.422 812.847 608.605 812.652 608.718L776.976 629.315C776.781 629.428 776.464 629.428 776.269 629.315L740.593 608.718C740.397 608.605 740.397 608.422 740.593 608.309L776.269 587.711C776.464 587.599 776.781 587.599 776.976 587.711L812.652 608.309ZM776.976 643.319C776.781 643.432 776.464 643.432 776.269 643.319L716.337 608.718C716.141 608.605 716.141 608.422 716.337 608.309L776.269 573.707C776.464 573.595 776.781 573.595 776.976 573.707L836.908 608.309C837.103 608.422 837.103 608.605 836.908 608.718L776.976 643.319Z"
        fill="url(#paint106_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M812.652 454.265C812.847 454.377 812.847 454.56 812.652 454.673L776.976 475.271C776.78 475.384 776.464 475.384 776.268 475.271L740.592 454.673C740.397 454.56 740.397 454.377 740.592 454.265L776.268 433.667C776.464 433.554 776.78 433.554 776.976 433.667L812.652 454.265ZM776.976 489.275C776.78 489.388 776.464 489.388 776.268 489.275L716.337 454.673C716.141 454.56 716.141 454.377 716.337 454.265L776.268 419.663C776.464 419.55 776.78 419.55 776.976 419.663L836.908 454.265C837.103 454.377 837.103 454.56 836.908 454.673L776.976 489.275Z"
        fill="url(#paint107_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M891.837 520.988C901.884 515.188 918.173 515.187 928.22 520.988C938.267 526.789 938.267 536.194 928.22 541.994C918.173 547.795 901.884 547.795 891.837 541.994C881.79 536.194 881.79 526.789 891.837 520.988ZM879.709 513.986C896.454 504.318 923.603 504.318 940.348 513.986C957.093 523.654 957.093 539.328 940.348 548.996C923.603 558.664 896.454 558.664 879.709 548.996C862.964 539.328 862.964 523.654 879.709 513.986Z"
        fill="url(#paint108_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M625.024 520.988C635.071 515.187 651.361 515.187 661.408 520.988C671.455 526.789 671.455 536.193 661.408 541.994C651.361 547.795 635.071 547.795 625.024 541.994C614.977 536.193 614.977 526.789 625.024 520.988ZM612.896 513.986C629.641 504.318 656.79 504.318 673.535 513.986C690.28 523.654 690.28 539.328 673.535 548.996C656.79 558.664 629.641 558.664 612.896 548.996C596.151 539.328 596.151 523.654 612.896 513.986Z"
        fill="url(#paint109_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M877.39 526.651L675.855 526.651L675.855 536.331L877.39 536.331L877.39 526.651Z"
        fill="url(#paint110_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M879.709 541.994L794.814 591.008L806.942 598.01L891.837 548.996L879.709 541.994ZM746.303 464.972L661.408 513.986L673.536 520.988L758.43 471.974L746.303 464.972Z"
        fill="url(#paint111_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1297.77 888.389C1297.96 888.502 1297.96 888.685 1297.77 888.798L1262.09 909.396C1261.89 909.508 1261.58 909.508 1261.38 909.396L1225.71 888.798C1225.51 888.685 1225.51 888.502 1225.71 888.389L1261.38 867.792C1261.58 867.679 1261.89 867.679 1262.09 867.792L1297.77 888.389ZM1262.09 923.4C1261.89 923.512 1261.58 923.512 1261.38 923.4L1201.45 888.798C1201.26 888.685 1201.26 888.502 1201.45 888.39L1261.38 853.788C1261.58 853.675 1261.89 853.675 1262.09 853.788L1322.02 888.389C1322.22 888.502 1322.22 888.685 1322.02 888.798L1262.09 923.4Z"
        fill="url(#paint112_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1297.77 734.345C1297.96 734.458 1297.96 734.641 1297.77 734.754L1262.09 755.351C1261.89 755.464 1261.58 755.464 1261.38 755.351L1225.71 734.754C1225.51 734.641 1225.51 734.458 1225.71 734.345L1261.38 713.747C1261.58 713.635 1261.89 713.635 1262.09 713.747L1297.77 734.345ZM1262.09 769.355C1261.89 769.468 1261.58 769.468 1261.38 769.355L1201.45 734.754C1201.25 734.641 1201.25 734.458 1201.45 734.345L1261.38 699.744C1261.58 699.631 1261.89 699.631 1262.09 699.744L1322.02 734.345C1322.22 734.458 1322.22 734.641 1322.02 734.754L1262.09 769.355Z"
        fill="url(#paint113_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1376.95 801.069C1387 795.268 1403.29 795.268 1413.33 801.069C1423.38 806.869 1423.38 816.274 1413.33 822.075C1403.29 827.875 1387 827.875 1376.95 822.075C1366.9 816.274 1366.9 806.869 1376.95 801.069ZM1364.82 794.067C1381.57 784.399 1408.72 784.399 1425.46 794.066C1442.21 803.734 1442.21 819.409 1425.46 829.077C1408.72 838.744 1381.57 838.744 1364.82 829.077C1348.08 819.409 1348.08 803.734 1364.82 794.067Z"
        fill="url(#paint114_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1110.14 801.068C1120.18 795.268 1136.47 795.268 1146.52 801.068C1156.57 806.869 1156.57 816.274 1146.52 822.074C1136.47 827.875 1120.18 827.875 1110.14 822.074C1100.09 816.274 1100.09 806.869 1110.14 801.068ZM1098.01 794.066C1114.75 784.399 1141.9 784.399 1158.65 794.066C1175.39 803.734 1175.39 819.409 1158.65 829.076C1141.9 838.744 1114.75 838.744 1098.01 829.076C1081.26 819.409 1081.26 803.734 1098.01 794.066Z"
        fill="url(#paint115_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1362.5 806.732L1160.97 806.732L1160.97 816.411L1362.5 816.411L1362.5 806.732Z"
        fill="url(#paint116_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1364.82 822.075L1279.93 871.089L1292.06 878.091L1376.95 829.077L1364.82 822.075ZM1231.42 745.052L1146.52 794.067L1158.65 801.068L1243.54 752.054L1231.42 745.052Z"
        fill="url(#paint117_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1066.69 754.967L1213.49 739.65L1209.93 728.282L1063.13 743.598L1066.69 754.967Z"
        fill="url(#paint118_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M826.247 616.084L974.813 599.746L971.069 588.397L822.503 604.735L826.247 616.084Z"
        fill="url(#paint119_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-36.1529 383.386C-36.3483 383.273 -36.665 383.273 -36.8604 383.386L-72.5364 403.983C-72.7318 404.096 -72.7318 404.279 -72.5364 404.392L-36.8604 424.989C-36.665 425.102 -36.3483 425.102 -36.1529 424.989L-0.476863 404.392C-0.281503 404.279 -0.281497 404.096 -0.47686 403.983L-36.1529 383.386ZM-96.7921 403.983C-96.9875 404.096 -96.9875 404.279 -96.7921 404.392L-36.8604 438.993C-36.665 439.106 -36.3483 439.106 -36.1529 438.993L23.7788 404.392C23.9742 404.279 23.9742 404.096 23.7788 403.983L-36.1529 369.382C-36.3483 369.269 -36.665 369.269 -36.8604 369.382L-96.7921 403.983Z"
        fill="url(#paint120_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M230.659 383.385C230.464 383.273 230.147 383.273 229.952 383.385L194.276 403.983C194.081 404.096 194.08 404.279 194.276 404.391L229.952 424.989C230.147 425.102 230.464 425.102 230.659 424.989L266.335 404.391C266.531 404.279 266.531 404.096 266.335 403.983L230.659 383.385ZM170.02 403.983C169.825 404.096 169.825 404.279 170.02 404.391L229.952 438.993C230.147 439.106 230.464 439.106 230.659 438.993L290.591 404.391C290.786 404.279 290.786 404.096 290.591 403.983L230.659 369.381C230.464 369.269 230.147 369.269 229.952 369.381L170.02 403.983Z"
        fill="url(#paint121_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.091 337.668C125.138 331.868 125.138 322.463 115.091 316.662C105.044 310.862 88.7549 310.862 78.7078 316.662C68.6608 322.463 68.6608 331.868 78.7078 337.668C88.7548 343.469 105.044 343.469 115.091 337.668ZM127.219 344.67C143.964 335.003 143.964 319.328 127.219 309.66C110.474 299.992 83.325 299.992 66.58 309.66C49.8349 319.328 49.8349 335.003 66.58 344.67C83.325 354.338 110.474 354.338 127.219 344.67Z"
        fill="url(#paint122_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.091 491.712C125.138 485.912 125.138 476.507 115.091 470.706C105.044 464.906 88.7547 464.906 78.7076 470.706C68.6606 476.507 68.6606 485.912 78.7077 491.712C88.7547 497.513 105.044 497.513 115.091 491.712ZM127.219 498.714C143.964 489.047 143.964 473.372 127.219 463.704C110.474 454.037 83.3249 454.037 66.5798 463.704C49.8348 473.372 49.8347 489.047 66.5798 498.714C83.3249 508.382 110.474 508.382 127.219 498.714Z"
        fill="url(#paint123_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M105.282 346.009L105.282 462.366L88.5168 462.366L88.5168 346.009L105.282 346.009Z"
        fill="url(#paint124_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M78.7079 344.67L-6.18693 393.684L-18.3148 386.682L66.5801 337.668L78.7079 344.67ZM212.114 421.692L127.219 470.707L115.091 463.705L199.986 414.69L212.114 421.692Z"
        fill="url(#paint125_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M206.404 523.426C206.208 523.313 205.892 523.313 205.696 523.426L170.02 544.023C169.825 544.136 169.825 544.319 170.02 544.432L205.696 565.029C205.892 565.142 206.208 565.142 206.404 565.029L242.08 544.432C242.275 544.319 242.275 544.136 242.08 544.023L206.404 523.426ZM145.765 544.023C145.569 544.136 145.569 544.319 145.765 544.432L205.696 579.033C205.892 579.146 206.208 579.146 206.404 579.033L266.336 544.432C266.531 544.319 266.531 544.136 266.336 544.023L206.404 509.422C206.208 509.309 205.892 509.309 205.696 509.422L145.765 544.023Z"
        fill="url(#paint126_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M473.216 523.426C473.021 523.313 472.704 523.313 472.509 523.426L436.833 544.023C436.637 544.136 436.637 544.319 436.833 544.432L472.509 565.029C472.704 565.142 473.021 565.142 473.216 565.029L508.892 544.432C509.088 544.319 509.088 544.136 508.892 544.023L473.216 523.426ZM412.577 544.023C412.382 544.136 412.382 544.319 412.577 544.432L472.509 579.033C472.704 579.146 473.021 579.146 473.216 579.033L533.148 544.432C533.343 544.319 533.343 544.136 533.148 544.023L473.216 509.422C473.021 509.309 472.704 509.309 472.509 509.422L412.577 544.023Z"
        fill="url(#paint127_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M357.648 477.708C367.695 471.908 367.695 462.503 357.648 456.702C347.601 450.902 331.312 450.902 321.265 456.702C311.218 462.503 311.218 471.908 321.265 477.708C331.312 483.509 347.601 483.509 357.648 477.708ZM369.776 484.71C386.521 475.043 386.521 459.368 369.776 449.7C353.031 440.033 325.882 440.033 309.137 449.7C292.392 459.368 292.392 475.043 309.137 484.71C325.882 494.378 353.031 494.378 369.776 484.71Z"
        fill="url(#paint128_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M357.648 631.753C367.695 625.952 367.695 616.547 357.648 610.747C347.601 604.946 331.311 604.946 321.264 610.747C311.217 616.547 311.217 625.952 321.264 631.753C331.311 637.553 347.601 637.553 357.648 631.753ZM369.776 638.755C386.521 629.087 386.521 613.412 369.776 603.745C353.031 594.077 325.882 594.077 309.137 603.745C292.392 613.412 292.391 629.087 309.137 638.755C325.882 648.422 353.031 648.422 369.776 638.755Z"
        fill="url(#paint129_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M347.839 486.049L347.839 602.406L331.074 602.406L331.074 486.049L347.839 486.049Z"
        fill="url(#paint130_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M321.265 484.711L236.37 533.725L224.242 526.723L309.137 477.709L321.265 484.711ZM454.671 561.733L369.776 610.747L357.648 603.745L442.543 554.731L454.671 561.733Z"
        fill="url(#paint131_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-278.71 243.345C-278.905 243.233 -279.222 243.233 -279.417 243.345L-315.093 263.943C-315.289 264.056 -315.289 264.239 -315.093 264.351L-279.417 284.949C-279.222 285.062 -278.905 285.062 -278.71 284.949L-243.034 264.351C-242.838 264.239 -242.838 264.056 -243.034 263.943L-278.71 243.345ZM-339.349 263.943C-339.544 264.056 -339.544 264.239 -339.349 264.351L-279.417 298.953C-279.222 299.066 -278.905 299.066 -278.71 298.953L-218.778 264.351C-218.583 264.239 -218.583 264.056 -218.778 263.943L-278.71 229.341C-278.905 229.229 -279.222 229.229 -279.417 229.341L-339.349 263.943Z"
        fill="url(#paint132_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-11.8974 243.345C-12.0927 243.232 -12.4095 243.232 -12.6048 243.345L-48.2809 263.943C-48.4762 264.056 -48.4763 264.238 -48.2809 264.351L-12.6048 284.949C-12.4095 285.062 -12.0927 285.062 -11.8974 284.949L23.7787 264.351C23.9741 264.238 23.9741 264.056 23.7787 263.943L-11.8974 243.345ZM-72.5366 263.943C-72.7319 264.056 -72.7319 264.238 -72.5366 264.351L-12.6048 298.953C-12.4095 299.066 -12.0927 299.066 -11.8974 298.953L48.0344 264.351C48.2297 264.238 48.2297 264.056 48.0344 263.943L-11.8974 229.341C-12.0927 229.228 -12.4095 229.228 -12.6048 229.341L-72.5366 263.943Z"
        fill="url(#paint133_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-127.465 197.628C-117.418 191.827 -117.418 182.423 -127.465 176.622C-137.512 170.821 -153.802 170.821 -163.849 176.622C-173.896 182.423 -173.896 191.827 -163.849 197.628C-153.802 203.429 -137.512 203.429 -127.465 197.628ZM-115.338 204.63C-98.5925 194.962 -98.5925 179.288 -115.338 169.62C-132.083 159.952 -159.232 159.952 -175.977 169.62C-192.722 179.288 -192.722 194.962 -175.977 204.63C-159.232 214.298 -132.083 214.298 -115.338 204.63Z"
        fill="url(#paint134_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-127.466 351.672C-117.419 345.872 -117.419 336.467 -127.466 330.666C-137.513 324.866 -153.802 324.866 -163.849 330.666C-173.896 336.467 -173.896 345.872 -163.849 351.672C-153.802 357.473 -137.513 357.473 -127.466 351.672ZM-115.338 358.674C-98.5927 349.006 -98.5927 333.332 -115.338 323.664C-132.083 313.996 -159.232 313.996 -175.977 323.664C-192.722 333.332 -192.722 349.006 -175.977 358.674C-159.232 368.342 -132.083 368.342 -115.338 358.674Z"
        fill="url(#paint135_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-137.274 205.969L-137.274 322.325L-154.04 322.325L-154.04 205.969L-137.274 205.969Z"
        fill="url(#paint136_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-163.849 204.63L-248.744 253.644L-260.872 246.642L-175.977 197.628L-163.849 204.63ZM-30.4426 281.652L-115.337 330.666L-127.465 323.664L-42.5704 274.65L-30.4426 281.652Z"
        fill="url(#paint137_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-47.6155 376.757L-21.0859 292.003L-1.39491 294.058L-27.9246 378.811L-47.6155 376.757Z"
        fill="url(#paint138_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M192.937 515.577L221.236 429.802L240.892 431.964L212.594 517.738L192.937 515.577Z"
        fill="url(#paint139_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M864.643 904.044C864.838 904.157 864.838 904.34 864.643 904.452L828.967 925.05C828.771 925.163 828.455 925.163 828.259 925.05L792.583 904.452C792.388 904.34 792.388 904.157 792.583 904.044L828.259 883.446C828.455 883.333 828.771 883.334 828.967 883.446L864.643 904.044ZM828.967 939.054C828.772 939.167 828.455 939.167 828.259 939.054L768.328 904.452C768.132 904.34 768.132 904.157 768.328 904.044L828.259 869.442C828.455 869.329 828.771 869.329 828.967 869.442L888.899 904.044C889.094 904.157 889.094 904.34 888.899 904.452L828.967 939.054Z"
        fill="url(#paint140_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M864.643 749.999C864.838 750.112 864.838 750.295 864.643 750.408L828.967 771.006C828.771 771.118 828.455 771.118 828.259 771.006L792.583 750.408C792.388 750.295 792.388 750.112 792.583 749.999L828.259 729.402C828.454 729.289 828.771 729.289 828.967 729.402L864.643 749.999ZM828.967 785.01C828.771 785.122 828.454 785.122 828.259 785.009L768.327 750.408C768.132 750.295 768.132 750.112 768.327 749.999L828.259 715.398C828.454 715.285 828.771 715.285 828.967 715.398L888.898 749.999C889.094 750.112 889.094 750.295 888.898 750.408L828.967 785.01Z"
        fill="url(#paint141_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M943.827 816.723C953.875 810.922 970.164 810.922 980.211 816.723C990.258 822.524 990.258 831.928 980.211 837.729C970.164 843.53 953.875 843.53 943.828 837.729C933.781 831.928 933.78 822.524 943.827 816.723ZM931.7 809.721C948.445 800.053 975.594 800.053 992.339 809.721C1009.08 819.389 1009.08 835.063 992.339 844.731C975.594 854.399 948.445 854.399 931.7 844.731C914.955 835.063 914.955 819.389 931.7 809.721Z"
        fill="url(#paint142_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M677.015 816.723C687.062 810.922 703.351 810.922 713.398 816.723C723.445 822.523 723.445 831.928 713.398 837.729C703.351 843.53 687.062 843.529 677.015 837.729C666.968 831.928 666.968 822.523 677.015 816.723ZM664.887 809.721C681.632 800.053 708.781 800.053 725.526 809.721C742.271 819.388 742.271 835.063 725.526 844.731C708.781 854.399 681.632 854.399 664.887 844.731C648.142 835.063 648.142 819.389 664.887 809.721Z"
        fill="url(#paint143_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M929.381 822.386L727.845 822.386L727.845 832.066L929.381 832.066L929.381 822.386Z"
        fill="url(#paint144_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M931.7 837.729L846.805 886.743L858.933 893.745L943.827 844.731L931.7 837.729ZM798.293 760.707L713.398 809.721L725.526 816.723L810.421 767.709L798.293 760.707Z"
        fill="url(#paint145_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M622.086 764.003C622.281 764.116 622.281 764.299 622.086 764.412L586.41 785.009C586.214 785.122 585.898 785.122 585.702 785.009L550.026 764.412C549.831 764.299 549.831 764.116 550.026 764.003L585.702 743.406C585.898 743.293 586.214 743.293 586.41 743.406L622.086 764.003ZM586.41 799.013C586.214 799.126 585.898 799.126 585.702 799.013L525.77 764.412C525.575 764.299 525.575 764.116 525.77 764.003L585.702 729.402C585.898 729.289 586.214 729.289 586.41 729.402L646.341 764.003C646.537 764.116 646.537 764.299 646.341 764.412L586.41 799.013Z"
        fill="url(#paint146_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M622.085 609.959C622.281 610.072 622.281 610.255 622.085 610.367L586.409 630.965C586.214 631.078 585.897 631.078 585.702 630.965L550.026 610.367C549.831 610.255 549.831 610.072 550.026 609.959L585.702 589.361C585.897 589.249 586.214 589.249 586.409 589.361L622.085 609.959ZM586.409 644.969C586.214 645.082 585.897 645.082 585.702 644.969L525.77 610.367C525.575 610.255 525.575 610.072 525.77 609.959L585.702 575.357C585.897 575.245 586.214 575.245 586.409 575.357L646.341 609.959C646.536 610.072 646.537 610.255 646.341 610.367L586.409 644.969Z"
        fill="url(#paint147_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M701.27 676.682C711.317 670.882 727.607 670.882 737.654 676.682C747.701 682.483 747.701 691.888 737.654 697.689C727.607 703.489 711.317 703.489 701.27 697.689C691.223 691.888 691.223 682.483 701.27 676.682ZM689.143 669.68C705.888 660.013 733.037 660.013 749.782 669.68C766.527 679.348 766.527 695.023 749.782 704.691C733.037 714.358 705.888 714.358 689.143 704.691C672.398 695.023 672.397 679.348 689.143 669.68Z"
        fill="url(#paint148_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M434.458 676.682C444.505 670.882 460.794 670.882 470.841 676.682C480.888 682.483 480.888 691.888 470.841 697.688C460.794 703.489 444.505 703.489 434.458 697.688C424.411 691.888 424.411 682.483 434.458 676.682ZM422.33 669.68C439.075 660.013 466.224 660.013 482.969 669.68C499.714 679.348 499.714 695.023 482.969 704.69C466.224 714.358 439.075 714.358 422.33 704.69C405.585 695.023 405.585 679.348 422.33 669.68Z"
        fill="url(#paint149_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M686.824 682.346L485.288 682.346L485.288 692.025L686.824 692.025L686.824 682.346Z"
        fill="url(#paint150_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M689.142 697.688L604.248 746.703L616.375 753.705L701.27 704.69L689.142 697.688ZM555.736 620.666L470.841 669.68L482.969 676.682L567.864 627.668L555.736 620.666Z"
        fill="url(#paint151_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1107.2 1044.08C1107.39 1044.2 1107.39 1044.38 1107.2 1044.49L1071.52 1065.09C1071.33 1065.2 1071.01 1065.2 1070.82 1065.09L1035.14 1044.49C1034.94 1044.38 1034.94 1044.2 1035.14 1044.08L1070.82 1023.49C1071.01 1023.37 1071.33 1023.37 1071.52 1023.49L1107.2 1044.08ZM1071.52 1079.09C1071.33 1079.21 1071.01 1079.21 1070.82 1079.09L1010.88 1044.49C1010.69 1044.38 1010.69 1044.2 1010.88 1044.08L1070.82 1009.48C1071.01 1009.37 1071.33 1009.37 1071.52 1009.48L1131.45 1044.08C1131.65 1044.2 1131.65 1044.38 1131.45 1044.49L1071.52 1079.09Z"
        fill="url(#paint152_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1107.2 890.039C1107.39 890.152 1107.39 890.335 1107.2 890.448L1071.52 911.045C1071.33 911.158 1071.01 911.158 1070.82 911.045L1035.14 890.448C1034.94 890.335 1034.94 890.152 1035.14 890.039L1070.82 869.442C1071.01 869.329 1071.33 869.329 1071.52 869.442L1107.2 890.039ZM1071.52 925.049C1071.33 925.162 1071.01 925.162 1070.82 925.049L1010.88 890.448C1010.69 890.335 1010.69 890.152 1010.88 890.039L1070.82 855.438C1071.01 855.325 1071.33 855.325 1071.52 855.438L1131.45 890.039C1131.65 890.152 1131.65 890.335 1131.45 890.448L1071.52 925.049Z"
        fill="url(#paint153_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1186.38 956.763C1196.43 950.962 1212.72 950.962 1222.77 956.763C1232.81 962.564 1232.81 971.968 1222.77 977.769C1212.72 983.57 1196.43 983.57 1186.38 977.769C1176.34 971.968 1176.34 962.564 1186.38 956.763ZM1174.26 949.761C1191 940.093 1218.15 940.093 1234.9 949.761C1251.64 959.429 1251.64 975.103 1234.9 984.771C1218.15 994.439 1191 994.439 1174.26 984.771C1157.51 975.103 1157.51 959.429 1174.26 949.761Z"
        fill="url(#paint154_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M919.571 956.763C929.618 950.962 945.908 950.962 955.955 956.763C966.002 962.563 966.002 971.968 955.955 977.769C945.908 983.569 929.618 983.569 919.571 977.769C909.524 971.968 909.524 962.563 919.571 956.763ZM907.443 949.761C924.188 940.093 951.337 940.093 968.082 949.761C984.828 959.428 984.828 975.103 968.082 984.771C951.337 994.439 924.188 994.439 907.443 984.771C890.698 975.103 890.698 959.429 907.443 949.761Z"
        fill="url(#paint155_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1171.94 962.426L970.402 962.426L970.402 972.106L1171.94 972.106L1171.94 962.426Z"
        fill="url(#paint156_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1174.26 977.769L1089.36 1026.78L1101.49 1033.78L1186.38 984.771L1174.26 977.769ZM1040.85 900.747L955.955 949.761L968.083 956.763L1052.98 907.749L1040.85 900.747Z"
        fill="url(#paint157_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M876.123 910.661L1022.92 895.344L1019.36 883.976L872.565 899.293L876.123 910.661Z"
        fill="url(#paint158_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M635.681 771.778L784.247 755.44L780.503 744.092L631.937 760.43L635.681 771.778Z"
        fill="url(#paint159_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-284.955 487.981C-285.15 487.868 -285.467 487.868 -285.662 487.981L-321.338 508.578C-321.534 508.691 -321.534 508.874 -321.338 508.987L-285.662 529.584C-285.467 529.697 -285.15 529.697 -284.955 529.584L-249.279 508.987C-249.083 508.874 -249.083 508.691 -249.279 508.578L-284.955 487.981ZM-345.594 508.578C-345.789 508.691 -345.789 508.874 -345.594 508.987L-285.662 543.588C-285.467 543.701 -285.15 543.701 -284.955 543.588L-225.023 508.987C-224.828 508.874 -224.828 508.691 -225.023 508.578L-284.955 473.977C-285.15 473.864 -285.467 473.864 -285.662 473.977L-345.594 508.578Z"
        fill="url(#paint160_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-18.1424 487.981C-18.3377 487.868 -18.6545 487.868 -18.8498 487.981L-54.5259 508.578C-54.7212 508.691 -54.7213 508.874 -54.5259 508.987L-18.8498 529.584C-18.6545 529.697 -18.3377 529.697 -18.1424 529.584L17.5337 508.987C17.7291 508.874 17.7291 508.691 17.5337 508.578L-18.1424 487.981ZM-78.7816 508.578C-78.977 508.691 -78.977 508.874 -78.7816 508.987L-18.8498 543.588C-18.6545 543.701 -18.3378 543.701 -18.1424 543.588L41.7893 508.987C41.9847 508.874 41.9847 508.691 41.7894 508.578L-18.1424 473.977C-18.3378 473.864 -18.6545 473.864 -18.8499 473.977L-78.7816 508.578Z"
        fill="url(#paint161_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-133.71 442.263C-123.663 436.463 -123.663 427.058 -133.71 421.257C-143.757 415.457 -160.047 415.457 -170.094 421.257C-180.141 427.058 -180.141 436.463 -170.094 442.263C-160.047 448.064 -143.757 448.064 -133.71 442.263ZM-121.583 449.265C-104.838 439.598 -104.838 423.923 -121.583 414.255C-138.328 404.588 -165.477 404.588 -182.222 414.255C-198.967 423.923 -198.967 439.598 -182.222 449.265C-165.477 458.933 -138.328 458.933 -121.583 449.265Z"
        fill="url(#paint162_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-133.711 596.308C-123.664 590.507 -123.664 581.102 -133.711 575.302C-143.758 569.501 -160.047 569.501 -170.094 575.302C-180.141 581.102 -180.141 590.507 -170.094 596.308C-160.047 602.108 -143.758 602.108 -133.711 596.308ZM-121.583 603.31C-104.838 593.642 -104.838 577.967 -121.583 568.3C-138.328 558.632 -165.477 558.632 -182.222 568.3C-198.967 577.967 -198.967 593.642 -182.222 603.31C-165.477 612.977 -138.328 612.977 -121.583 603.31Z"
        fill="url(#paint163_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-143.519 450.604L-143.519 566.961L-160.285 566.961L-160.285 450.604L-143.519 450.604Z"
        fill="url(#paint164_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-170.094 449.266L-254.989 498.28L-267.117 491.278L-182.222 442.264L-170.094 449.266ZM-36.6876 526.288L-121.582 575.302L-133.71 568.3L-48.8154 519.286L-36.6876 526.288Z"
        fill="url(#paint165_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-42.398 628.021C-42.5933 627.908 -42.91 627.908 -43.1054 628.021L-78.7814 648.619C-78.9768 648.731 -78.9769 648.914 -78.7815 649.027L-43.1054 669.625C-42.91 669.737 -42.5933 669.737 -42.398 669.625L-6.7219 649.027C-6.52654 648.914 -6.52648 648.731 -6.72184 648.619L-42.398 628.021ZM-103.037 648.619C-103.232 648.731 -103.232 648.914 -103.037 649.027L-43.1054 683.629C-42.91 683.741 -42.5933 683.741 -42.398 683.629L17.5338 649.027C17.7291 648.914 17.7292 648.731 17.5338 648.619L-42.3979 614.017C-42.5933 613.904 -42.91 613.904 -43.1054 614.017L-103.037 648.619Z"
        fill="url(#paint166_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M224.414 628.021C224.219 627.908 223.902 627.908 223.707 628.021L188.031 648.618C187.836 648.731 187.835 648.914 188.031 649.027L223.707 669.624C223.902 669.737 224.219 669.737 224.414 669.624L260.09 649.027C260.286 648.914 260.286 648.731 260.09 648.618L224.414 628.021ZM163.775 648.618C163.58 648.731 163.58 648.914 163.775 649.027L223.707 683.629C223.902 683.741 224.219 683.741 224.414 683.629L284.346 649.027C284.541 648.914 284.541 648.731 284.346 648.618L224.414 614.017C224.219 613.904 223.902 613.904 223.707 614.017L163.775 648.618Z"
        fill="url(#paint167_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.846 582.304C118.893 576.503 118.893 567.098 108.846 561.298C98.7993 555.497 82.5099 555.497 72.4628 561.298C62.4158 567.098 62.4158 576.503 72.4628 582.304C82.5098 588.104 98.7993 588.104 108.846 582.304ZM120.974 589.306C137.719 579.638 137.719 563.963 120.974 554.296C104.229 544.628 77.08 544.628 60.335 554.296C43.5899 563.963 43.5899 579.638 60.3349 589.306C77.08 598.973 104.229 598.973 120.974 589.306Z"
        fill="url(#paint168_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.846 736.348C118.893 730.547 118.893 721.142 108.846 715.342C98.7991 709.541 82.5097 709.541 72.4627 715.342C62.4156 721.142 62.4156 730.547 72.4626 736.348C82.5097 742.148 98.7991 742.148 108.846 736.348ZM120.974 743.35C137.719 733.682 137.719 718.008 120.974 708.34C104.229 698.672 77.0799 698.672 60.3348 708.34C43.5898 718.008 43.5897 733.682 60.3348 743.35C77.0798 753.018 104.229 753.018 120.974 743.35Z"
        fill="url(#paint169_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M99.0374 590.644L99.0374 707.001L82.2718 707.001L82.2718 590.644L99.0374 590.644Z"
        fill="url(#paint170_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M72.4629 589.306L-12.4319 638.32L-24.5598 631.318L60.3351 582.304L72.4629 589.306ZM205.869 666.328L120.974 715.342L108.847 708.34L193.741 659.326L205.869 666.328Z"
        fill="url(#paint171_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-527.511 347.941C-527.707 347.828 -528.024 347.828 -528.219 347.941L-563.895 368.538C-564.09 368.651 -564.09 368.834 -563.895 368.947L-528.219 389.544C-528.024 389.657 -527.707 389.657 -527.511 389.544L-491.835 368.947C-491.64 368.834 -491.64 368.651 -491.835 368.538L-527.511 347.941ZM-588.151 368.538C-588.346 368.651 -588.346 368.834 -588.151 368.947L-528.219 403.548C-528.024 403.661 -527.707 403.661 -527.511 403.548L-467.58 368.947C-467.384 368.834 -467.384 368.651 -467.58 368.538L-527.511 333.937C-527.707 333.824 -528.024 333.824 -528.219 333.937L-588.151 368.538Z"
        fill="url(#paint172_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-260.699 347.94C-260.894 347.828 -261.211 347.828 -261.407 347.94L-297.083 368.538C-297.278 368.651 -297.278 368.834 -297.083 368.947L-261.407 389.544C-261.211 389.657 -260.894 389.657 -260.699 389.544L-225.023 368.946C-224.828 368.834 -224.828 368.651 -225.023 368.538L-260.699 347.94ZM-321.338 368.538C-321.534 368.651 -321.534 368.834 -321.338 368.946L-261.407 403.548C-261.211 403.661 -260.894 403.661 -260.699 403.548L-200.767 368.946C-200.572 368.834 -200.572 368.651 -200.767 368.538L-260.699 333.936C-260.894 333.824 -261.211 333.824 -261.407 333.936L-321.338 368.538Z"
        fill="url(#paint173_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-376.267 302.223C-366.22 296.423 -366.22 287.018 -376.267 281.217C-386.314 275.417 -402.604 275.417 -412.651 281.217C-422.698 287.018 -422.698 296.423 -412.651 302.223C-402.604 308.024 -386.314 308.024 -376.267 302.223ZM-364.139 309.225C-347.394 299.558 -347.394 283.883 -364.139 274.215C-380.884 264.547 -408.033 264.547 -424.779 274.215C-441.524 283.883 -441.524 299.558 -424.779 309.225C-408.033 318.893 -380.884 318.893 -364.139 309.225Z"
        fill="url(#paint174_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-376.267 456.267C-366.22 450.467 -366.22 441.062 -376.267 435.261C-386.314 429.461 -402.604 429.461 -412.651 435.261C-422.698 441.062 -422.698 450.467 -412.651 456.267C-402.604 462.068 -386.314 462.068 -376.267 456.267ZM-364.139 463.269C-347.394 453.602 -347.394 437.927 -364.139 428.259C-380.885 418.592 -408.034 418.592 -424.779 428.259C-441.524 437.927 -441.524 453.602 -424.779 463.269C-408.034 472.937 -380.885 472.937 -364.139 463.269Z"
        fill="url(#paint175_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-386.076 310.564L-386.076 426.921L-402.842 426.921L-402.842 310.564L-386.076 310.564Z"
        fill="url(#paint176_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-412.651 309.225L-497.545 358.239L-509.673 351.237L-424.778 302.223L-412.651 309.225ZM-279.244 386.247L-364.139 435.262L-376.267 428.26L-291.372 379.245L-279.244 386.247Z"
        fill="url(#paint177_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-296.417 481.352L-269.888 396.598L-250.197 398.653L-276.726 483.406L-296.417 481.352Z"
        fill="url(#paint178_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-55.8643 620.172L-27.5661 534.397L-7.90952 536.559L-36.2078 622.333L-55.8643 620.172Z"
        fill="url(#paint179_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M615.841 1008.64C616.037 1008.75 616.037 1008.93 615.841 1009.05L580.165 1029.65C579.97 1029.76 579.653 1029.76 579.458 1029.65L543.782 1009.05C543.586 1008.93 543.586 1008.75 543.781 1008.64L579.458 988.041C579.653 987.929 579.97 987.929 580.165 988.042L615.841 1008.64ZM580.165 1043.65C579.97 1043.76 579.653 1043.76 579.458 1043.65L519.526 1009.05C519.331 1008.93 519.331 1008.75 519.526 1008.64L579.458 974.037C579.653 973.925 579.97 973.925 580.165 974.037L640.097 1008.64C640.292 1008.75 640.292 1008.93 640.097 1009.05L580.165 1043.65Z"
        fill="url(#paint180_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M615.841 854.595C616.036 854.707 616.036 854.89 615.841 855.003L580.165 875.601C579.969 875.714 579.653 875.714 579.457 875.601L543.781 855.003C543.586 854.89 543.586 854.707 543.781 854.595L579.457 833.997C579.653 833.884 579.969 833.884 580.165 833.997L615.841 854.595ZM580.165 889.605C579.969 889.718 579.653 889.717 579.457 889.605L519.526 855.003C519.33 854.89 519.33 854.707 519.526 854.595L579.457 819.993C579.653 819.88 579.969 819.88 580.165 819.993L640.096 854.595C640.292 854.707 640.292 854.89 640.097 855.003L580.165 889.605Z"
        fill="url(#paint181_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M695.026 921.318C705.073 915.517 721.362 915.517 731.409 921.318C741.456 927.119 741.456 936.524 731.409 942.324C721.362 948.125 705.073 948.125 695.026 942.324C684.979 936.524 684.979 927.119 695.026 921.318ZM682.898 914.316C699.643 904.648 726.792 904.648 743.537 914.316C760.282 923.984 760.282 939.658 743.537 949.326C726.792 958.994 699.643 958.994 682.898 949.326C666.153 939.658 666.153 923.984 682.898 914.316Z"
        fill="url(#paint182_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M428.213 921.318C438.26 915.517 454.55 915.517 464.597 921.318C474.644 927.119 474.644 936.523 464.597 942.324C454.55 948.125 438.26 948.125 428.213 942.324C418.166 936.523 418.166 927.119 428.213 921.318ZM416.085 914.316C432.83 904.648 459.979 904.648 476.724 914.316C493.469 923.984 493.47 939.658 476.724 949.326C459.979 958.994 432.83 958.994 416.085 949.326C399.34 939.658 399.34 923.984 416.085 914.316Z"
        fill="url(#paint183_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M680.579 926.981L479.043 926.981L479.044 936.661L680.579 936.661L680.579 926.981Z"
        fill="url(#paint184_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M682.898 942.324L598.003 991.338L610.131 998.34L695.026 949.326L682.898 942.324ZM549.492 865.302L464.597 914.316L476.725 921.318L561.619 872.304L549.492 865.302Z"
        fill="url(#paint185_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M373.284 868.599C373.479 868.711 373.479 868.894 373.284 869.007L337.608 889.605C337.413 889.717 337.096 889.717 336.9 889.605L301.224 869.007C301.029 868.894 301.029 868.711 301.224 868.599L336.9 848.001C337.096 847.888 337.413 847.888 337.608 848.001L373.284 868.599ZM337.608 903.609C337.413 903.721 337.096 903.721 336.9 903.609L276.969 869.007C276.773 868.894 276.773 868.711 276.969 868.599L336.9 833.997C337.096 833.884 337.413 833.884 337.608 833.997L397.54 868.599C397.735 868.711 397.735 868.894 397.54 869.007L337.608 903.609Z"
        fill="url(#paint186_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M373.284 714.554C373.479 714.667 373.479 714.85 373.284 714.963L337.608 735.56C337.412 735.673 337.096 735.673 336.9 735.56L301.224 714.963C301.029 714.85 301.029 714.667 301.224 714.554L336.9 693.957C337.096 693.844 337.412 693.844 337.608 693.957L373.284 714.554ZM337.608 749.564C337.412 749.677 337.096 749.677 336.9 749.564L276.968 714.963C276.773 714.85 276.773 714.667 276.968 714.554L336.9 679.953C337.096 679.84 337.412 679.84 337.608 679.953L397.539 714.554C397.735 714.667 397.735 714.85 397.539 714.963L337.608 749.564Z"
        fill="url(#paint187_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M452.469 781.278C462.516 775.477 478.805 775.477 488.852 781.278C498.899 787.078 498.899 796.483 488.852 802.284C478.805 808.084 462.516 808.084 452.469 802.284C442.422 796.483 442.422 787.078 452.469 781.278ZM440.341 774.276C457.086 764.608 484.235 764.608 500.98 774.276C517.725 783.943 517.725 799.618 500.98 809.286C484.235 818.954 457.086 818.954 440.341 809.286C423.596 799.618 423.596 783.943 440.341 774.276Z"
        fill="url(#paint188_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185.656 781.278C195.703 775.477 211.992 775.477 222.039 781.278C232.086 787.078 232.086 796.483 222.039 802.284C211.992 808.084 195.703 808.084 185.656 802.284C175.609 796.483 175.609 787.078 185.656 781.278ZM173.528 774.276C190.273 764.608 217.422 764.608 234.167 774.276C250.912 783.943 250.912 799.618 234.167 809.286C217.422 818.953 190.273 818.953 173.528 809.286C156.783 799.618 156.783 783.943 173.528 774.276Z"
        fill="url(#paint189_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M438.022 786.941L236.486 786.941L236.486 796.621L438.022 796.621L438.022 786.941Z"
        fill="url(#paint190_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M440.341 802.284L355.446 851.298L367.574 858.3L452.469 809.286L440.341 802.284ZM306.934 725.262L222.04 774.276L234.167 781.278L319.062 732.264L306.934 725.262Z"
        fill="url(#paint191_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M858.397 1148.68C858.593 1148.79 858.593 1148.97 858.398 1149.09L822.721 1169.69C822.526 1169.8 822.209 1169.8 822.014 1169.69L786.338 1149.09C786.142 1148.97 786.142 1148.79 786.338 1148.68L822.014 1128.08C822.209 1127.97 822.526 1127.97 822.721 1128.08L858.397 1148.68ZM822.721 1183.69C822.526 1183.8 822.209 1183.8 822.014 1183.69L762.082 1149.09C761.887 1148.97 761.887 1148.79 762.082 1148.68L822.014 1114.08C822.209 1113.96 822.526 1113.96 822.721 1114.08L882.653 1148.68C882.848 1148.79 882.848 1148.97 882.653 1149.09L822.721 1183.69Z"
        fill="url(#paint192_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M858.397 994.635C858.593 994.747 858.593 994.93 858.397 995.043L822.721 1015.64C822.526 1015.75 822.209 1015.75 822.014 1015.64L786.338 995.043C786.142 994.93 786.142 994.747 786.338 994.635L822.014 974.037C822.209 973.924 822.526 973.924 822.721 974.037L858.397 994.635ZM822.721 1029.64C822.526 1029.76 822.209 1029.76 822.014 1029.64L762.082 995.043C761.887 994.93 761.887 994.747 762.082 994.635L822.014 960.033C822.209 959.92 822.526 959.92 822.721 960.033L882.653 994.635C882.848 994.747 882.848 994.93 882.653 995.043L822.721 1029.64Z"
        fill="url(#paint193_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M937.582 1061.36C947.629 1055.56 963.919 1055.56 973.966 1061.36C984.013 1067.16 984.013 1076.56 973.966 1082.36C963.919 1088.16 947.629 1088.16 937.582 1082.36C927.535 1076.56 927.535 1067.16 937.582 1061.36ZM925.454 1054.36C942.199 1044.69 969.348 1044.69 986.093 1054.36C1002.84 1064.02 1002.84 1079.7 986.093 1089.37C969.348 1099.03 942.199 1099.03 925.454 1089.37C908.709 1079.7 908.709 1064.02 925.454 1054.36Z"
        fill="url(#paint194_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M670.769 1061.36C680.816 1055.56 697.106 1055.56 707.153 1061.36C717.2 1067.16 717.2 1076.56 707.153 1082.36C697.106 1088.16 680.816 1088.16 670.769 1082.36C660.722 1076.56 660.722 1067.16 670.769 1061.36ZM658.642 1054.36C675.387 1044.69 702.536 1044.69 719.281 1054.36C736.026 1064.02 736.026 1079.7 719.281 1089.37C702.536 1099.03 675.387 1099.03 658.641 1089.37C641.896 1079.7 641.897 1064.02 658.642 1054.36Z"
        fill="url(#paint195_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M923.135 1067.02L721.6 1067.02L721.6 1076.7L923.135 1076.7L923.135 1067.02Z"
        fill="url(#paint196_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M925.454 1082.36L840.559 1131.38L852.687 1138.38L937.582 1089.37L925.454 1082.36ZM792.048 1005.34L707.153 1054.36L719.281 1061.36L804.176 1012.34L792.048 1005.34Z"
        fill="url(#paint197_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M627.322 1015.26L774.119 999.94L770.561 988.571L623.763 1003.89L627.322 1015.26Z"
        fill="url(#paint198_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M386.879 876.373L535.445 860.035L531.701 848.687L383.135 865.025L386.879 876.373Z"
        fill="url(#paint199_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-472.698 642.263C-472.893 642.15 -473.21 642.15 -473.405 642.263L-509.081 662.861C-509.277 662.973 -509.277 663.156 -509.081 663.269L-473.405 683.867C-473.21 683.979 -472.893 683.979 -472.698 683.867L-437.022 663.269C-436.826 663.156 -436.826 662.973 -437.022 662.861L-472.698 642.263ZM-533.337 662.861C-533.532 662.973 -533.532 663.156 -533.337 663.269L-473.405 697.871C-473.21 697.983 -472.893 697.983 -472.698 697.871L-412.766 663.269C-412.571 663.156 -412.571 662.973 -412.766 662.861L-472.698 628.259C-472.893 628.146 -473.21 628.146 -473.405 628.259L-533.337 662.861Z"
        fill="url(#paint200_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-205.886 642.263C-206.081 642.15 -206.398 642.15 -206.593 642.263L-242.269 662.86C-242.464 662.973 -242.464 663.156 -242.269 663.269L-206.593 683.867C-206.398 683.979 -206.081 683.979 -205.886 683.867L-170.209 663.269C-170.014 663.156 -170.014 662.973 -170.209 662.86L-205.886 642.263ZM-266.525 662.86C-266.72 662.973 -266.72 663.156 -266.525 663.269L-206.593 697.871C-206.398 697.983 -206.081 697.983 -205.886 697.871L-145.954 663.269C-145.758 663.156 -145.758 662.973 -145.954 662.86L-205.886 628.259C-206.081 628.146 -206.398 628.146 -206.593 628.259L-266.525 662.86Z"
        fill="url(#paint201_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-321.454 596.546C-311.407 590.745 -311.407 581.34 -321.454 575.54C-331.501 569.739 -347.79 569.739 -357.837 575.54C-367.884 581.34 -367.884 590.745 -357.837 596.546C-347.79 602.346 -331.501 602.346 -321.454 596.546ZM-309.326 603.548C-292.581 593.88 -292.581 578.205 -309.326 568.538C-326.071 558.87 -353.22 558.87 -369.965 568.538C-386.71 578.205 -386.71 593.88 -369.965 603.548C-353.22 613.215 -326.071 613.215 -309.326 603.548Z"
        fill="url(#paint202_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-321.454 750.59C-311.407 744.789 -311.407 735.384 -321.454 729.584C-331.501 723.783 -347.79 723.783 -357.837 729.584C-367.884 735.384 -367.884 744.789 -357.837 750.59C-347.79 756.39 -331.501 756.391 -321.454 750.59ZM-309.326 757.592C-292.581 747.924 -292.581 732.25 -309.326 722.582C-326.071 712.914 -353.22 712.914 -369.965 722.582C-386.71 732.25 -386.71 747.924 -369.965 757.592C-353.22 767.26 -326.071 767.26 -309.326 757.592Z"
        fill="url(#paint203_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-331.263 604.887L-331.263 721.243L-348.028 721.243L-348.028 604.886L-331.263 604.887Z"
        fill="url(#paint204_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-357.837 603.548L-442.732 652.562L-454.86 645.56L-369.965 596.546L-357.837 603.548ZM-224.431 680.57L-309.326 729.584L-321.453 722.582L-236.559 673.568L-224.431 680.57Z"
        fill="url(#paint205_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-230.141 782.303C-230.337 782.19 -230.653 782.19 -230.849 782.303L-266.525 802.901C-266.72 803.014 -266.72 803.196 -266.525 803.309L-230.849 823.907C-230.653 824.02 -230.336 824.02 -230.141 823.907L-194.465 803.309C-194.27 803.196 -194.27 803.014 -194.465 802.901L-230.141 782.303ZM-290.78 802.901C-290.976 803.014 -290.976 803.196 -290.78 803.309L-230.849 837.911C-230.653 838.024 -230.336 838.024 -230.141 837.911L-170.209 803.309C-170.014 803.196 -170.014 803.014 -170.209 802.901L-230.141 768.299C-230.336 768.186 -230.653 768.186 -230.849 768.299L-290.78 802.901Z"
        fill="url(#paint206_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.6712 782.303C36.4758 782.19 36.1591 782.19 35.9638 782.303L0.287712 802.901C0.0923625 803.013 0.0922832 803.196 0.287646 803.309L35.9637 823.907C36.1591 824.02 36.4758 824.019 36.6712 823.907L72.3472 803.309C72.5426 803.196 72.5426 803.013 72.3473 802.901L36.6712 782.303ZM-23.968 802.901C-24.1633 803.013 -24.1633 803.196 -23.968 803.309L35.9637 837.911C36.1591 838.024 36.4758 838.024 36.6712 837.911L96.6029 803.309C96.7983 803.196 96.7983 803.013 96.6029 802.901L36.6712 768.299C36.4759 768.186 36.1591 768.186 35.9638 768.299L-23.968 802.901Z"
        fill="url(#paint207_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-78.8969 736.586C-68.8498 730.785 -68.8498 721.38 -78.8969 715.58C-88.9438 709.779 -105.233 709.779 -115.28 715.58C-125.327 721.381 -125.327 730.785 -115.28 736.586C-105.233 742.387 -88.9439 742.387 -78.8969 736.586ZM-66.769 743.588C-50.024 733.92 -50.0239 718.246 -66.769 708.578C-83.5141 698.91 -110.663 698.91 -127.408 708.578C-144.153 718.246 -144.153 733.92 -127.408 743.588C-110.663 753.256 -83.5141 753.256 -66.769 743.588Z"
        fill="url(#paint208_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-78.897 890.63C-68.85 884.829 -68.85 875.425 -78.897 869.624C-88.9441 863.823 -105.233 863.823 -115.28 869.624C-125.328 875.425 -125.328 884.829 -115.281 890.63C-105.234 896.431 -88.944 896.431 -78.897 890.63ZM-66.7692 897.632C-50.0241 887.964 -50.0241 872.29 -66.7692 862.622C-83.5142 852.954 -110.663 852.954 -127.408 862.622C-144.153 872.29 -144.153 887.964 -127.408 897.632C-110.663 907.3 -83.5142 907.3 -66.7692 897.632Z"
        fill="url(#paint209_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-88.7058 744.927L-88.7058 861.283L-105.471 861.283L-105.471 744.927L-88.7058 744.927Z"
        fill="url(#paint210_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-115.28 743.588L-200.175 792.602L-212.303 785.6L-127.408 736.586L-115.28 743.588ZM18.126 820.61L-66.7689 869.624L-78.8967 862.622L5.99821 813.608L18.126 820.61Z"
        fill="url(#paint211_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-715.255 502.223C-715.45 502.11 -715.767 502.11 -715.962 502.223L-751.638 522.82C-751.833 522.933 -751.833 523.116 -751.638 523.229L-715.962 543.826C-715.767 543.939 -715.45 543.939 -715.255 543.826L-679.579 523.229C-679.383 523.116 -679.383 522.933 -679.579 522.82L-715.255 502.223ZM-775.894 522.82C-776.089 522.933 -776.089 523.116 -775.894 523.229L-715.962 557.83C-715.767 557.943 -715.45 557.943 -715.255 557.83L-655.323 523.229C-655.127 523.116 -655.127 522.933 -655.323 522.82L-715.255 488.219C-715.45 488.106 -715.767 488.106 -715.962 488.219L-775.894 522.82Z"
        fill="url(#paint212_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-448.442 502.223C-448.638 502.11 -448.954 502.11 -449.15 502.223L-484.826 522.82C-485.021 522.933 -485.021 523.116 -484.826 523.229L-449.15 543.826C-448.954 543.939 -448.638 543.939 -448.442 543.826L-412.766 523.229C-412.571 523.116 -412.571 522.933 -412.766 522.82L-448.442 502.223ZM-509.081 522.82C-509.277 522.933 -509.277 523.116 -509.081 523.229L-449.15 557.83C-448.954 557.943 -448.638 557.943 -448.442 557.83L-388.511 523.229C-388.315 523.116 -388.315 522.933 -388.511 522.82L-448.442 488.219C-448.638 488.106 -448.954 488.106 -449.15 488.219L-509.081 522.82Z"
        fill="url(#paint213_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-564.01 456.505C-553.963 450.705 -553.963 441.3 -564.01 435.499C-574.057 429.699 -590.347 429.699 -600.394 435.499C-610.441 441.3 -610.441 450.705 -600.394 456.505C-590.347 462.306 -574.057 462.306 -564.01 456.505ZM-551.882 463.507C-535.137 453.84 -535.137 438.165 -551.882 428.497C-568.628 418.83 -595.777 418.83 -612.522 428.497C-629.267 438.165 -629.267 453.84 -612.522 463.508C-595.777 473.175 -568.628 473.175 -551.882 463.507Z"
        fill="url(#paint214_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-564.01 610.55C-553.963 604.749 -553.963 595.344 -564.01 589.544C-574.058 583.743 -590.347 583.743 -600.394 589.544C-610.441 595.344 -610.441 604.749 -600.394 610.55C-590.347 616.35 -574.058 616.35 -564.01 610.55ZM-551.883 617.552C-535.138 607.884 -535.138 592.209 -551.883 582.542C-568.628 572.874 -595.777 572.874 -612.522 582.542C-629.267 592.209 -629.267 607.884 -612.522 617.552C-595.777 627.219 -568.628 627.219 -551.883 617.552Z"
        fill="url(#paint215_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-573.819 464.846L-573.819 581.203L-590.585 581.203L-590.585 464.846L-573.819 464.846Z"
        fill="url(#paint216_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-600.394 463.508L-685.289 512.522L-697.416 505.52L-612.522 456.506L-600.394 463.508ZM-466.987 540.53L-551.882 589.544L-564.01 582.542L-479.115 533.528L-466.987 540.53Z"
        fill="url(#paint217_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-484.16 635.634L-457.631 550.881L-437.94 552.935L-464.469 637.689L-484.16 635.634Z"
        fill="url(#paint218_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-243.608 774.454L-215.309 688.679L-195.653 690.841L-223.951 776.616L-243.608 774.454Z"
        fill="url(#paint219_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M428.098 1162.92C428.293 1163.03 428.293 1163.22 428.098 1163.33L392.422 1183.93C392.227 1184.04 391.91 1184.04 391.714 1183.93L356.038 1163.33C355.843 1163.22 355.843 1163.03 356.038 1162.92L391.714 1142.32C391.91 1142.21 392.227 1142.21 392.422 1142.32L428.098 1162.92ZM392.422 1197.93C392.227 1198.04 391.91 1198.04 391.714 1197.93L331.783 1163.33C331.587 1163.22 331.587 1163.03 331.783 1162.92L391.714 1128.32C391.91 1128.21 392.227 1128.21 392.422 1128.32L452.354 1162.92C452.549 1163.03 452.549 1163.22 452.354 1163.33L392.422 1197.93Z"
        fill="url(#paint220_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M428.098 1008.88C428.293 1008.99 428.293 1009.17 428.098 1009.29L392.422 1029.88C392.226 1030 391.91 1030 391.714 1029.88L356.038 1009.29C355.843 1009.17 355.843 1008.99 356.038 1008.88L391.714 988.279C391.91 988.167 392.226 988.167 392.422 988.279L428.098 1008.88ZM392.422 1043.89C392.226 1044 391.909 1044 391.714 1043.89L331.782 1009.29C331.587 1009.17 331.587 1008.99 331.782 1008.88L391.714 974.275C391.91 974.163 392.226 974.163 392.422 974.275L452.353 1008.88C452.549 1008.99 452.549 1009.17 452.353 1009.29L392.422 1043.89Z"
        fill="url(#paint221_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M507.283 1075.6C517.33 1069.8 533.619 1069.8 543.666 1075.6C553.713 1081.4 553.713 1090.81 543.666 1096.61C533.619 1102.41 517.33 1102.41 507.283 1096.61C497.236 1090.81 497.236 1081.4 507.283 1075.6ZM495.155 1068.6C511.9 1058.93 539.049 1058.93 555.794 1068.6C572.539 1078.27 572.539 1093.94 555.794 1103.61C539.049 1113.28 511.9 1113.28 495.155 1103.61C478.41 1093.94 478.41 1078.27 495.155 1068.6Z"
        fill="url(#paint222_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M240.47 1075.6C250.517 1069.8 266.806 1069.8 276.853 1075.6C286.9 1081.4 286.901 1090.81 276.853 1096.61C266.806 1102.41 250.517 1102.41 240.47 1096.61C230.423 1090.81 230.423 1081.4 240.47 1075.6ZM228.342 1068.6C245.087 1058.93 272.236 1058.93 288.981 1068.6C305.726 1078.27 305.726 1093.94 288.981 1103.61C272.236 1113.28 245.087 1113.28 228.342 1103.61C211.597 1093.94 211.597 1078.27 228.342 1068.6Z"
        fill="url(#paint223_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M492.836 1081.26L291.3 1081.26L291.3 1090.94L492.836 1090.94L492.836 1081.26Z"
        fill="url(#paint224_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M495.155 1096.61L410.26 1145.62L422.388 1152.62L507.283 1103.61L495.155 1096.61ZM361.748 1019.58L276.854 1068.6L288.981 1075.6L373.876 1026.59L361.748 1019.58Z"
        fill="url(#paint225_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185.541 1022.88C185.736 1022.99 185.736 1023.18 185.541 1023.29L149.865 1043.89C149.669 1044 149.353 1044 149.157 1043.89L113.481 1023.29C113.286 1023.18 113.286 1022.99 113.481 1022.88L149.157 1002.28C149.353 1002.17 149.669 1002.17 149.865 1002.28L185.541 1022.88ZM149.865 1057.89C149.669 1058 149.353 1058 149.157 1057.89L89.2255 1023.29C89.0302 1023.18 89.0302 1022.99 89.2255 1022.88L149.157 988.279C149.353 988.166 149.669 988.166 149.865 988.279L209.796 1022.88C209.992 1022.99 209.992 1023.18 209.796 1023.29L149.865 1057.89Z"
        fill="url(#paint226_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M185.541 868.836C185.736 868.949 185.736 869.132 185.541 869.245L149.864 889.842C149.669 889.955 149.352 889.955 149.157 889.842L113.481 869.245C113.286 869.132 113.286 868.949 113.481 868.836L149.157 848.239C149.352 848.126 149.669 848.126 149.864 848.239L185.541 868.836ZM149.864 903.847C149.669 903.959 149.352 903.959 149.157 903.846L89.2253 869.245C89.0299 869.132 89.0299 868.949 89.2253 868.836L149.157 834.235C149.352 834.122 149.669 834.122 149.864 834.235L209.796 868.836C209.992 868.949 209.992 869.132 209.796 869.245L149.864 903.847Z"
        fill="url(#paint227_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M264.725 935.56C274.773 929.759 291.062 929.759 301.109 935.56C311.156 941.361 311.156 950.765 301.109 956.566C291.062 962.367 274.772 962.367 264.725 956.566C254.678 950.765 254.678 941.361 264.725 935.56ZM252.598 928.558C269.343 918.89 296.492 918.89 313.237 928.558C329.982 938.226 329.982 953.9 313.237 963.568C296.492 973.236 269.343 973.236 252.598 963.568C235.853 953.9 235.853 938.226 252.598 928.558Z"
        fill="url(#paint228_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-2.08726 935.56C7.95977 929.759 24.2492 929.759 34.2963 935.56C44.3433 941.36 44.3433 950.765 34.2963 956.566C24.2492 962.366 7.95978 962.366 -2.08725 956.566C-12.1343 950.765 -12.1343 941.36 -2.08726 935.56ZM-14.2151 928.558C2.52995 918.89 29.6791 918.89 46.4241 928.558C63.1691 938.226 63.1692 953.9 46.4241 963.568C29.6791 973.236 2.52995 973.236 -14.2151 963.568C-30.9601 953.9 -30.9602 938.226 -14.2151 928.558Z"
        fill="url(#paint229_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M250.279 941.223L48.7432 941.223L48.7432 950.903L250.279 950.903L250.279 941.223Z"
        fill="url(#paint230_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M252.598 956.566L167.703 1005.58L179.83 1012.58L264.725 963.568L252.598 956.566ZM119.191 879.544L34.2964 928.558L46.4242 935.56L131.319 886.546L119.191 879.544Z"
        fill="url(#paint231_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M670.654 1302.96C670.85 1303.07 670.85 1303.26 670.654 1303.37L634.978 1323.97C634.783 1324.08 634.466 1324.08 634.271 1323.97L598.595 1303.37C598.399 1303.26 598.399 1303.07 598.595 1302.96L634.271 1282.36C634.466 1282.25 634.783 1282.25 634.978 1282.36L670.654 1302.96ZM634.978 1337.97C634.783 1338.08 634.466 1338.08 634.271 1337.97L574.339 1303.37C574.144 1303.26 574.144 1303.07 574.339 1302.96L634.271 1268.36C634.466 1268.25 634.783 1268.25 634.978 1268.36L694.91 1302.96C695.105 1303.07 695.105 1303.26 694.91 1303.37L634.978 1337.97Z"
        fill="url(#paint232_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M670.654 1148.92C670.849 1149.03 670.849 1149.21 670.654 1149.33L634.978 1169.92C634.783 1170.04 634.466 1170.04 634.271 1169.92L598.594 1149.33C598.399 1149.21 598.399 1149.03 598.594 1148.92L634.27 1128.32C634.466 1128.21 634.783 1128.21 634.978 1128.32L670.654 1148.92ZM634.978 1183.93C634.783 1184.04 634.466 1184.04 634.27 1183.93L574.339 1149.33C574.143 1149.21 574.143 1149.03 574.339 1148.92L634.271 1114.32C634.466 1114.2 634.783 1114.2 634.978 1114.32L694.91 1148.92C695.105 1149.03 695.105 1149.21 694.91 1149.33L634.978 1183.93Z"
        fill="url(#paint233_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M749.839 1215.64C759.886 1209.84 776.175 1209.84 786.222 1215.64C796.269 1221.44 796.27 1230.85 786.223 1236.65C776.176 1242.45 759.886 1242.45 749.839 1236.65C739.792 1230.85 739.792 1221.44 749.839 1215.64ZM737.711 1208.64C754.456 1198.97 781.605 1198.97 798.35 1208.64C815.095 1218.31 815.095 1233.98 798.35 1243.65C781.605 1253.32 754.456 1253.32 737.711 1243.65C720.966 1233.98 720.966 1218.31 737.711 1208.64Z"
        fill="url(#paint234_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M483.026 1215.64C493.073 1209.84 509.363 1209.84 519.41 1215.64C529.457 1221.44 529.457 1230.85 519.41 1236.65C509.363 1242.45 493.073 1242.45 483.026 1236.65C472.979 1230.85 472.979 1221.44 483.026 1215.64ZM470.898 1208.64C487.643 1198.97 514.793 1198.97 531.537 1208.64C548.283 1218.31 548.283 1233.98 531.538 1243.65C514.793 1253.32 487.643 1253.32 470.898 1243.65C454.153 1233.98 454.153 1218.31 470.898 1208.64Z"
        fill="url(#paint235_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M735.392 1221.3L533.857 1221.3L533.857 1230.98L735.392 1230.98L735.392 1221.3Z"
        fill="url(#paint236_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M737.711 1236.65L652.816 1285.66L664.944 1292.66L749.839 1243.65L737.711 1236.65ZM604.305 1159.62L519.41 1208.64L531.538 1215.64L616.433 1166.63L604.305 1159.62Z"
        fill="url(#paint237_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M439.579 1169.54L586.376 1154.22L582.817 1142.85L436.02 1158.17L439.579 1169.54Z"
        fill="url(#paint238_linear_679:17017)"
        fillOpacity="0.28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M199.136 1030.66L347.702 1014.32L343.958 1002.97L195.392 1019.31L199.136 1030.66Z"
        fill="url(#paint239_linear_679:17017)"
        fillOpacity="0.28"
      />
      <defs>
        <linearGradient
          id="paint0_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint3_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint4_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint5_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint6_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint7_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint8_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint9_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint10_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint11_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint12_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint13_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint14_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint15_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint16_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint17_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint18_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint19_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint20_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint21_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint22_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint23_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint24_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint25_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint26_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint27_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint28_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint29_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint30_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint31_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint32_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint33_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint34_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint35_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint36_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint37_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint38_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint39_linear_679:17017"
          x1="292.425"
          y1="-155.825"
          x2="627.829"
          y2="-545.859"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint40_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint41_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint42_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint43_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint44_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint45_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint46_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint47_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint48_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint49_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint50_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint51_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint52_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint53_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint54_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint55_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint56_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint57_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint58_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint59_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint60_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint61_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint62_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint63_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint64_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint65_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint66_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint67_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint68_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint69_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint70_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint71_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint72_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint73_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint74_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint75_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint76_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint77_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint78_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint79_linear_679:17017"
          x1="140.711"
          y1="24.5122"
          x2="476.115"
          y2="-365.522"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint80_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint81_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint82_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint83_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint84_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint85_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint86_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint87_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint88_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint89_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint90_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint91_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint92_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint93_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint94_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint95_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint96_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint97_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint98_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint99_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint100_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint101_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint102_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint103_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint104_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint105_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint106_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint107_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint108_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint109_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint110_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint111_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint112_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint113_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint114_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint115_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint116_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint117_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint118_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint119_linear_679:17017"
          x1="-149.642"
          y1="108.745"
          x2="185.762"
          y2="-281.289"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint120_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint121_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint122_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint123_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint124_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint125_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint126_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint127_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint128_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint129_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint130_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint131_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint132_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint133_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint134_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint135_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint136_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint137_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint138_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint139_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint140_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint141_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint142_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint143_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint144_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint145_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint146_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint147_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint148_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint149_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint150_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint151_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint152_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint153_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint154_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint155_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint156_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint157_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint158_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint159_linear_679:17017"
          x1="-340.208"
          y1="264.439"
          x2="-4.80394"
          y2="-125.595"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint160_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint161_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint162_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint163_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint164_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint165_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint166_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint167_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint168_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint169_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint170_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint171_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint172_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint173_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint174_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint175_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint176_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint177_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint178_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint179_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint180_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint181_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint182_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint183_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint184_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint185_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint186_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint187_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint188_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint189_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint190_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint191_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint192_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint193_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint194_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint195_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint196_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint197_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint198_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint199_linear_679:17017"
          x1="-589.01"
          y1="369.034"
          x2="-253.606"
          y2="-20.9997"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint200_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint201_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint202_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint203_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint204_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint205_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint206_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint207_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint208_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint209_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint210_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint211_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint212_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint213_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint214_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint215_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint216_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint217_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint218_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint219_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint220_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint221_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint222_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint223_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint224_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint225_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint226_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint227_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint228_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint229_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint230_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint231_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint232_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint233_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint234_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint235_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint236_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint237_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint238_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id="paint239_linear_679:17017"
          x1="-776.753"
          y1="523.316"
          x2="-441.349"
          y2="133.282"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#236C4E" stopOpacity="0.2" />
          <stop offset="0.463542" stopColor="#367D6C" stopOpacity="0.14" />
          <stop offset="1" stopColor="#398C69" stopOpacity="0.54" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default Banner;
