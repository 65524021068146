import React, { useEffect, useState } from "react";
import { Button, Header, Modal, Progress, Segment } from "semantic-ui-react";
import { Label } from "./styles";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

let highTask = {};
let advancedStats = [];
///////FUNÇÕES DO GRÁFICO
function checkBranch(
  elements,
  item = {},
  branchTimeStart = 0,
  doneTimeStart = 0,
  donePreventTimeStart = 0,
  statsStart = [],
  advancedStats = [{ sad: 22 }],
  realStatsStart = [],
  realStat,
  task = {}
) {
  let realStats = realStatsStart;
  let stats = statsStart;
  let branchTime = branchTimeStart;
  let donePreventTime = donePreventTimeStart;
  let doneTime = doneTimeStart;
  const nowLocal = moment().utcOffset(-180);

  ////SE FOR ARESTA
  if (item.source) {
    return checkBranch(
      elements,
      elements.find((el) => el.id === item.target),
      branchTime,
      doneTime,
      donePreventTime,
      stats,
      advancedStats,
      realStats,
      realStat,
      task
    );
  }
  ///////SE FOR NO
  else {
    if (item.type === "task" || item.type === "timerEvent") {
      if (item.type === "timerEvent") {
        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });
        stats.push(
          stats.length > 0
            ? {
                x: stats[stats.length - 1].x + item.data.expiration.number / 24,
                y: stats[stats.length - 1].y - item.data.expiration.number / 24,
                taskTitle: "TEMPORIZADOR",
                start: moment(stats[stats.length - 1].finish),
                finish: moment(stats[stats.length - 1].finish).add(
                  item.data.expiration.number,
                  "hours",
                  true
                ),
                time: item.data.expiration.number / 24,
                taskId: item._id,
                initialResource: stats[stats.length - 1].y,
              }
            : {
                x:
                  advancedStats[advancedStats.length - 1]?.x +
                  item.data.expiration.number / 24,
                y:
                  advancedStats[advancedStats.length - 1]?.y -
                  item.data.expiration.number / 24,
                taskTitle: "TEMPORIZADOR",
                start: moment(advancedStats[advancedStats.length - 1]?.finish),
                finish: moment(
                  advancedStats[advancedStats.length - 1]?.finish
                ).add(item.data.expiration.number, "hours", true),
                time: item.data.expiration.number / 24,
                taskId: item._id,
                initialResource: advancedStats[advancedStats.length - 1].y,
              }
        );
      }
      if (item.type === "task") {
        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        stats.push(
          stats.length > 0
            ? {
                x: stats[stats.length - 1].x + item.data.expiration.number / 24,
                y: stats[stats.length - 1].y - item.data.expiration.number / 24,
                taskTitle: item.data.label,
                start: moment(stats[stats.length - 1].finish),
                finish: moment(stats[stats.length - 1].finish).add(
                  item.data.expiration.number,
                  "hours",
                  true
                ),
                time: item.data.expiration.number / 24,
                taskId: item._id,
                initialResource: stats[stats.length - 1].y,
              }
            : {
                x:
                  advancedStats[advancedStats.length - 1]?.x +
                  item.data.expiration.number / 24,
                y:
                  advancedStats[advancedStats.length - 1]?.y -
                  item.data.expiration.number / 24,
                taskTitle: item.data.label,
                start: moment(advancedStats[advancedStats.length - 1]?.finish),
                finish: moment(
                  advancedStats[advancedStats.length - 1]?.finish
                ).add(item.data.expiration.number, "hours", true),
                time: item.data.expiration.number / 24,
                taskId: item._id,
                initialResource: advancedStats[advancedStats.length - 1]?.y,
              }
        );
      }
      if (item.data.status === "done") {
        if (item.type === "timerEvent") {
          donePreventTime += item.data.expiration.number;
          doneTime += item.data.expiration.number;
        } else {
          realStats.sort(function (a, b) {
            return Number(a.x) - Number(b.x);
          });

          realStats.push(
            realStats.length > 0
              ? {
                  x:
                    realStats[realStats.length - 1].x +
                    parseFloat(
                      Number(
                        moment(item.data.finishedAt).diff(
                          moment(item.data.startedAt),
                          "days",
                          true
                        )
                      )
                    ),
                  y:
                    realStats[realStats.length - 1].y -
                    item.data.expiration.number / 24,
                  taskTitle: item.data.label,
                  taskId: item._id,
                  start: item.data.startedAt,
                  finish: item.data.finishedAt,
                }
              : {
                  x:
                    realStat[realStat.length - 1].x +
                    parseFloat(
                      Number(
                        moment(item.data.finishedAt).diff(
                          moment(item.data.startedAt),
                          "days",
                          true
                        )
                      )
                    ),
                  y:
                    realStat[realStat.length - 1].y -
                    item.data.expiration.number / 24,
                  taskTitle: item.data.label,
                  taskId: item._id,
                  start: item.data.startedAt,
                  finish: item.data.finishedAt,
                }
          );

          donePreventTime += item.data.expiration.number;
          doneTime += moment(item.data.finishedAt).diff(
            moment(item.data.startedAt),
            "hours",
            true
          );
        }
      }
      if (item.data.status === "pending") {
        branchTime += item.data.expiration.number;
      }
      if (item.data.status === "doing") {
        if (
          moment(item.data.startedAt)
            .add(item.data.expiration.number, "hours", true)
            .diff(nowLocal, "hours", true) < 0 &&
          item.type === "task"
        ) {
          branchTime += moment(nowLocal).diff(
            moment(item.data.startedAt),
            "hours",
            true
          );
          doneTime += moment(nowLocal).diff(
            moment(item.data.startedAt),
            "hours",
            true
          );
          donePreventTime += item.data.expiration.number;
        } else {
          branchTime += item.data.expiration.number;
        }

        return checkBranch(
          elements,
          elements.find((e) => e.source === item.id),
          branchTime,
          doneTime,
          donePreventTime,
          stats,
          advancedStats,
          realStats,
          realStat,
          item
        );
      }

      return checkBranch(
        elements,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        stats,
        advancedStats,
        realStats,
        realStat,
        task
      );
    } else if (item.type === "parallel" || item.type === "conditional") {
      const branchCountt = branchCount(
        elements,
        item,
        stats.length === 0 ? advancedStats : stats,
        realStat
      );

      branchTime += branchCountt.max;
      doneTime += branchCountt.maxDone;
      donePreventTime += branchCountt.maxPreventDone;

      branchCountt.stats.forEach((item) => {
        stats.push(item);
      });

      stats.sort(function (a, b) {
        return Number(a.x) - Number(b.x);
      });

      branchCountt.realStats.forEach((item) => {
        realStats.push(item);
      });

      realStats.sort(function (a, b) {
        return Number(a.x) - Number(b.x);
      });

      return checkBranch(
        elements,
        elements.find((e) => e.source === branchCountt.lastItem.id),
        branchTime,
        doneTime,
        donePreventTime,
        stats,
        advancedStats,
        realStats,
        realStat,
        branchCountt.task
      );
    } else if (item.type === "parallelEnd" || item.type === "conditionalEnd") {
      return {
        branchTime,
        lastItem: item,
        doneTime,
        donePreventTime,
        stats,
        realStats,
        task,
      };
    }
    /// SE NAO FOR NADA DISSO SÓ CONTINUA ANDANDO
    else
      return checkBranch(
        elements,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        stats,
        advancedStats,
        realStats,
        realStat,
        task
      );
  }
}

function branchCount(elements, item = {}, stat = [{ jurubebe: 12 }], realStat) {
  let max = 0;
  let maxDone = 0;
  let task;
  let maxPreventDone = 0;
  const allBranchs = elements.filter((e) => e.source === item.id);

  const times = allBranchs.map((e) => {
    const obj = checkBranch(elements, e, 0, 0, 0, [], stat, [], realStat);

    return obj;
  });

  let bestBranch = [];
  let bestRealBranch = [];

  times.forEach((el, index) => {
    if (index + 1 <= times.length) {
      if (el.branchTime >= max) {
        task = el.task;
      }

      max = Math.max(el.branchTime, max);
      maxDone = Math.max(el.doneTime, maxDone);
      maxPreventDone = Math.max(el.donePreventTime, maxPreventDone);

      if (item.type === "conditional") {
        if (max === el.branchTime) {
          bestBranch = el.stats;
          bestRealBranch = el.realStats;
        }
      } else {
        el.stats.forEach((item) => {
          bestBranch.push(item);
        });
        el.realStats.forEach((item) => {
          bestRealBranch.push(item);
        });
      }
    }
  });

  return {
    max,
    lastItem: times[0].lastItem,
    maxDone,
    maxPreventDone,
    stats: bestBranch,
    realStats: bestRealBranch,
    task,
  };
}

function count(
  elements,
  item = { type: "eventEnd" },
  branchTimeStart = 0,
  doneTimeStart = 0,
  donePreventTimeStart = 0,
  statsStart = [advancedStats[0]],
  realStatsStart = [advancedStats[0]],
  task = {}
) {
  let realStats = realStatsStart;
  let stats = statsStart;
  let branchTime = branchTimeStart;
  let donePreventTime = donePreventTimeStart;
  let doneTime = doneTimeStart;

  const nowLocal = moment().utcOffset(-180);

  //////////SE FOR ARESTA
  if (item.source) {
    return count(
      elements,
      elements.find((el) => el.id === item.target),
      branchTime,
      doneTime,
      donePreventTime,
      stats,
      realStats,
      task
    );
  }
  //////////SE FOR NÓ
  else {
    if (item.type === "task" || item.type === "timerEvent") {
      if (item.type === "timerEvent") {
        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        stats.push({
          x: stats[stats.length - 1].x + item.data.expiration.number / 24,
          y: stats[stats.length - 1].y - item.data.expiration.number / 24,
          taskTitle: "TEMPORIZADOR",
          start: moment(stats[stats.length - 1].finish),
          finish: moment(stats[stats.length - 1].finish).add(
            item.data.expiration.number,
            "hours",
            true
          ),
          time: item.data.expiration.number / 24,
          taskId: item._id,
          initialResource: stats[stats.length - 1].y,
        });
      }

      if (item.type === "task") {
        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        stats.push({
          x: stats[stats.length - 1].x + item.data.expiration.number / 24,
          y: stats[stats.length - 1].y - item.data.expiration.number / 24,
          taskTitle: item.data.label,
          start: moment(stats[stats.length - 1].finish),
          finish: moment(stats[stats.length - 1].finish).add(
            item.data.expiration.number,
            "hours",
            true
          ),
          time: item.data.expiration.number / 24,
          taskId: item._id,
          initialResource: stats[stats.length - 1].y,
        });
      }

      if (item.data.status === "done") {
        if (item.type === "timerEvent") {
          donePreventTime += item.data.expiration.number;
          doneTime += item.data.expiration.number;
        } else {
          realStats.sort(function (a, b) {
            return Number(a.x) - Number(b.x);
          });

          realStats.push({
            x:
              realStats[realStats.length - 1].x +
              parseFloat(
                Number(
                  moment(item.data.finishedAt).diff(
                    moment(item.data.startedAt),
                    "days",
                    true
                  )
                )
              ),
            y:
              realStats[realStats.length - 1].y -
              item.data.expiration.number / 24,
            taskTitle: item.data.label,
            taskId: item._id,
            start: item.data.startedAt,
            finish: item.data.finishedAt,
          });

          donePreventTime += item.data.expiration.number;
          doneTime += moment(item.data.finishedAt).diff(
            moment(item.data.startedAt),
            "hours",
            true
          );
        }
      } else if (item.data.status === "doing") {
        if (
          moment(item.data.startedAt)
            .add(item.data.expiration.number, "hours")
            .diff(nowLocal) < 0 &&
          item.type === "task"
        ) {
          branchTime += moment(nowLocal).diff(
            moment(item.data.startedAt),
            "hours",
            true
          );
          doneTime += moment(nowLocal).diff(
            moment(item.data.startedAt),
            "hours",
            true
          );
          donePreventTime += item.data.expiration.number;
        } else {
          branchTime += item.data.expiration.number;
        }

        return count(
          elements,
          elements.find((e) => e.source === item.id),
          branchTime,
          doneTime,
          donePreventTime,
          stats,
          realStats,
          item
        );
      } else if (item.data.status === "pending") {
        branchTime += item.data.expiration.number;
      }
      return count(
        elements,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        stats,
        realStats,
        task
      );
    } else {
      if (item.type === "eventEnd") {
        highTask = task;
        return { branchTime, doneTime, donePreventTime, stats, realStats };
      } else if (
        item.type === "parallel" ||
        (item.type === "conditional" && item.data.status !== "done")
      ) {
        /////VERIFICA O MAIOR CAMINHO E RETORNA O ID FINAL
        const finishBranchItem = branchCount(elements, item, stats, realStats);

        branchTime += finishBranchItem.max;
        doneTime += finishBranchItem.maxDone;
        donePreventTime += finishBranchItem.maxPreventDone;

        finishBranchItem.stats.forEach((item) => {
          stats.push(item);
        });

        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        finishBranchItem.realStats.forEach((item) => {
          realStats.push(item);
        });

        realStats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        return count(
          elements,
          elements.find((e) => e.source === finishBranchItem.lastItem.id),
          branchTime,
          doneTime,
          donePreventTime,
          stats,
          realStats,
          finishBranchItem.task
        );
      } else {
        return count(
          elements,
          elements.find((e) =>
            item.type === "conditional"
              ? e.source === item.id && e.data.status === "done"
              : e.source === item.id
          ),
          branchTime,
          doneTime,
          donePreventTime,
          stats,
          realStats,
          task
        );
      }
    }
  }
}

function checkBranchEntireTime(elements, item = {}, branchTimeStart = 0) {
  let branchTime = branchTimeStart;

  ////SE FOR ARESTA
  if (item.source) {
    return checkBranchEntireTime(
      elements,
      elements.find((el) => el.id === item.target),
      branchTime
    );
  }
  ///////SE FOR NO
  else {
    if (item.type === "task" || item.type === "timerEvent") {
      branchTime += item.data.expiration.number;

      if (item.data.status === "doing") {
        return checkBranchEntireTime(
          elements,
          elements.find((e) => e.source === item.id),
          branchTime
        );
      } else
        return checkBranchEntireTime(
          elements,
          elements.find((e) => e.source === item.id),
          branchTime
        );
    } else if (item.type === "parallel" || item.type === "conditional") {
      const branchCount = branchCountEntireTime(elements, item);

      branchTime += branchCount.max;

      return checkBranchEntireTime(
        elements,
        elements.find((e) => e.source === branchCount.lastItem.id),
        branchTime
      );
    } else if (item.type === "parallelEnd" || item.type === "conditionalEnd") {
      return { branchTime, lastItem: item };
    }
    /// SE NAO FOR NADA DISSO SÓ CONTINUA ANDANDO
    else
      return checkBranchEntireTime(
        elements,
        elements.find((e) => e.source === item.id),
        branchTime
      );
  }
}

function branchCountEntireTime(elements, item = {}) {
  let max = 0;
  const allBranchs = elements.filter((e) => e.source === item.id);

  const times = allBranchs.map((e) => {
    const obj = checkBranchEntireTime(elements, e);

    return obj;
  });

  times.forEach((el, index) => {
    if (index + 1 <= times.length) {
      max = Math.max(el.branchTime, max);
    }
  });

  return { max, lastItem: times[0].lastItem };
}

function flowTime(elements, item = { type: "eventEnd" }, branchTimeStart = 0) {
  let branchTime = branchTimeStart;

  //////////SE FOR ARESTA
  if (item.source) {
    return flowTime(
      elements,
      elements.find((el) => el.id === item.target),
      branchTime
    );
  }
  //////////SE FOR NÓ
  else {
    if (item.type === "task" || item.type === "timerEvent") {
      branchTime += item.data.expiration.number;

      if (item.data.status === "doing") {
        return flowTime(
          elements,
          elements.find((e) => e.source === item.id),
          branchTime
        );
      }

      return flowTime(
        elements,
        elements.find((e) => e.source === item.id),
        branchTime
      );
    } else {
      if (item.type === "eventEnd") {
        return { branchTime };
      } else if (
        item.type === "parallel" ||
        (item.type === "conditional" && item.data.status !== "done")
      ) {
        /////VERIFICA O MAIOR CAMINHO E RETORNA O ID FINAL
        const finishBranchItem = branchCountEntireTime(elements, item);

        branchTime += finishBranchItem.max;

        return flowTime(
          elements,
          elements.find((e) => e.source === finishBranchItem.lastItem.id),
          branchTime
        );
      } else {
        return flowTime(
          elements,
          elements.find((e) =>
            item.type === "conditional"
              ? e.source === item.id && e.data.status === "done"
              : e.source === item.id
          ),
          branchTime
        );
      }
    }
  }
}

function graphCheckBranchTime(
  elements,
  createdAt,
  actualDay,
  item = {},
  branchTimeStart = 0,
  doneTimeStart = 0,
  donePreventTimeStart = 0,
  task = {}
) {
  let branchTime = branchTimeStart;
  let donePreventTime = donePreventTimeStart;
  let doneTime = doneTimeStart;

  ////SE FOR ARESTA
  if (item.source) {
    return graphCheckBranchTime(
      elements,
      createdAt,
      actualDay,
      elements.find((el) => el.id === item.target),
      branchTime,
      doneTime,
      donePreventTime,
      task
    );
  }
  ///////SE FOR NO
  else {
    if (item.type === "task" || item.type === "timerEvent") {
      if (item.data.status === "done") {
        const verificationDone = moment(item.data.finishedAt)
          .startOf("day")
          .isSameOrBefore(
            moment(createdAt).add(actualDay, "days").startOf("day")
          );

        if (item.type === "timerEvent") {
          if (verificationDone) {
            donePreventTime += item.data.expiration.number;
            doneTime += item.data.expiration.number;
          } else branchTime += item.data.expiration.number;
        } else {
          if (verificationDone) {
            donePreventTime += item.data.expiration.number;
            doneTime += moment(item.data.finishedAt).diff(
              moment(item.data.startedAt),
              "hours",
              true
            );
          } else {
            branchTime += item.data.expiration.number;
            return graphCheckBranchTime(
              elements,
              createdAt,
              actualDay,
              elements.find((e) => e.source === item.id),
              branchTime,
              doneTime,
              donePreventTime,
              item
            );
          }
        }
      }
      if (item.data.status === "pending") {
        branchTime += item.data.expiration.number;
      }
      if (item.data.status === "doing") {
        branchTime += item.data.expiration.number;

        if (
          moment(item.data.startedAt)
            .startOf("day")
            .isSameOrBefore(
              moment(createdAt).add(actualDay, "days").startOf("day")
            )
        ) {
          return graphCheckBranchTime(
            elements,
            createdAt,
            actualDay,
            elements.find((e) => e.source === item.id),
            branchTime,
            doneTime,
            donePreventTime,
            item
          );
        }
      }

      return graphCheckBranchTime(
        elements,
        createdAt,
        actualDay,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        task
      );
    } else if (item.type === "parallel" || item.type === "conditional") {
      const branchCount = graphBranchCountTime(
        elements,
        createdAt,
        actualDay,
        item
      );

      branchTime += branchCount.max;
      doneTime += branchCount.maxDone;
      donePreventTime += branchCount.maxPreventDone;

      return graphCheckBranchTime(
        elements,
        createdAt,
        actualDay,
        elements.find((e) => e.source === branchCount.lastItem.id),
        branchTime,
        doneTime,
        donePreventTime,
        branchCount.task
      );
    } else if (item.type === "parallelEnd" || item.type === "conditionalEnd") {
      return {
        branchTime,
        lastItem: item,
        doneTime,
        donePreventTime,
        task,
      };
    }
    /// SE NAO FOR NADA DISSO SÓ CONTINUA ANDANDO
    else
      return graphCheckBranchTime(
        elements,
        createdAt,
        actualDay,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        item
      );
  }
}

function graphBranchCountTime(elements, createdAt, actualDay, item = {}) {
  let max = 0;
  let maxDone = 0;
  let task;
  let maxPreventDone = 0;

  const allBranchs = elements.filter((e) => e.source === item.id);

  const times = allBranchs.map((e) => {
    const obj = graphCheckBranchTime(elements, createdAt, actualDay, e);

    return obj;
  });

  times.forEach((el, index) => {
    if (index + 1 <= times.length) {
      if (el.branchTime >= max) {
        task = el.task;
      }

      max = Math.max(el.branchTime, max);
      maxDone = Math.max(el.doneTime, maxDone);
      maxPreventDone = Math.max(el.donePreventTime, maxPreventDone);
    }
  });

  return { max, lastItem: times[0].lastItem, maxDone, maxPreventDone, task };
}

function graphCountTime(
  elements,
  createdAt,
  actualDay,
  item = { type: "eventEnd" },
  branchTimeStart = 0,
  doneTimeStart = 0,
  donePreventTimeStart = 0,
  task = {}
) {
  let branchTime = branchTimeStart;
  let donePreventTime = donePreventTimeStart;
  let doneTime = doneTimeStart;

  if (item.source) {
    return graphCountTime(
      elements,
      createdAt,
      actualDay,
      elements.find((el) => el.id === item.target),
      branchTime,
      doneTime,
      donePreventTime,
      task
    );
  } else {
    if (item.type === "task" || item.type === "timerEvent") {
      if (item.data.status === "done") {
        const verificationDone = moment(item.data.finishedAt)
          .startOf("day")
          .isSameOrBefore(
            moment(createdAt).add(actualDay, "days").startOf("day")
          );

        if (item.type === "timerEvent") {
          if (verificationDone) {
            donePreventTime += item.data.expiration.number;
            doneTime += item.data.expiration.number;
          } else branchTime += item.data.expiration.number;
        } else {
          if (verificationDone) {
            donePreventTime += item.data.expiration.number;
            doneTime += moment(item.data.finishedAt).diff(
              moment(item.data.startedAt),
              "hours",
              true
            );
          } else {
            branchTime += item.data.expiration.number;

            if (Object.keys(task).length === 0) {
              return graphCountTime(
                elements,
                createdAt,
                actualDay,
                elements.find((e) => e.source === item.id),
                branchTime,
                doneTime,
                donePreventTime,
                item
              );
            }
          }
        }
      } else if (item.data.status === "doing") {
        branchTime += item.data.expiration.number;

        if (
          moment(item.data.startedAt)
            .startOf("day")
            .isSameOrBefore(
              moment(createdAt).add(actualDay, "days").startOf("day")
            )
        ) {
          return graphCountTime(
            elements,
            createdAt,
            actualDay,
            elements.find((e) => e.source === item.id),
            branchTime,
            doneTime,
            donePreventTime,
            item
          );
        }
      } else if (item.data.status === "pending") {
        branchTime += item.data.expiration.number;
      }
      return graphCountTime(
        elements,
        createdAt,
        actualDay,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        task
      );
    } else {
      if (item.type === "eventEnd") {
        highTask = task;

        return { branchTime, doneTime, donePreventTime };
      } else if (
        item.type === "parallel" ||
        (item.type === "conditional" && item.data.status !== "done")
      ) {
        /////VERIFICA O MAIOR CAMINHO E RETORNA O ID FINAL
        const finishBranchItem = graphBranchCountTime(
          elements,
          createdAt,
          actualDay,
          item
        );
        branchTime += finishBranchItem.max;
        doneTime += finishBranchItem.maxDone;
        donePreventTime += finishBranchItem.maxPreventDone;

        return graphCountTime(
          elements,
          createdAt,
          actualDay,
          elements.find((e) => e.source === finishBranchItem.lastItem.id),
          branchTime,
          doneTime,
          donePreventTime,
          finishBranchItem.task
        );
      } else {
        return graphCountTime(
          elements,
          createdAt,
          actualDay,
          elements.find((e) =>
            item.type === "conditional"
              ? e.source === item.id && e.data.status === "done"
              : e.source === item.id
          ),
          branchTime,
          doneTime,
          donePreventTime,
          task
        );
      }
    }
  }
}

const StatsModal = ({ change, open, flow }) => {
  const nowLocal = moment().utcOffset(-180);
  const history = useHistory();
  const user = useSelector((state) => state.auth.user);
  const [stats, setStats] = useState(false);

  const handleCompletedFlow = () => {
    return Math.round(
      ((stats.stats[0].y - stats.graphStats[stats.graphStats.length - 1].y) /
        stats.stats[0].y) *
        100
    );
  };

  const handleProductivity = (elements) => {
    let ideal = 0;
    let real = 0;

    elements.forEach((item) => {
      if (item.type === "task") {
        if (item.data.status === "done") {
          real += moment(item.data.finishedAt).diff(
            moment(item.data.startedAt),
            "hours",
            true
          );
          ideal += item.data.expiration.number;
        }
        if (item.data.status === "doing") {
          if (
            moment(item.data.startedAt)
              .add(item.data.expiration.number, "hours")
              .diff(nowLocal) < 0
          ) {
            ideal += item.data.expiration.number;
            real += moment(nowLocal).diff(
              moment(item.data.startedAt),
              "hours",
              true
            );
          } else if (item.data.subtasks.length > 0) {
            real +=
              (item.data.subtasks.filter((it) => it.checked === true).length /
                item.data.subtasks.length) *
              item.data.expiration.number;
            ideal += moment(nowLocal).diff(
              moment(item.data.startedAt),
              "hours",
              true
            );
          }
        }
      }
    });

    return { ideal, real };
  };

  const [percentage, setPercentage] = useState(false);
  const [productivity, setProductivity] = useState(false);

  useEffect(() => {
    if (flow && open === true) {
      const start = flow && flow.elements.find((e) => e.type === "eventStart");
      const flowFullTime = flow && flowTime(flow.elements, start);

      advancedStats = [
        {
          x: 0,
          y: flowFullTime?.branchTime / 24,
          taskTitle: "INÍCIO",
          start: flow?.createdAt,
          finish: flow?.createdAt,
          time: 0,
        },
      ];

      let stats = flow && count(flow.elements, start);

      stats.stats.push({
        x: flowFullTime.branchTime / 24,
        y: 0.00001,
        taskTitle: "FIM",
        start: stats.stats[stats.stats.length - 1].finished,
        finish: stats.stats[stats.stats.length - 1].finished,
        time: 0,
      });

      if (flow?.status[0] === "finished") {
        stats.realStats.push({
          x: stats.realStats[stats.realStats.length - 1].x,
          y: 0,
          taskTitle: "FIM",
          finished: flow?.finishedAt,
          start: flow?.finishedAt,
          time: 0,
        });
      }

      let realStats = [
        {
          x: 0,
          y: flowFullTime.branchTime / 24,
          doneTasks: [{ data: { label: "INÍCIO" } }],
        },
      ];
      let timeB = 0;
      let value = -1;

      if (flow.status[0] !== "finished") {
        const actualDay = moment(nowLocal)
          .startOf("day")
          .diff(moment(flow.createdAt).utcOffset(-180).startOf("day"), "days");

        for (let i = 0; i < actualDay; i++) {
          const graphTime = graphCountTime(
            flow.elements,
            flow.createdAt,
            i,
            start
          );

          if (i === 0) timeB = graphTime.branchTime;

          const actualDate = moment(flow.createdAt)
            .utcOffset(-180)
            .startOf("day")
            .add(i, "days");

          const doneTasks = flow.elements.filter(
            (it) =>
              it.data.status === "done" &&
              (it.type === "task" || it.type === "timerEvent") &&
              moment(it.data.finishedAt).startOf("day").format("lll") ===
                moment(actualDate).startOf("day").format("lll")
          );

          if (
            moment(highTask?.data?.startedAt)
              .add(highTask?.data?.expiration?.number, "hours")
              .diff(
                moment(flow.createdAt)
                  .utcOffset(-180)
                  .startOf("day")
                  .add(i, "days"),
                "days"
              ) >= 1
          ) {
            value += 1;

            if (i !== 0 || (i === 0 && doneTasks.length > 0))
              realStats.push({
                x: i,
                y: (timeB - 24 * value) / 24,
                doneTasks,
              });
          } else {
            realStats.push({
              x: i,
              y: (timeB - 24 * value) / 24,
              doneTasks,
            });
          }
        }
      } else {
        const actualDay = moment(flow.finishedAt)
          .startOf("day")
          .diff(moment(flow.createdAt).utcOffset(-180).startOf("day"), "days");

        for (let i = 0; i < actualDay + 1; i++) {
          const graphTime = graphCountTime(
            flow.elements,
            flow.createdAt,
            i,
            start
          );

          if (i === 0) timeB = graphTime.branchTime;

          const actualDate = moment(flow.createdAt)
            .utcOffset(-180)
            .startOf("day")
            .add(i, "days");

          const doneTasks = flow.elements.filter(
            (it) =>
              it.data.status === "done" &&
              (it.type === "task" || it.type === "timerEvent") &&
              moment(it.data.finishedAt).startOf("day").format("lll") ===
                moment(actualDate).startOf("day").format("lll")
          );

          if (doneTasks.length > 0)
            realStats.push({
              x: i,
              y: graphTime.branchTime / 24,
              doneTasks,
            });
        }
      }

      setStats({ ...stats, graphStats: realStats });
    }
  }, [open]);

  useEffect(() => {
    if (stats) {
      setPercentage(
        flow?.status[0] === "finished" ? 100 : handleCompletedFlow()
      );
      setProductivity(handleProductivity(flow.elements));
    }
  }, [stats]);

  return open === true && productivity !== false && percentage !== false ? (
    <Modal
      onClose={() => change(false)}
      onOpen={() => change(true)}
      open={open}
    >
      <Modal.Header
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
        }}
      >
        ESTATÍSTICAS DE {flow.title.toUpperCase()}
        {user.rank === "Gerente" && (
          <Button
            content="Abrir estatísticas avançadas"
            icon="line graph"
            color="green"
            labelPosition="left"
            onClick={() => {
              history.push("/advancedstats/" + flow._id);
            }}
          />
        )}
      </Modal.Header>

      <Modal.Content image>
        <div style={{ paddingRight: "20px" }}>
          <CircularProgressbarWithChildren value={percentage}>
            {}
            <div style={{ fontSize: 26, margin: 40, textAlign: "center" }}>
              <strong>Você concluiu {percentage}% do fluxo</strong>
            </div>
          </CircularProgressbarWithChildren>
        </div>
        <Modal.Description style={{ alignSelf: "center" }}>
          {flow.status[0] !== "finished" ? (
            <Label>
              Previsão de conclusão do restante do fluxo:{" "}
              {Math.ceil(stats.branchTime / 24)} dias
            </Label>
          ) : null}

          {stats.doneTime > 0 ? (
            <Segment>
              <Header style={{ backgroundColor: "white" }}>
                Análise de progresso
              </Header>
              <Progress
                percent={Math.round(
                  (productivity.ideal / productivity.real) * 100
                )}
                indicating
                content={`Sua produtividade está em ${Math.round(
                  (productivity.ideal / productivity.real) * 100
                )}%`}
              />
              <Label style={{ marginTop: "10px" }}>
                Andamento do fluxo:{" "}
                {flow?.status[0] !== "finished"
                  ? stats.graphStats[stats.graphStats.length - 1].x === 0 ||
                    !stats.graphStats[stats.graphStats.length - 1].x
                    ? "Iniciado"
                    : stats.stats[0].y -
                        stats.graphStats[stats.graphStats.length - 1].y >
                      stats.graphStats[stats.graphStats.length - 1].x
                    ? "Adiantado"
                    : stats.stats[0].y -
                        stats.graphStats[stats.graphStats.length - 1].y ===
                      stats.graphStats[stats.graphStats.length - 1].x
                    ? "Em dia"
                    : "Atrasado"
                  : "Finalizado"}
              </Label>
            </Segment>
          ) : null}
        </Modal.Description>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => change(false)} content="Voltar" />
      </Modal.Actions>
    </Modal>
  ) : null;
};

export default StatsModal;
