import React, { useState, useRef, useEffect, useCallback } from "react";
import {
  Controls,
  ReactFlowProvider,
  Background,
  useStoreActions,
  MiniMap,
  useStoreState,
  useZoomPanHelper,
} from "react-flow-renderer";
import Sidebar from "../../components/Sidebar/index";

import { v4 as uuid } from "uuid";
import ConditionalEndNode from "../../components/ConditionalEndNode/index";
import ParallelNode from "../../components/ParallelNode/index";
import ParallelEndNode from "../../components/ParallelEndNode/index";
import EventStartNode from "../../components/EventStartNode/index";
import EventEndNode from "../../components/EventEndNode/index";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  handleFetchSingleFlow,
  handleNewVersionFlow,
} from "../../state/flows/actions";
import { findFlowAndVersion } from "../../state/flows/selectors";
import CustomEdgeWithoutLabel from "../../components/CustomEdgeWithoutLabel/index";
import { Input } from "semantic-ui-react";
import CustomConnectionLine from "../../components/CustomConnectionLine/index";
import TimerEventNode from "../../components/TimerEventNode/index";
import { DndFlow, ReactFlowWithValidation, ReactFlowWrapper } from "./styles";
import Loading from "../../components/LoadingTemplate/index";
import { useHotkeys } from "react-hotkeys-hook";
import {
  clearUndoFlow,
  updateAddEdge,
  updateDrop,
  updateFlow,
  updateRemove,
} from "../../state/undoFlow/actions";
import { ActionCreators } from "redux-undo";
import { selectUndoFlow } from "../../state/undoFlow/selectors";
import ConditionalNode from "../../components/ConditionalNode";
import TaskNode from "../../components/TaskNode";
import CustomMark from "../../components/CustomMark";
import CustomText from "../../components/CustomText";
import CustomEdge from "../../components/CustomEdge";
import { useKey } from "rooks"; //Arrows

const ChangePosition = ({ off, xy }) => {
  const { transform } = useZoomPanHelper();

  const state = useStoreState((state) => state);
  const x = state.transform[0];
  const y = state.transform[1];
  const z = state.transform[2];

  useEffect(() => {
    transform({ x: x + xy.x, y: y + xy.y, zoom: z + xy.z });
    off();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

const NodesSelected = ({ off, elements }) => {
  const setSelectedElements = useStoreActions(
    (actions) => actions.setSelectedElements
  );

  useEffect(() => {
    setSelectedElements(elements.map((node) => ({ id: node.id })));
    off();
  }, []);

  return null;
};

const FlowNewVersionPage = ({ location }) => {
  const params = new URLSearchParams(location.search);
  const flow = useSelector((state) =>
    findFlowAndVersion(
      state.flows.entities,
      params.get("flowId"),
      params.get("version")
    )
  );

  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const history = useHistory();
  const flowId = params.get("flowId");
  const reactFlowWrapper = useRef(null);
  const [reactFlowapi, setReactFlowapi] = useState(null);
  const flowStatus = useSelector((state) => state.flows.status);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const [count, setCount] = useState(1);
  const [flowValidation, setFlowValidation] = useState("empty");
  const [versionNumber, setVersionNumber] = useState("");
  const [loading, setLoading] = useState(false);

  // copy/past hotkey
  const [selected, setSelected] = useState(null);
  const [copied, setCopied] = useState(null);

  //Selectable Component
  const [isSelectable, setIsSelectable] = useState(false);
  const [selectableFlow, setSelectableFlow] = useState(null);

  //Redux Undo
  const stateUndoFlow = useSelector((state) => state.undoFlow);
  const undoFlow = useSelector(selectUndoFlow);
  //Arrows controler
  const [arrowFlag, setArrowFlag] = useState(false);
  const [coordinates, setCoordinates] = useState(false);

  useEffect(
    () => () => {
      //unmount
      dispatch(clearUndoFlow());
      dispatch(ActionCreators.clearHistory());
    },
    []
  );

  useEffect(() => {
    if (flowStatus === "idle")
      dispatch(
        handleFetchSingleFlow({
          enterpriseId: !user.enterpriseId ? user?._id : user.enterpriseId,
          flowId: flow?._id,
        })
      ); // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (flow) {
      dispatch(updateFlow(JSON.parse(JSON.stringify(flow.elements))));
      dispatch(ActionCreators.clearHistory());
    }
  }, [flow]);

  useEffect(() => {
    if (!token) history.push("/login");
  }, [history, token]);

  const nodeTypes = {
    conditional: ConditionalNode,
    conditionalEnd: ConditionalEndNode,
    parallel: ParallelNode,
    parallelEnd: ParallelEndNode,
    task: TaskNode,
    eventStart: EventStartNode,
    eventEnd: EventEndNode,
    timerEvent: TimerEventNode,
    customMark: CustomMark,
    customText: CustomText,
  };
  const edgeTypes = {
    customEdge: CustomEdge,
    customEdgeWithoutLabel: CustomEdgeWithoutLabel,
  };

  const onLoad = (_reactFlowapi) => setReactFlowapi(_reactFlowapi);

  const handleSelectionChange = (elements) => setSelected(elements);

  const concatIdEdge = (id1, id2, sourceH, targetH) => {
    return "reactflow__edge-" + id1 + sourceH + "-" + id2 + targetH;
  };

  const randomPosition = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const handlePasteFlow = useCallback(
    () => {
      const finalFlow = JSON.parse(JSON.stringify(copied));
      const initialFlow = JSON.parse(JSON.stringify(copied));
      const APIelements = JSON.parse(
        JSON.stringify(reactFlowapi.getElements())
      );

      finalFlow.forEach((item) => (item.lastId = item.id));

      const max = 250;
      const min = 50;
      let x = randomPosition(min, max);
      let y = randomPosition(min, max);

      initialFlow.forEach((el, i) => {
        let id_source = uuid();
        let id_target = uuid();
        //Se for aresta

        if (el.source && !el.visited) {
          //Caso o encontrou o nó correspondente ao source / target da aresta
          let node_source = initialFlow.find((item) => item.id === el.source);
          let node_target = initialFlow.find((item) => item.id === el.target);

          // Caso não tenha sido visitado e o nó source exista
          if (!node_source.isVisited) {
            //Setando true no nó source
            initialFlow.find((item) => item.id === el.source).isVisited = true;

            //Trocando os valores dos ids
            finalFlow.find((item) => item.id === el.source).id = id_source;
            finalFlow[i].source = id_source;
          } else {
            //Caso encontre um nó source ja visitado
            finalFlow[i].source = finalFlow.find(
              (item) => item.lastId === el.source
            ).id;
          }
          if (!node_target.isVisited) {
            //Setando true no nó target
            initialFlow.find((item) => item.id === el.target).isVisited = true;

            //Trocando os valores dos ids
            finalFlow.find((item) => item.id === el.target).id = id_target;
            finalFlow[i].target = id_target;
          } else {
            //Caso encontre um nó target ja visitado
            finalFlow[i].target = finalFlow.find(
              (item) => item.lastId === el.target
            ).id;
          }
          //No fim gera um novo id baseado na concatenação do source e target
          finalFlow[i].id = concatIdEdge(
            finalFlow[i].source,
            finalFlow[i].target,
            finalFlow[i].sourceHandle,
            finalFlow[i].targetHandle
          );
          el.isVisited = true;
        }
      });
      //Caso haja nós soltos, conditional edges e deletando o lastId..
      finalFlow.forEach((el) => {
        if (!el.source) {
          el.position.x += x;
          el.position.y += y;
        }
        if (el.lastId === el.id) el.id = uuid();
        delete el.lastId;
      });

      //Somente Marcadores
      let markElements = finalFlow.filter((item) => item.type === "customMark");

      //Se houver Marcadores
      if (markElements.length > 0) {
        markElements.forEach((item) => (item.data.draggable = true));

        //Elementos sem marcadores
        let Elements = finalFlow.filter((item) => item.type !== "customMark");

        //API Elements
        let actualElements = JSON.parse(
          JSON.stringify(reactFlowapi?.getElements())
        );

        let insertIndex = actualElements.findIndex(
          (item) => item.type !== "customMark"
        );

        //insertIndex = -1 -> não há nós comuns entre os elements
        //insertIndex > 0  -> posição que reflete um nó comum de elements
        if (insertIndex < 0) {
          actualElements.splice(actualElements.length, 0, ...markElements);
        } else {
          actualElements.splice(insertIndex, 0, ...markElements);
        }
        let elementsWithMark = actualElements;
        let totalFlow = elementsWithMark.concat(Elements);
        dispatch(updateFlow(totalFlow));
      } else {
        dispatch(updateFlow(APIelements.concat(finalFlow)));
      }

      setSelectableFlow(finalFlow);
      setIsSelectable(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [copied]
  );

  // Validação necessária caso ocorra a aparição de arestas avulsas selecionadas
  function copyValidation(flowElements) {
    let isSource;
    let isTarget;
    let position = [];

    flowElements.forEach((el, i) => {
      if (el.source) {
        isSource = flowElements.find((item) => item.id === el.source);
        isTarget = flowElements.find((item) => item.id === el.target);

        if (!isSource || !isTarget) {
          position.push(i);
        }
      }
    });

    position.forEach((item) => {
      delete flowElements[item];
    });

    return flowElements;
  }

  //Deleta ids de referencia (backend) dos elementos copiados
  function filterElementId(flowElements) {
    flowElements.forEach((el) => {
      if (el._id) {
        delete el._id;
        delete el.flowId;
      }
    });
    return flowElements;
  }

  const handleCopy = () => {
    let reactFlowElements = JSON.parse(
      JSON.stringify(reactFlowapi?.getElements())
    );
    setCopied(null);
    let selectedElements = JSON.parse(JSON.stringify(selected));
    selectedElements = copyValidation(selectedElements);

    selectedElements = filterElementId(selectedElements);
    reactFlowElements = filterElementId(reactFlowElements);

    //Filtrando a lista selecionada para considerar as posições
    //corretas e os  handles equivalentes pela lista da API

    let filtered = reactFlowElements.filter((el) =>
      selectedElements.some((item) => el.id === item.id)
    );
    setCopied(JSON.parse(JSON.stringify(filtered)));
    //}
  };

  const handlePaste = () => {
    if (copied) {
      handlePasteFlow();
    }
  };

  const undoOperation = () => dispatch(ActionCreators.undo());
  const redoOperation = () => dispatch(ActionCreators.redo());

  useHotkeys(
    "ctrl+c",
    () => (selected ? handleCopy() : null),
    { keydown: true },
    [selected]
  );
  useHotkeys("ctrl+v", handlePaste, { keydown: true }, [copied]);
  useHotkeys("ctrl+z", undoOperation, { keydown: true }, [undoFlow]);
  useHotkeys("ctrl+y", redoOperation, { keydown: true }, [undoFlow]);

  //  Movendo vários itens selecionados
  const onNodeSelectDrag = (e, nodes) => {
    let positionElements = reactFlowapi?.getElements();
    positionElements.forEach((item) => {
      nodes.forEach((n) => {
        if (n.id === item.id) {
          item.position = n.position;
        }
      });
    });

    dispatch(updateFlow(positionElements));
  };
  //  Movendo um item selecionado
  const onNodeSingleDrag = (e, node) => {
    let positionElements = reactFlowapi?.getElements();

    positionElements.forEach((item) => {
      if (item.id === node.id) {
        item.position = node.position;
      }
    });

    dispatch(updateFlow(positionElements));
  };

  useEffect(() => {
    if (!token) history.push("/login");
  }, [history, token]);

  const handleValidation = (elements) => {
    let validation = "empty";

    ///SE TIVER VAZIO
    if (elements.length === 0) validation = "Fluxo vazio";
    ////SE TEM EVENTO DE INÍCIO E EVENTO DE FIM
    else if (!elements.find((e) => e.type === "eventStart"))
      validation = "Falta evento de início";
    else if (!elements.find((e) => e.type === "eventEnd"))
      validation = "Falta evento de fim";
    ///SE EXISTE O MESMO NÚMERO DE INÍCIOS E FINS DE PARALELOS
    else {
      let condStart = 0;
      let condEnd = 0;
      let paraStart = 0;
      let paraEnd = 0;

      elements.forEach((e) => {
        if (e.type === "conditional") condStart += 1;
        if (e.type === "conditionalEnd") condEnd += 1;
        if (e.type === "parallel") paraStart += 1;
        if (e.type === "parallelEnd") paraEnd += 1;
      });

      if (condStart !== condEnd)
        if (condStart > condEnd) validation = "Falta fechar condicional";
        else validation = "Falta abrir condicional";
      else if (paraStart !== paraEnd)
        if (paraStart > paraEnd) validation = "Falta fechar paralelo";
        else validation = "Falta abrir paralelo";
    }
    //////VERIFICAR SE TODOS OS NÓS ESTÃO LIGADOS
    if (validation === "empty")
      elements.forEach((item) => {
        if (!item.source) {
          if (item.type === "eventStart") {
            if (!elements.find((e) => e.source === item.id))
              validation = "Falta ligar componentes";
          } else if (item.type === "eventEnd") {
            if (!elements.find((e) => e.target === item.id))
              validation = "Falta ligar componentes";
          } else {
            if (!elements.find((e) => e.target === item.id)) {
              validation = "Falta ligar componentes";
            }
            if (!elements.find((e) => e.source === item.id)) {
              validation = "Falta ligar componentes";
            }
          }
        }
      });

    return validation;
  };

  const onConnect = (params) => {
    if (undoFlow.find((e) => e.id === params.source).type === "conditional") {
      dispatch(
        updateAddEdge({
          params: {
            ...params,
            type: "customEdge",
            data: { text: `Etiqueta ${count}`, status: "pending" },
          },
        })
      );
      setCount((e) => e + 1);
    } else {
      dispatch(
        updateAddEdge({
          params: {
            ...params,
            type: "customEdgeWithoutLabel",
            data: { status: "pending" },
          },
        })
      );
    }
  };
  const onElementsRemove = (elementsToRemove) => {
    dispatch(updateRemove(elementsToRemove));
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  //Dropar elementos da sidebar na mesa
  const onDrop = (event) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");
    const position = reactFlowapi.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });

    let newNode = {};

    switch (type) {
      case "customText":
        newNode = {
          id: uuid(),
          type,
          position,
          data: {
            label: "CAIXA DE TEXTO",
            status: "model",
            hexColor: "#111111",
            fontSize: 16,
            draggable: true,
          },
        };
        break;
      case "customMark":
        newNode = {
          id: uuid(),
          type,
          position,
          data: {
            label: "Marcador",
            status: "model",
            hexColor: "#47977f6f",
            draggable: true,
            refData: {
              width: 300,
              height: 100,
            },
          },
        };
        break;
      default:
        newNode = {
          id: uuid(),
          type,
          position,
          data: {
            label:
              type === "conditional"
                ? "Início da condicional"
                : type === "eventEnd"
                ? "Evento de fim"
                : type === "eventStart"
                ? "Evento de início"
                : type === "task"
                ? "Tarefa"
                : type === "conditionalEnd"
                ? "Fim da condicional"
                : type === "parallel"
                ? "Paralelo"
                : type === "parallelEnd"
                ? "Fim do paralelo"
                : type === "timerEvent"
                ? "Temporizador"
                : null,
            status: "model",
            expiration: {
              number: 5 * 24,
              time: "days",
            },
            subtasks: [],
          },

          targetPosition: "left",
          sourcePosition: "right",
        };
    }
    if (type !== "") {
      dispatch(updateDrop({ newNode }));
    }
  };

  //? Implementando comandos por Teclado
  function ArrowLeftEnter(e) {
    setCoordinates({ x: 10, y: 0, z: 0 });
    setArrowFlag(true);
  }
  function ArrowRightEntered(e) {
    setCoordinates({ x: -10, y: 0, z: 0 });
    setArrowFlag(true);
  }
  function ArrowUpEntered(e) {
    setCoordinates({ x: 0, y: 10, z: 0 });
    setArrowFlag(true);
  }
  function ArrowDownEntered(e) {
    setCoordinates({ x: 0, y: -10, z: 0 });
    setArrowFlag(true);
  }
  useKey(["ArrowLeft"], ArrowLeftEnter);
  useKey(["ArrowRight"], ArrowRightEntered);
  useKey(["ArrowUp"], ArrowUpEntered);
  useKey(["ArrowDown"], ArrowDownEntered);

  return flowStatus === "succeeded" && !loading ? (
    <DndFlow>
      <Sidebar />
      <ReactFlowProvider>
        <ReactFlowWrapper ref={reactFlowWrapper}>
          <ReactFlowWithValidation
            className="nodrag"
            elementsSelectable={true}
            minZoom={0}
            deleteKeyCode="Delete"
            connectionLineComponent={CustomConnectionLine}
            elements={undoFlow}
            onConnect={onConnect}
            onSelectionChange={(element) => {
              handleSelectionChange(element);
            }}
            onElementsRemove={onElementsRemove}
            onLoad={onLoad}
            onDrop={onDrop}
            onDragOver={onDragOver}
            nodeTypes={nodeTypes}
            edgeTypes={edgeTypes}
            multiSelectionKeyCode="Control"
            onNodeDragStop={onNodeSingleDrag}
            onSelectionDragStop={onNodeSelectDrag}
          >
            {isSelectable && (
              <NodesSelected
                off={() => setIsSelectable(false)}
                elements={selectableFlow}
              />
            )}
            {
              arrowFlag && (
                <ChangePosition
                  off={() => setArrowFlag(false)}
                  xy={coordinates}
                />
              ) // ponte entre as funcions e state do ReactFlowProvider
            }
            <MiniMap
              style={{ background: "#aaa" }}
              nodeColor={(node) => {
                if (node.type === "customMark") {
                  return node.data.hexColor;
                } else {
                  return "#fff";
                }
              }}
            />
            <Controls />
            <Background />
          </ReactFlowWithValidation>
        </ReactFlowWrapper>
        <aside>
          <div className="column">
            <h3 style={{ userSelect: "none", cursor: "default" }}>Título: </h3>
            <Input
              placeholder="Número da versão"
              draggable="false"
              value={versionNumber}
              onChange={(e) => {
                const pointer = e.target.selectionStart;

                window.requestAnimationFrame(() => {
                  e.target.selectionStart = pointer;
                  e.target.selectionEnd = pointer;
                });

                setVersionNumber(e.target.value);
              }}
            />
            <p style={{ color: "red", userSelect: "none", cursor: "default" }}>
              {error === "" ? null : error}
            </p>
            <p style={{ color: "red", userSelect: "none", cursor: "default" }}>
              {flowValidation !== "empty" && flowValidation}
            </p>
            <button
              className="ui button"
              onClick={() => {
                if (flow.title === "") {
                  return setError("Necessário preencher o número da versão");
                } else {
                  const validation = handleValidation(
                    reactFlowapi
                      .getElements()
                      .filter(
                        (el) =>
                          el.type !== "customMark" && el.type !== "customText"
                      )
                  );

                  if (validation !== "empty") setFlowValidation(validation);
                  else {
                    setFlowValidation("empty");

                    let newElements = JSON.parse(
                      JSON.stringify(reactFlowapi.getElements())
                    );

                    newElements.forEach(function (v) {
                      delete v._id;
                    });

                    setLoading(true);
                    return dispatch(
                      handleNewVersionFlow(
                        newElements,
                        versionNumber,
                        flow.title,
                        flowId,
                        !user.enterpriseId ? user?._id : user.enterpriseId
                      )
                    ).then(() => {
                      history.push("/");
                      setLoading(false);
                    });
                  }
                }
              }}
            >
              Salvar nova versão
            </button>
          </div>
        </aside>
      </ReactFlowProvider>
    </DndFlow>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

export default FlowNewVersionPage;
