import React from "react";

const CloseRed = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 8 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.19336 1.33594L6.84082 6.98339"
        stroke="#CD5959"
        strokeWidth="0.752994"
        strokeLinecap="round"
      />
      <path
        d="M6.84082 1.33594L1.19336 6.98339"
        stroke="#CD5959"
        strokeWidth="0.752994"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default CloseRed;
