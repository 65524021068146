import React from "react";

const MarkIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask id="path-1-inside-1_707_18464" fill="white">
        <rect
          x="0.900879"
          y="2.46094"
          width="19.7781"
          height="11.8669"
          rx="0.941243"
        />
      </mask>
      <rect
        x="0.900879"
        y="2.46094"
        width="19.7781"
        height="11.8669"
        rx="0.941243"
        stroke="#7E858F"
        strokeWidth="2.63548"
        mask="url(#path-1-inside-1_707_18464)"
      />
      <path
        d="M10.79 0.482422V16.3049"
        stroke="#7E858F"
        strokeWidth="0.941243"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default MarkIcon;
