import React, { memo } from "react";
import { Handle, useStoreState, Position } from "react-flow-renderer";
import { EventStart } from "./styles.js";
import { Clock } from "react-feather";

const TimerEventNode = ({
  data,
  targetPosition = Position.Left,
  sourcePosition = Position.Right,
  selected,
}) => {
  const edges = useStoreState((state) => state.edges);
  const nodes = useStoreState((state) => state.nodes);

  return (
    <EventStart status={data.status} selected={selected}>
      <Clock size={20} />
      <Handle
        type="source"
        position={sourcePosition}
        id="a"
        style={{ width: "8px", height: "8px" }}
        isValidConnection={(e) =>
          nodes.find((item) => item.id === e.target).type === "parallelEnd" ||
          nodes.find((item) => item.id === e.target).type === "conditionalEnd"
            ? !edges.find((item) => item.source === e.source)
            : !edges.find(
                (item) => item.source === e.source || item.target === e.target
              )
        }
      />
      <Handle
        type="target"
        position={targetPosition}
        id="a"
        style={{ width: "8px", height: "8px" }}
        isValidConnection={(e) =>
          nodes.find((item) => item.id === e.source).type === "parallel" ||
          nodes.find((item) => item.id === e.source).type === "conditional"
            ? true
            : !edges.find(
                (item) => item.target === e.target || item.source === e.source
              )
        }
      />
    </EventStart>
  );
};

export default memo(TimerEventNode);
