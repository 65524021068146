import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Modal, Input, Table, Dropdown } from "semantic-ui-react";
import { handleAddActivedFlow } from "../../../state/activedFlows/actions";

const NewActivedFlowModal = ({ open, change, setLoading }) => {
  const dispatch = useDispatch();
  const flows = useSelector((state) => state.flows.entities);
  const flowsStatus = useSelector((state) => state.flows.status);
  const [state, setState] = useState("");
  const [error, setError] = useState("");
  const [selected, setSelected] = useState({ index: null, item: null });
  const user = useSelector((state) => state.auth.user);
  const [options, setOptions] = useState();
  const [version, setVersion] = useState();
  const [flow, setFlow] = useState();

  useEffect(() => {
    if (selected.item) {
      setOptions([{ key: "default", value: "default", text: "Original" }]);

      selected.item.versions.forEach((item) => {
        setOptions((prev) => [
          ...prev,
          {
            key: item.versionNumber,
            value: item.versionNumber,
            text: item.versionNumber,
          },
        ]);
      });

      if (selected.item.defaultVersion === "default") {
        setFlow(selected.item);
      } else {
        selected.item.versions.forEach((item) => {
          if (item._id === selected.item.defaultVersion) {
            setFlow(item);
          }
        });
      }
    }
  }, [selected]);

  useEffect(() => {
    if (version) {
      if (version === "default") {
        setFlow(selected.item);
      } else {
        selected.item.versions.forEach((els) => {
          if (els.versionNumber === version) {
            setFlow(els);
          }
        });
      }
    }
  }, [version]);

  return flowsStatus === "succeeded" ? (
    <Modal
      onClose={() => change(false)}
      onOpen={() => change(true)}
      open={open}
    >
      <Modal.Header style={{ textAlign: "center" }}>
        ATIVAR NOVO FLUXO
      </Modal.Header>
      <Modal.Content>
        <Input
          placeholder="Insira o nome cliente"
          label="Nome do cliente"
          value={state}
          onChange={(e) => {
            const pointer = e.target.selectionStart;

            window.requestAnimationFrame(() => {
              e.target.selectionStart = pointer;
              e.target.selectionEnd = pointer;
            });

            setState(e.target.value.toUpperCase());
          }}
        />

        <Table celled padded color="yellow" selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell singleLine>
                LISTA DE FLUXOS MODELO
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {flows.map((item, index) => (
              <Table.Row
                active={selected.index === index}
                key={index}
                style={{ cursor: "pointer" }}
                onClick={() => setSelected({ index, item })}
              >
                <Table.Cell style={{ padding: "10px" }}>
                  {item.title.toUpperCase()}
                </Table.Cell>
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <p style={{ color: "red", textAlign: "center" }}>
          {error !== "" ? error : null}
        </p>

        {selected.item && (
          <>
            <p>Qual versão do fluxo deseja ativar?</p>
            <Dropdown
              label="Versão atual"
              labeled
              fluid
              selection
              defaultValue={
                selected.item.defaultVersion
                  ? selected.item.defaultVersion === "default"
                    ? "default"
                    : selected.item.versions.find(
                        (item) => item._id === selected.item.defaultVersion
                      )?.versionNumber
                  : "default"
              }
              options={options}
              value={version}
              onChange={(e, data) => setVersion(data.value)}
            />
          </>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button content="Voltar" color="black" onClick={() => change(false)} />
        <Button
          content="Ativar fluxo"
          positive
          icon="check"
          labelPosition="right"
          onClick={() => {
            if (state === "")
              setError("Necessário preencher o nome do cliente");
            else if (selected.index === null)
              setError("Necessário escolher um modelo de fluxo");
            else {
              setError("");
              setSelected({ index: null, item: null });
              setState("");

              setLoading(true);

              dispatch(
                handleAddActivedFlow(
                  flow,
                  selected.item.title.toUpperCase(),
                  state.toUpperCase(),
                  user.enterpriseId ? user.enterpriseId : user?._id,
                  version
                )
              ).then(() => {
                change(false);
                setLoading(false);
              });
            }
          }}
        />
      </Modal.Actions>
    </Modal>
  ) : null;
};

export default NewActivedFlowModal;
