import DashboardModelPage from "./pages/DashboardModel";
import NotFoundPage from "./pages/NotFound";
import Header from "./components/Header/index";
import React from "react";
import {
  BrowserRouter,
  Route,
  Switch,
  withRouter,
  Redirect,
} from "react-router-dom";
import ActivedFlowsPage from "./pages/DashboardActived/index";
import FinishedFlowsPage from "./pages/DashboardFinished/index";
import FlowEditionPage from "./pages/FlowEdition/index";
import FlowViewerActivedPage from "./pages/FlowViewerActived/index";
import FlowViewerFinishedPage from "./pages/FlowViewerFinished/index";
import FlowViewerModelPage from "./pages/FlowViewerModel/index";
import NewAccountPage from "./pages/NewAccount/index";
import LoginPage from "./pages/Login/index";
import FilterPage from "./pages/Filter/index";
import AdvancedStatsPage from "./pages/AdvancedStats/index";
import DashboardEmployeesPage from "./pages/DashboardEmployees/index";
import FlowCreationPage from "./pages/FlowCreation/index";
import EmployeerStatsPage from "./pages/EmployeerStats/index";
import ForgotPasswordPage from "./pages/ForgotPassword/index";
import ResetPasswordPage from "./pages/ResetPassword/index";
import FlowNewVersionPage from "./pages/FlowNewVersion";
import FlowVersionEditorPage from "./pages/FlowVersionEditor";
import ProfilePage from "./pages/Profile";
import NewAccountSendPage from "./pages/NewAccountSend";
import PaneProvider from "./pages/Playground/Provider";

const IndexRouter = () => {
  return (
    <BrowserRouter>
      <div>
        <Header />
        <Switch>
          <Route exact path="/" component={DashboardModelPage} />
          <Route path="/viewer/:id" component={FlowViewerModelPage} />
          <Route
            path="/activedviewer/:id/:taskId"
            component={FlowViewerActivedPage}
          />
          <Route
            path="/finishedviewer/:id/:taskId"
            component={FlowViewerFinishedPage}
          />
          <Route path="/advancedstats/:id" component={AdvancedStatsPage} />
          <Route path="/activedflows" component={ActivedFlowsPage} />
          <Route path="/finishedflows" component={FinishedFlowsPage} />
          <Route path="/createflow" component={FlowCreationPage} />
          <Route path="/editflow" component={FlowEditionPage} />
          <Route path="/editversionflow" component={FlowVersionEditorPage} />
          <Route path="/newversionflow" component={FlowNewVersionPage} />
          <Route path="/newaccount/:token" component={NewAccountPage} />
          <Route path="/newaccountsend" component={NewAccountSendPage} />
          <Route path="/employees" component={DashboardEmployeesPage} />
          <Route path="/filter" component={FilterPage} />
          <Route path="/forgotpassword" component={ForgotPasswordPage} />
          <Route path="/profile" component={ProfilePage} />
          <Route path="/artur" component={PaneProvider} />
          <Route path="/resetpassword/:token" component={ResetPasswordPage} />
          <Route path="/employeerstats" component={EmployeerStatsPage} />
          <Route exact path="/login" component={LoginPage} />
          <Route path="/404" component={NotFoundPage} />
          <Redirect to="/404" />
        </Switch>
      </div>
    </BrowserRouter>
  );
};

export default withRouter(IndexRouter);
