import React, { useState } from "react";
import PenIcon from "../../../../../assets/icons/Pen";
import Modal from "../../../Modal";
import { Container, Content, Header, Button, DivIcon } from "./styles";
import Input from "../../../Input";
import Deadline from "./Deadline";
import Subtasks from "./Subtasks";
import Check from "./Check";
import Close from "../../../../../assets/icons/Close";
import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { updateTaskNode } from "../../../../../undoFlow/actions";

const TaskModal = ({ isOpen, onClose, selector, className, data, id }) => {
  const [title, setTitle] = useState(data.label);
  const [attachFile, setAttachFile] = useState(data.attachFile);
  const [lockTask, setLockTask] = useState(data.lockTask);
  const [subtasks, setSubtasks] = useState(data.subtasks);
  const [deadline, setDeadLine] = useState(data.expiration.number / 24);

  const dispatch = useDispatch();

  //Solução provisória

  const clearLocalState = () => {
    setTitle(data.label);
    setAttachFile(data.attachFile);
    setLockTask(data.lockTask);
    setSubtasks(data.subtasks);
    setDeadLine(data.expiration.number / 24);
  };

  const taskValidation = () => {
    if (
      data.label === title &&
      data.attachFile === attachFile &&
      data.lockTask === lockTask &&
      data.expiration.number / 24 === deadline &&
      JSON.stringify(data.subtasks) === JSON.stringify(subtasks)
    ) {
      return false;
    } else if (deadline < 1) {
      clearLocalState();
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (taskValidation()) {
      dispatch(
        updateTaskNode({
          id,
          text: title,
          attachFile: attachFile,
          lockTask: lockTask,
          subtasks: subtasks,
          deadline: deadline,
        })
      );
    }
  }, [isOpen]);

  useEffect(() => {
    if (!isOpen) {
      if (taskValidation()) {
        clearLocalState();
      }
    }
  }, [isOpen, data]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      selector={selector}
      className={className}
    >
      <Container>
        <Content>
          <Header>
            <PenIcon />
            <span>Editar Tarefa</span>
          </Header>
          <Input
            value={title}
            setValue={(e) => {
              setTitle(e);
            }}
            label="Nome da Tarefa"
            placeholder="Ex. Orçamento de compra"
            type="text"
            onKeyDown={(e) => (e.key === "Enter" ? onClose() : null)}
            style={{ width: "22.354375rem" }}
          />
          <Deadline deadline={deadline} setDeadline={setDeadLine} />
          <Subtasks subtasks={subtasks} setSubtasks={setSubtasks} />
          <Check
            attachFile={attachFile}
            lockTask={lockTask}
            setAttach={() => setAttachFile((prev) => !prev)}
            setLockTask={() => setLockTask((prev) => !prev)}
          />
          <Button onClick={() => onClose()}>Voltar</Button>

          <DivIcon>
            <Close onClick={() => onClose()} />
          </DivIcon>
        </Content>
      </Container>
    </Modal>
  );
};
export default TaskModal;
