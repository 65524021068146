import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { Dropdown, Form, Input } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { handleSendEmail } from "../../state/users/actions";
import { BoxFormWithHeader, LoginContainer } from "./styles";
import Loading from "../../components/LoadingTemplate/index";

const NewAccountSendPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const [nameError, setNameError] = useState(false);
  const [error, setError] = useState(null);
  const [emailError, setEmailError] = useState(false);
  const [reemailError, setReemailError] = useState(false);
  const [rank, setRank] = useState("Funcionário");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!token) history.push("/login");
  }, [history, token]);

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      reemail: "",
    },
    onSubmit: (values) => {
      if (
        values.name === "" ||
        values.reemail === "" ||
        values.email === "" ||
        values.reemail !== values.email
      ) {
        if (values.name === "") setNameError(true);
        if (values.reemail === "") setReemailError(true);
        if (values.email === "") setEmailError(true);
        if (values.reemail !== values.email) setError("Emails não coincidem");
      } else {
        setNameError(false);
        setReemailError(false);
        setEmailError(false);
        setError(false);
        setLoading(true);
        dispatch(
          handleSendEmail({
            name: values.name,
            email: values.email,
            rank,
            enterpriseId: user.enterpriseId ? user.enterpriseId : user._id,
          })
        ).then((res) => {
          if (res.payload.error) setError("Email já cadastrado");
          else {
            history.push("/employees");
          }
          setLoading(false);
        });
      }
    },
  });

  return !loading ? (
    <BoxFormWithHeader>
      <div>
        <Form onSubmit={formik.handleSubmit}>
          <LoginContainer>
            <div
              style={{
                display: "flex",
                flexDirection: "column",

                padding: "10px",
                borderRadius: "5px",
                width: "100%",
              }}
            >
              <label>Nome</label>
              <Input
                icon="user"
                iconPosition="left"
                type="text"
                style={{ marginBottom: "10px" }}
                name="name"
                error={nameError}
                placeholder="Nome"
                onChange={formik.handleChange}
                value={formik.values.name}
              />
              <label>Email</label>
              <Input
                icon="mail"
                iconPosition="left"
                error={emailError}
                type="email"
                name="email"
                style={{ marginBottom: "10px" }}
                placeholder="Email"
                onChange={formik.handleChange}
                value={formik.values.email}
              />

              <label>Confirmar Email</label>
              <Input
                icon="mail"
                iconPosition="left"
                error={reemailError}
                type="email"
                name="reemail"
                style={{ marginBottom: "10px" }}
                placeholder="Confirmar Email"
                onChange={formik.handleChange}
                value={formik.values.reemail}
              />

              <div style={{ marginRight: "20px", marginBottom: "10px" }}>
                <h4>Cargo</h4>
                <Dropdown
                  placeholder="Cargo"
                  fluid
                  selection
                  onChange={(e, data) => {
                    setRank(data.value);
                  }}
                  options={[
                    {
                      key: "Funcionário",
                      value: "Funcionário",
                      text: "Colaborador",
                    },
                    { key: "Gerente", value: "Gerente", text: "Gerente" },
                  ]}
                  defaultValue={"Funcionário"}
                />
              </div>

              {error && (
                <p
                  style={{
                    color: "red",
                    alignSelf: "center",
                    marginBottom: "10px",
                  }}
                >
                  {error}
                </p>
              )}

              <button className="ui button" type="submit">
                Enviar Email de Cadastro
              </button>
            </div>
          </LoginContainer>
        </Form>
      </div>
    </BoxFormWithHeader>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

export default NewAccountSendPage;
