import styled, { css, keyframes } from "styled-components";

export const Container = styled.div`
  position: absolute;
  width: 8.075rem;
  height: 0.4708rem;
  border-radius: 0.29416rem;
  background: #d4d6da;
  bottom: 20%;
  left: 50%;
  transform: translate(-50%);
  opacity: ${({ animation }) => (animation ? "1" : "0")};
  transition: opacity 1.5s linear;
  user-select: none;
`;

const aniBar = keyframes`
    0%{
        width: 0%;
    }
    5%{
        width: 5%;
    }
    10%{
        width: 0%;
    }
    15%{
        width: 5%;
    }
    20%{
        width: 0%;
    }

    35%{
        width: 100%;
    }

    40%{
        width: 105%;

    }
    55%{
        width: 100%;

    }
    100%{
        width: 100%;

    }
`;

export const Progression = styled.div`
  /* width: ${({ progressionLevel }) => progressionLevel + "%"}; */
  width: 0%;
  height: 100%;
  background: linear-gradient(180deg, #40e5a0 0%, #4cc5bd 100%);
  border-radius: 7.06453px;

  animation: ${({ animation }) =>
    animation &&
    css`
      ${aniBar} 4s ease-in forwards 1.5s
    `};
`;
