import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import {
  addError,
  clearUsers,
  resendEmail,
  sendEmail,
  signUp,
} from "./actions";
import { deleteUser } from "./actions";
import api from "../../services/api";

export const fetchUsers = createAsyncThunk(
  "users/fetchUsers",
  async (enterpriseId) => {
    const response = await api.get("/employees/" + enterpriseId);

    return response.data;
  }
);

const usersReducer = createReducer(
  { entities: [], status: "idle" },
  {
    [fetchUsers.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchUsers.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.entities = action.payload; //passando o users para estado
    },

    /**
     * ? deleteUser
     * * Deleta funcionário X da lista de funcionários do gerente (EmployeeModal)
     */
    [deleteUser]: (state, action) => {
      state.entities = state.entities.filter(
        (user) => user._id !== action.payload.userId
      );
    },

    /**
     * ? addError
     * * Mensagem de erro associado ao usuário enviado pelo banco
     */
    [addError]: (state, action) => {
      state.errorMessage = action.payload;
    },

    /**
     * ? clearUsers
     * * Limpando conta de usuário do estado local
     */
    [clearUsers]: (state) => {
      state.status = "idle";
      state.entities = [];
    },

    /**
     * ? signUp
     * * Criação de conta de funcionário dentro da conta de gerente
     */
    [signUp]: (state, action) => {
      state.entities.push(action.payload.user);
    },
    [sendEmail]: (state, action) => {
      state.entities.push(action.payload.user);
    },
    [resendEmail]: (state) => {
      return state;
    },
  }
);

export default usersReducer;
