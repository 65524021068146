import React, { useEffect } from "react";
import { useZoomPanHelper } from "react-flow-renderer";

const ZoomNode = ({ id, nodes, load }) => {
  const { setCenter } = useZoomPanHelper();

  useEffect(() => {
    if (load) {
      const node = nodes.find((e) => e.id === id);

      const x = node.position.x + 80;
      const y = node.position.y + 30;
      const zoom = 2.7;
      setCenter(x, y, zoom);
    }
  }, [load]);

  return null;
};

export default ZoomNode;
