import React from "react";

export const InterrogationIcon = ({ width, height, onClick }) => (
  <svg
    width={width}
    height={height}
    onClick={onClick}
    viewBox="0 0 11 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.79444 11.2635V7.57755H5.55456C6.36905 7.57755 6.97646 7.36358 7.37681 6.93562C7.77715 6.49387 7.97732 5.95548 7.97732 5.32045C7.97732 4.64401 7.77025 4.10562 7.3561 3.70528C6.95576 3.29113 6.41046 3.08406 5.72022 3.08406C4.97475 3.08406 4.38805 3.31184 3.9601 3.7674C3.54595 4.20916 3.33887 4.76135 3.33887 5.42399H0.584799C0.584799 4.54047 0.78497 3.73979 1.18531 3.02194C1.58565 2.29028 2.17236 1.70357 2.94544 1.26181C3.71851 0.820055 4.66414 0.599177 5.78234 0.599177C6.8039 0.599177 7.68051 0.799348 8.41217 1.19969C9.15764 1.58623 9.73054 2.11772 10.1309 2.79416C10.5312 3.4706 10.7314 4.23677 10.7314 5.09267V5.34116C10.7314 6.45936 10.3794 7.41189 9.67532 8.19877C8.98508 8.97185 7.98422 9.46192 6.67276 9.66899L6.65205 11.2635H3.79444ZM3.56666 16.1504V13.0857H6.87983V16.1504H3.56666Z"
      fill="#585E66"
    />
  </svg>
);

export default InterrogationIcon;
