import { createReducer } from "@reduxjs/toolkit";
import { addEdge, removeElements } from "react-flow-renderer";
import {
  clearUndoFlow,
  updateAddEdge,
  updateConditionalNodeLABEL,
  updateCustomEdgeLABEL,
  updateCustomMark,
  updateCustomTextFORM,
  updateDrop,
  updateFlow,
  updateNodeSelectDrag,
  updateNodeSingleDrag,
  updateRemove,
  updateTaskNode,
  updateTimerNode,
} from "./actions";

const undoFlowReducer = createReducer(
  {
    flow: [],
  },
  {
    // ? Flow Pages
    // * Reseta o estado redux
    [clearUndoFlow]: (state) => {
      state.flow = [];
    },

    // * updateFlow -> Atualiza todo o fluxo atual do estado redux
    [updateFlow]: (state, action) => {
      state.flow = action.payload;
    },
    // * updateDrop -> Adiciona um elemento via drop na tela
    [updateDrop]: (state, action) => {
      if (action.payload.newNode.type === "customMark") {
        let newArray = JSON.parse(JSON.stringify(state.flow));

        let indexInsert = newArray.findIndex(
          (item) => item.type !== "customMark"
        );
        if (indexInsert < 0)
          newArray.splice(newArray.length, 0, action.payload.newNode);
        else if (indexInsert > 0)
          newArray.splice(indexInsert, 0, action.payload.newNode);
        else newArray.unshift(action.payload.newNode);
        state.flow = newArray;
      } else {
        state.flow.push(action.payload.newNode);
      }
    },
    // * updateRemove -> Remove elementos selecionados da lista
    [updateRemove]: (state, action) => {
      state.flow = removeElements(action.payload, state.flow);
    },

    // * updateAddEdge -> Adiciona uma aresta entre dois nós
    [updateAddEdge]: (state, action) => {
      state.flow = addEdge(action.payload.params, state.flow);
    },

    // * updateNodeSingleDrag ->  Movendo um item selecionado
    [updateNodeSingleDrag]: (state, action) => {
      const node = action.payload.node;
      const API = action.payload.API;

      API.forEach((item) => {
        if (item.id === node.id) item.position = node.position;
      });

      state.flow = API;
    },

    // * updateNodeSelectDrag -> Movendo vários itens selecionados
    [updateNodeSelectDrag]: (state, action) => {
      const nodes = action.payload.nodes;
      const API = action.payload.API;

      API.forEach((item) => {
        nodes.forEach((n) => {
          if (n.id === item.id) {
            item.position = n.position;
          }
        });
      });
      state.flow = API;
    },

    //TODO: Componentes

    // ? CustomMark

    // * Atualiza o estado de um marcador
    [updateCustomMark]: (state, action) => {
      state.flow.forEach((item) => {
        if (item.id === action.payload.id) {
          item.data = {
            ...item.data,
            refData: {
              width: action.payload.width,
              height: action.payload.height,
            },
            draggable: action.payload.draggable,
            hexColor: action.payload.style.hex,
          };
        }
      });
    },

    // ? CustomText
    // * Atualiza o estado de um Custom Text
    [updateCustomTextFORM]: (state, action) => {
      state.flow.forEach((item) => {
        if (item.id === action.payload.id) {
          item.data = {
            ...item.data,
            label: action.payload.text,
            fontSize: action.payload.fontSize,
            hexColor: action.payload.hexColor,
            draggable: action.payload.draggable,
          };
        }
      });
    },

    // ? TaskNode
    // * Atualiza o  nó tarefa
    [updateTaskNode]: (state, action) => {
      state.flow.forEach((item) => {
        if (item.id === action.payload.id) {
          item.data = {
            ...item.data,
            label: action.payload.text,
            attachFile: action.payload.attachFile,
            lockTask: action.payload.lockTask,
            subtasks: action.payload.subtasks,
            expiration: {
              ...item.data.expiration,
              number: action.payload.deadline * 24,
            },
          };
        }
      });
    },

    // ? TimerNode
    // * Atualiza o  nó Temporizador
    [updateTimerNode]: (state, action) => {
      state.flow.forEach((item) => {
        if (item.id === action.payload.id) {
          item.data = {
            ...item.data,
            expiration: {
              ...item.data.expiration,
              number: action.payload.deadline * 24,
            },
          };
        }
      });
    },

    // ? ConditionalNode
    // * Atualiza o rótulo do nó condicional
    [updateConditionalNodeLABEL]: (state, action) => {
      state.flow.forEach((item) => {
        if (item.id === action.payload.id) {
          item.data = {
            ...item.data,
            label: action.payload.text,
          };
        }
      });
    },

    // ? CustomEdge
    // * Atualiza o rótulo da aresta customEdge
    [updateCustomEdgeLABEL]: (state, action) => {
      state.flow.forEach((item) => {
        if (item.id === action.payload.id) {
          item.data = {
            ...item.data,
            text: action.payload.text,
          };
        }
      });
    },
  }
);

export default undoFlowReducer;
