import React from "react";
import { StyledLink } from "./style";
const Link = ({
  content,
  onClick = () => console.log(content),
  theme = false,
  color = !theme ? "#30C285" : "#585E66",
  colorOnClick = !theme ? "#118052" : "#212326",
  href,
}) => {
  return (
    <StyledLink
      color={color}
      colorOnClick={colorOnClick}
      onClick={() => onClick()}
      href={href}
    >
      {content}
    </StyledLink>
  );
};

export default Link;
