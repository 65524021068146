import { Handle } from "react-flow-renderer";
import styled, { css } from "styled-components";

export const ParallelStyle = styled.div`
  width: 43px;
  height: 43px;
  box-sizing: border-box;
  border-radius: 4px;
  transform: rotate(-45deg);
  align-items: center;
  align-self: center;
  justify-content: center;
  display: flex;
  box-shadow: 0 0 0 1.2px #42464c;
  background-color: #fff;

  path {
    stroke: #42464c;
    stroke-width: 0.4px;
  }

  &:hover {
    background: #cff8e7;
    box-shadow: 0 0 0 1.2px #40e5a0;
    path {
      stroke: #40e5a0;
      stroke-width: 0.4px;
    }
  }

  &.critical {
    background: #d93333 !important;
    border: none !important;
    box-shadow: none !important;
    path {
      stroke: #eff1f5;
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      background: #fff !important;
      box-shadow: 0 0 3px 2px #40e5a0 !important;
      path {
        stroke: #42464c !important;
        stroke-width: 0.4px !important;
      }
    `}
`;

export const HandleStyle = styled(Handle)`
  width: 8px;
  height: 8px;
`;
