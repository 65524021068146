import styled from "styled-components";

export const Content = styled.div``;

export const ContentText = styled.span``;

export const PopoverContent = styled.div`
  display: flex;
  flex-direction: column;
`;
