import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import undoFlow from "./undoFlow/reducers";
import undoable from "redux-undo";

const rootReducer = combineReducers({
  undoFlow: undoable(undoFlow),
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export { store };
