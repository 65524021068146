import React from "react";

const CustomTooltip = ({ datum, getStyle, dark }) => {
  const showCount = 10;

  const sortedGroupDatums =
    datum !== undefined
      ? [...datum.group].sort((a, b) => {
          return a.seriesIndex > b.seriesIndex ? 1 : -1;
        })
      : null;

  const resolvedShowCount = showCount % 2 === 0 ? showCount : showCount + 1;
  const length = sortedGroupDatums?.length;

  // Get the focused series' index
  const activeIndex = sortedGroupDatums?.findIndex((d) => d === datum);
  // Get the start by going back half of the showCount
  let start = activeIndex > -1 ? activeIndex - resolvedShowCount / 2 : 0;
  // Make sure it's at least 0
  start = Math.max(start, 0);
  // Use the start and add the showCount to get the end
  let end = activeIndex > -1 ? start + resolvedShowCount : length;
  // Don't let the end go passed the length
  end = Math.min(end, length);
  // Double check we aren't clipping the start
  start = Math.max(end - resolvedShowCount, 0);
  // Slice the datums by start and end
  const visibleSortedGroupDatums = sortedGroupDatums?.slice(start, end);

  return datum ? (
    <div
      style={{
        color: "white",
        pointerEvents: "none",
      }}
    >
      <div
        style={{
          marginBottom: "3px",
          textAlign: "center",
        }}
      >
        <strong>Dia {datum.primaryAxis.format(datum.primary)}</strong>
      </div>
      <table
        style={{
          whiteSpace: "nowrap",
        }}
      >
        <tbody>
          {visibleSortedGroupDatums?.map((sortedDatum, i) => {
            const active = sortedDatum === datum;
            return sortedDatum.originalDatum?.doneTasks?.length > 0 ? (
              sortedDatum.originalDatum.doneTasks.map((item, index) => (
                <tr
                  key={i + item._id}
                  style={{
                    opacity: active ? 1 : 0.8,
                    fontWeight: active && "bold",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  <td
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      marginRight: "5px",
                    }}
                  >
                    {sortedDatum.originalDatum.taskTitle ===
                    "Tarefas em andamento" ? (
                      <svg width="16" height="16">
                        <circle
                          cx="8"
                          cy="8"
                          r="7"
                          style={{
                            fill: "url(#2)",
                            stroke: dark ? "black" : "white",
                            strokeWidth: active ? 2 : 1,
                          }}
                        />
                      </svg>
                    ) : (
                      <svg width="16" height="16">
                        <circle
                          cx="8"
                          cy="8"
                          r="7"
                          style={{
                            ...getStyle(sortedDatum),
                            stroke: dark ? "black" : "white",
                            strokeWidth: active ? 2 : 1,
                          }}
                        />
                      </svg>
                    )}
                  </td>

                  <td>
                    {sortedDatum.seriesLabel}: {item.data.label}
                  </td>
                </tr>
              ))
            ) : (
              <tr
                key={i}
                style={{
                  opacity: active ? 1 : 0.8,
                  fontWeight: active && "bold",
                  display: "flex",
                  flexDirection: "row",
                }}
              >
                <td
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: "5px",
                  }}
                >
                  {sortedDatum.originalDatum.taskTitle ===
                  "Tarefas em andamento" ? (
                    <svg width="16" height="16">
                      <circle
                        cx="8"
                        cy="8"
                        r="7"
                        style={{
                          fill: "url(#2)",
                          stroke: dark ? "black" : "white",
                          strokeWidth: active ? 2 : 1,
                        }}
                      />
                    </svg>
                  ) : (
                    <svg width="16" height="16">
                      <circle
                        cx="8"
                        cy="8"
                        r="7"
                        style={{
                          ...getStyle(sortedDatum),
                          stroke: dark ? "black" : "white",
                          strokeWidth: active ? 2 : 1,
                        }}
                      />
                    </svg>
                  )}
                </td>
                {!sortedDatum.originalDatum.taskTitle ? (
                  <td>Nenhuma tarefa entregue</td>
                ) : (
                  <td>
                    {sortedDatum.seriesLabel}:{" "}
                    {sortedDatum.originalDatum.taskTitle}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  ) : null;
};

export default CustomTooltip;
