import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  position: relative;
  font-family: Sora;
  user-select: none;
  height: 1.765rem;
  z-index: 5;
  justify-content: flex-end;
`;
export const UserClick = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  background: ${({ open }) => (open ? "#d4d6da" : "none")};

  &:hover {
    background: #d4d6da !important;
  }

  z-index: 99999;

  border-radius: 0.35296625rem;
  height: 1.646875rem;
  padding: 0 0.235625rem 0 0.353125rem;

  margin-left: 0.15625rem;

  svg {
    width: 0.588125rem;
    height: 0.588125rem;
    margin-left: 0.5rem;
  }

  span {
    font-size: 0.705625rem;
    line-height: 0.889375rem;

    font-weight: 600;
    color: ${({ open }) => (open ? "#42464C" : "#585E66")};
  }
`;

export const Avatar = styled.img`
  width: ${({ UserInfo }) => (UserInfo ? "2.470625rem" : "1.765rem")};
  height: ${({ UserInfo }) => (UserInfo ? "2.470625rem" : "1.765rem")};
  border-radius: 50%;
  background: lightblue;
`;
export const DropContent = styled.div`
  display: ${({ open }) => (open ? "flex" : "none")};
  flex-direction: column;
  position: absolute;
  top: 100%;
  right: 0;
  background: #42464c;
  width: 11.765625rem;
  //height: 12rem;
  padding-bottom: 1.5rem;
  border-radius: 0.353125rem;
  margin-top: 0.570625rem;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin: 1.1rem 0 0 1.1rem;
`;
export const HeadInfo = styled.div`
  display: flex;
  flex-direction: column;

  justify-content: flex-start;
  margin-left: 0.70625rem;
  line-height: 0.815625rem;
  text-align: left;
  width: 7.25rem;
`;
export const UserName = styled.span`
  color: #fff;
  font-weight: 600;
  font-size: 0.705625rem;
  margin-bottom: 3px;

  //Quebra Linha
  display: flex;
  flex-wrap: wrap;

  //Reticencias
  /* overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis; */
`;
export const Rank = styled.span`
  font-size: 0.646875rem;
  color: #a9aeb4;
`;

export const HorizontalLine = styled.div`
  width: 100%;
  background: #585e66;
  height: 1px;
  margin: 1.1rem 0 0.588125rem 0;
`;
export const UList = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 100%;
  padding: 0;
  margin: 0;
`;
export const ListItem = styled.li`
  font-size: 0.705625rem;
  line-height: 0.889375rem;
  color: #eff1f5;
  padding: 0.470625rem 0 0.30125rem 1rem;
  cursor: pointer;

  &:hover {
    background: #35383d;
  }
`;
