import React from "react";

const StartIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="14.8026"
        cy="15.0668"
        r="13.7981"
        stroke="#7E858F"
        strokeWidth="1.96988"
      />
      <path
        d="M10.708 14.752V12.8607C10.708 10.5161 12.3003 9.5527 14.2449 10.7286L15.81 11.6778L17.3752 12.627C19.3198 13.8028 19.3198 15.7225 17.3752 16.8984L15.81 17.8476L14.2449 18.7967C12.3003 19.9514 10.708 18.9951 10.708 16.6433V14.752Z"
        stroke="#7E858F"
        strokeWidth="1.96988"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StartIcon;
