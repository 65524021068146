import React, { useState } from "react";
import LoadingInput from "../../../assets/icons/LoadingInput";

import Button from "../Button";
import {
  Container,
  Label,
  CustomInput,
  ErrorLabel,
  EyeSlashIconStyled,
  EyeIconStyled,
  FooterInput,
  LoadingAnimation,
} from "./styles";

const Input = ({
  label,
  placeholder,
  error,
  errorMsg,
  type = "text",
  onKeyDown = undefined,
  value,
  setValue,
  Footer,
  FooterMensage,
  style = {},
  Icon,
  buttonConfirm = false,
  buttonConfirmText,
  onButtonConfirm,
  loading = false,
  disabled = false,
}) => {
  const [visible, setVisible] = useState(false);
  const [focus, setFocus] = useState(false);
  const checkFocus = (e) => {
    if (e.type === "focus") {
      setFocus(true);
    } else if (e.type === "blur") {
      setFocus(false);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        ...style,
      }}
    >
      {label && <Label>{label}</Label>}
      <Container focus={focus} disabled={disabled}>
        {type === "password" &&
          (!visible ? (
            <div onClick={() => setVisible((prev) => !prev)}>
              <EyeSlashIconStyled />
            </div>
          ) : (
            <div onClick={() => setVisible((prev) => !prev)}>
              <EyeIconStyled />
            </div>
          ))}

        {Icon && <Icon />}
        <CustomInput
          disabled={disabled}
          onBlur={checkFocus}
          onFocus={checkFocus}
          onKeyDown={onKeyDown}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          visible={visible}
          error={error}
          placeholder={placeholder}
          type={
            type === "password" && !visible
              ? type
              : type === "password"
              ? "text"
              : type
          }
        />

        {loading && (
          <LoadingAnimation style={{ marginRight: "1rem" }}>
            <LoadingInput />
          </LoadingAnimation>
        )}

        {buttonConfirm && (
          <Button
            onClick={onButtonConfirm}
            content={buttonConfirmText}
            colorText="#42464C"
            style={{
              width: "6rem",
              position: "absolute",
              height: "105%",
              right: -1,
              top: -1,
              borderTopLeftRadius: 0,
              borderBottomLeftRadius: 0,
            }}
          />
        )}
        {error && <ErrorLabel>{errorMsg}</ErrorLabel>}
        {Footer && <FooterInput>{FooterMensage}</FooterInput>}
      </Container>
    </div>
  );
};

export default Input;
