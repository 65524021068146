import styled from "styled-components";

export const Content = styled.div`
  position: relative;
  z-index: 5;
`;

export const CriticalButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: 2.470625rem;
  height: 2.470625rem;
  border-radius: 50%;
  background: ${({ critical }) => (critical ? "#C94949 " : "#fde2dc")};
  border: none;
  box-shadow: 0px 7.52994px 11.2949px rgba(95, 95, 95, 0.2);
  padding: 0;
`;

export const IconDiv = styled.div`
  display: flex;
  align-items: center;

  width: 1.529375rem;

  path {
    stroke: ${({ critical }) => (critical ? "#EFF1F5 " : "#EB5757")};
  }
`;
