import React from "react";
import FlowPane from "./index";
import { ReactFlowProvider } from "react-flow-renderer";
import GlobalStyle from "../../styles/globalStyles";
import { Provider } from "react-redux";
import { store } from "./store";

const PaneProvider = () => (
  <>
    <GlobalStyle />
    <Provider store={store}>
      <ReactFlowProvider>
        <FlowPane />
      </ReactFlowProvider>
    </Provider>
  </>
);

export default PaneProvider;
