import React, { useEffect, useRef, useState } from "react";
import AvatarArrow from "../../../assets/icons/AvatarArrow";
import {
  Container,
  Avatar,
  DropContent,
  UserClick,
  UserInfo,
  HeadInfo,
  UserName,
  Rank,
  HorizontalLine,
  UList,
  ListItem,
} from "./styles";

import AvatarDefault from "../../../assets/avatar_default.jpg";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useOutsideDrop } from "../../hooks/useOutsideDrop";

const User = ({ user, picture = AvatarDefault, style = {} }) => {
  const [open, setOpen] = useState(false);
  const wrapperRef = useRef(null);
  const clearDropdown = () => setOpen(false);
  //Avatar profile

  const history = useHistory();
  const dispatch = useDispatch();

  useOutsideDrop(wrapperRef, clearDropdown);

  return (
    <Container ref={wrapperRef} style={style}>
      <Avatar src={picture} />
      <UserClick open={open} onClick={() => setOpen((prev) => !prev)}>
        <span>{user?.username.split(" ")[0]}</span>
        <AvatarArrow />
      </UserClick>
      <DropContent open={open}>
        <UserInfo>
          <Avatar UserInfo src={picture} />
          <HeadInfo>
            <UserName>{user?.username}</UserName>
            <Rank>{user?.rank}</Rank>
          </HeadInfo>
        </UserInfo>
        <HorizontalLine />
        <UList>
          <ListItem>Editar Perfil</ListItem>
          <ListItem>Ajuda</ListItem>
          <ListItem>Fazer logout</ListItem>
        </UList>
      </DropContent>
    </Container>
  );
};

export default User;
