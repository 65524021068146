import styled from "styled-components";
import theme from "../../../styles/theme";
import EyeSlashIcon from "../../../assets/icons/eye-slash";
import EyeIcon from "../../../assets/icons/eye";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 0 1.0591rem 0;
`;
export const Label = styled.p`
  font-weight: 600;
  font-size: 0.7062rem;
  line-height: 0.875rem;
  color: ${theme.defaultGray};
  margin-bottom: 0.3608rem;
`;
export const CustomInput = styled.input`
  border: ${({ error }) =>
    error ? "1.41239px solid #eb5757" : "1.41239px solid #a9aeb4"};

  box-sizing: border-box;
  border-radius: 8.47434px;
  line-height: 0.875rem;
  padding: 0.765rem 0 0.71375rem 0.6504rem;
  outline: none;
  height: 2.352rem;
  font-family: ${({ type, visible }) =>
    !visible && type === "password" ? "Sans-Serif" : "Sora"};

  font-size: ${({ type, visible }) =>
    !visible && type === "password" ? "1.25rem" : "0.70833rem"}; //0.70833rem;;

  letter-spacing: ${({ visible, type }) =>
    !visible && type === "password" ? "0.25rem" : "0px"};

  color: ${({ type }) => (type === "password" ? "#585e66" : "#111")};

  &:placeholder-shown {
    font-family: Sora;
    color: #a9aeb4;
    letter-spacing: 0px;
    font-size: 0.70833rem;
  }

  &:hover {
    border: ${({ error }) =>
      error
        ? "1.41239px solid #eb5757"
        : "1.41239px solid rgba(0, 0, 0, 0.87)"};
  }
  &:focus {
    border: ${({ error }) =>
      error ? "2.41239px solid #eb5757" : "1.41239px solid #40e5a0"};
  }
`;

export const ErrorLabel = styled.span`
  position: absolute;
  color: #ea6868;
  font-size: 0.6468rem;
  line-height: 0.8333rem;
  bottom: -0.1rem;
`;

export const EyeSlashIconStyled = styled(EyeSlashIcon)`
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 1.05833rem;
  right: 1rem;
  border-radius: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const EyeIconStyled = styled(EyeIcon)`
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 1.05833rem;
  right: 1rem;
  border-radius: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
`;
