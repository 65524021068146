import React from "react";
import ListItem from "./ListItem";
import { Container, Label, UList, AddSubtask } from "./styles";

const Subtasks = ({ subtasks, setSubtasks }) => {
  const sample = {
    title: "Subtarefa " + subtasks.length,
    checked: false,
  };
  const addMoreStyle = {
    fontFamily: "Sora",
    fontSize: "0.9rem",
    fontWeight: "500",
  };

  const handleAddSubtask = () => {
    setSubtasks((item) => item.concat(sample));
  };

  return (
    <Container>
      <Label>Subtarefas</Label>
      <UList len={subtasks.length}>
        {subtasks.length === 0 ? (
          <span style={addMoreStyle}>Adicione novas Subtarefas!</span>
        ) : (
          subtasks.map((item, i) => (
            <ListItem key={i} item={item} setSubtasks={setSubtasks} />
          ))
        )}
      </UList>
      <AddSubtask onClick={handleAddSubtask}>
        <p>+ Adicionar Subtarefa</p>
      </AddSubtask>
    </Container>
  );
};

export default Subtasks;
