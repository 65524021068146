import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findTaskOneFlow } from "../../../state/flows/selectors";

import {
  addTaskSubtasks,
  editTaskExpirationTime,
  editTaskSubtasks,
  handleEditTask,
  removeTaskSubtasks,
} from "../../../state/flows/actions";
import {
  Button,
  Dropdown,
  Header,
  Input,
  Label,
  Segment,
  Icon,
  Checkbox,
} from "semantic-ui-react";
import { Modal, Table } from "semantic-ui-react";
import Loading from "../../../components/LoadingTemplate/index";

const ModelTaskModal = ({
  flowId,
  taskId,
  open,
  change,
  version,
  setTaskUpdate,
}) => {
  const dispatch = useDispatch();
  const [versionx, setVersion] = useState(version);
  const user = useSelector((state) => state.auth.user);
  const [number, setNumber] = useState();
  const [error, setError] = useState("");
  const [initialTitle, setInitialTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const task = useSelector((state) =>
    findTaskOneFlow(taskId, flowId, state.flows.entities, versionx)
  );
  const [attachFile, setAttachFile] = useState(
    task.data.attachFile ? task.data.attachFile : false
  );
  const [lockTask, setLockTask] = useState(
    task.data.lockTask ? task.data.lockTask : false
  );

  useEffect(() => {
    if (version !== undefined) setVersion(version);
  }, [version]);

  const options = [
    {
      key: "days",
      value: "days",
      text: "DIAS",
    },
    {
      key: "hours",
      value: "hours",
      text: "HORAS",
    },
  ];

  useEffect(() => {
    setNumber(
      task?.data?.expiration.time === "days"
        ? task?.data?.expiration.number / 24
        : task?.data?.expiration.time === "minutes"
        ? task?.data?.expiration.number * 60
        : task?.data?.expiration.time === "hours"
        ? task?.data?.expiration.number
        : "undefined"
    );
  }, [task?.data?.expiration]);

  useEffect(() => {
    setInitialTitle(task?.data?.label);
  }, [task, open]);

  return task !== undefined && task.data !== undefined && !loading ? (
    <Modal
      onClose={() => change(false)}
      onOpen={() => change(true)}
      open={open}
    >
      <Modal.Header>EDITE AS INFORMAÇÕES DESEJADAS</Modal.Header>
      <Modal.Content scrolling style={{ minHeight: "380px" }}>
        <Segment color="yellow">
          <Input
            label="TÍTULO"
            value={initialTitle}
            onChange={(e) => {
              const pointer = e.target.selectionStart;

              window.requestAnimationFrame(() => {
                e.target.selectionStart = pointer;
                e.target.selectionEnd = pointer;
              });

              setInitialTitle(e.target.value.toUpperCase());
            }}
          />
        </Segment>
        <Segment color="yellow">
          <Header style={{ backgroundColor: "white" }}>PRAZO DA TAREFA</Header>

          <Input
            style={{ marginLeft: "10px" }}
            label="NÚMERO"
            type="number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />

          <Label
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
              padding: 0,
              paddingLeft: "10px",
              width: "300px",
            }}
          >
            <div style={{ minWidth: "140px", padding: 0, fontSize: 14 }}>
              MEDIDA DE TEMPO
            </div>
            <Dropdown
              labeled
              label="MEDIDA DE TEMPO"
              placeholder={task.data.expiration.time}
              fluid
              selection
              defaultValue={task.data.expiration.time}
              onChange={(e, data) =>
                dispatch(
                  editTaskExpirationTime({
                    flowId: flowId,
                    taskId: task.id,
                    time: data.value,
                    version,
                  })
                )
              }
              options={options}
            />
          </Label>

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
              padding: 0,
              paddingLeft: "10px",
              width: "300px",
            }}
          >
            <Checkbox
              style={{ marginRight: "10px" }}
              checked={attachFile}
              onClick={() => {
                setAttachFile((prev) => !prev);
              }}
            />
            <p>Anexo Obrigatório</p>
          </div>

          <div
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
              padding: 0,
              paddingLeft: "10px",
              width: "300px",
            }}
          >
            <Checkbox
              style={{ marginRight: "10px" }}
              checked={lockTask}
              onClick={() => {
                setLockTask((prev) => !prev);
              }}
            />
            <p>Suspensão até 2ª Ordem</p>
          </div>
        </Segment>
        {task?.data?.subtasks?.length > 0 ? (
          <div style={{ marginTop: "10px" }}>
            <Table celled padded color="yellow">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell textAlign="center">
                    SUB-TAREFAS
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center">
                    REMOVER
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {task.data.subtasks.map((item, index) => (
                  <Table.Row key={index}>
                    <Table.Cell style={{ width: "100%" }}>
                      <Input
                        style={{ width: "100%" }}
                        value={item.toUpperCase()}
                        onChange={(e) => {
                          const pointer = e.target.selectionStart;

                          window.requestAnimationFrame(() => {
                            e.target.selectionStart = pointer;
                            e.target.selectionEnd = pointer;
                          });

                          dispatch(
                            editTaskSubtasks({
                              flowId: flowId,
                              taskId: task.id,
                              title: e.target.value.toUpperCase(),
                              index,
                              version,
                            })
                          );
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell
                      selectable
                      onClick={() => {
                        dispatch(
                          removeTaskSubtasks({
                            flowId: flowId,
                            taskId: task.id,
                            index,
                            version,
                          })
                        );
                      }}
                      textAlign="center"
                      negative
                      style={{
                        alignItems: "center",

                        cursor: "pointer",
                      }}
                    >
                      <Icon name="trash alternate" size="large" />
                    </Table.Cell>
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
          </div>
        ) : null}

        <Button
          icon="plus"
          labelPosition="left"
          style={{ marginTop: "10px" }}
          content="ADICIONAR SUB-TAREFA"
          onClick={() =>
            dispatch(
              addTaskSubtasks({
                flowId: flowId,
                taskId: task.id,
                version,
              })
            )
          }
        />
        <p style={{ color: "red", textAlign: "center" }}>
          {error !== "" ? error : null}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button
          color="black"
          onClick={() => {
            change(false);
          }}
        >
          Voltar
        </Button>
        <Button
          content="Salvar tarefa"
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            if (task.data.label === "") {
              setError("Necessário colocar um título para a tarefa");
            } else if (number === "0" || !number) {
              setError("Necessário colocar número para o prazo da tarefa");
            } else if (
              task.data.subtasks.filter((item) => item.length === 0).length > 0
            ) {
              setError(
                "Necessário preencher a descrição de todas as sub-tarefas"
              );
            } else {
              setError("");
              setLoading(true);
              dispatch(
                handleEditTask(
                  initialTitle,
                  {
                    time: task.data.expiration.time,
                    number:
                      task.data.expiration.time === "days"
                        ? number * 24
                        : task.data.expiration.time === "hours"
                        ? number
                        : task.data.expiration.time === "minutes"
                        ? number / 60
                        : "undefined",
                  },
                  task.data.subtasks,
                  task._id,
                  !user.enterpriseId ? user?._id : user.enterpriseId,
                  version
                )
              ).then(() => {
                setTaskUpdate();
                change(false);
                setLoading(true);
              });
            }
          }}
          positive
        />
      </Modal.Actions>
    </Modal>
  ) : (
    <Modal
      onClose={() => change(false)}
      onOpen={() => change(true)}
      open={open}
    >
      <div>
        <Loading />
      </div>
    </Modal>
  );
};

export default ModelTaskModal;
