import React, { memo, useState } from "react";

import { Handle, useStoreState, Position } from "react-flow-renderer";
import { TaskStyle } from "./styles.js";

import TaskModal from "./TaskModal";

const TaskNode = ({
  data,
  targetPosition = Position.Left,
  sourcePosition = Position.Right,
  id,
  selected,
}) => {
  const edges = useStoreState((state) => state.edges);
  const nodes = useStoreState((state) => state.nodes);

  const [isModalOpen, setModalOpen] = useState(false);

  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  const handleStyle = {
    width: "8px",
    height: "8px",
    background: "#42464c",
  };

  return (
    <>
      <TaskStyle
        selected={selected}
        onDoubleClick={() => handleOpen()}
        className="flow_elements_node"
        data-id={id}
      >
        <Handle
          type="target"
          id="c"
          position={targetPosition}
          style={handleStyle}
          isValidConnection={(e) =>
            nodes.find((item) => item.id === e.source).type === "parallel" ||
            nodes.find((item) => item.id === e.source).type === "conditional"
              ? true
              : !edges.find(
                  (item) => item.target === e.target || item.source === e.source
                )
          }
        />
        <div style={{ wordBreak: "break-word" }}>
          {data.label.toUpperCase()}
        </div>
        <Handle
          type="source"
          position={sourcePosition}
          id="a"
          style={handleStyle}
          isValidConnection={(e) =>
            nodes.find((item) => item.id === e.target).type === "parallelEnd" ||
            nodes.find((item) => item.id === e.target).type === "conditionalEnd"
              ? !edges.find((item) => item.source === e.source)
              : !edges.find(
                  (item) => item.source === e.source || item.target === e.target
                )
          }
        />
      </TaskStyle>

      <TaskModal
        selector="#root"
        id={id}
        data={data}
        isOpen={isModalOpen}
        onClose={handleClose}
        className="nodrag"
      />
    </>
  );
};

export default memo(TaskNode);
