import styled, { css, keyframes } from "styled-components";

const aniLogo = keyframes`
 
 0%{//posição inicial
  top: 5rem;
  left: 16rem;
  width: 2.11875rem; //50.85px;
  transform: translate(0);
  transform: rotate(0deg);

 }
 8.8%{//pro canto esquerdo
  top:  3.5rem;
  left: 14.5rem;
  width: 2.11875rem; //50.85px;
  transform: translate(0);
  transform: rotate(0deg);


}
 31.6%{ //centro + vai ate o canto
  top: 38.5%;
  left: calc(53.5% - 2.119375rem);
  width: 3.5321rem; //84.77px 

  transform: rotate(0deg);

 }
 42.10%{ //centraliza na volta
  top: 34%;
  left: calc(49% - 2.119375rem);
  width: 3.5321rem; //84.77px 

  transform: rotate(0deg);

 }

52.6%{ //começa a girar
  top: 35%;
  left: calc(50% - 2.119375rem);
  width: 3.5321rem; //84.77px 

  transform: rotate(15deg);

}
84.2%{  //180 + 15
  top: 35%;
  left: calc(50% - 2.119375rem);
  width: 4.23875rem; //101.73px


  transform: rotate(-210deg)
}
100%{ //estabiliza
  top: 35%;
  left: calc(50% - 2.119375rem);
  width: 4.23875rem; //101.73px
 
  transform: rotate(-180deg)
}


`;

export const LogoAnimation = styled.img`
  position: absolute;
  width: 2.11875rem;
  z-index: 5;
  user-select: none;

  top: 5rem;
  left: 16rem;

  animation: ${({ animation }) =>
    animation &&
    css`
      ${aniLogo} 3.7s ease-in forwards
    `};
`;

export const NameAnimation = styled.img`
  position: absolute;
  width: 9.824375rem; //235.94px
  left: 50%;
  top: 50%;
  transform: translate(-50%);
  user-select: none;

  opacity: ${({ animation }) => (animation ? "1" : "0")};

  transition: opacity 1s linear 2.2s;
  z-index: 5;
`;
