import { createReducer } from "@reduxjs/toolkit";
import {
  addError,
  changeUser,
  newPassword,
  resetPassword,
  signIn,
  signOut,
  updateToken,
  editEmail,
  editPassword,
  editUsername,
  editEnterpriseName,
} from "../auth/actions";

const authReducer = createReducer(
  {
    token: localStorage.getItem("token"),
    status: "idle",
    errorMessage: "",
    user: JSON.parse(localStorage.getItem("user")),
  },
  {
    //*Atribui alguma mensagem de erro pro estado local vindo do banco
    [addError]: (state, action) => {
      state.errorMessage = action.payload;
    },

    /**
     * ? signIn
     * * Ação de Login do MOVI, armazena uma chave token e uma chave refreshToken
     * * para autenticação na máquina local, duram respectivamente 2 horas e 2 dias.
     * * O refresh token é utilizado para solicitar um novo token quando o mesmo expira
     */

    [signIn]: (state, action) => {
      if (action.payload.check)
        localStorage.setItem("refreshToken", action.payload.refreshToken);

      localStorage.setItem("token", action.payload.token);
      localStorage.setItem(
        "user",
        JSON.stringify({
          ...action.payload.user,
          enterpriseName: action.payload.enterpriseName,
        })
      );
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.errorMessage = "";
    },

    /**
     * ? signOut
     * * Ação de de deslogamento do MOVI, análogo ao signIn pórem removendo
     * * as propriedades alocadas localmente para login de usuário.
     */

    [signOut]: (state, action) => {
      localStorage.removeItem("token");
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("user");
      state.token = null;
      state.user = null;
      state.errorMessage = "";
    },

    //*Refresh de token
    [updateToken]: (state, action) => {
      state.token = action.payload.token;
    },
    //!Redefinição de dados de usuário
    [changeUser]: (state, action) => {
      state.user = action.payload;
    },
    //*Troca de senha
    [newPassword]: (state) => state,
    //*Reseta senha via Esqueci minha senha
    [resetPassword]: (state) => state,
    //*Edita nome de usuário
    [editUsername]: (state, action) =>
      (state.user.username = action.payload.usename),
    //*Edita senha de usuário
    [editPassword]: (state) => state,
    //*Edita email de usuário
    [editEmail]: (state, action) => {
      state.user.email = action.payload.email;
    },
    [editEnterpriseName]: (state, action) =>
      void (state.user.enterpriseName = action.payload.enterpriseName),
  }
);

export default authReducer;
