import React from "react";

const Play = ({ style }) => (
  <svg
    style={style}
    width="20"
    height="21"
    viewBox="-1 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="19.8"
      height="20.7"
      transform="translate(0.0637207 0.266724)"
      fill="none"
    />
    <path
      d="M5.01367 10.6167V8.31383C5.01367 5.45895 6.95242 4.28595 9.32017 5.7177L11.2259 6.87345L13.1317 8.0292C15.4994 9.46095 15.4994 11.7983 13.1317 13.2301L11.2259 14.3858L9.32017 15.5416C6.95242 16.9475 5.01367 15.7831 5.01367 12.9196V10.6167Z"
      stroke="#585E66"
      strokeWidth="1.4"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Play;
