import moment from "moment";

/**
 * ? findActivedFlow
 *  * Utilizado em AdvancedStats, FlowViewerActived e FlowViewerFinished.
 *  * O seu prósito é retornar um fluxo especifico em meio as entidades do
 *  * estado global, seus parametros são basicamente o estado global e um _id
 *  * do fluxo selecionado.
 */

export const findActivedFlow = (flows, id) => {
  if (flows.length > 0) return flows?.find((item) => item?._id === id);
};

/**
 * ? findActivedTaskOneFlow
 * * Utilizado em ActiveTaskModal, ActiveTimerModal e FlowViewerActived. O seu
 * * propósito é retornar um elemento do tipo tarefa. Os seus parâmetros são o
 * * id do elemento(interno), o flowId e as entidades do estado global.
 */

export const findActivedTaskOneFlow = (taskId, flowId, flows) => {
  if (flows.length > 0) {
    const flow = flows?.find((item) => item?._id === flowId);

    if (flow !== undefined) {
      const task = flow?.elements.find((item) => item?.id === taskId);

      return task;
    }
  }

  return false;
};

/**
 * ? getAllTask
 * * Utilizado em EmployeerStats. Sua utilização visa simplismente
 * * capturar todos os elementos que tem o campo elements.type = "task"
 * * e retorna-los numa lista.
 */

export const getAllTasks = (flows) => {
  if (flows.length) {
    const allTasks = [];

    flows.forEach((item) => {
      item.elements.forEach((el) => {
        if (el.type === "task") {
          allTasks.push(el);
        }
      });
    });

    return allTasks;
  }
};

export const filterTasksForStats = (state, email, start, end) => {
  return state.filter(
    (item) =>
      item.type === "task" &&
      item.data.accountable === email &&
      ((item.data.status === "done" &&
        moment(item.data.finishedAt).isBetween(
          start === null ? undefined : moment(start),
          end === null ? undefined : moment(end)
        )) ||
        item.data.status === "doing")
  );
};
