import React from "react";

const AvatarArrow = ({ width, height, onClick }) => {
  return (
    <svg
      width={width}
      height={height}
      onClick={onClick}
      viewBox="0 0 8 4"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.84013 0.671875L4.28309 3.22892C3.98111 3.5309 3.48695 3.5309 3.18497 3.22892L0.62793 0.671875"
        stroke="#7E858F"
        strokeWidth="0.941243"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AvatarArrow;
