import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Dropdown,
  Header,
  Icon,
  Input,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import { fetchUsers } from "../../state/users/reducers";
import { ContainerCenter, Dashboard } from "./styles";
import moment from "moment";
import Loading from "../../components/LoadingTemplate";
import { fetchActivedTasks } from "../../state/activedTasks/reducers";

const FilterPage = ({ location }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const params = new URLSearchParams(location.search);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const usersStatus = useSelector((state) => state.users.status);
  const accountable = [
    { key: "undefined", value: "undefined", text: "Todos" },
    { key: "Ninguém", value: "Ninguém", text: user?.username },
  ];

  const users = useSelector((state) => state.users.entities);

  const [employeer, setEmployeer] = useState(params.get("employeer"));
  const [status, setStatus] = useState(params.get("status"));
  const [title, setTitle] = useState(params.get("title").toUpperCase());
  const [client, setClient] = useState(params.get("client").toUpperCase());
  const [flowTitle, setFlowTitle] = useState(
    params.get("flowTitle").toUpperCase()
  );
  const [flowType, setFlowType] = useState(params.get("flowType"));

  const activedTaskStatus = useSelector((state) => state.activedTasks.status);
  const nowLocal = moment().utcOffset(-180);
  const [activePage, setActivePage] = useState(1);

  const tasks = useSelector((state) => state.activedTasks.entities);
  const pages = useSelector((state) => state.activedTasks.pages);

  users.forEach((item, index) => {
    accountable.push({
      key: index,
      value: item.email,
      text: item.username,
    });
  });

  const flowTypeOptions = [
    {
      key: "undefined",
      value: "undefined",
      text: "Todos os tipos",
    },

    { key: "actived", value: "actived", text: "Ativo" },
    {
      key: "finished",
      value: "finished",
      text: "Encerrado",
    },
  ];

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      history.push({
        pathname: "/filter",
        search:
          "?" +
          new URLSearchParams({
            title,
            flowTitle,
            client,
            status,
            flowType,
            employeer,
          }).toString(),
      });
      setActivePage(1);
    }
  };

  const statusOptions = [
    {
      key: "undefined",
      value: "undefined",
      text: "Todas as tarefas",
    },
    {
      key: "pending",
      value: "pending",
      text: "Tarefa pendente",
    },
    {
      key: "doing",
      value: "doing",
      text: "Tarefa em andamento",
    },
    {
      key: "done",
      value: "done",
      text: "Tarefa concluída",
    },
    {
      key: "expired",
      value: "expired",
      text: "Tarefa expirada",
    },
    {
      key: "doneExpired",
      value: "doneExpired",
      text: "Tarefa concluída fora do prazo",
    },
  ];

  useEffect(() => {
    if (token === null) history.push("/login");

    if (usersStatus === "idle")
      dispatch(fetchUsers(!user.enterpriseId ? user?._id : user.enterpriseId));

    dispatch(
      fetchActivedTasks({
        enterpriseId: !user.enterpriseId ? user?._id : user.enterpriseId,
        page: params.get("page") ? params.get("page") : 1,
        title: params.get("title") ? params.get("title") : "undefined",
        flowTitle: params.get("flowTitle")
          ? params.get("flowTitle")
          : "undefined",
        client: params.get("client") ? params.get("client") : "undefined",
        status: params.get("status") ? params.get("status") : "undefined",
        flowType: params.get("flowType") ? params.get("flowType") : "undefined",
        employeer: params.get("employeer")
          ? params.get("employeer")
          : "undefined",
      })
    );
  }, [dispatch]);

  return usersStatus === "succeeded" && activedTaskStatus === "succeeded" ? (
    <ContainerCenter>
      <Segment compact>
        <Dashboard>
          <Header style={{ alignSelf: "center", backgroundColor: "white" }}>
            Filtrar Tarefas
          </Header>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Input
              style={{ marginRight: "10px", marginTop: "33px" }}
              label="Fluxo"
              onChange={(e) => {
                const pointer = e.target.selectionStart;

                window.requestAnimationFrame(() => {
                  e.target.selectionStart = pointer;
                  e.target.selectionEnd = pointer;
                });

                setFlowTitle(e.target.value.toUpperCase());
              }}
              onKeyDown={handleKeyDown}
              value={flowTitle.toUpperCase()}
            />
            <div style={{ marginRight: "10px", marginTop: "33px" }}>
              <Input
                label="Cliente"
                onChange={(e) => {
                  const pointer = e.target.selectionStart;

                  window.requestAnimationFrame(() => {
                    e.target.selectionStart = pointer;
                    e.target.selectionEnd = pointer;
                  });

                  setClient(e.target.value.toUpperCase());
                }}
                onKeyDown={handleKeyDown}
                value={client.toUpperCase()}
              />
            </div>
            <div style={{ marginRight: "10px", marginTop: "33px" }}>
              <Input
                label="Tarefa"
                onChange={(e) => {
                  const pointer = e.target.selectionStart;

                  window.requestAnimationFrame(() => {
                    e.target.selectionStart = pointer;
                    e.target.selectionEnd = pointer;
                  });

                  setTitle(e.target.value.toUpperCase());
                }}
                onKeyDown={handleKeyDown}
                value={title.toUpperCase()}
              />
            </div>

            <div style={{ marginRight: "20px" }}>
              <h4>Status da tarefa</h4>
              <Dropdown
                placeholder="Status da tarefa"
                fluid
                selection
                onChange={(e, data) => {
                  setStatus(data.value);
                }}
                options={statusOptions}
                defaultValue={status}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div style={{ marginRight: "10px" }}>
              <h4>Tipo de fluxo</h4>
              <Dropdown
                placeholder={flowType ? flowType : "Tipo de fluxo"}
                fluid
                selection
                onChange={(e, data) => {
                  setFlowType(data.value);
                }}
                options={flowTypeOptions}
                defaultValue={flowType}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div>
              <h4>Funcionário</h4>
              <Dropdown
                placeholder={employeer ? employeer : "Funcionário"}
                fluid
                search
                selection
                onChange={(e, data) => {
                  setEmployeer(data.value);
                }}
                options={accountable}
                defaultValue={employeer}
                onKeyDown={handleKeyDown}
              />
            </div>

            <Button
              style={{ margin: "10px" }}
              content="Filtrar"
              icon="search"
              labelPosition="right"
              color="blue"
              onClick={() => {
                history.push({
                  pathname: "/filter",
                  search:
                    "?" +
                    new URLSearchParams({
                      title,
                      flowTitle,
                      client,
                      status,
                      flowType,
                      employeer,
                    }).toString(),
                });

                dispatch(
                  fetchActivedTasks({
                    enterpriseId: !user.enterpriseId
                      ? user?._id
                      : user.enterpriseId,
                    page: 1,
                    title: title ? title : "undefined",
                    flowTitle: flowTitle ? flowTitle : "undefined",
                    client: client ? client : "undefined",
                    status: status ? status : "undefined",
                    flowType: flowType ? flowType : "undefined",
                    employeer: employeer ? employeer : "undefined",
                  })
                );

                setActivePage(1);
              }}
            />
          </div>
          {tasks?.length > 0 ? (
            <>
              <Table celled padded color="yellow" selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell singleLine>Fluxo</Table.HeaderCell>
                    <Table.HeaderCell singleLine>Cliente</Table.HeaderCell>
                    <Table.HeaderCell>Tarefa</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Tipo de fluxo</Table.HeaderCell>
                    <Table.HeaderCell>Responsável</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {tasks?.map((item, index) => (
                    <Table.Row
                      key={index}
                      onClick={() =>
                        item.data.flowType === "finished"
                          ? history.push(
                              `/finishedviewer/${item.flowId}/${item.id}`
                            )
                          : history.push(
                              `/activedviewer/${item.flowId}/${item.id}`
                            )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Table.Cell style={{ padding: "10px" }}>
                        {item?.data?.flowTitle}
                      </Table.Cell>
                      <Table.Cell style={{ padding: "10px" }}>
                        {item?.data?.client}
                      </Table.Cell>
                      <Table.Cell style={{ padding: "10px" }}>
                        {item.data.label.toUpperCase()}
                      </Table.Cell>
                      <Table.Cell style={{ padding: "10px" }}>
                        {item.data.status.toUpperCase() === "DOING"
                          ? moment(item.data.startedAt)
                              .add(item.data.expiration.number, "hours")
                              .diff(nowLocal, "hours", true) < 0
                            ? "EXPIRADA"
                            : "EM ANDAMENTO"
                          : item.data.status.toUpperCase() === "DONE"
                          ? item.data.expired === true
                            ? "CONCLUÍDA FORA DO PRAZO"
                            : "CONCLUÍDA"
                          : "PENDENTE"}
                      </Table.Cell>
                      <Table.Cell>
                        {item.data.flowType === "finished"
                          ? "ENCERRADO"
                          : "ATIVO"}
                      </Table.Cell>
                      <Table.Cell style={{ padding: "10px" }}>
                        {item.data.accountable === "Ninguém"
                          ? user?.username?.toUpperCase()
                          : users
                              ?.find((it) => it.email === item.data.accountable)
                              ?.username?.toUpperCase()}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <Pagination
                style={{ alignSelf: "center" }}
                activePage={activePage}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                onPageChange={(e, pageInfo) => {
                  setActivePage(pageInfo.activePage);
                  dispatch(
                    fetchActivedTasks({
                      enterpriseId: !user.enterpriseId
                        ? user?._id
                        : user.enterpriseId,
                      page: pageInfo.activePage,
                      title: params.get("title")
                        ? params.get("title")
                        : "undefined",
                      flowTitle: params.get("flowTitle")
                        ? params.get("flowTitle")
                        : "undefined",
                      client: params.get("client")
                        ? params.get("client")
                        : "undefined",
                      status: params.get("status")
                        ? params.get("status")
                        : "undefined",
                      flowType: params.get("flowType")
                        ? params.get("flowType")
                        : "undefined",
                      employeer: params.get("employeer")
                        ? params.get("employeer")
                        : "undefined",
                    })
                  );
                }}
                totalPages={pages}
              />
            </>
          ) : (
            <p style={{ alignSelf: "center", margin: "30px", fontSize: 30 }}>
              Não existe tarefas disponíveis
            </p>
          )}
        </Dashboard>
      </Segment>
    </ContainerCenter>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

export default FilterPage;
