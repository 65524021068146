import React from "react";
import {
  getEdgeCenter,
  getMarkerEnd,
  getSmoothStepPath,
  Position,
} from "react-flow-renderer";
import { GlobalStyle } from "./styles";

import "../../../node_modules/react-flow-renderer/dist/style.css";

const CustomEdgeWithoutLabel = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  targetPosition = Position.Left,
  sourcePosition = Position.Right,
  style = {
    stroke: "#222",
    strokeWidth: 1.5,
    fill: "none",
    strokeDasharray: 5,
  },
  data,
  arrowHeadType,
  markerEndId,
  selected,
}) => {
  if (Math.abs(sourceY - targetY) < 1) {
    sourceY = targetY;
  }

  const center = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
    borderRadius: 0,
    centerX: center[0],
    centerY: center[1],
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  return (
    <>
      <GlobalStyle />
      <path
        id={id}
        style={
          selected === true
            ? {
                ...style,
                strokeWidth: 1.5,
                stroke: "blue",
                fill: "none",
                pointerEvents: "all",
              }
            : data.status === "done"
            ? {
                ...style,
                strokeWidth: 1.5,
                stroke: "#339933",
                fill: "none",
                pointerEvents: "all",
              }
            : data.criticalPath === true
            ? { ...style, pointerEvents: "all", stroke: "red" }
            : { ...style, pointerEvents: "all" }
        }
        d={edgePath}
        markerEnd={markerEnd}
      />

      <path
        id={id}
        style={{
          ...style,
          stroke: "transparent",
          strokeWidth: "5px",
          strokeDasharray: 0,
        }}
        d={edgePath}
        strokeLinejoin="round"
      />
    </>
  );
};

export default CustomEdgeWithoutLabel;
