import moment from "moment";
import "moment/min/locales";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  editSubtasks,
  editTaskAccountable,
  handleDeleteSubtask,
  handleEditChanges,
  handleEditComment,
  handleEditSubtask,
  handleFetchSingleActivedFlow,
  handleNewSubtask,
  handleNewTaskTitle,
  handleSendTask,
} from "../../state/activedFlows/actions";
import { findActivedTaskOneFlow } from "../../state/activedFlows/selectors";
import {
  Button,
  Confirm,
  Dropdown,
  Icon,
  Input,
  Progress,
  Segment,
} from "semantic-ui-react";
import {
  Modal,
  Checkbox,
  Table,
  Header,
  Comment,
  Form,
} from "semantic-ui-react";
import { fetchUsers } from "../../state/users/reducers";
import "semantic-ui-css/semantic.min.css";
import {
  handleNewPost,
  handleNewPostSubtask,
} from "../../state/activedFlows/actions";
import {
  HeaderModal,
  HeaderTitle,
  StickyNotes,
  StickyTitle,
  TitleInput,
  TitleName,
} from "./styles";
import Upload from "../UploadFile";
import { uniqueId } from "lodash";
import filesize from "filesize";
import FileList from "./FileList/index";
import api from "../../services/api";
import Loading from "../../components/LoadingTemplate/index";

const ActivedTaskModal = ({ flow, taskId, open, change, counterRef }) => {
  const dispatch = useDispatch();
  moment.locale("pt-br");
  const [error, setError] = useState(null);
  const [commentEdition, setCommentEdition] = useState(false);
  const [commentEditionSubtask, setCommentEditionSubtask] = useState(false);
  const [stateSubtask, setStateSubtask] = useState("");
  const task = useSelector((state) =>
    findActivedTaskOneFlow(taskId, flow?._id, state.activedFlows.entities)
  ); //task.data.accountable = usuario delegado
  const users = useSelector((state) => state.users.entities);
  const usersStatus = useSelector((state) => state.users.status);
  const user = useSelector((state) => state.auth.user);
  const enterpriseId = !user.enterpriseId ? user._id : user.enterpriseId;
  const [titleError, setTitleError] = useState(false);
  const [subtaskTitle, setSubtaskTitle] = useState("");
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [subtaskToDelete, setSubtaskToDelete] = useState("");
  const [subtaskModal, setSubtaskModal] = useState(false);
  const [state, setState] = useState("");
  const nowLocal = moment().utcOffset(-180);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [newName, setNewName] = useState("");
  const [mainSubtask, setMainSubtask] = useState(false);
  const [comments, setComments] = useState("");
  const [subtasks, setSubtasks] = useState("");
  const [employeers, setEmployeers] = useState([]);
  const [editTitle, setEditTitle] = useState(false);
  const [editTitleName, setEditTitleName] = useState("");
  const [files, setFiles] = useState({ uploadedFiles: [] });
  const filesRef = useRef({});
  filesRef.current = files;

  const handleUpload = (items) => {
    const uploadedFiles = items.map((file) => ({
      file,
      id: uniqueId(),
      name: file.name,
      readableSize: filesize(file.size),
      preview: URL.createObjectURL(file),
      progress: 0,
      uploaded: false,
      error: false,
      url: null,
    }));

    setFiles({
      uploadedFiles: filesRef.current.uploadedFiles.concat(uploadedFiles),
    });

    uploadedFiles.forEach(processUpload);
  };

  const updateFile = (id, data) => {
    setFiles({
      uploadedFiles: filesRef.current.uploadedFiles.map((uploadedFile) => {
        return id === uploadedFile.id
          ? { ...uploadedFile, ...data }
          : uploadedFile;
      }),
    });
  };

  const processUpload = (item) => {
    const data = new FormData();

    data.append("file", item.file, item.name);
    data.append("originalId", task._id);
    data.append("type", "task");
    data.append("enterpriseId", enterpriseId);

    api
      .put("/actived-flows/actived-flow/task/new-file", data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        onUploadProgress: (e) => {
          const progress = parseInt(Math.round((e.loaded * 100) / e.total));

          updateFile(item.id, {
            progress,
          });
        },
      })
      .then((response) => {
        updateFile(item.id, {
          uploaded: true,
          id: response.data._id,
          url: response.data.url,
        });
      })
      .catch(() => {
        updateFile(item.id, {
          error: true,
        });
      });
  };

  const handleDelete = async (id) => {
    await api.delete(`actived-flows/actived-flow/task/remove-file/${id}`);

    setFiles({
      uploadedFiles: filesRef.current.uploadedFiles.filter(
        (file) => file.id !== id
      ),
    });
  };

  const handleSubmit = () => {
    if (state !== "") {
      setLoading(true);
      dispatch(
        handleNewPost(task._id, {
          username: user?.username,
          text: state,
          time: nowLocal,
        })
      ).then(() => {
        setState("");
        setLoading(false);
      });
    }
  };

  const taskValidation = (subtasks, haveFile, isAttachFile) => {
    //Caso necessite de arquivo obrigatorio e não tenha no anexo
    if (!haveFile && isAttachFile) {
      exitModal("Anexe o arquivo necessário!");
      return false;
    } else if (subtasks) {
      //Caso haja subtasks desmarcadas
      if (subtasks.find((e) => e.checked === false)) {
        exitModal("Termine as sub-tarefas para poder enviar");
        return false;
      } else {
        exitModal("");
        return true;
      }
    } else {
      return true;
    }
  };

  useEffect(() => {
    async function fetchFiles() {
      if (open) {
        const response = await api.get(
          `/actived-flows/actived-flow/task/get-files/${task._id}`
        );

        setFiles({
          uploadedFiles: response.data.map((file) => ({
            id: file._id,
            name: file.name,
            readableSize: filesize(file.size),
            preview: file.url,
            uploaded: true,
            url: file.url,
          })),
        });
      }
    }

    fetchFiles();
  }, [open]);

  const handleSubmitSubtask = () => {
    if (stateSubtask !== "") {
      setLoading(true);
      dispatch(
        handleNewPostSubtask(
          task._id,
          {
            username: user?.username,
            text: stateSubtask,
            time: nowLocal,
          },
          mainSubtask
        )
      ).then(() => {
        setStateSubtask("");
        setLoading(false);
      });
    }
  };

  const handleSubtaskToDelete = (item) => {
    setLoading(true);
    dispatch(handleDeleteSubtask(task._id, item)).then(() => {
      setDeleteConfirm(false);
      setLoading(false);
    });
  };

  const handleCancel = () => setDeleteConfirm(false);

  useEffect(() => {});

  useEffect(() => {
    if (open && !commentEdition) setComments(task?.data?.comments);
  }, [task]);

  useEffect(() => {
    if (open) {
      //Pre-carregando estados ao abrir o modal
      setComments(task?.data?.comments);
      setSubtasks(task?.data?.subtasks);
      setEditTitleName(task?.data?.label);
    }
  }, [open]);

  useEffect(() => {
    if (subtaskModal) {
      setComments(task?.data?.subtasks[mainSubtask]?.comments);
    }
  }, [subtaskModal]);

  useEffect(() => {
    if (usersStatus === "idle" && open) {
      dispatch(
        fetchUsers(user?.rank === "Gerente" ? enterpriseId : user?.enterpriseId)
      );
    }
    if (open && usersStatus === "succeeded") {
      setEmployeers(() => {
        const newArray = [
          {
            key: "Ninguém",
            value: "Ninguém",
            text:
              user.rank === "Funcionário"
                ? user?.enterpriseName
                : user.username,
          },
        ];

        users.forEach((item, index) => {
          newArray.push({
            key: index,
            value: item.email,
            text: item.username,
          });
        });
        return newArray;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersStatus, open]);

  const exitModal = (msg) => {
    setError(msg);
    setConfirmOpen(false);
  };

  //A tarefa expirou? (caso a saída seja < 0)
  const isExpired = (task) => {
    return moment(task.data.startedAt)
      .add(task.data.expiration.number, "hours")
      .diff(nowLocal, "hours", true);
  };
  const [loading, setLoading] = useState(false);

  return task !== null &&
    task !== undefined &&
    task.data !== undefined &&
    loading === false &&
    open === true ? (
    <Modal
      onClose={() => change(false)}
      onOpen={() => change(true)}
      open={open}
    >
      <Modal
        basic
        onClose={() => setSubtaskModal(false)}
        onOpen={() => setSubtaskModal(true)}
        open={subtaskModal}
        size="small"
      >
        <Modal.Header>{task.data.subtasks[mainSubtask]?.title}</Modal.Header>

        <Modal.Content
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Segment>
            <Comment.Group minimal style={{ maxWidth: "400px" }}>
              <Header as="h3" dividing style={{ backgroundColor: "white" }}>
                Comentários
              </Header>

              {task.data.subtasks[mainSubtask]?.posts?.map((item, index) => (
                <Comment key={index}>
                  <Comment.Content>
                    <Comment.Author as="a">{item.username}</Comment.Author>
                    <Comment.Metadata>
                      <div>{`${moment(item.time).calendar()} | ${moment(
                        item.time
                      ).from(nowLocal)}`}</div>
                    </Comment.Metadata>
                    <Comment.Text>{item.text}</Comment.Text>
                  </Comment.Content>
                </Comment>
              ))}

              {flow.status[0] !== "finished" ? (
                <Form onSubmit={handleSubmitSubtask}>
                  <Form.TextArea
                    name="post"
                    value={stateSubtask}
                    onChange={(e) => setStateSubtask(e.target.value)}
                  />
                  <Button
                    content="Responder"
                    labelPosition="left"
                    icon={<Icon name="edit" style={{ paddingLeft: 2 }} />}
                    primary
                    type="submit"
                  />
                </Form>
              ) : null}
            </Comment.Group>
          </Segment>
          <StickyNotes
            style={{
              minWidth: "300px",
              minHeight: "200px",
              right: "60px",
              marginLeft: "80px",
            }}
          >
            <StickyTitle>
              <div
                style={
                  commentEditionSubtask === false
                    ? { position: "relative", left: "10px", color: "black" }
                    : { position: "relative", left: "22px", color: "black" }
                }
              >
                Anotações
              </div>

              {flow.status[0] !== "finished" ? (
                commentEditionSubtask === false ? (
                  <Icon
                    style={{
                      position: "relative",
                      background: "none",
                      padding: 0,
                      cursor: "pointer",
                      left: "100px",
                      margin: 0,
                      color: "black",
                    }}
                    name="edit"
                    onClick={() => {
                      setCommentEditionSubtask(true);
                    }}
                  />
                ) : (
                  <>
                    <Icon
                      style={{
                        position: "relative",
                        background: "none",
                        padding: 0,
                        cursor: "pointer",
                        right: "155px",
                        color: "black",
                      }}
                      name="cancel"
                      onClick={() => {
                        setCommentEditionSubtask(false);
                      }}
                    />
                    <Icon
                      style={{
                        position: "relative",
                        background: "none",
                        padding: 0,
                        cursor: "pointer",
                        left: "90px",
                        color: "black",
                      }}
                      name="check"
                      onClick={() => {
                        setLoading(true);
                        dispatch(
                          handleEditChanges(
                            task.id,
                            flow._id,
                            subtasks,
                            task.data.accountable,
                            enterpriseId
                          )
                        ).then(() => {
                          setCommentEditionSubtask(false);
                          setLoading(false);
                        });
                      }}
                    />
                  </>
                )
              ) : null}
            </StickyTitle>

            {commentEditionSubtask ? (
              <Form style={{ marginBottom: "10px" }}>
                <Form.TextArea
                  rows={6}
                  disabled={!commentEditionSubtask}
                  name="post"
                  value={subtasks[mainSubtask]?.comments}
                  onChange={(e) =>
                    setSubtasks((prev) => {
                      let newArray = JSON.parse(JSON.stringify(prev));
                      newArray[mainSubtask].comments = e.target.value;
                      return newArray;
                    })
                  }
                />
              </Form>
            ) : (
              <h5
                style={{
                  margin: "10px",
                  overflowWrap: "break-word",
                  whiteSpace: "pre-wrap",
                  textAlign: "left",
                }}
              >
                {subtasks[mainSubtask]?.comments}
              </h5>
            )}
          </StickyNotes>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="blue"
            icon="arrow left"
            labelPosition="left"
            onClick={() => setSubtaskModal(false)}
            content="Voltar"
          />
        </Modal.Actions>
      </Modal>
      <Confirm
        open={deleteConfirm}
        content={`Tem certeza que deseja excluir a sub-tarefa ${subtaskToDelete.title}?`}
        onCancel={handleCancel}
        onConfirm={() => handleSubtaskToDelete(subtaskToDelete)}
        confirmButton="Confirmar"
        cancelButton="Cancelar"
      />
      <Modal
        basic
        onClose={() => setConfirmOpen(false)}
        onOpen={() => setConfirmOpen(true)}
        open={confirmOpen}
        size="small"
      >
        <Header icon>
          <Icon name="archive" />
          Concluir tarefa
        </Header>
        <Modal.Content>
          <div style={{ color: "white", fontSize: 18 }}>
            Antes de concluir a tarefa {task.data.label.toUpperCase()},
            certifique-se de verificar os comentários e anotações. Só prossiga
            caso tudo esteja em ordem. Essa é uma ação irreversível!
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            basic
            color="red"
            inverted
            onClick={() => setConfirmOpen(false)}
          >
            <Icon name="remove" /> Cancelar
          </Button>
          <Button
            color="green"
            inverted
            onClick={() => {
              let haveFile = !!files.uploadedFiles[0]; //sinal de itens armazenados
              let isAttachfile = task.data.attachFile; //sinal de anexo obrigatorio

              let validation = taskValidation(
                task.data.subtasks,
                haveFile,
                isAttachfile
              );

              if (validation) {
                //Caso haja subtasks porém todas selecionadas
                setLoading(true);
                dispatch(
                  handleEditChanges(
                    task.id,
                    flow._id,
                    task.data.subtasks,
                    task.data.accountable,
                    enterpriseId
                  )
                ).then(() =>
                  dispatch(handleSendTask(flow._id, task._id)).then(() =>
                    dispatch(
                      handleFetchSingleActivedFlow({
                        enterpriseId: !user.enterpriseId
                          ? user?.id
                          : user.enterpriseId,
                        flowId: flow._id,
                      })
                    ).then(() => {
                      setConfirmOpen(false);
                      change(false);
                      setLoading(false);
                    })
                  )
                );
              }
            }}
          >
            <Icon name="checkmark" /> Concluir tarefa
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal.Header>
        <HeaderModal>
          <HeaderTitle>
            {!editTitle ? (
              <>
                <TitleName>{task.data.label.toUpperCase()}</TitleName>
                <Icon
                  name="edit"
                  style={{
                    cursor: "pointer",
                    padding: 0,
                    fontSize: 15,
                    marginTop: 2,
                    marginLeft: 15,
                  }}
                  onClick={() => setEditTitle(true)}
                />
              </>
            ) : (
              <>
                <TitleInput
                  value={editTitleName}
                  onChange={(e) => {
                    const pointer = e.target.selectionStart;

                    window.requestAnimationFrame(() => {
                      e.target.selectionStart = pointer;
                      e.target.selectionEnd = pointer;
                    });
                    setEditTitleName(e.target.value);
                  }}
                />
                <Icon
                  name="check"
                  style={{
                    padding: 0,
                    fontSize: 15,
                    marginLeft: 15,
                    marginTop: 5,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    if (editTitleName.length !== 0) {
                      setLoading(true);
                      dispatch(
                        handleNewTaskTitle(task._id, editTitleName)
                      ).then(() => {
                        setEditTitle(false);
                        setLoading(false);
                      });
                    }
                  }}
                />
              </>
            )}
          </HeaderTitle>
          <div>
            {task.data.status === "done"
              ? task.data.expired === true
                ? "Tarefa concluída fora do prazo"
                : "Tarefa concluída"
              : task.data.status === "doing"
              ? "Tarefa em andamento"
              : "Tarefa pendente"}
          </div>
        </HeaderModal>
      </Modal.Header>
      <Modal.Content
        scrolling
        className="content"
        style={{ minHeight: "550px" }}
      >
        {task.type === "task" ? (
          <Progress
            percent={
              task.data.status !== "done"
                ? Math.round(
                    (task.data.subtasks.filter((e) => e.checked === true)
                      .length *
                      100) /
                      task.data.subtasks.length
                  )
                : 100
            }
            indicating
            progress
          >
            Andamento da tarefa
          </Progress>
        ) : null}

        <HeaderModal>
          {task.data.status === "doing" && task.type === "task" ? (
            moment(task.data.startedAt)
              .add(task.data.expiration.number, "hours")
              .diff(nowLocal, "hours", true) >= 0 ? (
              <h3 style={{ color: "red", marginRight: "50px" }}>
                A tarefa irá expirar em{" "}
                {task.data.expiration.time === "days"
                  ? (
                      moment(task.data.startedAt)
                        .add(task.data.expiration.number, "hours")
                        .diff(nowLocal, "hours", true) / 24
                    ).toFixed(2)
                  : task.data.expiration.time === "hours"
                  ? moment(task.data.startedAt)
                      .add(task.data.expiration.number, "hours")
                      .diff(nowLocal, "hours", true)
                      .toFixed(2)
                  : task.data.expiration.time === "minutes"
                  ? (
                      moment(task.data.startedAt)
                        .add(task.data.expiration.number, "hours")
                        .diff(nowLocal, "hours", true) * 60
                    ).toFixed(2)
                  : "indefinido"}{" "}
                {task.data.expiration.time === "days"
                  ? "dias"
                  : task.data.expiration.time === "hours"
                  ? "horas"
                  : task.data.expiration.time === "minutes"
                  ? "minutos"
                  : "indefinido"}
              </h3>
            ) : (
              <h3 style={{ color: "red", marginRight: "50px" }}>
                TAREFA EXPIRADA HÁ{" "}
                {Math.round(
                  nowLocal.diff(
                    moment(task?.data?.startedAt).add(
                      task.data.expiration.number,
                      "hours"
                    ),
                    "hours",
                    true
                  ) / 24
                )}{" "}
                DIAS
              </h3>
            )
          ) : null}

          {task.data.startedAt ? (
            <div style={{ marginBottom: "20px" }}>
              <h3 style={{ marginRight: "50px" }}>
                Início: {moment(task.data.startedAt).format("LLL")}
              </h3>
            </div>
          ) : null}
          {task.data.finishedAt ? (
            <div style={{ marginBottom: "20px" }}>
              <h3 style={{ marginRight: "50px" }}>
                Fim: {moment(task.data.finishedAt).format("LLL")}
              </h3>
            </div>
          ) : null}
        </HeaderModal>

        {task.data.subtasks && task.data.subtasks.length > 0 ? (
          <div>
            <Table celled>
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell>SUB-TAREFAS</Table.HeaderCell>
                  <Table.HeaderCell>EDITAR</Table.HeaderCell>
                  <Table.HeaderCell>EXCLUIR</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {task.data.subtasks.map((item, index) => (
                  <Table.Row key={index}>
                    <Table.Cell>
                      {task.data.accountable === user.email ||
                      user.rank === "Gerente" ? (
                        <Checkbox
                          id={index}
                          disabled={task.data.status !== "doing"}
                          checked={item.checked}
                          onClick={(value) => {
                            //mudança local editSubtask
                            dispatch(
                              editSubtasks({
                                checked: value.target.checked,
                                flowId: flow._id,
                                taskId: task.id,
                                index,
                              })
                            );
                          }}
                        />
                      ) : null}
                    </Table.Cell>

                    <Table.Cell>
                      {editMode === index ? (
                        <div>
                          <Input
                            value={newName}
                            onChange={(e) => {
                              const pointer = e.target.selectionStart;

                              window.requestAnimationFrame(() => {
                                e.target.selectionStart = pointer;
                                e.target.selectionEnd = pointer;
                              });
                              setNewName(e.target.value.toUpperCase());
                            }}
                          />
                          <Icon
                            name="check"
                            style={{ marginLeft: "10px", cursor: "pointer" }}
                            onClick={() => {
                              setLoading(true);
                              dispatch(
                                handleEditSubtask(task._id, newName, item.title)
                              ).then(() => {
                                setEditMode(false);
                                setLoading(false);
                              });
                            }}
                          />
                        </div>
                      ) : (
                        <p
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setSubtaskModal(true);
                            setMainSubtask(index);
                          }}
                        >
                          {item.title}
                        </p>
                      )}
                    </Table.Cell>
                    {editMode === index &&
                    (task.data.accountable === user.email ||
                      user.rank === "Gerente") ? (
                      <Table.Cell
                        style={{ cursor: "pointer" }}
                        onClick={() => setEditMode(false)}
                      >
                        <Icon
                          name="delete"
                          style={{
                            background: "none",
                            padding: 0,
                            margin: 0,
                          }}
                        />
                      </Table.Cell>
                    ) : task.data.accountable === user.email ||
                      user.rank === "Gerente" ? (
                      <Table.Cell
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setEditMode(index);
                          setNewName(item.title);
                        }}
                      >
                        <Icon
                          name="edit"
                          style={{
                            position: "relative",
                            background: "none",
                            padding: 0,
                            margin: 0,
                          }}
                        />
                      </Table.Cell>
                    ) : null}
                    {task.data.accountable === user.email ||
                    user.rank === "Gerente" ? (
                      <Table.Cell
                        negative
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          setDeleteConfirm(true);
                          setSubtaskToDelete(item);
                          setEditMode(false);
                        }}
                      >
                        <Icon name="trash" />
                      </Table.Cell>
                    ) : null}
                  </Table.Row>
                ))}
              </Table.Body>
            </Table>
            {error && <h3 style={{ color: "red" }}>{error}</h3>}
          </div>
        ) : null}

        {task.data.status !== "done" &&
        (task.data.accountable === user.email || user.rank === "Gerente") ? (
          <div style={{ alignItems: "center", display: "flex" }}>
            <Input
              label="Adicionar sub-tarefa"
              placeholder="Título"
              error={titleError}
              value={subtaskTitle}
              onChange={(e) => {
                const pointer = e.target.selectionStart;

                window.requestAnimationFrame(() => {
                  e.target.selectionStart = pointer;
                  e.target.selectionEnd = pointer;
                });
                setSubtaskTitle(e.target.value.toUpperCase());

                if (e.target.value !== "" && titleError === true)
                  setTitleError(false);
              }}
            />
            <Button
              style={{ marginLeft: "10px" }}
              content="Adicionar"
              icon="plus"
              labelPosition="right"
              color="blue"
              onClick={() => {
                if (subtaskTitle === "") {
                  setTitleError(true);
                } else {
                  setTitleError(false);
                  setLoading(true);
                  dispatch(handleNewSubtask(task._id, subtaskTitle)).then(
                    () => {
                      setSubtaskTitle("");
                      setLoading(false);
                    }
                  );
                }
              }}
            />
          </div>
        ) : null}

        {task.type === "task" &&
        task.data.status !== "done" &&
        user.rank === "Gerente" ? (
          <div
            style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
          >
            <h4>Delegar para:</h4>
            <Dropdown
              placeholder={task.data.accountable}
              fluid
              search
              selection
              onChange={(e, data) => {
                dispatch(
                  editTaskAccountable({
                    flowId: flow._id,
                    taskId,
                    accountable: data.value,
                  })
                );
              }}
              defaultValue={task.data.accountable}
              options={employeers}
            />
          </div>
        ) : task.type === "task" ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            {" "}
            <h4>Delegada para: {task.data.accountable}</h4>
          </div>
        ) : null}
        {task.data.attachFile && (
          <h4
            style={{ color: "red", display: "flex", justifyContent: "center" }}
          >
            Anexo obrigatorio
          </h4>
        )}
        <Upload onUpload={handleUpload} />

        <div>
          {!!filesRef.current.uploadedFiles.length && (
            <FileList
              files={filesRef.current.uploadedFiles}
              onDelete={handleDelete}
            />
          )}
        </div>

        <div
          style={{ display: "flex", flexDirection: "row", marginTop: "10px" }}
        >
          {task.type === "task" ? (
            <Comment.Group minimal style={{ maxWidth: "400px" }}>
              <Header as="h3" dividing style={{ backgroundColor: "white" }}>
                Comentários
              </Header>

              {task.data.posts.map((item, index) => (
                <Comment key={index}>
                  <Comment.Content>
                    <Comment.Author as="a">{item.username}</Comment.Author>
                    <Comment.Metadata>
                      <div>{`${moment(item.time).calendar()} | ${moment(
                        item.time
                      ).from(nowLocal)}`}</div>
                    </Comment.Metadata>
                    <Comment.Text>{item.text}</Comment.Text>
                  </Comment.Content>
                </Comment>
              ))}

              {flow.status[0] !== "finished" ? (
                <Form onSubmit={handleSubmit}>
                  <Form.TextArea
                    name="post"
                    value={state}
                    onChange={(e) => setState(e.target.value)}
                  />
                  <Button
                    content="Responder"
                    labelPosition="left"
                    icon={<Icon name="edit" style={{ paddingLeft: 2 }} />}
                    primary
                    type="submit"
                  />
                </Form>
              ) : null}
            </Comment.Group>
          ) : null}
          {task.type === "task" ? (
            <StickyNotes
              style={{
                width: "400px",
                height: "300px",
                right: "60px",
                marginLeft: "80px",
              }}
            >
              <StickyTitle>
                <div
                  style={
                    commentEdition === false
                      ? { position: "relative", left: "10px" }
                      : { position: "relative", left: "22px" }
                  }
                >
                  Anotações
                </div>

                {flow.status[0] !== "finished" ? (
                  commentEdition === false ? (
                    <Icon
                      style={{
                        position: "relative",
                        background: "none",
                        padding: 0,
                        cursor: "pointer",
                        left: "150px",
                        margin: 0,
                      }}
                      name="edit"
                      onClick={() => {
                        setCommentEdition(true);
                      }}
                    />
                  ) : (
                    <>
                      <Icon
                        style={{
                          position: "relative",
                          background: "none",
                          padding: 0,
                          cursor: "pointer",
                          right: "205px",
                        }}
                        name="cancel"
                        onClick={() => {
                          setCommentEdition(false);
                        }}
                      />
                      <Icon
                        style={{
                          position: "relative",
                          background: "none",
                          padding: 0,
                          cursor: "pointer",
                          left: "140px",
                        }}
                        name="check"
                        onClick={() => {
                          setLoading(true);
                          dispatch(
                            handleEditComment(
                              "task",
                              flow._id,
                              task.id,
                              comments,
                              enterpriseId
                            )
                          ).then(() => {
                            setCommentEdition(false);
                            setLoading(false);
                          });
                        }}
                      />
                    </>
                  )
                ) : null}
              </StickyTitle>

              {commentEdition ? (
                <Form style={{ marginBottom: "10px" }}>
                  <Form.TextArea
                    rows={6}
                    disabled={!commentEdition}
                    name="post"
                    value={comments}
                    onChange={(e) => setComments(e.target.value)}
                  />
                </Form>
              ) : (
                <h5
                  style={{
                    margin: "10px",
                    overflowWrap: "break-word",
                    whiteSpace: "pre-wrap",
                    textAlign: "left",
                  }}
                >
                  {task.data.comments}
                </h5>
              )}
            </StickyNotes>
          ) : null}
        </div>
        {isExpired(task) < 0 && task.data.lockTask && user.rank !== "Gerente" && (
          <div style={{ display: "flex", justifyContent: "right" }}>
            <p style={{ color: "red" }}>Bloqueado até segunda ordem!</p>
          </div>
        )}
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => change(false)} content="Voltar" />

        {task.data.status !== "done" &&
        task.type === "task" &&
        (task.data.accountable === user.email || user.rank === "Gerente") ? (
          <Button
            onClick={() => {
              setLoading(true);
              dispatch(
                handleEditChanges(
                  task.id,
                  flow._id,
                  task.data.subtasks,
                  task.data.accountable,
                  enterpriseId
                )
              ).then(() => {
                change(false);
                setLoading(false);
              });
            }}
            primary
            content="Salvar alterações"
            icon="save"
            labelPosition="right"
          />
        ) : null}
        {task.data.status === "doing" &&
        task.type !== "conditional" &&
        (task.data.accountable === user.email || user.rank === "Gerente") ? (
          <Button
            disabled={
              isExpired(task) < 0 &&
              task.data.lockTask &&
              user.rank !== "Gerente"
            }
            content="Concluir tarefa"
            labelPosition="right"
            icon="checkmark"
            onClick={() => setConfirmOpen(true)}
            positive
          />
        ) : null}
      </Modal.Actions>
    </Modal>
  ) : (
    <Modal
      onClose={() => change(false)}
      onOpen={() => change(true)}
      open={open}
    >
      <div>
        <Loading />
      </div>
    </Modal>
  );
};

export default ActivedTaskModal;
