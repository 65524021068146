import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Input, Button } from "semantic-ui-react";
import { Formik, Form } from "formik";
import { handleResetPassword } from "../../state/auth/actions";
import { BoxForm, FormContainer, LoginContainer } from "./styles";
import Loading from "../../components/LoadingTemplate/index";

const ForgotPasswordPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const [error, setError] = useState(false);
  const [message, setMessage] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (token !== null)
      user.rank === "Gerente"
        ? history.push("/")
        : history.push({
            pathname: "/",
            search:
              "?" +
              new URLSearchParams({
                title: "",
                flowTitle: "",
                client: "",
                status: "all",
                flowType: "all",
              }).toString(),
          });
  }, [history, token]);

  const handleSubmit = (values) => {
    if (values.email === "") {
      setMessage(false);
      setError("Necessário inserir e-mail");
    } else {
      setLoading(true);
      dispatch(handleResetPassword(values.email)).then(() => {
        setError(false);
        setLoading(false);
        setMessage(
          "Você receberá um e-mail com um link de redefinição de senha em instantes!"
        );
      });
    }
  };

  return !loading ? (
    <BoxForm>
      <Formik
        initialValues={{
          email: "",
        }}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit, values, handleChange }) => (
          <Form onSubmit={handleSubmit}>
            <LoginContainer>
              <h2>Insira seu e-mail</h2>
              <FormContainer>
                <Input
                  disabled={message}
                  icon="mail"
                  iconPosition="left"
                  style={{ marginBottom: "10px" }}
                  id="email"
                  error={error}
                  name="email"
                  placeholder="E-mail"
                  value={values.email}
                  onChange={handleChange}
                />
                {error && <p style={{ color: "red" }}>{error}</p>}
                {message && <p style={{ color: "green" }}>{message}</p>}
                {!message && <Button type="submit">Enviar e-mail</Button>}
              </FormContainer>
            </LoginContainer>
          </Form>
        )}
      </Formik>
    </BoxForm>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

export default ForgotPasswordPage;
