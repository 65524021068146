import React from "react";

const PushBackIcon = ({
  width,
  height,
  style,
  stroke = "#EFF1F5",
  onClick,
}) => {
  return (
    <svg
      width={width}
      height={height}
      style={style}
      onClick={onClick}
      viewBox="0 0 29 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.1738 24.2764L10.5032 16.6357C9.59735 15.7334 9.59735 14.2568 10.5032 13.3545L18.1738 5.71385"
        stroke={stroke}
        strokeWidth="2.81411"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PushBackIcon;
