import styled from "styled-components";

export const ContainerCenter = styled.div`
  height: calc(100vh - (72px));
  width: 100%;
  padding-right: 30%;
  padding-left: 30%;
  justify-content: center;
  padding-top: 10%;
  display: flex;
`;
