import styled from "styled-components";

export const BoxFormWithHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - (72px));
  border: 1px solid #ddd;

  background-image: repeating-linear-gradient(
    45deg,
    #eee 10px,
    #eee 12px,
    transparent 12px,
    transparent 20px
  );
`;

export const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 6px 6px 5px 1px rgba(130, 130, 130, 0.93);
`;
