import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import activedFlowsReducer from "./activedFlows/reducers";
import flowsReducer from "./flows/reducers";
import usersReducer from "./users/reducers";
import authReducer from "./auth/reducers";
import activedTasksReducer from "./activedTasks/reducers";
import undoFlow from "./undoFlow/reducers";

import undoable from "redux-undo";

const rootReducer = combineReducers({
  flows: flowsReducer,
  activedFlows: activedFlowsReducer,
  users: usersReducer,
  auth: authReducer,
  activedTasks: activedTasksReducer,
  undoFlow: undoable(undoFlow),
});

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export { store };
