import React, { memo } from "react";
import { Handle, useStoreState, Position } from "react-flow-renderer";
import { Condi } from "../ConditionalNode/styles.js";

import { ConditionalStyle } from "./styles.js";

const ConditionalNodeForView = ({
  data,
  id,
  targetPosition = Position.Left,
  sourcePosition = Position.Right,
}) => {
  const edges = useStoreState((state) => state.edges);

  return (
    <Condi>
      <ConditionalStyle
        criticalPath={data.criticalPath}
        status={data.status}
        style={
          data.status === "doing"
            ? { cursor: "pointer" }
            : { cursor: "default" }
        }
      >
        <Handle
          type="target"
          id="c"
          position={targetPosition}
          style={
            sourcePosition === "right"
              ? { top: 0, background: "#555" }
              : { left: "100%", background: "#555" }
          }
          isValidConnection={(e) =>
            edges.find((item) => item.target === e.target)?.targetHandle !== "c"
          }
        />
        <Handle
          type="source"
          position={sourcePosition}
          id="a"
          style={
            sourcePosition === "bottom"
              ? {
                  left: 0,
                  background: "#555",
                }
              : { top: "100%", background: "#555" }
          }
        />
      </ConditionalStyle>
      <div
        style={{
          margin: "10px",
          fontFamily: "jetbrains-mono",
        }}
      >
        {data.label.toUpperCase()}
      </div>
    </Condi>
  );
};

export default memo(ConditionalNodeForView);
