import React from "react";

const TickCicle = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 13 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.56608 10.4027C9.1545 10.4027 11.2723 8.28487 11.2723 5.69645C11.2723 3.10803 9.1545 0.990234 6.56608 0.990234C3.97766 0.990234 1.85986 3.10803 1.85986 5.69645C1.85986 8.28487 3.97766 10.4027 6.56608 10.4027Z"
        fill="#40E5A0"
        stroke="#35383D"
        strokeWidth="0.564746"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M4.56592 5.69709L5.89778 7.02895L8.5662 4.36523"
        stroke="#35383D"
        strokeWidth="0.941243"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default TickCicle;
