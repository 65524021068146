import styled from "styled-components";

export const StickyNotes = styled.div`
  background: rgb(248, 238, 167);
  background: -moz-linear-gradient(
    0deg,
    rgba(248, 238, 167, 1) 0%,
    rgba(247, 234, 141, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(248, 238, 167, 1) 0%,
    rgba(247, 234, 141, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(248, 238, 167, 1) 0%,
    rgba(247, 234, 141, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8eea7",endColorstr="#f7ea8d",GradientType=1);

  background-color: #f8eb9c;
  margin: 10px;

  -webkit-box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.31);
  box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.31);

  min-height: 180px;
`;

export const StickyTitle = styled.div`
  background-color: #e9dd86;
  display: flex;
  flex-direction: row;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  justify-content: center;
`;

export const HeaderModal = styled.div`
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  height: 35px;
`;

export const HeaderTitle = styled.div`
  display: flex;
`;

export const TitleName = styled.p`
  margin: 0;
  padding: 0;
`;

export const TitleInput = styled.input`
  padding: 5px 10px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  &:focus {
    outline: none !important;
    transition: box-shadow 0.1s ease, border-color 0.1s ease;
    border-color: #85b7d9;
    box-shadow: none;
  }
`;

export const Container = styled.ul`
  margin-top: 20px;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #444;
    & + li {
      margin-top: 15px;
    }
  }
`;

export const FileInfo = styled.div`
  display: flex;
  align-items: center;
  div {
    display: flex;
    flex-direction: column;
    span {
      font-size: 12px;
      color: #999;
      margin-top: 5px;
      button {
        border: 0;
        background: transparent;
        color: #e57878;
        margin-left: 5px;
        cursor: pointer;
      }
    }
  }
`;

export const Preview = styled.div`
  width: 36px;
  height: 36px;
  border-radius: 5px;
  background-image: url(${(props) => props.src});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
  margin-right: 10px;
`;
