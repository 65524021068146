import { useEffect } from "react";
import { useStoreState, useZoomPanHelper } from "react-flow-renderer";

const useChangePosition = (setArrow, coordinates, isArrow) => {
  const { transform } = useZoomPanHelper();

  const coord = useStoreState((state) => state.transform);
  useEffect(() => {
    const isModalOpen = document.getElementById("modalContainer");

    if (isArrow && !isModalOpen) {
      const x = coord[0];
      const y = coord[1];
      const z = coord[2];
      transform({
        x: x + coordinates.x,
        y: y + coordinates.y,
        zoom: z + coordinates.z,
      });
      setArrow(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isArrow]);

  return null;
};
export default useChangePosition;
