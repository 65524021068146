import React, { useState, useEffect } from "react";
import {
  EdgeText,
  getEdgeCenter,
  getMarkerEnd,
  getSmoothStepPath,
  Position,
  useStoreState,
} from "react-flow-renderer";
import { Input } from "semantic-ui-react";
import { Popover } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { updateCustomEdgeLABEL } from "../../undoFlow/actions";

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  targetPosition = Position.Left,
  sourcePosition = Position.Right,
  style = {
    stroke: "#222",
    strokeWidth: "1px",
    fill: "none",
    strokeDasharray: 0,
  },
  data,
  arrowHeadType,
  markerEndId,
  selected,
}) => {
  if (Math.abs(sourceY - targetY) < 1) {
    sourceY = targetY;
  }

  const center = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition, // optional
    targetX,
    targetY,
    targetPosition, // optional
    borderRadius: 0, // optional
    centerX: center[0], // optional
    centerY: center[1], // optional
  });

  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  const [anchor, setAnchor] = useState(null);
  const [text, setText] = useState(data.text);
  const [open, setOpen] = useState(false);
  const idOpa = open ? "simple-popover" : undefined;

  const textValidation = (text) => {
    if (data.text === text) {
      return false;
    } else {
      return true;
    }
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (!open) {
      if (textValidation(text)) setText(data.text);
    }
  }, [open, data]);

  useEffect(() => {
    if (textValidation(text)) {
      dispatch(updateCustomEdgeLABEL({ id, text }));
    }
  }, [open]);

  return (
    <>
      <path
        id={id}
        style={
          selected === true
            ? {
                stroke: "#40e5a0",
                strokeWidth: "1px",
                strokeDasharray: 0,
              }
            : style
        }
        d={edgePath}
        markerEnd={markerEnd}
        className="react-flow__edge-path flow_elements_edge"
        data-id={id}
        data-type="label"
      />
      <path
        id={id}
        style={{
          ...style,
          stroke: "transparent",
          strokeWidth: "5px",
          strokeDasharray: 0,
        }}
        className="react-flow__edge-path"
        d={edgePath}
        strokeLinejoin="round"
      />
      <EdgeText
        onDoubleClick={(event) => {
          setAnchor(event.target);
          setOpen(true);
        }}
        x={center[0]}
        y={center[1]}
        label={text.length > 0 ? text.toUpperCase() : "0"}
      />
      <Popover
        className="nodrag"
        id={idOpa}
        open={open}
        anchorEl={anchor}
        onClose={() => setOpen(false)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Input
          type="text"
          value={text.toUpperCase()}
          onChange={(e) => {
            e.preventDefault();
            const pointer = e.target.selectionStart;

            window.requestAnimationFrame(() => {
              e.target.selectionStart = pointer;
              e.target.selectionEnd = pointer;
            });
            setText(e.target.value.toUpperCase());
          }}
        />
      </Popover>
    </>
  );
};
export default CustomEdge;
