import React from "react";

const TimerNodeIcon = ({ width, height, onClick, style }) => (
  <svg
    width={width}
    height={height}
    onClick={onClick}
    style={style}
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M23.3437 14.9064C23.3437 20.3401 18.9337 24.7501 13.5 24.7501C8.06625 24.7501 3.65625 20.3401 3.65625 14.9064C3.65625 9.47262 8.06625 5.06262 13.5 5.06262C18.9337 5.06262 23.3437 9.47262 23.3437 14.9064Z"
      stroke="#585E66"
      strokeWidth="1.69362"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.4998 9V14.625"
      stroke="#585E66"
      strokeWidth="1.12908"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.1252 2.25H16.8752"
      stroke="#585E66"
      strokeWidth="1.12908"
      strokewiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default TimerNodeIcon;
