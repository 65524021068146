import React from "react";

const ParallelEndIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.56644"
        y="11.291"
        width="14.8813"
        height="14.8813"
        rx="1.41186"
        transform="rotate(-45 1.56644 11.291)"
        stroke="#7E858F"
        strokeWidth="0.941243"
      />
      <rect
        x="4.35501"
        y="11.291"
        width="10.9256"
        height="10.9256"
        rx="1.41186"
        transform="rotate(-45 4.35501 11.291)"
        stroke="#7E858F"
        strokeWidth="0.941243"
      />
      <path
        d="M8.81201 11.291H14.7455"
        stroke="#7E858F"
        strokeWidth="0.752994"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7788 14.2577V8.32422"
        stroke="#7E858F"
        strokeWidth="0.752994"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ParallelEndIcon;
