import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Close from "../../../assets/icons/Close";
import PenIcon from "../../../assets/icons/Pen";
import { updateTimerNode } from "../../../undoFlow/actions";
import Deadline from "../../general/FlowElements/Task/TaskModal/Deadline";

import Modal from "../../general/Modal";
import { Container, Content, Header, Button, DivIcon } from "./styles";

const TimerModal = ({ isOpen, onClose, selector, className, data, id }) => {
  const [deadline, setDeadLine] = useState(data.expiration.number / 24);

  const dispatch = useDispatch();

  const timerValidation = () => {
    if (data.expiration.number / 24 === deadline) {
      return false;
    } else if (deadline < 1) {
      setDeadLine(data.expiration.number / 24);
      return false;
    } else {
      return true;
    }
  };

  useEffect(() => {
    if (!isOpen) {
      if (timerValidation()) {
        setDeadLine(data.expiration.number / 24);
      }
    }
  }, [isOpen, data]);

  useEffect(() => {
    if (timerValidation()) {
      dispatch(
        updateTimerNode({
          id,
          deadline: deadline,
        })
      );
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      selector={selector}
      className={className}
    >
      <Container>
        <Content>
          <Header>
            <PenIcon />
            <span>Editar Temporizador</span>
          </Header>
          <Deadline deadline={deadline} setDeadline={setDeadLine} />
          <Button onClick={() => onClose()}>Voltar</Button>
          <DivIcon>
            <Close onClick={() => onClose()} />
          </DivIcon>
        </Content>
      </Container>
    </Modal>
  );
};
export default TimerModal;
