import React, { memo } from "react";

import { Content, ContentText } from "./styles";

const CustomText = ({ selected, data, id }) => {
  return (
    <>
      <Content>
        <ContentText
          style={{
            color: data.hexColor,
            fontSize: data.fontSize + "px",
          }}
        >
          {data.label}
        </ContentText>
      </Content>
    </>
  );
};

export default memo(CustomText);
