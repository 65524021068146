import ProfilePicture from "@dsalvagni/react-profile-picture";
import React, { forwardRef, useImperativeHandle, useRef } from "react";
import "../../../../node_modules/@dsalvagni/react-profile-picture/build/ProfilePicture.css";
import api from "../../../services/api";

const ProfilePicturePicker = forwardRef(
  ({ user, change, setPicture, setLoading }, ref) => {
    const profilePictureRef = useRef();

    function dataURItoBlob(dataURI) {
      // convert base64 to raw binary data held in a string
      // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
      var byteString = atob(dataURI.split(",")[1]);

      // separate out the mime component
      var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];

      // write the bytes of the string to an ArrayBuffer
      var ab = new ArrayBuffer(byteString.length);

      // create a view into the buffer
      var ia = new Uint8Array(ab);

      // set the bytes of the buffer to the correct values
      for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
      }

      // write the ArrayBuffer to a blob, and you're done
      var blob = new Blob([ab], { type: mimeString });
      return blob;
    }

    useImperativeHandle(ref, () => ({
      handleUpload() {
        if (profilePictureRef.current.state.status === "EMPTY") {
          return 0;
        }

        const PP = profilePictureRef.current;

        const imageAsDataURL = PP.getImageAsDataUrl();

        const blob = dataURItoBlob(imageAsDataURL);

        const data = new FormData();

        data.append("file", blob, "profile");
        data.append("originalId", user._id);
        data.append("type", "profile");
        data.append("enterpriseId", user.enterpriseId);

        setLoading(true);

        api
          .put("/users/profile/picture/new", data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((response) => {
            setPicture(response.data.url);
            change(false);
            setLoading(false);
          })
          .catch(() => {
            console.log("deu erro");
          });
      },
    }));

    const messages = {
      DEFAULT: "Arraste sua foto pra cá ou pressione para importar",
      DRAGOVER: "Drop your photo",
      INVALID_FILE_TYPE: "Only images allowed.",
      INVALID_IMAGE_SIZE: "Your photo must be larger than 350px.",
    };

    return (
      <React.Fragment>
        <ProfilePicture
          ref={profilePictureRef}
          useHelper={true}
          cropSize={400}
          frameFormat="circle"
          messages={messages}
        />
      </React.Fragment>
    );
  }
);

export default ProfilePicturePicker;
