import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";
import {
  addActivedFlow,
  assignAllTasks,
  deleteSubtask,
  editChangesSave,
  editComment,
  editSubtask,
  editSubtasks,
  editTaskAccountable,
  newFlowPost,
  newSubtask,
  sendTask,
  sortActivedFlow,
  undoTask,
  removeActivedFlow,
  newPost,
  newPostSubtask,
  clearActivedFlows,
  newFlowTitle,
  newTaskTitle,
  searchActived,
  fetchSingleActivedFlow,
} from "./actions";
import api from "../../services/api";

export const fetchActivedFlows = createAsyncThunk(
  "activedFlows/fetchActivedFlows",
  async ({ enterpriseId, page, type }) => {
    const response = await api.get(
      "/actived-flows/" + enterpriseId + "/" + page + "/" + type
    );
    return response.data;
  }
);

const activedFlowsReducer = createReducer(
  { entities: [], status: "idle", pages: 1 },
  {
    /**
     * ? addActivedFlow
     * * Recebe um novo fluxo a ser ativo
     * * e o adiciona em uma cópia do estado atual para
     * * no final retorná-lo.
     */

    [addActivedFlow]: (state, action) => {
      const newState = JSON.parse(JSON.stringify(state));
      newState.entities.push(action.payload.flow);
      return newState;
    },

    /**
     * ? newFlowTitle
     * * Altera o nome do fluxo na aba opções de fluxo.
     */

    [newFlowTitle]: (state, action) => {
      state.entities.forEach((flow) => {
        if (flow._id === action.payload.newFlow._id) {
          flow.title = action.payload.newFlow.title;
        }
      });
    },
    /**
     * ? newTaskTitle
     * * Altera o nome da Tarefa no modal de tarefas (ActiveTaskModal)
     */
    [newTaskTitle]: (state, action) => {
      state.entities.forEach((flow) => {
        if (flow._id === action.payload.newNode.flowId)
          flow.elements.forEach((el) => {
            if (el._id === action.payload.newNode._id)
              el.data.label = action.payload.newNode.data.label;
          });
      });
    },

    /**
     * ? sendTask
     * * Conclui uma tarefa de um fluxo ativo e atualiza o
     * * estado atual(local) com o fluxo atualizado do banco
     * * (com o status da respectiva tarefa marcada em "done").
     */

    [sendTask]: (state, action) => {
      let newState = JSON.parse(JSON.stringify(state));
      newState.entities = newState.entities.map((els) => {
        if (els._id === action.payload.flow._id) {
          return action.payload.flow;
        } else return els;
      });
      return newState;
    },

    /**
     * ? editComment
     * * Atualiza o bloco de anotações presente no FlowViewer
     * * e nas tarefas. Verifica pelo tipo: flow ou task.
     */

    [editComment]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.flowId) {
          if (action.payload.type === "flow") {
            els.comments = action.payload.comments;
          } else {
            els.elements.forEach((el) => {
              if (el._id === action.payload.itemId) {
                el.data.comments = action.payload.comments;
              }
            });
          }
        }
      });
    },
    [fetchActivedFlows.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchActivedFlows.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.entities = action.payload.flows;
      state.pages = action.payload.pages;
    },

    /**
     * ? editChangesSave
     * * Salva no banco o estado atualizado das subtasks de
     * * uma tarefa. Localmente recebe o fluxo
     * * atualizado do banco de dados.
     */

    [editChangesSave]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.newTask.flowId) {
          els.elements.forEach((el) => {
            if (el.id === action.payload.newTask.id) {
              el = action.payload.newTask;
            }
          });
        }
      });

      return state;
    },

    /**
     * ? editTaskAccountable
     * * Edição do usuário delegado de uma determinada tarefa.
     * * A redefinição é feita localmente checando o id específico
     * * da tarefa a ser delegada.
     */

    [editTaskAccountable]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.flowId) {
          els.elements.forEach((el) => {
            if (el.id === action.payload.taskId) {
              el.data.accountable = action.payload.accountable;
            }
          });
        }
      });
    },

    /**
     * ? newFlowPost
     * * Atualiza o estado atual com a sessão de comentários
     * * do painel interno do fluxo ativo (flowViewer).
     * * A atualização local é feita recebendo o fluxo com as novas
     * * informações do banco.
     */

    [newFlowPost]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.newFlow._id) {
          els.posts = action.payload.newFlow.posts;
        }
      });
    },

    /**
     * ? undoTask
     * * Retorna a ultima tarefa concluída para o status
     * * "doing", permitindo que o fluxo volte para o passo
     * * anterior. Esta opção está presente no modal FlowOptionsModal
     */

    [undoTask]: (state, action) => {
      let newState = JSON.parse(JSON.stringify(state));
      newState.entities = newState.entities.map((els) => {
        if (els._id === action.payload.newFlow._id) {
          return action.payload.newFlow;
        } else return els;
      });
      return newState;
    },

    /**
     * ? assignAllTasks
     * * Delega todas as tarefas para um usuário em específico.
     * * Esta opção está presente no modal FlowOptionsModal.
     * * Localmente, atualiza o estado com o novo fluxo do banco.
     */

    [assignAllTasks]: (state, action) => {
      let newState = JSON.parse(JSON.stringify(state));
      newState.entities = newState.entities.map((els) => {
        if (els._id === action.payload.flow._id) {
          return action.payload.flow;
        } else return els;
      });
      return newState;
    },

    /**
     * ?newSubtask
     * * Adiciona uma nova Subtarefa a uma tarefa específica do fluxo.
     * * Localmente atualiza a listas de subtasks do estado atual com a
     * * do fluxo recebido do banco de dados.
     */

    [newSubtask]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.newNode.flowId) {
          els.elements.forEach((el) => {
            if (el.id === action.payload.newNode.id) {
              el.data.subtasks = action.payload.newNode.data.subtasks;
            }
          });
        }
      });
    },

    /**
     * ?deleteSubtask
     * * Deleta uma Subtarefa de uma tarefa específica do fluxo.
     * * Localmente atualiza a listas de subtasks do estado atual com a
     * * do fluxo recebido do banco de dados.
     */
    [deleteSubtask]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.newNode.flowId) {
          els.elements.forEach((el) => {
            if (el.id === action.payload.newNode.id) {
              el.data.subtasks = action.payload.newNode.data.subtasks;
            }
          });
        }
      });
    },

    /**
     * ? editSubtasks
     * * Seleciona uma subtask específica e assinala na checkbox
     * * no atributo "checked" (local), presente no modal ActivedTaskModal
     */

    [editSubtasks]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.flowId) {
          els.elements.forEach((el) => {
            if (el.id === action.payload.taskId) {
              el.data.subtasks[action.payload.index].checked =
                action.payload.checked;
            }
          });
        }
      });
    },

    /**
     * ?editSubtask
     * * Edita o nome de uma subtarefa dentro do modal ActivedTaskModal.
     * * O estado é atualizado com a redefinição das subtarefas pelo banco.
     */

    [editSubtask]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.newNode.flowId) {
          els.elements.forEach((el) => {
            if (el.id === action.payload.newNode.id) {
              el.data.subtasks = action.payload.newNode.data.subtasks;
            }
          });
        }
      });
    },

    /**
     * ?removeActivedFlow
     * * Filtra a lista de fluxos ativos, excluindo o fluxo selecionado
     * * do estado atual.
     *
     */

    [removeActivedFlow]: (state, action) => {
      state.entities = state.entities.filter(
        (item) => item._id !== action.payload.flowId
      );
    },
    /**
     * ?sortActivedFlow
     * * Permite organizar as arestas conforme o fluxo, deixando as arestas
     * * verdes em cima das pretas (animação do React flow). A ausência
     * * deste metodo impacta no progresso visual das arestas em estruturas
     * * condicionais. Localmente, atualiza-se os elementos do estado atual
     * * com as do banco.
     */
    [sortActivedFlow]: (state, action) => {
      state.entities.forEach((item, index) => {
        if (item._id === action.payload.flowId)
          item.elements = action.payload.elements;

      });
    },
    /**
     * ? newPost
     * * Atualiza o estado atual com a sessão de comentários
     * * de uma tarefa. A atualização local é feita recebendo o fluxo
     * * com as novas informações do banco.
     */
    [newPost]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.newTask.flowId) {
          els.elements.forEach((el) => {
            if (el.id === action.payload.newTask.id) {
              el.data.posts = action.payload.newTask.data.posts;
            }
          });
        }
      });
    },

    [newPostSubtask]: (state, action) => {
      state.entities.forEach((els) => {
        if (els._id === action.payload.newTask.flowId) {
          els.elements.forEach((el) => {
            if (el.id === action.payload.newTask.id) {
              el.data.subtasks = action.payload.newTask.data.subtasks;
            }
          });
        }
      });
    },

    /**
     * ? clearActivedFlows
     * * Limpa o estado atual localmente de fluxos ativos cadastrados;
     */

    [clearActivedFlows]: (state) => {
      state.status = "idle";
      state.entities = [];
    },
    [searchActived]: (state, action) => {
      state.entities = action.payload.flows;
      state.pages = action.payload.pages;
    },
    [fetchSingleActivedFlow]: (state, action) => {
      state.entities = [action.payload.flow];
      state.pages = 1;
      state.status = "succeeded";
    },
  }
);

export default activedFlowsReducer;
