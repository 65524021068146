import styled from "styled-components";
import EyeSlashIcon from "../../../assets/icons/eye-slash";
import EyeIcon from "../../../assets/icons/eye";

export const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 0 0 1.0591rem 0;
  background: ${({ disabled }) => (disabled ? "#EFF1F5" : "white")};
  border: ${({ error, focus, disabled }) =>
    error
      ? "1.41239px solid #eb5757"
      : focus && !disabled
      ? "2px solid #40E5A0"
      : "2px solid #D4D6DA"};

  Button {
    background: ${({ focus }) => (!focus ? "#585E66" : "#40E5A0")};
    color: ${({ focus }) => (!focus ? "#EFF1F5" : "#42464C")};
  }
  height: 2.68rem;
  box-sizing: border-box;
  border-radius: 8.47434px;
  line-height: 0.875rem;
  padding: 0.765rem 0 0.71375rem 0.6504rem;
  outline: none;
  font-family: ${({ type, visible }) =>
    !visible && type === "password" ? "Sans-Serif" : "Sora"};

  font-size: ${({ type, visible }) =>
    !visible && type === "password" ? "1.25rem" : "0.70833rem"}; //0.70833rem;;

  letter-spacing: ${({ visible, type }) =>
    !visible && type === "password" ? "0.25rem" : "0px"};

  color: ${({ type, disabled }) =>
    type === "password" && !disabled
      ? "#585e66"
      : disabled
      ? "#7E858F"
      : "#111"};

  &:hover {
    border: ${({ error, disabled }) =>
      error && !disabled
        ? "1.41239px solid #eb5757"
        : disabled
        ? "2px solid #D4D6DA"
        : "2px solid #40E5A0"};

    Button {
      background: ${({ disabled }) =>
        disabled ? "#585E66 !important" : "#40e5a0 !important"};
      color: ${({ disabled }) => (disabled ? "#EFF1F5 !important" : "#42464c")};
    }
  }
`;
export const Label = styled.p`
  font-weight: 600;
  font-size: 0.7062rem;
  line-height: 0.875rem;
  color: #585e66;
  margin-bottom: 0.3608rem;
  user-select: none;
`;
export const CustomInput = styled.input`
  border: ${({ error }) =>
    error ? "1.41239px solid #eb5757" : "1.41239px solid #a9aeb4"};

  box-sizing: border-box;
  border-radius: 8.47434px;
  line-height: 0.875rem;
  padding: 0.765rem 0 0.71375rem 0.6504rem;
  outline: none;
  border: none;
  width: 100%;
  height: 70% !important;

  font-family: ${({ type, visible }) =>
    !visible && type === "password" ? "Sans-Serif" : "Sora"};

  font-size: ${({ type, visible }) =>
    !visible && type === "password" ? "1.25rem" : "0.70833rem"}; //0.70833rem;;

  letter-spacing: ${({ visible, type }) =>
    !visible && type === "password" ? "0.25rem" : "0px"};

  color: ${({ type }) => (type === "password" ? "#585e66" : "#111")};

  &:placeholder-shown {
    font-family: Sora;
    font-style: normal;
    font-weight: normal;
    font-size: 0.8rem;
    line-height: 1rem;
    letter-spacing: 0rem;

    color: #a9aeb4;
  }
`;

export const ErrorLabel = styled.span`
  position: absolute;
  color: #ea6868;
  font-size: 0.6468rem;
  line-height: 0.8333rem;
  bottom: -1.33rem;
`;

export const FooterInput = styled.span`
  position: absolute;
  color: #7e858f;
  font-size: 0.6468rem;
  line-height: 0.8333rem;
  bottom: -1.33rem;
`;

export const EyeSlashIconStyled = styled(EyeSlashIcon)`
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 1.05833rem;
  right: 1rem;
  border-radius: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const EyeIconStyled = styled(EyeIcon)`
  position: absolute;
  cursor: pointer;
  height: 100%;
  width: 1.05833rem;
  right: 1rem;
  border-radius: 0.625rem;
  top: 50%;
  transform: translateY(-50%);
`;

export const LoadingAnimation = styled.div`
  animation: rotating 0.9s linear infinite;
  width: 1.2rem;
  height: 1.2rem;

  @keyframes rotating {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
