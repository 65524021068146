import { Handle } from "react-flow-renderer";
import styled, { css } from "styled-components";

export const ConditionalEndStyle = styled.div`
  width: 43px;
  height: 43px;
  box-shadow: 0 0 0 1.2px #42464c;
  box-sizing: border-box;
  border-radius: 2px;
  transform: rotate(-45deg);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  &:hover {
    background: #cff8e7;
    box-shadow: 0 0 0 1.2px #40e5a0;
    div:first-child {
      box-shadow: 0 0 0 0.8px #40e5a0;
    }
  }
  &.critical {
    background: #d93333 !important;
    border: none !important;
    box-shadow: none !important;
    div:first-child {
      box-shadow: 0 0 0 0.8px #eff1f5;
    }
  }
  ${({ selected }) =>
    selected &&
    css`
      background: #fff !important;
      box-shadow: 0 0 3px 2px #40e5a0 !important;
      div:first-child {
        box-shadow: 0 0 0 0.8px #42464c !important;
      }
    `}
`;

export const HandleStyle = styled(Handle)`
  width: 8px;
  height: 8px;
`;

export const ConditionalEndStyleInside = styled.div`
  width: 35px;
  height: 35px;
  box-sizing: border-box;
  border-radius: 2px;
  box-shadow: 0 0 0 0.8px #42464c;
  display: flex;
  justify-content: center;
  align-items: center;
`;
