import styled, { createGlobalStyle } from "styled-components";
import LockIcon from "@material-ui/icons/Lock";
import LockOpenIcon from "@material-ui/icons/LockOpen";

export const Content = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border: none;
`;

export const Lock = styled(LockIcon)`
  position: absolute;
  top: 0;
  right: 0;
  color: black;
  z-index: 500;
  font-size: 16px;
`;
export const Unlock = styled(LockOpenIcon)`
  position: absolute;
  top: 0;
  right: 0;
  color: black;
  z-index: 500;
  font-size: 16px;
`;

export const ColorPickButton = styled.button`
  position: absolute;
  z-index: 10;
  top: 30px;
  right: 2px;
  background: red;
  width: 16px;
  height: 10px;
  opacity: 1;
`;

export const IndexPosition = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 45px;
  right: 2px;
  padding: 0px 3px;
  font-weight: bold;
  user-select: none;
  pointer-events: none;
  border: 1px solid #111;
`;

export const ConfirmButton = styled.button`
  display: flex;
  width: 100%;
  height: 20px;
  align-self: center;
  background-color: #44c767;
  border: 1px solid #18ab29;
  display: inline-block;
  cursor: pointer;
  color: #ffffff;
  font-family: Arial;
  font-size: 14px;
  font-weight: bold;

  text-decoration: none;
  text-shadow: 0px 1px 26px #2f6627;

  &:hover {
    background-color: #5cbf2a;
  }
`;
