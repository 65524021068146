import React from "react";
import GlobalStyle from "../../../globalStyles";
import Activities from "./Activities";
import Conditionals from "./Conditionals";
import Events from "./NodeEvents";
import Parallels from "./Parallels";
import { Container } from "./styles";

const Sidebar = () => {
  const onDragStart = (event, nodeType) => {
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  return (
    <>
      <GlobalStyle />
      <Container>
        <Events onDrag={onDragStart} Label="Eventos e Texto" />
        <Activities onDrag={onDragStart} Label="Atividades" />
        <Conditionals onDrag={onDragStart} Label="Condicionais" />
        <Parallels onDrag={onDragStart} Label="Paralelos" />
      </Container>
    </>
  );
};

export default Sidebar;
