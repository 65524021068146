import styled from "styled-components";
import ReactFlow, { MiniMap } from "react-flow-renderer";
export const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
`;

export const FlowBoard = styled(ReactFlow)`
  position: relative;
  width: 100%;
  height: 100%;

  //Padrão ReactFlow
  line-height: 22.856px;
  font-size: 16px;

  .react-flow__edges {
    position: absolute;
    top: 0;
    left: 0;
    /* z-index: 3 !important; */
  }
  .react-flow__nodes {
    z-index: 3;
  }

  .react-flow_pane {
    z-index: auto;
  }

  .react-flow_background {
    z-index: auto;
    pointer-events: none;
  }
  .react-flow__handle-connecting {
    background: #ff6060;
  }

  .react-flow__handle-valid {
    background: #55dd99;
  }
`;

export const Container = styled.div`
  position: absolute;
  top: 1.0625rem;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: space-around;
  width: 29.875rem;

  @media screen and (max-width: 780px) {
    display: none;
  }
`;
export const Map = styled(MiniMap)`
  position: absolute;
  bottom: 1.25rem;
  right: 6.25rem;
  /* height: 7.176875rem; */

  background: #aaa;
`;
