import React, { memo } from "react";
import { Handle, useStoreState, Position } from "react-flow-renderer";

import { ConditionalStyle } from "./styles.js";
import { Plus } from "react-feather";

const ParallelNode = ({
  data,
  targetPosition = Position.Left,
  sourcePosition = Position.Right,
  selected,
}) => {
  const edges = useStoreState((state) => state.edges);
  const nodes = useStoreState((state) => state.nodes);

  return (
    <ConditionalStyle
      criticalPath={data.criticalPath}
      status={data.status}
      selected={selected}
    >
      <Plus style={{ transform: "rotate(45deg)" }} size={50} />

      <Handle
        id="c"
        type="target"
        position={targetPosition}
        style={{ top: 0, width: "8px", height: "8px" }}
        isValidConnection={(e) =>
          nodes.find((item) => item.id === e.source).type === "parallel" ||
          nodes.find((item) => item.id === e.source).type === "conditional"
            ? true
            : !edges.find(
                (item) => item.target === e.target || item.source === e.source
              )
        }
      />

      <Handle
        type="source"
        position={sourcePosition}
        id="a"
        style={{ top: "100%", width: "8px", height: "8px" }}
      />
    </ConditionalStyle>
  );
};

export default memo(ParallelNode);
