import { useEffect } from "react";

function checkBranch(
  elements,
  item = {},
  branchTimeStart = 0,
  doneTimeStart = 0,
  donePreventTimeStart = 0,
  statsStart = [],
  advancedStats = [{ sad: 22 }],
  task = {}
) {
  let stats = statsStart;
  let branchTime = branchTimeStart;
  let donePreventTime = donePreventTimeStart;
  let doneTime = doneTimeStart;

  ////SE FOR ARESTA
  if (item.source) {
    return checkBranch(
      elements,
      elements.find((el) => el.id === item.target),
      branchTime,
      doneTime,
      donePreventTime,
      stats,
      advancedStats,
      task
    );
  }
  ///////SE FOR NO
  else {
    if (item.type === "task" || item.type === "timerEvent") {
      if (item.type === "timerEvent") {
        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });
        stats.push(
          stats.length > 0
            ? {
                taskTitle: "TEMPORIZADOR",
                taskId: item.id,
                time: item.data.expiration.number / 24,
              }
            : {
                taskTitle: "TEMPORIZADOR",
                time: item.data.expiration.number / 24,
                taskId: item.id,
              }
        );
      }
      if (item.type === "task") {
        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        stats.push(
          stats.length > 0
            ? {
                taskTitle: item.data.label,
                time: item.data.expiration.number / 24,
                taskId: item.id,
              }
            : {
                taskTitle: item.data.label,
                time: item.data.expiration.number / 24,
                taskId: item.id,
              }
        );
      }

      branchTime += item.data.expiration.number;

      return checkBranch(
        elements,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        stats,
        advancedStats,
        task
      );
    } else if (item.type === "parallel" || item.type === "conditional") {
      const branchCountt = branchCount(
        elements,
        item,
        stats.length === 0 ? advancedStats : stats
      );

      branchTime += branchCountt.max;
      doneTime += branchCountt.maxDone;
      donePreventTime += branchCountt.maxPreventDone;

      branchCountt.stats.forEach((item) => {
        stats.push(item);
      });

      stats.sort(function (a, b) {
        return Number(a.x) - Number(b.x);
      });

      return checkBranch(
        elements,
        elements.find((e) => e.source === branchCountt.lastItem.id),
        branchTime,
        doneTime,
        donePreventTime,
        stats,
        advancedStats,
        branchCountt.task
      );
    } else if (item.type === "parallelEnd" || item.type === "conditionalEnd") {
      return {
        branchTime,
        lastItem: item,
        doneTime,
        donePreventTime,
        stats,
        task,
      };
    }
    /// SE NAO FOR NADA DISSO SÓ CONTINUA ANDANDO
    else
      return checkBranch(
        elements,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        stats,
        advancedStats,
        task
      );
  }
}

function branchCount(elements, item = {}, stat = [{ jurubebe: 12 }]) {
  let max = 0;
  let maxDone = 0;
  let task;
  let maxPreventDone = 0;
  const allBranchs = elements.filter((e) => e.source === item.id);

  const times = allBranchs.map((e) => {
    const obj = checkBranch(elements, e, 0, 0, 0, [], stat, []);

    return obj;
  });

  let bestBranch = [];

  times.forEach((el, index) => {
    if (index + 1 <= times.length) {
      if (el.branchTime >= max) {
        task = el.task;
      }

      max = Math.max(el.branchTime, max);
      maxDone = Math.max(el.doneTime, maxDone);
      maxPreventDone = Math.max(el.donePreventTime, maxPreventDone);

      if (max === el.branchTime) {
        bestBranch = el.stats;
      }
    }
  });

  return {
    max,
    lastItem: times[0].lastItem,
    maxDone,
    maxPreventDone,
    stats: bestBranch,
    task,
  };
}

function count(
  elements,
  item = { type: "eventEnd" },
  branchTimeStart = 0,
  doneTimeStart = 0,
  donePreventTimeStart = 0,
  statsStart = [],
  task = {}
) {
  let stats = statsStart;
  let branchTime = branchTimeStart;
  let donePreventTime = donePreventTimeStart;
  let doneTime = doneTimeStart;

  //////////SE FOR ARESTA
  if (item.source) {
    return count(
      elements,
      elements.find((el) => el.id === item.target),
      branchTime,
      doneTime,
      donePreventTime,
      stats,
      task
    );
  }
  //////////SE FOR NÓ
  else {
    if (item.type === "task" || item.type === "timerEvent") {
      if (item.type === "timerEvent") {
        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        stats.push({
          taskTitle: "TEMPORIZADOR",
          time: item.data.expiration.number / 24,
          taskId: item.id,
        });
      }

      if (item.type === "task") {
        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        stats.push({
          taskTitle: item.data.label,
          time: item.data.expiration.number / 24,
          taskId: item.id,
        });
      }

      branchTime += item.data.expiration.number;

      return count(
        elements,
        elements.find((e) => e.source === item.id),
        branchTime,
        doneTime,
        donePreventTime,
        stats,
        task
      );
    } else {
      if (item.type === "eventEnd") {
        return { branchTime, doneTime, donePreventTime, stats };
      } else if (item.type === "parallel" || item.type === "conditional") {
        /////VERIFICA O MAIOR CAMINHO E RETORNA O ID FINAL
        const finishBranchItem = branchCount(elements, item, stats);

        branchTime += finishBranchItem.max;
        doneTime += finishBranchItem.maxDone;
        donePreventTime += finishBranchItem.maxPreventDone;

        finishBranchItem.stats.forEach((item) => {
          stats.push(item);
        });

        stats.sort(function (a, b) {
          return Number(a.x) - Number(b.x);
        });

        return count(
          elements,
          elements.find((e) => e.source === finishBranchItem.lastItem.id),
          branchTime,
          doneTime,
          donePreventTime,
          stats,
          finishBranchItem.task
        );
      } else {
        return count(
          elements,
          elements.find((e) =>
            item.type === "conditional"
              ? e.source === item.id && e.data.status === "done"
              : e.source === item.id
          ),
          branchTime,
          doneTime,
          donePreventTime,
          stats,
          task
        );
      }
    }
  }
}

function recursionCritical(itemId, elements) {
  let newElements = JSON.parse(JSON.stringify(elements));

  newElements.forEach(function (it, index) {
    if (itemId === it.id) {
      this[index].data.criticalPath = true;

      if (!it.source) {
        newElements.forEach(function (el, index) {
          if (
            el.source === itemId &&
            !newElements.find(
              (item) =>
                item.source === itemId && item.data.criticalPath === true
            )
          ) {
            if (
              newElements.filter((item) => item.source === itemId).length > 1
            ) {
              if (
                newElements.find(
                  (item) =>
                    el.target === item.id && el.data.criticalPath === true
                )
              ) {
                this[index].data.criticalPath = true;
                return recursionCritical(el.id, newElements);
              }
            } else {
              this[index].data.criticalPath = true;
              return recursionCritical(el.id, newElements);
            }
          } else if (
            el.target === itemId &&
            !newElements.find(
              (item) =>
                item.target === itemId && item.data.criticalPath === true
            )
          ) {
            if (
              newElements.filter((item) => item.target === itemId).length > 1
            ) {
              if (
                newElements.find(
                  (item) =>
                    el.source === item.id && el.data.criticalPath === true
                )
              ) {
                this[index].data.criticalPath = true;
                return recursionCritical(el.id, newElements);
              }
            } else {
              this[index].data.criticalPath = true;
              return recursionCritical(el.id, newElements);
            }
          }
        }, newElements);
      } else {
        newElements.forEach(function (el, index) {
          if (el.id === it.source || el.id === it.target) {
            if (el.data.criticalPath !== true) {
              this[index].data.criticalPath = true;
              return recursionCritical(el.id, newElements);
            }
          }
        }, newElements);
      }
    }
  }, newElements);

  return newElements;
}

function newTry(elements, oldElementsLength = 0) {
  let oldElementsLength_var = oldElementsLength;
  let elements_var = elements;

  elements_var.forEach((item) => {
    if (item.source) {
      if (
        !!elements_var.find(
          (it) =>
            (it.id === item.source || it.id === item.target) &&
            it.data.criticalPath !== true
        )
      )
        if (item.data.criticalPath === true)
          elements_var = recursionCritical(item.id, elements_var);
    } else {
      if (
        (!elements_var.find(
          (it) => it.target === item.id && it.data.criticalPath === true
        ) ||
          !elements_var.find(
            (it) => it.source === item.id && it.data.criticalPath === true
          )) &&
        !(
          !elements_var.find(
            (it) => it.target === item.id && it.data.criticalPath === true
          ) &&
          !elements_var.find(
            (it) => it.source === item.id && it.data.criticalPath === true
          )
        )
      )
        if (item.data.criticalPath === true)
          elements_var = recursionCritical(item.id, elements_var);
    }
  });

  if (
    elements_var.filter((item) => item.data.criticalPath).length !==
    oldElementsLength
  ) {
    oldElementsLength_var = elements_var.filter(
      (item) => item.data.criticalPath
    ).length;

    return newTry(elements_var, oldElementsLength_var);
  }

  return elements_var;
}

//Captura lista dos elementos Ids da tela
const getElementsID = (elements = []) => {
  const elementsId = [];

  for (let i = 0; i < elements.length; i++) {
    elementsId.push(elements[i].attributes.getNamedItem("data-id").value);
  }

  return elementsId;
};

const getId = (element) => element.attributes.getNamedItem("data-id").value;

const getType = (element) => element.attributes.getNamedItem("data-type").value;

//Pega a interseção de Ids de nós críticos com os da tela
const getIntersectionNodes = (nodesId = [], criticalIds = []) => {
  const inter = criticalIds.filter((item) => nodesId.includes(item.id));
  return inter.map(
    (item) =>
      (item = {
        id: item.id,
        type: item.type,
      })
  );
};

//Pega a interseção de Ids de arestas críticos com os da tela
const getIntersectionEdges = (edgesId = [], criticalIds = []) => {
  const inter = criticalIds.filter((item) => edgesId.includes(item.id));
  return inter.map(
    (item) =>
      (item = {
        id: item.id,
        type: item.type,
      })
  );
};

//Colore os nós via Ids críticos
const colorizeNodes = (nodes = [], undoFlow) => {
  const nodesIds = getElementsID(nodes);
  const criticalList = getIntersectionNodes(nodesIds, undoFlow);

  for (let i = 0; i < nodes.length; i++) {
    const isContain = criticalList.find((item) => item.id === getId(nodes[i]));
    if (isContain) {
      nodes[i].classList.add("critical");
    }
  }
};
//Descolore todos os nós
const descolorizeNodes = (nodes = [], undoFlow) => {
  const nodesIds = getElementsID(nodes);
  const criticalList = getIntersectionNodes(nodesIds, undoFlow);

  for (let i = 0; i < nodes.length; i++) {
    const isContain = criticalList.find((item) => item.id === getId(nodes[i]));

    if (isContain) {
      nodes[i].classList.remove("critical");
    }
  }
};

//Colore as arestas via Ids críticos
const colorizeEdges = (edges = [], undoFlow) => {
  const edgesIds = getElementsID(edges);
  const criticalList = getIntersectionEdges(edgesIds, undoFlow);

  for (let i = 0; i < edges.length; i++) {
    const isContain = criticalList.find((item) => item.id === getId(edges[i]));
    if (isContain) {
      edges[i].style.stroke = "#D93333";
      edges[i].style.strokeWidth = "4px";
    }
  }
};

//Descolore todos as arestas obs: rgb(17, 17,17 ) p/ sem rótulo
const descolorizeEdges = (edges = []) => {
  for (let i = 0; i < edges.length; i++) {
    edges[i].style.stroke = "rgb(17, 17, 17)";
    edges[i].style.strokeWidth = "1px";
  }
};

const useCriticalPath = (critical, undoFlow = [], setStats) => {
  useEffect(() => {
    const nodes = document.getElementsByClassName("flow_elements_node");
    const edges = document.getElementsByClassName("flow_elements_edge");

    if (critical) {
      if (nodes) {
        const eventStart =
          undoFlow && undoFlow.find((e) => e.type === "eventStart");

        const stats = count(undoFlow, eventStart).stats;

        let elements = JSON.parse(JSON.stringify(undoFlow));

        elements.forEach((item) => {
          item.data.criticalPath = false;
        });

        elements.forEach((el) => {
          if (!!stats.find((item) => item.taskId === el.id)) {
            el.data.criticalPath = true;
          }

          if (
            el.source &&
            !!stats.find(
              (item) => item.taskId === el.source || item.taskId === el.target
            )
          ) {
            el.data.criticalPath = true;
          }
        });

        elements.sort(function (a, b) {
          if (a.data.criticalPath === true && b.data.criticalPath === false)
            return 1;
          else return -1;
        });

        let newElements = recursionCritical(
          undoFlow.find((item) => item.type === "eventStart").id,
          elements
        );

        const nextElements = newTry(newElements);

        const criticalPath = nextElements
          .filter((item) => item.data.criticalPath)
          .map(
            (el) =>
              (el = {
                id: el.id,
                data: el.data,
                type: el.type,
              })
          );

        const criticalTime = stats.reduce(
          (partialSum, item) => partialSum + item.time,
          0
        );

        setStats(criticalTime);
        colorizeNodes(nodes, criticalPath);
        colorizeEdges(edges, criticalPath);
      }
    } else {
      if (nodes) {
        descolorizeNodes(nodes, undoFlow);
        descolorizeEdges(edges, undoFlow);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [critical]);
};

export default useCriticalPath;
