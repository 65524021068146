import { Handle } from "react-flow-renderer";
import styled, { css } from "styled-components";

export const ConditionalStyle = styled.div`
  width: 43px;
  height: 43px;
  box-sizing: border-box;
  border-radius: 2px;
  transform: rotate(-45deg);
  font-size: 17px;
  background-color: #fff;

  box-shadow: 0 0 0 1.2px #42464c;
  &:hover {
    background: #cff8e7;
    box-shadow: 0 0 0 1.2px #40e5a0;
    path {
      stroke: #40e5a0;
      stroke-width: 0.4px;
    }
  }

  &.critical {
    background: #d93333 !important;
    border: none !important;
    box-shadow: none !important;
    path {
      stroke: #eff1f5;
    }
  }

  ${({ selected }) =>
    selected &&
    css`
      background: #fff !important;
      box-shadow: 0 0 3px 2px #40e5a0 !important;
    `}
`;

export const HandleStyle = styled(Handle)`
  width: 8px;
  height: 8px;
`;
export const Condi = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  font-size: 17px;
`;
