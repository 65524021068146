/**
 * TODO: DashboardModel
 * * Painel externo de fluxos modelos
 * * Funções: Criar fluxo, deletar fluxo, copiar fluxo, pesquisar por titulo
 * * Exibição: Tabela -> Título, Data de Criação, Deletar fluxo e Copiar fluxo;
 */

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Confirm,
  Dropdown,
  Icon,
  Input,
  Pagination,
  Segment,
  Table,
} from "semantic-ui-react";
import moment from "moment";

import {
  handleAddModelFlow,
  handleRemoveModelFlow,
  handleSearchFlow,
} from "../../state/flows/actions";
import { ContainerCenter, Dashboard } from "./styles";
import Loading from "../../components/LoadingTemplate/index";
import { fetchActivedTasks } from "../../state/activedTasks/reducers";
import { fetchFlows } from "../../state/flows/reducers";

const DashboardModelPage = ({ location }) => {
  const params = new URLSearchParams(location.search); //coleta de parametros da rota
  const [titleSearch, setTitleSearch] = useState(""); //filtrar titulo do fluxo
  const flows = useSelector(
    (
      state //fluxos na tela
    ) => state.flows.entities //seletor de filtros
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const flowsStatus = useSelector((state) => state.flows.status);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const pagesFlow = useSelector((state) => state.flows.pages);

  const [title, setTitle] = useState(
    user?.rank === "Funcionário" &&
      (params.get("title") === null ? "" : params.get("title").toUpperCase())
  );
  const [client, setClient] = useState(
    user?.rank === "Funcionário" &&
      (params.get("client") === null ? "" : params.get("client").toUpperCase())
  );

  const [flowTitle, setFlowTitle] = useState(
    user?.rank === "Funcionário" &&
      (params.get("flowTitle") === null
        ? ""
        : params.get("flowTitle").toUpperCase())
  );
  const [flowType, setFlowType] = useState(
    params.get("flowType") === null ? "all" : params.get("flowType")
  );

  const [status, setStatus] = useState(
    params.get("status") === null ? "all" : params.get("status")
  );

  const pages = useSelector((state) => state.activedTasks.pages);

  const tasks = useSelector((state) => state.activedTasks.entities);
  const activedTasksStatus = useSelector((state) => state.activedTasks.status);
  const statusOptions = [
    {
      key: "undefined",
      value: "undefined",
      text: "Todas as tarefas",
    },
    {
      key: "pending",
      value: "pending",
      text: "Tarefa pendente",
    },
    {
      key: "doing",
      value: "doing",
      text: "Tarefa em andamento",
    },
    {
      key: "done",
      value: "done",
      text: "Tarefa concluída",
    },
    {
      key: "expired",
      value: "expired",
      text: "Tarefa expirada",
    },
    {
      key: "doneExpired",
      value: "doneExpired",
      text: "Tarefa concluída fora do prazo",
    },
  ];
  const flowTypeOptions = [
    {
      key: "undefined",
      value: "undefined",
      text: "Todas os tipos",
    },

    { key: "actived", value: "actived", text: "Ativo" },
    {
      key: "finished",
      value: "finished",
      text: "Encerrado",
    },
  ];
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [confirmCopyOpen, setConfirmCopyOpen] = useState(false);
  const [actualFlow, setActualFlow] = useState(null);
  const nowLocal = moment().utcOffset(-180);
  const [activePage, setActivePage] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!token) history.push("/login");
  }, [history, token]);

  useEffect(() => {
    if (user?.rank === "Gerente") {
      dispatch(
        fetchFlows({
          enterpriseId: !user?.enterpriseId ? user?._id : user.enterpriseId,
          page: 1,
        })
      );
    }
    if (user?.rank === "Funcionário")
      dispatch(
        fetchActivedTasks({
          enterpriseId: !user.enterpriseId ? user?._id : user.enterpriseId,
          page: 1,
          title: params.get("title") ? params.get("title") : "undefined",
          flowTitle: params.get("flowTitle")
            ? params.get("flowTitle")
            : "undefined",
          client: params.get("client") ? params.get("client") : "undefined",
          status: params.get("status") ? params.get("status") : "undefined",
          flowType: params.get("flowType")
            ? params.get("flowType")
            : "undefined",
          employeer: user.email,
        })
      );
  }, [dispatch]);

  const handleConfirm = (flowId) => {
    setLoading(true);
    dispatch(handleRemoveModelFlow(flowId)).then(() => {
      setConfirmOpen(false);
      setLoading(false);
    });
  };
  const handleCancel = () => setConfirmOpen(false);

  const handleCopyConfirm = () => {
    let elements = JSON.parse(JSON.stringify(actualFlow.elements));

    elements.forEach(function (v) {
      delete v._id;
    });
    setLoading(true);
    dispatch(
      handleAddModelFlow(
        elements,
        `${actualFlow.title.toUpperCase()} CÓPIA`,
        user?.enterpriseId ? user.enterpriseId : user._id
      )
    ).then(() => setConfirmCopyOpen(false));
    setLoading(false);
  };

  const handleCopyCancel = () => setConfirmCopyOpen(false);

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      history.push({
        pathname: "/",
        search:
          "?" +
          new URLSearchParams({
            title,
            flowTitle,
            client,
            status,
            flowType,
          }).toString(),
      });

      dispatch(
        fetchActivedTasks({
          enterpriseId: !user.enterpriseId ? user?._id : user.enterpriseId,
          page: 1,
          title: title ? title : "undefined",
          flowTitle: flowTitle ? flowTitle : "undefined",
          client: client ? client : "undefined",
          status: status ? status : "undefined",
          flowType: flowType ? flowType : "undefined",
          employeer: user.email,
        })
      );

      setActivePage(1);
    }
  };

  return flowsStatus === "succeeded" && user?.rank === "Gerente" && !loading ? (
    <ContainerCenter>
      <Segment compact>
        <Confirm
          open={confirmOpen}
          content={`Tem certeza que deseja excluir o fluxo modelo: ${actualFlow?.title.toUpperCase()} ?`}
          onCancel={handleCancel}
          onConfirm={() => handleConfirm(actualFlow?._id)}
          confirmButton="Excluir"
          cancelButton="Cancelar"
        />
        <Confirm
          open={confirmCopyOpen}
          content={`Copiar o fluxo modelo: ${actualFlow?.title.toUpperCase()} ?`}
          onCancel={handleCopyCancel}
          onConfirm={() => handleCopyConfirm()}
          confirmButton="Copiar"
          cancelButton="Cancelar"
        />
        <div
          style={{
            marginBottom: "10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <p
            style={{
              fontSize: 20,
              fontWeight: "bold",
            }}
          >
            FLUXOS MODELO DISPONÍVEIS
          </p>
          <Button
            style={{ marginLeft: "10px" }}
            icon="add"
            labelPosition="right"
            onClick={() => history.push("/createflow")}
            content="Criar fluxo"
          />
        </div>

        <Input
          style={{ marginBottom: "10px" }}
          label="Título"
          value={titleSearch}
          onChange={(e) => {
            const pointer = e.target.selectionStart;

            window.requestAnimationFrame(() => {
              e.target.selectionStart = pointer;
              e.target.selectionEnd = pointer;
            });

            setTitleSearch(e.target.value.toUpperCase());
            setActivePage(1);
          }}
        />
        <Button
          style={{ margin: "10px" }}
          content="Filtrar"
          icon="search"
          labelPosition="right"
          color="blue"
          onClick={() => {
            history.push({
              pathname: "/",
              search:
                "?" +
                new URLSearchParams({
                  title,
                  flowTitle,
                  client,
                  status,
                  flowType,
                }).toString(),
            });

            dispatch(
              fetchActivedTasks({
                enterpriseId: !user.enterpriseId
                  ? user?._id
                  : user.enterpriseId,
                page: 1,
                title: title ? title : "undefined",
                flowTitle: flowTitle ? flowTitle : "undefined",
                client: client ? client : "undefined",
                status: status ? status : "undefined",
                flowType: flowType ? flowType : "undefined",
                employeer: user.email,
              })
            );

            setActivePage(1);
          }}
        />

        <Dashboard>
          {flows.length > 0 ? (
            <>
              <Table celled padded color="yellow" selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell singleLine>
                      Título do fluxo
                    </Table.HeaderCell>
                    <Table.HeaderCell>Data de criação</Table.HeaderCell>
                    <Table.HeaderCell>Deletar fluxo</Table.HeaderCell>
                    <Table.HeaderCell>Copiar fluxo</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {flows.map((item, index) => (
                    <Table.Row style={{ cursor: "pointer" }} key={index}>
                      <Table.Cell
                        style={{ padding: "10px" }}
                        onClick={() => history.push(`/viewer/${item._id}`)}
                      >
                        {item.title.toUpperCase()}
                      </Table.Cell>
                      <Table.Cell
                        onClick={() => history.push(`/viewer/${item._id}`)}
                        style={{ padding: "10px" }}
                      >
                        {moment(item.createdAt).format("lll").toUpperCase()}
                      </Table.Cell>
                      <Table.Cell
                        onClick={() => {
                          setActualFlow(item);
                          setConfirmOpen(true);
                        }}
                        negative
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Icon name="trash alternate" />
                      </Table.Cell>

                      <Table.Cell
                        textAlign="center"
                        onClick={() => {
                          setActualFlow(item);
                          setConfirmCopyOpen(true);
                        }}
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        <Icon name="copy" />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <Pagination
                style={{ alignSelf: "center" }}
                activePage={activePage}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                onPageChange={(e, pageInfo) => {
                  setActivePage(pageInfo.activePage);

                  setLoading(true);

                  if (titleSearch !== "")
                    dispatch(
                      handleSearchFlow({
                        title: titleSearch === "" ? "undefined" : titleSearch,
                        enterpriseId: !user.enterpriseId
                          ? user?._id
                          : user.enterpriseId,
                        page: pageInfo.activePage,
                      })
                    ).then(() => setLoading(false));
                  else
                    dispatch(
                      fetchFlows({
                        enterpriseId: !user?.enterpriseId
                          ? user?._id
                          : user.enterpriseId,
                        page: pageInfo.activePage,
                      })
                    ).then(() => setLoading(false));
                }}
                totalPages={pagesFlow}
              />
            </>
          ) : (
            "VOCÊ NÃO POSSUI FLUXOS CADASTRADOS NO MOMENTO, CRIE UM NA TELA DE CRIAÇÃO DE FLUXOS!"
          )}
        </Dashboard>
      </Segment>
    </ContainerCenter>
  ) : user?.rank === "Funcionário" &&
    activedTasksStatus === "succeeded" &&
    !loading ? (
    <ContainerCenter>
      <Segment compact>
        <Dashboard>
          <h3>SUAS TAREFAS ATIVAS</h3>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <Input
              style={{ marginRight: "10px", marginTop: "33px" }}
              label="Fluxo"
              onChange={(e) => {
                const pointer = e.target.selectionStart;

                window.requestAnimationFrame(() => {
                  e.target.selectionStart = pointer;
                  e.target.selectionEnd = pointer;
                });

                setFlowTitle(e.target.value.toUpperCase());
              }}
              value={flowTitle.toUpperCase()}
              onKeyDown={handleKeyDown}
            />
            <div style={{ marginRight: "10px", marginTop: "33px" }}>
              <Input
                label="Cliente"
                onChange={(e) => {
                  const pointer = e.target.selectionStart;

                  window.requestAnimationFrame(() => {
                    e.target.selectionStart = pointer;
                    e.target.selectionEnd = pointer;
                  });
                  setClient(e.target.value.toUpperCase());
                }}
                value={client.toUpperCase()}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div style={{ marginRight: "10px", marginTop: "33px" }}>
              <Input
                label="Tarefa"
                onChange={(e) => {
                  const pointer = e.target.selectionStart;

                  window.requestAnimationFrame(() => {
                    e.target.selectionStart = pointer;
                    e.target.selectionEnd = pointer;
                  });

                  setTitle(e.target.value.toUpperCase());
                }}
                value={title.toUpperCase()}
                onKeyDown={handleKeyDown}
              />
            </div>

            <div style={{ marginRight: "20px" }}>
              <h4>Status da tarefa</h4>
              <Dropdown
                placeholder="Status da tarefa"
                fluid
                selection
                onChange={(e, data) => {
                  setStatus(data.value);
                }}
                options={statusOptions}
                defaultValue={status}
                onKeyDown={handleKeyDown}
              />
            </div>
            <div style={{ marginRight: "10px" }}>
              <h4>Tipo de fluxo</h4>
              <Dropdown
                placeholder={flowType ? flowType : "Tipo de fluxo"}
                fluid
                selection
                onChange={(e, data) => {
                  setFlowType(data.value);
                }}
                options={flowTypeOptions}
                defaultValue={flowType}
                onKeyDown={handleKeyDown}
              />
            </div>

            <Button
              style={{ margin: "10px" }}
              content="Filtrar"
              icon="search"
              labelPosition="right"
              color="blue"
              onClick={() => {
                history.push({
                  pathname: "/",
                  search:
                    "?" +
                    new URLSearchParams({
                      title,
                      flowTitle,
                      client,
                      status,
                      flowType,
                    }).toString(),
                });

                dispatch(
                  fetchActivedTasks({
                    enterpriseId: !user.enterpriseId
                      ? user?._id
                      : user.enterpriseId,
                    page: 1,
                    title: title ? title : "undefined",
                    flowTitle: flowTitle ? flowTitle : "undefined",
                    client: client ? client : "undefined",
                    status: status ? status : "undefined",
                    flowType: flowType ? flowType : "undefined",
                    employeer: user.email,
                  })
                );

                setActivePage(1);
              }}
            />
          </div>
          {tasks?.length > 0 ? (
            <div
              style={{
                margin: "20px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Table celled padded color="yellow" selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell>Fluxo</Table.HeaderCell>
                    <Table.HeaderCell>Cliente</Table.HeaderCell>
                    <Table.HeaderCell>Tarefa</Table.HeaderCell>
                    <Table.HeaderCell>Status</Table.HeaderCell>
                    <Table.HeaderCell>Tipo de fluxo</Table.HeaderCell>
                    <Table.HeaderCell>Responsável</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {tasks?.map((item, index) => (
                    <Table.Row
                      key={index}
                      onClick={() =>
                        item.data.flowType === "finished"
                          ? history.push(
                              `/finishedviewer/${item.flowId}/${item.id}`
                            )
                          : history.push(
                              `/activedviewer/${item.flowId}/${item.id}`
                            )
                      }
                      style={{ cursor: "pointer" }}
                    >
                      <Table.Cell style={{ padding: "10px" }}>
                        {item?.data?.flowTitle}
                      </Table.Cell>
                      <Table.Cell style={{ padding: "10px" }}>
                        {item?.data?.client}
                      </Table.Cell>
                      <Table.Cell style={{ padding: "10px" }}>
                        {item.data.label.toUpperCase()}
                      </Table.Cell>
                      <Table.Cell style={{ padding: "10px" }}>
                        {item.data.status.toUpperCase() === "DOING"
                          ? moment(item.data.startedAt)
                              .add(item.data.expiration.number, "hours")
                              .diff(nowLocal, "hours", true) < 0
                            ? "EXPIRADA"
                            : "EM ANDAMENTO"
                          : item.data.status.toUpperCase() === "DONE"
                          ? item.data.expired === true
                            ? "CONCLUÍDA FORA DO PRAZO"
                            : "CONCLUÍDA"
                          : "PENDENTE"}
                      </Table.Cell>
                      <Table.Cell>
                        {item.data.flowType === "finished"
                          ? "ENCERRADO"
                          : "ATIVO"}
                      </Table.Cell>
                      <Table.Cell style={{ padding: "10px" }}>
                        {user?.username?.toUpperCase()}
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <Pagination
                style={{ alignSelf: "center" }}
                activePage={activePage}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                onPageChange={(e, pageInfo) => {
                  setActivePage(pageInfo.activePage);

                  dispatch(
                    fetchActivedTasks({
                      enterpriseId: !user.enterpriseId
                        ? user?._id
                        : user.enterpriseId,
                      page: pageInfo.activePage,
                      title: params.get("title")
                        ? params.get("title")
                        : "undefined",
                      flowTitle: params.get("flowTitle")
                        ? params.get("flowTitle")
                        : "undefined",
                      client: params.get("client")
                        ? params.get("client")
                        : "undefined",
                      status: params.get("status")
                        ? params.get("status")
                        : "undefined",
                      flowType: params.get("flowType")
                        ? params.get("flowType")
                        : "undefined",
                      employeer: user.email,
                    })
                  );
                }}
                totalPages={pages}
              />
            </div>
          ) : (
            <p style={{ alignSelf: "center", margin: "30px", fontSize: 30 }}>
              Não existe tarefas disponíveis
            </p>
          )}
        </Dashboard>
      </Segment>
    </ContainerCenter>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

export default DashboardModelPage;
