import ReactFlow from "react-flow-renderer";
import styled from "styled-components";

export const StickyNotes = styled.div`
  background: rgb(248, 238, 167);
  background: -moz-linear-gradient(
    0deg,
    rgba(248, 238, 167, 1) 0%,
    rgba(247, 234, 141, 1) 100%
  );
  background: -webkit-linear-gradient(
    0deg,
    rgba(248, 238, 167, 1) 0%,
    rgba(247, 234, 141, 1) 100%
  );
  background: linear-gradient(
    0deg,
    rgba(248, 238, 167, 1) 0%,
    rgba(247, 234, 141, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f8eea7",endColorstr="#f7ea8d",GradientType=1);

  background-color: #f8eb9c;
  margin: 10px;

  -webkit-box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.31);
  -moz-box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.31);
  box-shadow: 10px 10px 5px -7px rgba(0, 0, 0, 0.31);

  min-height: 180px;
`;

export const StickyTitle = styled.div`
  background-color: #e9dd86;
  display: flex;
  flex-direction: row;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  justify-content: center;
`;

export const ReactFlowWrapper = styled.div`
  flex: 1;
  border-right: 1px solid #eee;
  padding: 15px 10px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
`;

export const ButtonTest = styled.div`
  position: absolute;
  z-index: 10;
  top: 30px;
  left: 80px;
`;

export const ButtonTest2 = styled.div`
  position: absolute;
  z-index: 10;
  top: 70px;
  left: 80px;
`;
export const ButtonTest3 = styled.div`
  top: 110px;
  left: 80px;
  position: absolute;
  z-index: 10;
`;
export const ReactFlowBoard = styled(ReactFlow)`
  position: relative;

  .react-flow__edges {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3 !important;
  }
`;
