import styled from "styled-components";

export const Label = styled.div`
  padding: 10px;
  background-color: ${({ status }) =>
    status === "done" || status === "doing" ? "green" : "white"};
  border: 1px solid black;
  border-radius: 10px;
  cursor: default;
  z-index: 1;
`;
