import React, { useState } from "react";
import { Icon, Image, Menu, Segment } from "semantic-ui-react";
import "semantic-ui-css/semantic.min.css";
import { withRouter } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { signOut } from "../../state/auth/actions";
import logo from "../../assets/logo.png";
import { clearUsers } from "../../state/users/actions";
import { clearActivedFlows } from "../../state/activedFlows/actions";
import { clearFlows } from "../../state/flows/actions";
import { TitleHeader } from "./styles";

const Header = ({ history }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const [state, setState] = useState({
    activeItem:
      history.location.pathname === "/"
        ? user?.rank === "Gerente"
          ? "Fluxos modelo"
          : "Suas tarefas"
        : history.location.pathname === "/activedflows"
        ? "Fluxos Ativos"
        : history.location.pathname === "/finishedflows"
        ? "Fluxos encerrados"
        : history.location.pathname === "/employees"
        ? "Funcionários"
        : history.location.pathname === "/filter"
        ? "Tarefas"
        : history.location.pathname === "/employeerstats"
        ? user?.rank === "Gerente"
          ? "Estatísticas do funcionário"
          : "Minhas estatísticas"
        : null,
  });

  history.listen((location, actions) => {
    setState({
      activeItem:
        history.location.pathname === "/"
          ? user?.rank === "Gerente"
            ? "Fluxos modelo"
            : "Suas tarefas"
          : history.location.pathname === "/activedflows"
          ? "Fluxos Ativos"
          : // : history.location.pathname === "/help"
          // ? "Ajuda"
          history.location.pathname === "/finishedflows"
          ? "Fluxos encerrados"
          : history.location.pathname === "/employees"
          ? "Funcionários"
          : history.location.pathname === "/filter"
          ? "Tarefas"
          : history.location.pathname === "/employeerstats"
          ? user?.rank === "Gerente"
            ? "Estatísticas do funcionário"
            : "Minhas estatísticas"
          : null,
    });
  });

  const handleItemClick = (e, { name }) => {
    if (name === "Fluxos modelo") {
      history.push("/");
    } else if (name === "Fluxos Ativos") {
      history.push("/activedflows");
    } else if (name === "Fluxos encerrados") {
      history.push("/finishedflows");
    } else if (name === "Funcionários") {
      history.push("/employees");
    } else if (name === "Tarefas") {
      history.push({
        pathname: "/filter",
        search:
          "?" +
          new URLSearchParams({
            title: "",
            flowTitle: "",
            client: "",
            status: "doing",
            flowType: "actived",
            employeer: user.email,
          }).toString(),
      });
    } else if (name === "Estatísticas do funcionário") {
      history.push("/employeerstats");
    }
    setState({ activeItem: name });
  };
  const handleItemClickAccountable = (e, { name }) => {
    if (name === "Suas tarefas") {
      history.push({
        pathname: "/",
        search:
          "?" +
          new URLSearchParams({
            title: "",
            flowTitle: "",
            client: "",
            status: "undefined",
            flowType: "undefined",
          }).toString(),
      });
    } else if (name === "Minhas estatísticas") {
      history.push("/employeerstats");
    }
    setState({ activeItem: name });
  };

  return history.location.pathname !== "/login" &&
    history.location.pathname !== "/playground" &&
    user?.rank === "Gerente" &&
    history.location.pathname !== "/404" ? (
    <Segment inverted style={{ borderRadius: 0, margin: 0 }}>
      <Menu inverted pointing secondary>
        <TitleHeader>
          <Image
            src={logo}
            size="mini"
            style={{
              backgroundColor: "#F0F0F0",
              padding: "3px",
              borderRadius: "5px",
              userSelect: "none",
              cursor: "default",
            }}
          />
          <div style={{ marginLeft: "20px" }}>
            <h3
              style={{ color: "white", userSelect: "none", cursor: "default" }}
            >
              ZAIVU
            </h3>
          </div>
        </TitleHeader>
        <Menu.Item
          name="Fluxos modelo"
          active={state.activeItem === "Fluxos modelo"}
          onClick={handleItemClick}
        />
        <Menu.Item
          name="Fluxos Ativos"
          active={state.activeItem === "Fluxos Ativos"}
          onClick={handleItemClick}
        />
        <Menu.Item
          name="Fluxos encerrados"
          active={state.activeItem === "Fluxos encerrados"}
          onClick={handleItemClick}
        />
        <Menu.Item
          name="Funcionários"
          active={state.activeItem === "Funcionários"}
          onClick={handleItemClick}
        >
          Funcionários
        </Menu.Item>
        <Menu.Item
          name="Tarefas"
          active={state.activeItem === "Tarefas"}
          onClick={handleItemClick}
        />
        <Menu.Item
          name="Estatísticas do funcionário"
          active={state.activeItem === "Estatísticas do funcionário"}
          onClick={handleItemClick}
        >
          Estatísticas do Funcionário
        </Menu.Item>

        <div className="right menu">
          <div
            style={{ marginRight: "20px" }}
            onClick={() => history.push("/profile")}
          >
            <a className="ui yellow big label">
              <Icon name="user" />
              {user?.username}
              <div className="detail">
                {user.rank === "Gerente" && !!!user.enterpriseId
                  ? "Administrador"
                  : user?.rank}
              </div>
            </a>
          </div>
          <button
            className="ui right labeled icon button"
            onClick={() => {
              dispatch(signOut());
              history.push("/login");
              dispatch(clearUsers());
              dispatch(clearActivedFlows());
              dispatch(clearFlows());
            }}
          >
            <i className="right arrow icon"></i>
            Sair
          </button>
        </div>
      </Menu>
    </Segment>
  ) : user?.rank === "Funcionário" &&
    history.location.pathname !== "/login" &&
    history.location.pathname !== "/404" ? (
    <Segment inverted style={{ borderRadius: 0, margin: 0 }}>
      <Menu inverted pointing secondary>
        <TitleHeader>
          <Image
            src={logo}
            size="mini"
            style={{
              backgroundColor: "#F0F0F0",
              padding: "3px",
              borderRadius: "5px",
              userSelect: "none",
              cursor: "default",
            }}
          />
          <div style={{ marginLeft: "20px" }}>
            <h3
              style={{ color: "white", userSelect: "none", cursor: "default" }}
            >
              ZAIVU
            </h3>
          </div>
        </TitleHeader>
        <Menu.Item
          name="Suas tarefas"
          active={state.activeItem === "Suas tarefas"}
          onClick={handleItemClickAccountable}
        />

        <Menu.Item
          name="Minhas estatísticas"
          active={state.activeItem === "Minhas estatísticas"}
          onClick={handleItemClickAccountable}
        >
          Minhas estatísticas
        </Menu.Item>

        <div className="right menu">
          <div
            style={{ marginRight: "20px" }}
            onClick={() => history.push("/profile")}
          >
            <a className="ui yellow big label">
              <Icon name="user" />
              {user?.username}
              <div className="detail">{user?.rank}</div>
            </a>
          </div>
          <button
            className="ui right labeled icon button"
            onClick={() => {
              dispatch(signOut());
              history.push("/login");
            }}
          >
            <i className="right arrow icon"></i>
            Sair
          </button>
        </div>
      </Menu>
    </Segment>
  ) : null;
};

export default withRouter(Header);
