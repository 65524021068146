import React from "react";

const ParallelStartIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="1.83022"
        y="12.123"
        width="14.5048"
        height="14.5048"
        rx="1.22362"
        transform="rotate(-45 1.83022 12.123)"
        stroke="#7E858F"
        strokeWidth="1.31774"
      />
      <path
        d="M8.81006 11.9805H14.7435"
        stroke="#7E858F"
        strokeWidth="0.752994"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.7764 14.9471V9.01367"
        stroke="#7E858F"
        strokeWidth="0.752994"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ParallelStartIcon;
