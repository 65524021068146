import React from "react";
import { Container, Progression } from "./styles";

const LoadingBar = ({ progressionLevel = "0", animation = false }) => {
  return (
    <Container animation={animation}>
      <Progression
        progressionLevel={progressionLevel}
        animation={animation}
      ></Progression>
    </Container>
  );
};
export default LoadingBar;
