import React from "react";
import { HelpDialog, IconCloseDiv } from "./styles";
import RedEye from "../../../../assets/icons/RedEye";

import CloseRed from "../../../../assets/icons/CloseRed";

const Help = ({ setHelp }) => {
  return (
    <>
      <HelpDialog>
        <RedEye />
        <p>
          Dê um clique para visualizar o caminho crítico das principais tarefas
          do fluxo
        </p>
        <IconCloseDiv onClick={() => setHelp()}>
          <CloseRed />
        </IconCloseDiv>
      </HelpDialog>
    </>
  );
};

export default Help;
