import React from "react";
import RedoIcon from "../../../assets/icons/RedoIcon";
import UndoIcon from "../../../assets/icons/UndoIcon";
import { Container } from "./styles";

const UndoRedo = ({ undo, redo }) => {
  return (
    <Container>
      <UndoIcon onClick={() => undo()} />
      <RedoIcon onClick={() => redo()} />
    </Container>
  );
};

export default UndoRedo;
