import ReactFlow from "react-flow-renderer";
import styled from "styled-components";

export const ReactFlowWrapper = styled.div`
  flex: 1;
  border-right: 1px solid #eee;
  padding: 15px 10px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
`;

export const ButtonTest = styled.div`
  position: absolute;
  z-index: 10;
  top: 30px;
  left: 80px;
`;

export const ReactFlowBoard = styled(ReactFlow)`
  position: relative;

  .react-flow__edges {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3 !important;
  }
`;
