import React from "react";

const AlertIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.3902 14.0259L13.3663 3.18281C12.5568 1.72388 11.4367 0.923828 10.2037 0.923828C8.97067 0.923828 7.85059 1.72388 7.04112 3.18281L1.01717 14.0259C0.254759 15.4096 0.170048 16.7367 0.781855 17.7815C1.39366 18.8263 2.59845 19.4004 4.17974 19.4004H16.2276C17.8089 19.4004 19.0137 18.8263 19.6255 17.7815C20.2373 16.7367 20.1526 15.4001 19.3902 14.0259ZM9.49776 7.51253C9.49776 7.12662 9.81778 6.80659 10.2037 6.80659C10.5896 6.80659 10.9096 7.12662 10.9096 7.51253V12.2187C10.9096 12.6046 10.5896 12.9247 10.2037 12.9247C9.81778 12.9247 9.49776 12.6046 9.49776 12.2187V7.51253ZM10.872 15.7107C10.8249 15.7484 10.7779 15.7861 10.7308 15.8237C10.6743 15.8613 10.6178 15.8896 10.5614 15.9084C10.5049 15.9366 10.4484 15.9555 10.3825 15.9649C10.3261 15.9743 10.2602 15.9837 10.2037 15.9837C10.1472 15.9837 10.0813 15.9743 10.0154 15.9649C9.95897 15.9555 9.9025 15.9366 9.84602 15.9084C9.78955 15.8896 9.73307 15.8613 9.6766 15.8237C9.62954 15.7861 9.58247 15.7484 9.53541 15.7107C9.36599 15.5319 9.26245 15.2872 9.26245 15.0425C9.26245 14.7977 9.36599 14.553 9.53541 14.3742C9.58247 14.3365 9.62954 14.2989 9.6766 14.2612C9.73307 14.2236 9.78955 14.1954 9.84602 14.1765C9.9025 14.1483 9.95897 14.1295 10.0154 14.1201C10.1378 14.0918 10.2696 14.0918 10.3825 14.1201C10.4484 14.1295 10.5049 14.1483 10.5614 14.1765C10.6178 14.1954 10.6743 14.2236 10.7308 14.2612C10.7779 14.2989 10.8249 14.3365 10.872 14.3742C11.0414 14.553 11.1449 14.7977 11.1449 15.0425C11.1449 15.2872 11.0414 15.5319 10.872 15.7107Z"
        fill="#DF5959"
      />
    </svg>
  );
};
export default AlertIcon;
