import React, { memo } from "react";
import { Handle, useStoreState, Position } from "react-flow-renderer";

import { ConditionalEndStyle, ConditionalEndStyleInside } from "./styles.js";
import { Plus } from "react-feather";

const ParallelEndNode = ({
  data,
  targetPosition = Position.Left,
  sourcePosition = Position.Right,
  selected,
}) => {
  const edges = useStoreState((state) => state.edges);
  const nodes = useStoreState((state) => state.nodes);

  return (
    <ConditionalEndStyle
      criticalPath={data.criticalPath}
      status={data.status}
      selected={selected}
    >
      <ConditionalEndStyleInside>
        <Plus style={{ transform: "rotate(45deg)" }} size={50} />
      </ConditionalEndStyleInside>

      <Handle
        id="b"
        type="target"
        position={targetPosition}
        style={{ top: 0, width: "8px", height: "8px" }}
        isValidConnection={(e) =>
          nodes.find((item) => item.id === e.source).type === "parallel" ||
          nodes.find((item) => item.id === e.source).type === "conditional"
            ? true
            : !edges.find((item) => item.source === e.source)
        }
      />

      <Handle
        type="source"
        position={sourcePosition}
        id="a"
        style={{
          top: "100%",
          width: "8px",
          height: "8px",
        }}
        isValidConnection={(e) =>
          nodes.find((item) => item.id === e.target).type === "parallelEnd" ||
          nodes.find((item) => item.id === e.target).type === "conditionalEnd"
            ? !edges.find((item) => item.source === e.source)
            : !edges.find(
                (item) => item.source === e.source || item.target === e.target
              )
        }
      />
    </ConditionalEndStyle>
  );
};

export default memo(ParallelEndNode);
