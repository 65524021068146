import React from "react";
import InterrogationIcon from "../../../assets/icons/Interrogation";
import { Container, Button } from "./styles";

const HelpButton = () => {
  return (
    <Container>
      <Button onClick={() => console.log("help")}>
        <InterrogationIcon />
      </Button>
    </Container>
  );
};

export default HelpButton;
