import styled from "styled-components";

export const Container = styled.div`
  width: 18.35625rem;

  display: flex;
  flex-direction: column;
  margin-top: 1.8125rem;

  font-family: Sora;
`;

export const Label = styled.span`
  font-size: 0.70625rem;
  color: #585e66;
  padding-left: 0.46875rem;
  font-weight: 600;
  line-height: 0.885rem;
`;

export const UList = styled.ul`
  margin: 0.36625rem 0 0 0;
  padding: 0 0 0 0.46875rem;
  display: flex;
  flex-direction: column;
  max-height: 5.3125rem;
  overflow-y: ${({ len }) => (len > 3 ? "scroll" : "none")};

  ::-webkit-scrollbar {
    width: 0.25rem;
  }
`;

export const AddSubtask = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;

  border: none;
  width: 9.4125rem;
  padding: 0;
  height: 1.921875rem;
  border-radius: 0.35296625rem;
  background: #585e66;
  color: #eff1f5;
  margin: 1.25rem 0 0 0.46875rem;
  cursor: pointer;

  p {
    font-family: Sora;
    text-align: center;
    font-size: 0.70625rem;
    line-height: 0.885rem;
    font-weight: 600;
  }
`;
