import styled, { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`
/* 
svg.react-flow__edges {
  position: absolute; 
  top: 0;
  left: 0; 
  z-index: 4;
  //user-select: unset;
  //pointer-events: none;
}  */

`;
