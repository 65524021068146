import React from "react";

const RedoIcon = ({ width, height, onClick }) => (
  <svg
    width={width}
    height={height}
    onClick={onClick}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.8798 4.30017C11.1974 4.09623 10.4444 3.96289 9.61298 3.96289C5.85585 3.96289 2.8125 7.00624 2.8125 10.7634C2.8125 14.5283 5.85585 17.5717 9.61298 17.5717C13.3701 17.5717 16.4135 14.5283 16.4135 10.7712C16.4135 9.37504 15.9899 8.07298 15.2683 6.99055"
      stroke="#42464C"
      strokeWidth="1.31774"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8524 4.48887L10.5856 1.88477"
      stroke="#42464C"
      strokeWidth="1.31774"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.8524 4.49023L10.2091 6.41978"
      stroke="#42464C"
      strokeWidth="1.31774"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RedoIcon;
