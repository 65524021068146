import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 12.765625rem;
  height: 36.531875rem;
  background: #fff;
  box-shadow: 10px 3.76497px 11.2949px rgba(88, 94, 102, 0.18);
  border-radius: 2.82373px;
  user-select: none;

  //****************** */
  position: absolute;
  top: 5.25rem;
  left: 1rem;
  z-index: 5;
`;
