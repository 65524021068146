import React, { useEffect, useState } from "react";
import Portal from "../Modal/Portal";
import { Container, Text } from "./styles";

const Alert = ({ open, setOpen, version, type, errorMsg, selector }) => {
  const [versionName, setVersionName] = useState("");
  const [typeAlert, setTypeAlert] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (open) {
      setVersionName(version);
      setTypeAlert(type);
      setMessage(errorMsg);
    }
  }, [open]);

  return (
    <Portal selector={selector}>
      {open && (
        <Container open={open} onAnimationEnd={() => setOpen(false)}>
          {type === "save" ? (
            <Text type={typeAlert}>
              Versão
              <span>"{versionName}" </span>
              salva com sucesso!
            </Text>
          ) : type === "error" ? (
            <Text type={typeAlert}>
              Erro para salvar
              <span> "{message}"</span>
            </Text>
          ) : type === "rename" ? (
            <Text type={typeAlert}>
              Versão
              <span> "{versionName}" </span>
              renomeada com sucesso!
            </Text>
          ) : type === "delete" ? (
            <Text type={typeAlert}>
              Versão
              <span> "{versionName}" </span>
              deletada com sucesso!
            </Text>
          ) : (
            <Text type={typeAlert}>
              Versão
              <span> "{versionName}" </span>
              definidada como padrão!
            </Text>
          )}
        </Container>
      )}
    </Portal>
  );
};

export default Alert;
