import React from "react";
import { Container, Input, Label, Wrapper } from "./styles";

const Deadline = ({ deadline, setDeadline, style = null }) => {
  const handleDeadline = (e) => setDeadline(e.target.value);

  const content = deadline > 1 ? "dias" : "dia";
  const errorStyle = {
    border: "0.941243px solid red",
  };

  return (
    <Container style={style}>
      <Label>Dias para concluir</Label>
      <Wrapper value={deadline} content={content}>
        <Input
          style={deadline < 1 ? errorStyle : null}
          type="number"
          min={1}
          max={999}
          value={deadline}
          onChange={handleDeadline}
        />
      </Wrapper>
    </Container>
  );
};

export default Deadline;
