import ReactFlow from "react-flow-renderer";
import styled from "styled-components";

export const ReactFlowWrapper = styled.div`
  flex: 1;
  border-right: 1px solid #eee;
  padding: 15px 10px;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
`;

export const DndFlow = styled.div`
  flex-direction: column;
  display: flex;
  height: calc(100vh - (72px));
  aside {
    border-right: 1px solid #eee;
    padding: 15px 10px;
    font-size: 12px;
    background: #fcfcfc;
    width: 20%;
    max-width: 250px;
  }

  @media screen and (min-width: 768px) {
    flex-direction: row;
  }
`;

export const ReactFlowWithValidation = styled(ReactFlow)`
  position: relative;

  .react-flow__edges {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3 !important;
  }
  .react-flow__nodes {
    z-index: 3;
  }

  .react-flow_pane {
    z-index: auto;
  }

  .react-flow__handle-connecting {
    background: #ff6060;
  }

  .react-flow__handle-valid {
    background: #55dd99;
  }
`;
