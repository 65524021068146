import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findTaskOneFlow } from "../../../state/flows/selectors";
import {
  editTaskExpirationTime,
  handleEditTimer,
} from "../../../state/flows/actions";
import {
  Button,
  Dropdown,
  Header,
  Input,
  Label,
  Segment,
} from "semantic-ui-react";
import { Modal } from "semantic-ui-react";
import Loading from "../../../components/LoadingTemplate/index";

const ModelTimerModal = ({
  flowId,
  taskId,
  open,
  change,
  version,
  setTaskUpdate,
}) => {
  const dispatch = useDispatch();
  const task = useSelector((state) =>
    findTaskOneFlow(taskId, flowId, state.flows.entities, version)
  );
  const user = useSelector((state) => state.auth.user);
  const [number, setNumber] = useState();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const options = [
    {
      key: "days",
      value: "days",
      text: "DIAS",
    },
    {
      key: "hours",
      value: "hours",
      text: "HORAS",
    },
  ];

  useEffect(() => {
    setNumber(
      task?.data?.expiration.time === "days"
        ? task?.data?.expiration.number / 24
        : task?.data?.expiration.time === "minutes"
        ? task?.data?.expiration.number * 60
        : task?.data?.expiration.time === "hours"
        ? task?.data?.expiration.number
        : "undefined"
    );
  }, [task?.data?.expiration]);

  return task !== undefined && task.data !== undefined && !loading ? (
    <Modal
      onClose={() => change(false)}
      onOpen={() => change(true)}
      open={open}
    >
      <Modal.Header>EDITE AS INFORMAÇÕES DESEJADAS</Modal.Header>
      <Modal.Content scrolling style={{ minHeight: "380px" }}>
        <Segment color="yellow">
          <Header style={{ backgroundColor: "white" }}>
            PRAZO DO TEMPORIZADOR
          </Header>

          <Input
            style={{ marginLeft: "10px" }}
            label="NÚMERO"
            type="number"
            value={number}
            onChange={(e) => setNumber(e.target.value)}
          />

          <Label
            style={{
              marginTop: "10px",
              display: "flex",
              alignItems: "center",
              marginLeft: "10px",
              padding: 0,
              paddingLeft: "10px",
              width: "300px",
            }}
          >
            <div style={{ minWidth: "140px", padding: 0, fontSize: 14 }}>
              MEDIDA DE TEMPO
            </div>
            <Dropdown
              labeled
              label="MEDIDA DE TEMPO"
              placeholder={task.data.expiration.time}
              fluid
              selection
              defaultValue={task.data.expiration.time}
              onChange={(e, data) =>
                dispatch(
                  editTaskExpirationTime({
                    flowId: flowId,
                    taskId: task.id,
                    time: data.value,
                  })
                )
              }
              options={options}
            />
          </Label>
        </Segment>
        <p style={{ color: "red", textAlign: "center" }}>
          {error !== "" ? error : null}
        </p>
      </Modal.Content>
      <Modal.Actions>
        <Button color="black" onClick={() => change(false)}>
          Voltar
        </Button>
        <Button
          content="Salvar tarefa"
          labelPosition="right"
          icon="checkmark"
          onClick={() => {
            if (number === "0" || !number) {
              setError(
                "Necessário colocar número para o prazo do temporizador"
              );
            } else {
              setLoading(true);
              setError("");
              dispatch(
                handleEditTimer(
                  task._id,
                  {
                    time: task.data.expiration.time,
                    number:
                      task.data.expiration.time === "days"
                        ? number * 24
                        : task.data.expiration.time === "hours"
                        ? number
                        : task.data.expiration.time === "minutes"
                        ? number / 60
                        : "undefined",
                  },
                  !user.enterpriseId ? user?._id : user.enterpriseId,
                  version
                )
              ).then(() => {
                setTaskUpdate();
                change(false);
                setLoading(false);
              });
            }
          }}
          positive
        />
      </Modal.Actions>
    </Modal>
  ) : (
    <Modal
      onClose={() => change(false)}
      onOpen={() => change(true)}
      open={open}
    >
      <div>
        <Loading />
      </div>
    </Modal>
  );
};

export default ModelTimerModal;
