import React from "react";

const RedEye = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8909 8.6288C11.8909 10.0265 10.7614 11.156 9.36366 11.156C7.96592 11.156 6.83643 10.0265 6.83643 8.6288C6.83643 7.23105 7.96592 6.10156 9.36366 6.10156C10.7614 6.10156 11.8909 7.23105 11.8909 8.6288Z"
        stroke="#CD5959"
        strokeWidth="1.41186"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.3636 14.4664C11.8555 14.4664 14.1781 12.998 15.7946 10.4567C16.43 9.46132 16.43 7.78826 15.7946 6.7929C14.1781 4.25154 11.8555 2.7832 9.3636 2.7832C6.87166 2.7832 4.54914 4.25154 2.93256 6.7929C2.29722 7.78826 2.29722 9.46132 2.93256 10.4567C4.54914 12.998 6.87166 14.4664 9.3636 14.4664Z"
        stroke="#CD5959"
        strokeWidth="1.41186"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default RedEye;
