import React from "react";
import ReactDOM from "react-dom";
import IndexRouters from "./routes";
import { Provider } from "react-redux";
import { store } from "./state/store";
import { BrowserRouter } from "react-router-dom";
import { ReactFlowProvider } from "react-flow-renderer";

const jsx = (
  <BrowserRouter>
    <Provider store={store}>
      <IndexRouters />
    </Provider>
  </BrowserRouter>
);

ReactDOM.render(jsx, document.getElementById("root"));
