import { createAction } from "@reduxjs/toolkit";

// ? Flow Page
export const clearUndoFlow = createAction("CLEAR_UNDO_FLOW");
export const updateFlow = createAction("UPDATE_FLOW");
export const updateDrop = createAction("UPDATE_DROP");
export const updateRemove = createAction("UPDATE_REMOVE");
export const updateAddEdge = createAction("UPDATE_ADD_EDGE");
export const updateNodeSingleDrag = createAction("UPDATE_NODE_SINGLE_DRAG");
export const updateNodeSelectDrag = createAction("UPDATE_NODE_SELECT_DRAG");

// ? CustomMark
export const updateCustomMark = createAction("CUSTOM_MARK_UPDATE");

// ? CustomText
export const updateCustomTextFORM = createAction("CUSTOM_TEXT_FORM");

// ? TaskNode
export const updateTaskNode = createAction("UPDATE_TASK_NODE");

// ? TimerEvent
export const updateTimerNode = createAction("UPDATE_TIMER_NODE");

// ? ConditionalNode
export const updateConditionalNodeLABEL = createAction("COND_NODE_LABEL");

// ? CustomEdge
export const updateCustomEdgeLABEL = createAction("CUSTOM_EDGE_LABEL");
