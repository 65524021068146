import React from "react";

const ConditionalStartIcon = ({ width, height }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.931852"
        width="14.5036"
        height="14.5036"
        rx="1.22362"
        transform="matrix(0.707159 -0.707054 0.707159 0.707054 0.171322 11.4304)"
        stroke="#7E858F"
        strokeWidth="1.31774"
      />
    </svg>
  );
};

export default ConditionalStartIcon;
