import React, { memo } from "react";
import { Resizable } from "re-resizable";
import { Content } from "./styles";

const CustomMarkForView = ({ selected, id, data }) => {
  const handleStyles = {
    top: {
      marginTop: -3,
      marginLeft: -5,
      top: 0,
      left: "50%",
      cursor: "ns-resize",
      border: "3px solid #999",
      borderLeft: "none",
      borderRight: "none",
      borderBottom: "none",
      borderWidth: 5,
      borderColor: selected ? "#1a192b" : "lightgray",
      width: 10,
      height: 10,
      boxSizing: "border-box",
      zIndex: 1,
    },
    topLeft: {
      marginTop: -2,
      marginLeft: -2,
      top: 0,
      left: 0,
      cursor: "nwse-resize",
      border: "3px solid #999",
      borderRight: "none",
      borderBottom: "none",
      borderColor: selected ? "#1a192b" : "lightgray",
      width: 10,
      height: 10,
      boxSizing: "border-box",
      zIndex: 1,
    },
    left: {
      marginTop: -5,
      marginLeft: -3,
      top: "50%",
      left: 0,
      cursor: "ew-resize",
      border: "3px solid #999",
      borderTop: "none",
      borderRight: "none",
      borderBottom: "none",
      borderWidth: 5,
      borderColor: selected ? "#1a192b" : "lightgray",
      width: 10,
      height: 10,
      boxSizing: "border-box",
      zIndex: 1,
    },
    bottomLeft: {
      marginTop: -7,
      marginLeft: -2,
      top: "100%",
      left: 0,
      cursor: "nesw-resize",
      border: "3px solid #999",
      borderRight: "none",
      borderTop: "none",
      borderColor: selected ? "#1a192b" : "lightgray",
      width: 10,
      height: 10,
      boxSizing: "border-box",
      zIndex: 1,
    },
    bottom: {
      marginTop: -7,
      marginLeft: -5,
      top: "100%",
      left: "50%",
      cursor: "ns-resize",
      border: "3px solid #999",
      borderLeft: "none",
      borderRight: "none",
      borderTop: "none",
      borderWidth: 5,
      borderColor: selected ? "#1a192b" : "lightgray",
      width: 10,
      height: 10,
      boxSizing: "border-box",
      zIndex: 1,
    },
    bottomRight: {
      marginTop: -7,
      marginLeft: -7,
      top: "100%",
      left: "100%",
      cursor: "nwse-resize",
      border: "3px solid #999",
      borderLeft: "none",
      borderTop: "none",
      borderColor: selected ? "#1a192b" : "lightgray",
      width: 10,
      height: 10,
      boxSizing: "border-box",
      zIndex: 1,
    },
    right: {
      marginTop: -5,
      marginLeft: -7,
      top: "50%",
      left: "100%",
      cursor: "ew-resize",
      border: "3px solid #999",
      borderTop: "none",
      borderLeft: "none",
      borderBottom: "none",
      borderWidth: 5,
      borderColor: selected ? "#1a192b" : "lightgray",
      width: 10,
      height: 10,
      boxSizing: "border-box",
      zIndex: 1,
    },
    topRight: {
      marginTop: -2,
      marginLeft: -7,
      top: 0,
      left: "100%",
      cursor: "nesw-resize",
      border: "3px solid #999",
      borderLeft: "none",
      borderBottom: "none",
      borderColor: selected ? "#1a192b" : "lightgray",
      width: 10,
      height: 10,
      boxSizing: "border-box",
      zIndex: 1,
    },
  };

  return (
    <Resizable
      style={{ userSelect: "none" }}
      draggable={false}
      size={{ width: data.refData.width, height: data.refData.height }}
      enable={false}
      handleClasses={{
        top: "nodrag",
        right: "nodrag",
        bottom: "nodrag",
        left: "nodrag",
        topRight: "nodrag",
        bottomRight: "nodrag",
        bottomLeft: "nodrag",
        topLeft: "nodrag",
      }}
      //handleStyles={handleStyles}
    >
      <Content
        style={{
          background: data.hexColor,
          userSelect: "none",
        }}
        className="nodrag"
      ></Content>
    </Resizable>
  );
};

export default memo(CustomMarkForView);
