import React from "react";
import Checkbox from "../../../../Checkbox";
import { Container } from "./styles";

const Check = ({ attachFile, setAttach, lockTask, setLockTask }) => {
  return (
    <Container>
      <div>
        <Checkbox
          checked={attachFile}
          onChange={() => setAttach()}
          label="Anexo de arquivo obrigatório, para concluir tarefa."
        />
      </div>
      <div style={{ marginTop: "1.05rem" }}>
        <Checkbox
          checked={lockTask}
          onChange={() => setLockTask()}
          label="Travar conclusão de tarefa se expirar o prazo."
        />
      </div>
    </Container>
  );
};

export default Check;
