import { useEffect } from "react";
import { useStoreActions } from "react-flow-renderer";

const useNodesSelected = (off, elements, isSelectable) => {
  const setSelectedElements = useStoreActions(
    (actions) => actions.setSelectedElements
  );

  useEffect(() => {
    if (isSelectable) {
      setSelectedElements(elements.map((node) => ({ id: node.id })));
      off();
    }
  }, [isSelectable]);

  return null;
};

export default useNodesSelected;
