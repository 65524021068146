import React from "react";

const ArrowDown = () => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.29997 3.72925L5.58331 6.44591C5.26247 6.76675 4.73747 6.76675 4.41664 6.44591L1.69997 3.72925"
        stroke="#EFF1F5"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArrowDown;
