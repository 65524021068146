import React from "react";
import { getEdgeCenter, getSmoothStepPath } from "react-flow-renderer";

const CustomConnectionLine = ({
  sourceX,
  sourceY,
  sourcePosition,
  targetX,
  targetY,
  targetPosition,
}) => {
  const center = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition, // optional
    targetX,
    targetY,
    targetPosition, // optional
    borderRadius: 0, // optional
    centerX: center[0], // optional
    centerY: center[1], // optional
  });

  return (
    <>
      <path
        fill="none"
        stroke="#222"
        strokeWidth={1.5}
        className="animated"
        d={edgePath}
      />
      <circle
        cx={targetX}
        cy={targetY}
        fill="#fff"
        r={3}
        stroke="#222"
        strokeWidth={1.5}
      />
    </>
  );
};

export default CustomConnectionLine;
