import { createAction } from "@reduxjs/toolkit";
import api from "../../services/api";

export const addActivedFlow = createAction("ADD_ACTIVED_FLOW");
export const sendTask = createAction("SEND_TASK");
export const editComment = createAction("EDIT_COMMENT");
export const editSubtask = createAction("EDIT_SUBTASK");
export const editSubtasks = createAction("EDIT_CHANGES");
export const editChangesSave = createAction("EDIT_CHANGES_SAVE");
export const editTaskAccountable = createAction("EDIT_TASK_ACCOUNTABLE");
export const newFlowPost = createAction("NEW_FLOW_POST");
export const undoTask = createAction("UNDO_TASK");
export const assignAllTasks = createAction("ASSIGN_ALL_TASKS");
export const newSubtask = createAction("NEW_SUBTASK");
export const deleteSubtask = createAction("DELETE_SUBTASK");
export const sortActivedFlow = createAction("SORT_ACTIVED_FLOW");
export const removeActivedFlow = createAction("REMOVE_ACTIVED_FLOW");
export const newPost = createAction("NEW_POST");
export const newPostSubtask = createAction("NEW_POST_SUBTASK");
export const clearActivedFlows = createAction("CLEAR_ACTIVED_FLOWS");
export const newFlowTitle = createAction("NEW_FLOW_TITLE");
export const newTaskTitle = createAction("NEW_TASK_TITLE");
export const searchActived = createAction("SEARCH_ACTIVED");
export const fetchSingleActivedFlow = createAction("FETCH_SINGLE_ACTIVED_FLOW");

export const handleSearch = ({ title, client, enterpriseId, page, type }) => {
  return (dispatch) =>
    api
      .get(
        "/actived-flows/search/" +
          enterpriseId +
          "/" +
          page +
          "/" +
          title +
          "/" +
          client +
          "/" +
          type
      )
      .then((res) => dispatch(searchSucess(res.data)))
      .catch((err) => dispatch(searchFailure(err.message)));
};

const searchSucess = (payload) => searchActived(payload);

const searchFailure = (error) => searchActived(error);

export const handleFetchSingleActivedFlow = ({ flowId, enterpriseId }) => {
  return (dispatch) =>
    api
      .get("/actived-flows/actived-flow/search/" + enterpriseId + "/" + flowId)
      .then((res) => dispatch(fetchSingleActivedFlowSucess(res.data)))
      .catch((err) => dispatch(fetchSingleActivedFlowFailure(err.message)));
};

const fetchSingleActivedFlowSucess = (payload) =>
  fetchSingleActivedFlow(payload);

const fetchSingleActivedFlowFailure = (error) => fetchSingleActivedFlow(error);

export const handleAddActivedFlow = (
  flow,
  title,
  client,
  enterpriseId,
  version
) => {
  return (dispatch) =>
    api
      .post("/actived-flows/actived-flow/new", {
        flow,
        title,
        client,
        enterpriseId,
        version,
      })
      .then((res) => dispatch(addActivedFlowSucess(res.data)))
      .catch((err) => dispatch(addActivedFlowFailure(err.message)));
};

const addActivedFlowSucess = (payload) => addActivedFlow(payload);

const addActivedFlowFailure = (error) => addActivedFlow(error);

export const handleRemoveActivedFlow = (flowId) => {
  return (dispatch) =>
    api
      .delete(`/actived-flows/actived-flow/delete/${flowId}`)
      .then((res) => dispatch(removeActivedFlowSucess(res.data)))
      .catch((err) => dispatch(removeActivedFlowFailure(err.message)));
};

const removeActivedFlowSucess = (payload) => removeActivedFlow(payload);

const removeActivedFlowFailure = (error) => removeActivedFlow(error);

export const handleEditComment = (
  type,
  flowId,
  taskId,
  value,
  enterpriseId
) => {
  return (dispatch) =>
    api
      .put("/actived-flows/actived-flow/edit-comment", {
        type,
        flowId,
        taskId,
        value,
        enterpriseId,
      })
      .then((res) => dispatch(editCommentSucess(res.data)))
      .catch((err) => dispatch(editCommentFailure(err.message)));
};

const editCommentSucess = (payload) => editComment(payload);

const editCommentFailure = (error) => editComment(error);

export const handleNewFlowTitle = (flowId, title) => {
  return (dispatch) =>
    api
      .put("/actived-flows/actived-flow/new-title", {
        flowId,
        title,
      })
      .then((res) => dispatch(newFlowTitleSucess(res.data)))
      .catch((err) => dispatch(newFlowTitleFailure(err.message)));
};

const newFlowTitleSucess = (payload) => newFlowTitle(payload);
const newFlowTitleFailure = (error) => newFlowTitle(error);

export const handleNewTaskTitle = (taskId, title) => {
  return (dispatch) =>
    api
      .put("/actived-flows/actived-flow/task/new-title", {
        taskId,
        title,
      })
      .then((res) => dispatch(newTaskTitleSucess(res.data)))
      .catch((err) => dispatch(newTaskTitleFailure(err.message)));
};

const newTaskTitleSucess = (payload) => newTaskTitle(payload);
const newTaskTitleFailure = (error) => newTaskTitle(error);

export const handleSendTask = (flowId, taskId, edgeId, enterpriseId) => {
  return (dispatch) =>
    api
      .put("/actived-flows/actived-flow/send-task", {
        flowId,
        taskId,
        edgeId,
        enterpriseId,
      })
      .then((res) => dispatch(sendTaskSucess(res.data)))
      .catch((err) => dispatch(sendTaskFailure(err.message)));
};

const sendTaskSucess = (payload) => sendTask(payload);

const sendTaskFailure = (error) => sendTask(error);

export const handleEditChanges = (
  taskId,
  flowId,
  subtasks,
  accountable,
  enterpriseId
) => {
  return (dispatch) =>
    api
      .put("/actived-flows/actived-flow/edit-task", {
        flowId,
        taskId,
        subtasks,
        accountable,
        enterpriseId,
      })
      .then((res) => dispatch(editChangesSucess(res.data)))
      .catch((err) => dispatch(editChangesFailure(err.message)));
};

const editChangesSucess = (payload) => editChangesSave(payload);

const editChangesFailure = (error) => editChangesSave(error);

export const handleNewFlowPost = (flowId, newFlowPost) => {
  return (dispatch) =>
    api
      .put("/actived-flows/actived-flow/flow-post/new", { flowId, newFlowPost })
      .then((res) => dispatch(newFlowPostSucess(res.data)))
      .catch((err) => dispatch(newFlowPostFailure(err.message)));
};

const newFlowPostSucess = (payload) => newFlowPost(payload);

const newFlowPostFailure = (error) => newFlowPost(error);

export const handleUndoTask = (flowId) => {
  return (dispatch) =>
    api
      .put("/actived-flows/actived-flow/undo-task", { flowId })
      .then((res) => dispatch(undoTaskSucess(res.data)))
      .catch((err) => dispatch(undoTaskFailure(err.message)));
};

const undoTaskSucess = (payload) => undoTask(payload);

const undoTaskFailure = (error) => undoTask(error);

export const handleAssignAllTasks = (flowId, employeer) => {
  return (dispatch) =>
    api
      .put("/actived-flows/actived-flow/assign-tasks", { flowId, employeer })
      .then((res) => dispatch(assignAllTasksSucess(res.data)))
      .catch((err) => dispatch(assignAllTasksFailure(err.message)));
};

const assignAllTasksSucess = (payload) => assignAllTasks(payload);

const assignAllTasksFailure = (error) => assignAllTasks(error);

export const handleNewSubtask = (taskId, subtask) => {
  return (dispatch) =>
    api
      .put("/actived-flows/actived-flow/task/new-subtask", { taskId, subtask })
      .then((res) => dispatch(newSubtaskSucess(res.data)))
      .catch((err) => dispatch(newSubtaskFailure(err.message)));
};

const newSubtaskSucess = (payload) => newSubtask(payload);

const newSubtaskFailure = (error) => newSubtask(error);

export const handleDeleteSubtask = (taskId, subtask) => {
  return (dispatch) =>
    api
      .put("/actived-flows/actived-flow/task/remove-subtask", {
        taskId,
        subtask,
      })
      .then((res) => dispatch(deleteSubtaskSucess(res.data)))
      .catch((err) => dispatch(deleteSubtaskFailure(err.message)));
};

const deleteSubtaskSucess = (payload) => deleteSubtask(payload);

const deleteSubtaskFailure = (error) => deleteSubtask(error);

export const handleEditSubtask = (taskId, subtask, prevValue) => {
  return (dispatch) =>
    api
      .put("/actived-flows/actived-flow/task/edit-subtask", {
        taskId,
        subtask,
        prevValue,
      })
      .then((res) => dispatch(editSubtaskSucess(res.data)))
      .catch((err) => dispatch(editSubtaskFailure(err.message)));
};

const editSubtaskSucess = (payload) => editSubtask(payload);

const editSubtaskFailure = (error) => editSubtask(error);

export const handleNewPost = (taskId, newPost) => {
  return (dispatch) =>
    api
      .put("/actived-flows/actived-flow/task/new-post", { taskId, newPost })
      .then((res) => dispatch(newPostSucess(res.data)))
      .catch((err) => dispatch(newPostFailure(err.message)));
};

const newPostSucess = (payload) => newPost(payload);

const newPostFailure = (error) => newPost(error);

export const handleNewPostSubtask = (taskId, newPostSubtask, subtask) => {
  return (dispatch) =>
    api
      .put("/actived-flows/actived-flow/task/new-subtask-post", {
        taskId,
        newPostSubtask,
        subtask,
      })
      .then((res) => dispatch(newPostSubtaskSucess(res.data)))
      .catch((err) => dispatch(newPostSubtaskFailure(err.message)));
};

const newPostSubtaskSucess = (payload) => newPostSubtask(payload);

const newPostSubtaskFailure = (error) => newPostSubtask(error);
