import React, { useEffect, useRef, useState } from "react";
import { Background } from "react-flow-renderer";
import { Wrapper, FlowBoard, Map, Container } from "./styles";
import Controls from "./components/general/Controls";
import UndoRedo from "./components/general/UndoRedo";
import {
  nodeTypes,
  edgeTypes,
  getNodeType,
  ArrowLeftEnter,
  ArrowRightEntered,
  ArrowUpEntered,
  ArrowDownEntered,
} from "./utils";
import Sidebar from "./components/general/Sidebar";
import DropSelectVersion from "./components/general/DropSelectVersion";
import Critical from "./components/general/CriticalButton";
import User from "./components/general/UserAvatar";
import HelpButton from "./components/general/HelpButton";

import { useDispatch, useSelector } from "react-redux";
import { selectUndoFlow } from "./undoFlow/selectors";
import {
  clearUndoFlow,
  updateAddEdge,
  updateDrop,
  updateFlow,
  updateNodeSelectDrag,
  updateNodeSingleDrag,
  updateRemove,
} from "./undoFlow/actions";
import CustomConnectionLine from "./components/CustomConnectionLine";
import useNodesSelected from "./components/hooks/useNodesSelected";
import useChangePosition from "./components/hooks/useChangePosition";
import { ActionCreators } from "redux-undo";
import { useCutCopyPaste } from "./components/hooks/useCutCopyPaste";
import { useHotkeys } from "react-hotkeys-hook";
import { useKey } from "rooks";

import { useHistory, useLocation } from "react-router-dom";

import SaveButton from "./components/general/SaveButton";
import useCriticalPath from "./components/hooks/useCriticalPath";

const FlowPane = (props) => {
  //router Location
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  //token de usuário

  const [picture, setPicture] = useState(null);

  //React router 5.2.0
  const history = useHistory();

  //redux em edit mode e version

  //Estado da Pagina -> Creation/Edition
  const state = "creation";

  //Booleano de confirmação de fetch

  //Painel
  const [title, setTitle] = useState("Project Name");

  //Caminho critico
  const [critical, setCritical] = useState(false);
  const [criticalStats, setCriticalStats] = useState(null);

  //ReactFlow
  const [reactFlowapi, setReactFlowapi] = useState(null);
  const [count, setCount] = useState(1);
  const [selected, setSelected] = useState(null);
  const undoFlow = useSelector(selectUndoFlow);

  //Nodes Selected Hook
  const [isSelectable, setIsSelectable] = useState(false);
  const [selectableFlow, setSelectableFlow] = useState(null);

  //Arrow Keys hook
  const [arrow, setArrow] = useState(false);
  const [coordinates, setCoordinates] = useState(false);

  //Controlador de Versão
  const [version, setVersion] = useState("");

  //Drag Lock
  const [lock, setUnlock] = useState(false);

  //Ref da tela
  const reactFlowWrapper = useRef(null);

  //Todas os Fluxos contidos no Id

  //Todas as versões (titulos) em lista

  const dispatch = useDispatch();

  useEffect(
    () => () => {
      //unmount
      dispatch(clearUndoFlow());
      dispatch(ActionCreators.clearHistory());
    },
    []
  );
  const user = {
    username: "Artur",
    rank: "Admin",
  };

  //Somente utilizado em edit e version(fetching)

  const onLoad = (_reactFlowapi) => setReactFlowapi(_reactFlowapi);

  const onConnect = (params) => {
    if (undoFlow.find((e) => e.id === params.source).type === "conditional") {
      dispatch(
        updateAddEdge({
          params: {
            ...params,
            type: "customEdge",
            data: { text: `Etiqueta ${count}`, status: "pending" },
          },
        })
      );
      setCount((e) => e + 1);
    } else {
      dispatch(
        updateAddEdge({
          params: {
            ...params,
            type: "customEdgeWithoutLabel",
            data: { status: "pending" },
          },
        })
      );
    }
  };

  const onElementsRemove = (elementsToRemove) => {
    dispatch(updateRemove(elementsToRemove));
  };

  const onDragOver = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onDrop = (event) => {
    event.preventDefault();
    const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
    const type = event.dataTransfer.getData("application/reactflow");

    const position = reactFlowapi.project({
      x: event.clientX - reactFlowBounds.left,
      y: event.clientY - reactFlowBounds.top,
    });
    const newNode = getNodeType(position, type);

    if (newNode) {
      dispatch(updateDrop({ newNode }));
    }
  };

  //  Movendo vários itens selecionados
  const onNodeSelectDrag = (e, nodes) =>
    dispatch(
      updateNodeSelectDrag({ nodes: nodes, API: reactFlowapi?.getElements() })
    );
  //  Movendo um item selecionado
  const onNodeSingleDrag = (e, node) =>
    dispatch(
      updateNodeSingleDrag({ node: node, API: reactFlowapi?.getElements() })
    );
  const handleSelect = (selected) => setSelected(selected);

  //undo-redo
  const undoOperation = () => dispatch(ActionCreators.undo());
  const redoOperation = () => dispatch(ActionCreators.redo());

  //Selectable Flow
  const selectFlow = (flow) => setSelectableFlow(flow);
  const isSelectableTrue = () => setIsSelectable(true);
  const isSelectableFalse = () => setIsSelectable(false);

  //Minimap

  const nodeColor = (node) => {
    if (node.type === "customMark") {
      return node.data.hexColor;
    } else {
      return "#fff";
    }
  };

  //Hooks
  useHotkeys("ctrl+z", undoOperation, { keydown: true }, [undoFlow]);
  useHotkeys("ctrl+y", redoOperation, { keydown: true }, [undoFlow]);
  useKey(["ArrowLeft"], () => ArrowLeftEnter(setCoordinates, setArrow));
  useKey(["ArrowRight"], () => ArrowRightEntered(setCoordinates, setArrow));
  useKey(["ArrowUp"], () => ArrowUpEntered(setCoordinates, setArrow));
  useKey(["ArrowDown"], () => ArrowDownEntered(setCoordinates, setArrow));
  useCutCopyPaste(undoFlow, selected, selectFlow, isSelectableTrue);
  useNodesSelected(isSelectableFalse, selectableFlow, isSelectable);
  useChangePosition(setArrow, coordinates, arrow);
  useCriticalPath(critical, undoFlow, setCriticalStats);

  return (
    <>
      <Wrapper>
        <FlowBoard
          minZoom={0}
          ref={reactFlowWrapper}
          elements={undoFlow}
          nodeTypes={nodeTypes}
          edgeTypes={edgeTypes}
          nodesDraggable={!lock && !critical}
          nodesConnectable={!lock && !critical}
          elementsSelectable={!lock && !critical}
          onLoad={onLoad}
          onConnect={onConnect}
          connectionLineComponent={CustomConnectionLine}
          onElementsRemove={onElementsRemove}
          onDragOver={onDragOver}
          onDrop={onDrop}
          onNodeDragStop={onNodeSingleDrag}
          onSelectionDragStop={onNodeSelectDrag}
          deleteKeyCode="Delete"
          multiSelectionKeyCode="Control"
          onSelectionChange={(select) => handleSelect(select)}
        >
          <Sidebar />
          <Controls lock={lock} setUnlock={setUnlock} />
          <UndoRedo undo={undoOperation} redo={redoOperation} />
          <Background />
          <Map nodeColor={nodeColor} />
          <DropSelectVersion
            selector={false}
            title={title}
            setTitle={(data) => setTitle(data)}
            List={[]}
            version={version}
            setVersion={(data) => setVersion(data)}
            setCritical={setCritical}
          />
          <Container>
            <SaveButton
              elements={undoFlow}
              title={title}
              user={user}
              state={state}
              version={version}
              setVersion={(e) => setVersion(e)}
              allVersions={[]}
              flowId={"000000"}
              flow={undoFlow}
              originalId={"000000"}
              defaultVersion={"default"}
            />
            <Critical
              elements={undoFlow}
              version={version}
              critical={critical}
              setCritical={() => setCritical((prev) => !prev)}
              selectFlow={selectFlow}
              setIsSelectable={setIsSelectable}
              value={criticalStats ? criticalStats : null}
            />
            <HelpButton />
            <User user={user} />
          </Container>
        </FlowBoard>
      </Wrapper>
    </>
  );
};

export default FlowPane;
