import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";

import api from "../../services/api";
import { employeerStats } from "./actions";

export const fetchActivedTasks = createAsyncThunk(
  "activedTasks/fetchActivedTasks",
  async ({
    enterpriseId,
    page,
    title,
    flowTitle,
    client,
    status,
    flowType,
    employeer,
  }) => {
    const response = await api.get(
      "/actived-tasks/search/" +
        enterpriseId +
        "/" +
        title +
        "/" +
        page +
        "/" +
        flowTitle +
        "/" +
        client +
        "/" +
        status +
        "/" +
        flowType +
        "/" +
        employeer
    );

    return response.data;
  }
);

const activedTasksReducer = createReducer(
  { entities: [], status: "idle", pages: 1 },
  {
    [fetchActivedTasks.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchActivedTasks.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.entities = action.payload.tasks;
      state.pages = action.payload.pages;
    },
    [employeerStats]: (state, action) => {
      return state;
    },
  }
);

export default activedTasksReducer;
