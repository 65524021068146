import React from "react";

const Close = ({ width, height, onClick }) => (
  <svg
    width={width}
    height={height}
    onClick={onClick}
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.252 1.08398L0.957031 12.2285"
      stroke="#BEBEBE"
      strokeWidth="1.50599"
      strokeMiterlimit="10"
    />
    <path
      d="M0.957031 1.08398L12.252 12.2285"
      stroke="#BEBEBE"
      strokeWidth="1.50599"
      strokeMiterlimit="10"
    />
  </svg>
);

export default Close;
