import React, { memo } from "react";
import { Handle, Position } from "react-flow-renderer";

import { TaskStyle } from "./styles.js";
import moment from "moment";

const TaskNodeForView = ({
  data,
  targetPosition = Position.Left,
  sourcePosition = Position.Right,
  style = {},
}) => {
  const nowLocal = moment().utcOffset(-180);

  return (
    <TaskStyle
      status={data.status}
      style={{ cursor: "pointer" }}
      criticalPath={data.criticalPath}
      expired={
        moment(data.startedAt)
          .add(data.expiration.number, "hours")
          .diff(nowLocal, "hours", true) < 0
      }
      expiredTask={data.expired}
    >
      <Handle
        type="target"
        id="c"
        position={targetPosition}
        style={{ width: "8px", height: "8px" }}
      />
      <div style={{ wordBreak: "break-word" }}>{data.label}</div>

      <Handle
        type="source"
        position={sourcePosition}
        id="a"
        style={{ width: "8px", height: "8px" }}
      />
    </TaskStyle>
  );
};

export default memo(TaskNodeForView);
