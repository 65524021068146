import styled from "styled-components";

export const StyledLink = styled.span`
  color: ${({ color }) => color};
  font-size: 0.64684rem;
  text-decoration-line: underline;
  cursor: pointer;
  user-select: none;
  size: compact;

  &:active {
    color: ${({ colorOnClick }) => colorOnClick};
  }
`;
