import React from "react";
import {
  EdgeText,
  getEdgeCenter,
  getMarkerEnd,
  getSmoothStepPath,
  Position,
} from "react-flow-renderer";

const CustomEdgeForView = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  targetPosition = Position.Left,
  sourcePosition = Position.Right,
  style = {
    stroke: "#222",
    strokeWidth: 1.5,
    strokeDasharray: 5,
    fill: "none",
  },
  data,
  arrowHeadType,
  markerEndId,
  labelShowBg,
  labelStyle = {},
  labelBgStyle = {},
  labelBgPadding,
  labelBgBorderRadius,
}) => {
  if (Math.abs(sourceY - targetY) < 1) {
    sourceY = targetY;
  }

  const center = getEdgeCenter({
    sourceX,
    sourceY,
    targetX,
    targetY,
  });

  const edgePath = getSmoothStepPath({
    sourceX,
    sourceY,
    sourcePosition, // optional
    targetX,
    targetY,
    targetPosition, // optional
    borderRadius: 0, // optional
    centerX: center[0], // optional
    centerY: center[1], // optional
  });
  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  return (
    <>
      <path
        id={id}
        style={
          data.status === "done"
            ? {
                stroke: "#339933",
                strokeWidth: 1.5,
                fill: "none",
                strokeDasharray: 5,
              }
            : data.criticalPath === true
            ? { ...style, pointerEvents: "all", stroke: "red" }
            : style
        }
        className="react-flow__edge-path"
        d={edgePath}
        fill="none"
        stroke="#222"
        strokeWidth={1.5}
        markerEnd={markerEnd}
      />
      <EdgeText
        x={center[0]}
        y={center[1]}
        label={data.text.toUpperCase()}
        labelStyle={{ labelStyle }}
        labelShowBg={labelShowBg}
        labelBgStyle={
          data.criticalPath === true
            ? { ...labelBgStyle, fill: "red" }
            : { labelBgStyle }
        }
        labelBgPadding={labelBgPadding}
        labelBgBorderRadius={labelBgBorderRadius}
      />
    </>
  );
};

export default CustomEdgeForView;
