import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2.7rem;

  label {
    :last-child {
      margin-left: 0.3125rem;
    }
  }
`;
