import React from "react";
import AlertIcon from "../../../../assets/icons/AlertIcon";
import CloseRed from "../../../../assets/icons/CloseRed";
import { Content, IconCloseDiv } from "./styles";

const CriticalDialog = ({ value, setCritical }) => {
  return (
    <Content>
      <AlertIcon />

      <p>
        Total de dias do caminho crítico: <span>{value} dias</span>
      </p>
      <IconCloseDiv onClick={() => setCritical()}>
        <CloseRed />
      </IconCloseDiv>
    </Content>
  );
};

export default CriticalDialog;
