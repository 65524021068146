import React from "react";

const UndoIcon = ({ width, height, onClick }) => (
  <svg
    width={width}
    height={height}
    onClick={onClick}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.63766 4.30017C8.32006 4.09623 9.07305 3.96289 9.90448 3.96289C13.6616 3.96289 16.705 7.00624 16.705 10.7634C16.705 14.5205 13.6616 17.5638 9.90448 17.5638C6.14736 17.5638 3.104 14.5205 3.104 10.7634C3.104 9.36719 3.52756 8.06514 4.24918 6.98271"
      stroke="#42464C"
      strokeWidth="1.31774"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66504 4.48887L8.93187 1.88477"
      stroke="#42464C"
      strokeWidth="1.31774"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.66504 4.49023L9.30836 6.41978"
      stroke="#42464C"
      strokeWidth="1.31774"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UndoIcon;
