import React from "react";
import { Chart, curveLinear } from "react-charts";
import CustomTooltip from "../CustomToolTip/index";
import { Container } from "./styles";

// "react-charts": "^2.0.0-beta.7",

const MyChart = ({ activeDatumIndex, activeSeriesIndex, setState, stats }) => {
  let sortedData = JSON.parse(JSON.stringify(stats.stats));
  sortedData.sort(function (a, b) {
    return Number(a.x) - Number(b.x);
  });

  const data = React.useMemo(
    () => [
      {
        label: `Entrega Realizada`,
        data: stats.graphStats,
      },
      {
        label: `Entrega Prevista`,
        data: sortedData,
      },
    ],
    []
  );
  //paddingBottom: "50%", boxShadow: "0 5px 100px rgba(0,0,0,.3)"
  const axes = React.useMemo(
    () => [
      {
        primary: true,
        type: "linear",
        position: "bottom",
      },
      {
        type: "linear",
        position: "left",
      },
    ],
    []
  );

  const tooltip = React.useMemo(
    () => ({
      render: ({ datum, getStyle, dark }) => (
        <CustomTooltip
          {...{
            datum,
            getStyle,
            dark,
          }}
        />
      ),
    }),
    []
  );

  const series = React.useMemo(
    () => ({ type: "line", curve: curveLinear }),
    []
  );

  const getSeriesStyle = React.useCallback(
    (series) => ({
      color: `url(#${series.index % 4})`,
      opacity:
        activeSeriesIndex > -1
          ? series.index === activeSeriesIndex
            ? 1
            : 0.3
          : 1,
    }),
    [activeSeriesIndex]
  );

  const getDatumStyle = React.useCallback(
    (datum) => ({
      r:
        activeDatumIndex === datum.index &&
        activeSeriesIndex === datum.seriesIndex
          ? 7
          : activeDatumIndex === datum.index
          ? 5
          : datum.series.index === activeSeriesIndex
          ? 3
          : datum.otherHovered
          ? 2
          : 2,
    }),
    [activeDatumIndex, activeSeriesIndex]
  );
  const onFocus = React.useCallback(
    (focused) =>
      setState({
        activeSeriesIndex: focused ? focused.series.id : -1,
        activeDatumIndex: focused ? focused.index : -1,
      }),
    [setState]
  );

  return (
    <Chart
      primaryCursor
      secondaryCursor
      style={{
        zIndex: 3,
      }}
      data={data}
      axes={axes}
      series={series}
      getSeriesStyle={getSeriesStyle}
      getDatumStyle={getDatumStyle}
      onFocus={onFocus}
      tooltip={tooltip}
      renderSVG={() => (
        <defs>
          <linearGradient id="1" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stopColor="#17EAD9" />
            <stop offset="100%" stopColor="#6078EA" />
          </linearGradient>
          <linearGradient id="0" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stopColor="#FCE38A" />
            <stop offset="100%" stopColor="#F38181" />
          </linearGradient>
          <linearGradient id="2" x1="0" x2="0" y1="1" y2="0">
            <stop offset="0%" stopColor="#ffee00" />
            <stop offset="100%" stopColor="#c0b959" />
          </linearGradient>
        </defs>
      )}
    />
  );
};

const BurndownChart = ({ flow, stats }) => {
  const [{ activeSeriesIndex, activeDatumIndex }, setState] = React.useState({
    activeSeriesIndex: -1,
    activeDatumIndex: -1,
  });

  return (
    <Container>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          marginRight: 20,
          overflow: "unset !important",
        }}
      >
        <div
          style={{
            border: "1px solid transparent",
            height: "600px",
            width: "750px",
            margin: "10px",
          }}
        >
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <p>Dias restantes para concluir o fluxo</p>
            <div>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: 10,
                  }}
                >
                  <svg width="16" height="16">
                    <circle
                      cx="8"
                      cy="8"
                      r="7"
                      style={{
                        fill: "url(#1)",
                        strokeWidth: 2,
                      }}
                    />
                  </svg>
                  <p>Ideal</p>
                </div>

                <div style={{ display: "flex", alignItems: "center" }}>
                  <svg width="16" height="16">
                    <circle
                      cx="8"
                      cy="8"
                      r="7"
                      style={{
                        fill: "url(#0)",
                        strokeWidth: 2,
                      }}
                    />
                  </svg>
                  <p>Real</p>
                </div>
              </div>
            </div>
          </div>
          <MyChart
            stats={stats}
            setState={setState}
            activeDatumIndex={activeDatumIndex}
            activeSeriesIndex={activeSeriesIndex}
          />
        </div>
        <p style={{ margin: 25, alignSelf: "flex-end", marginRight: -10 }}>
          Dia atual
        </p>
      </div>
    </Container>
  );
};

export default BurndownChart;
