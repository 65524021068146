import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 2.625rem;
  height: 10.176875rem;
  align-items: center;
  justify-content: space-around;
  background: #ffffff;
  box-shadow: 5px 5.88249px 7.52994px rgba(88, 94, 102, 0.25);

  border-radius: 2.82373px;

  //**************** */
  position: absolute;
  z-index: 5;
  bottom: 1.25rem;
  right: 1.25rem;
  padding: 0.5228125rem 0 0.5228125rem 0;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: content-box;
  background: #fff;
  cursor: pointer;
  padding: 0.3125rem;
  user-select: none;
  border: none;

  svg {
    width: 1.5rem;
    height: 1.5rem;
  }
`;
