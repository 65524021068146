import styled from "styled-components";

export const Container = styled.div`
  width: 280px;
  height: 175px;

  display: flex;
  justify-content: center;

  box-shadow: 0px 4px 64px rgba(66, 70, 76, 0.15);
  user-select: none;
`;
