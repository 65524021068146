import React, { memo } from "react";
import { useStoreState, Position } from "react-flow-renderer";

import { HandleStyle, ParallelStyle } from "./styles.js";
import Add from "../../assets/icons/Add";

const ParallelNode = ({
  id,
  data,
  targetPosition = Position.Left,
  sourcePosition = Position.Right,
  selected,
}) => {
  const edges = useStoreState((state) => state.edges);
  const nodes = useStoreState((state) => state.nodes);

  const AddStyle = {
    transform: "rotate(45deg)",
    alignSelf: "center",
    width: "22px",
    height: "22px",
  };

  return (
    <ParallelStyle
      criticalPath={data.criticalPath}
      status={data.status}
      selected={selected}
      className="flow_elements_node"
      data-id={id}
    >
      <Add style={AddStyle} />

      <HandleStyle
        id="c"
        type="target"
        position={targetPosition}
        selected={selected}
        style={{ top: 0 }}
        isValidConnection={(e) =>
          nodes.find((item) => item.id === e.source).type === "parallel" ||
          nodes.find((item) => item.id === e.source).type === "conditional"
            ? true
            : !edges.find(
                (item) => item.target === e.target || item.source === e.source
              )
        }
      />

      <HandleStyle
        type="source"
        position={sourcePosition}
        selected={selected}
        id="a"
        style={{ top: "100%" }}
      />
    </ParallelStyle>
  );
};

export default memo(ParallelNode);
