import React from "react";
import ConditionalEndIcon from "../../../../assets/SideBar/ConditionalEndIcon";
import ConditionalStartIcon from "../../../../assets/SideBar/ConditionalStartIcon";
import {
  Container,
  Title,
  Draggable,
  ConditionalNode,
  IconDiv,
} from "./styles";

const Conditionals = ({ Label, onDrag }) => {
  return (
    <Container>
      <Title>{Label}</Title>
      <Draggable>
        <ConditionalNode style={{ marginRight: "0.125rem" }}>
          <IconDiv
            draggable
            onDragStart={(event) => onDrag(event, "conditional")}
          >
            <ConditionalStartIcon />
          </IconDiv>
          <span>Início</span>
        </ConditionalNode>
        <ConditionalNode>
          <IconDiv
            draggable
            onDragStart={(event) => onDrag(event, "conditionalEnd")}
          >
            <ConditionalEndIcon />
          </IconDiv>
          <span>Fim</span>
        </ConditionalNode>
      </Draggable>
    </Container>
  );
};

export default Conditionals;
