import styled, { css } from "styled-components";
//Padrão HD
export const TaskStyle = styled.div`
  display: flex;
  width: 168px;
  min-height: 68px;
  padding: 10px;
  font-size: 11.3px;
  border-radius: 1.88249px;

  align-items: center;
  justify-content: center;

  font-family: Sora;
  font-weight: 600;
  text-align: center;

  color: #42464c;
  text-transform: uppercase;
  background: #fff;

  border: 1.31774px solid #42464c;
  box-shadow: 0 0 0 0.5px #42464c;

  &:hover {
    background: #cff8e7;
    box-shadow: 0 0 0 0.5px #40e5a0;
    border: 1.31774px solid #40e5a0;
  }

  ${({ selected }) =>
    selected &&
    css`
      box-shadow: 0 0 3px 0.5px #40e5a0 !important;
      border: 1.31774px solid #40e5a0 !important;
      background: #fff !important;
    `}

  &.critical {
    background: #d93333 !important;
    box-shadow: none !important;
    border: none !important;
    color: #fff;
  }
`;
