import styled from "styled-components";
import PushBackIcon from "../../../assets/icons/PushBackIcon";

//p/ 1080p -> 1.35rem
//p/ 720p -> 1rem
//? Considerando os valores para 720p
export const Container = styled.div`
  font-family: Sora;
  display: flex;
  background: #585e66;
  //min-width: 32.3425rem; //517px
  padding-right: 1.625rem;
  height: 3.763rem; //60.24px
  align-items: center;
  user-select: none;
  border-radius: 0.18125rem;

  //******************** */
  position: absolute;
  top: 0.3125rem;
  z-index: 5;
`;
export const LogoZaivu = styled.img`
  width: 5.82375rem; //93.18px
  height: 1.52375rem; //24.38px
  margin-left: 1.2rem;
  user-select: none;
`;

export const Input = styled.input`
  font-family: Sora;
  font-size: 0.705625rem; //11.29px;
  color: #eff1f5;
  width: 11.765rem;
  height: 2rem; //32px;
  font-weight: 600;
  background: none;
  line-height: 1.329375rem;
  border: 1.2px solid #40e5a0;
  box-sizing: border-box;
  outline: none;
  padding: 0.63325rem 0 0.545rem 0.921875rem;

  ::selection {
    background: #40e5a0;
  }

  border-radius: 0.352375rem; //5.64746px;
`;
export const ProjectName = styled.div`
  display: flex;
  margin-left: ${({ openInput }) => (openInput ? "1.728125rem" : "2.6875rem")};
  align-items: center;
  //width: 200px;

  span {
    font-size: 0.705625rem; //11.29px
    color: #fff;
    font-weight: 600;
    flex-wrap: wrap;
  }
`;

export const IconDiv = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.6875rem;
  width: 1.4375rem; //23px
  z-index: 50;
  cursor: pointer;
`;

export const VerticalLine = styled.div`
  width: 0px;
  height: 80%;
  background: #7e858f;
  margin-left: 1.1rem; //17.6px

  border: 0.541243px solid #7e858f;
`;
