import React, { useEffect, useState } from "react";

import { useSelector, useDispatch } from "react-redux";
import { fetchActivedFlows } from "../../state/activedFlows/reducers";
import {
  Dimmer,
  Header,
  Confirm,
  Icon,
  Input,
  Loader,
  Pagination,
  Segment,
  Table,
  Button,
} from "semantic-ui-react";
import moment from "moment";
import { ContainerCenter, Dashboard } from "./styles";
import {
  handleRemoveActivedFlow,
  handleSearch,
} from "../../state/activedFlows/actions";
import Loading from "../../components/LoadingTemplate";

const FinishedFlowsPage = ({ history }) => {
  const dispatch = useDispatch();
  const [titleSearch, setTitleSearch] = useState("");
  const [clientSearch, setClientSearch] = useState("");
  const activedFlows = useSelector((state) => state.activedFlows.entities);
  const pages = useSelector((state) => state.activedFlows.pages);
  const activedFlowsStatus = useSelector((state) => state.activedFlows.status);
  const token = useSelector((state) => state.auth.token);
  const user = useSelector((state) => state.auth.user);
  const [activePage, setActivePage] = useState(1);

  const [actualFlow, setActualFlow] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleConfirm = (flowId) => {
    setLoading(true);
    dispatch(handleRemoveActivedFlow(flowId)).then(() => {
      setConfirmOpen(false);
      setLoading(false);
    });
  };

  const handleCancel = () => setConfirmOpen(false);

  useEffect(() => {
    if (!token) history.push("/login");
  }, [history, token]);

  useEffect(() => {
    dispatch(
      fetchActivedFlows({
        enterpriseId: !user?.enterpriseId ? user?._id : user.enterpriseId,
        page: 1,
        type: "finished",
      })
    );
  }, []);

  return activedFlowsStatus === "succeeded" && !loading ? (
    <ContainerCenter>
      <Segment compact>
        <Header
          style={{
            backgroundColor: "white",
            display: "flex",
            justifyContent: "center",
          }}
        >
          SEU HISTÓRICO DE FLUXOS ENCERRADOS
        </Header>
        <Confirm
          open={confirmOpen}
          content={`Tem certeza que deseja excluir o fluxo 
            ${actualFlow?.title.toUpperCase()} ?`}
          onCancel={handleCancel}
          onConfirm={() => handleConfirm(actualFlow?._id)}
          confirmButton="Excluir"
          cancelButton="Cancelar"
        />

        <Input
          style={{ marginBottom: "10px" }}
          label="Título"
          value={titleSearch}
          onChange={(e) => {
            const pointer = e.target.selectionStart;

            window.requestAnimationFrame(() => {
              e.target.selectionStart = pointer;
              e.target.selectionEnd = pointer;
            });

            setTitleSearch(e.target.value.toUpperCase());
            setActivePage(1);
          }}
        />
        <Input
          style={{ marginBottom: "10px", marginLeft: "10px" }}
          label="Cliente"
          value={clientSearch}
          onChange={(e) => {
            const pointer = e.target.selectionStart;

            window.requestAnimationFrame(() => {
              e.target.selectionStart = pointer;
              e.target.selectionEnd = pointer;
            });

            setClientSearch(e.target.value.toUpperCase());
            setActivePage(1);
          }}
        />

        <Button
          style={{ margin: "10px" }}
          content="Filtrar"
          icon="search"
          labelPosition="right"
          color="blue"
          onClick={() => {
            setActivePage(1);
            setLoading(true);
            if ((clientSearch || titleSearch) !== "")
              dispatch(
                handleSearch({
                  title: titleSearch === "" ? "undefined" : titleSearch,
                  client: clientSearch === "" ? "undefined" : clientSearch,
                  enterpriseId: !user.enterpriseId
                    ? user?._id
                    : user.enterpriseId,
                  page: 1,
                  type: "finished",
                })
              );
            else
              dispatch(
                fetchActivedFlows({
                  enterpriseId: !user.enterpriseId
                    ? user?._id
                    : user.enterpriseId,
                  page: 1,
                  type: "finished",
                })
              );

            setLoading(false);
          }}
        />
        <Dashboard>
          {activedFlows.filter((item) => item.status[0] === "finished").length >
          0 ? (
            <>
              <Table celled padded color="yellow" selectable>
                <Table.Header>
                  <Table.Row>
                    <Table.HeaderCell singleLine>
                      Título do fluxo
                    </Table.HeaderCell>
                    <Table.HeaderCell singleLine>Cliente</Table.HeaderCell>
                    <Table.HeaderCell singleLine>
                      Data de início do fluxo
                    </Table.HeaderCell>
                    <Table.HeaderCell>
                      Data de finalização do fluxo
                    </Table.HeaderCell>
                    <Table.HeaderCell>Deletar</Table.HeaderCell>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {activedFlows.map((item, index) => (
                    <Table.Row key={index} style={{ cursor: "pointer" }}>
                      <Table.Cell
                        onClick={() =>
                          history.push(`/finishedviewer/${item._id}/full`)
                        }
                        style={{ padding: "10px" }}
                      >
                        {item.title.toUpperCase()}
                      </Table.Cell>
                      <Table.Cell
                        onClick={() =>
                          history.push(`/finishedviewer/${item._id}/full`)
                        }
                        style={{ padding: "10px" }}
                      >
                        {item.client.toUpperCase()}
                      </Table.Cell>
                      <Table.Cell
                        onClick={() =>
                          history.push(`/finishedviewer/${item._id}/full`)
                        }
                        style={{ padding: "10px" }}
                      >
                        {moment(item.createdAt).format("lll").toUpperCase()}
                      </Table.Cell>
                      <Table.Cell
                        onClick={() =>
                          history.push(`/finishedviewer/${item._id}/full`)
                        }
                        style={{ padding: "10px" }}
                      >
                        {moment(item.finishedAt).format("lll").toUpperCase()}
                      </Table.Cell>
                      <Table.Cell
                        onClick={() => {
                          setActualFlow(item);
                          setConfirmOpen(true);
                        }}
                        negative
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          cursor: "pointer",
                        }}
                      >
                        <Icon name="trash alternate" />
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
              <Pagination
                style={{ alignSelf: "center" }}
                activePage={activePage}
                ellipsisItem={{
                  content: <Icon name="ellipsis horizontal" />,
                  icon: true,
                }}
                firstItem={{
                  content: <Icon name="angle double left" />,
                  icon: true,
                }}
                lastItem={{
                  content: <Icon name="angle double right" />,
                  icon: true,
                }}
                prevItem={{ content: <Icon name="angle left" />, icon: true }}
                nextItem={{ content: <Icon name="angle right" />, icon: true }}
                onPageChange={(e, pageInfo) => {
                  setActivePage(pageInfo.activePage);
                  setLoading(true);

                  if ((clientSearch || titleSearch) !== "")
                    dispatch(
                      handleSearch({
                        title: titleSearch === "" ? "undefined" : titleSearch,
                        client:
                          clientSearch === "" ? "undefined" : clientSearch,
                        enterpriseId: !user.enterpriseId
                          ? user?._id
                          : user.enterpriseId,
                        page: pageInfo.activePage,
                        type: "finished",
                      })
                    );
                  else
                    dispatch(
                      fetchActivedFlows({
                        enterpriseId: !user.enterpriseId
                          ? user?._id
                          : user.enterpriseId,
                        page: pageInfo.activePage,
                        type: "finished",
                      })
                    );

                  setLoading(false);
                }}
                totalPages={pages}
              />
            </>
          ) : (
            "VOCÊ NÃO POSSUI NENHUM FLUXO FINALIZADO. APÓS ENCERRADO, O FLUXO ESTARÁ AQUI PARA CONSULTAS POSTERIORES!"
          )}
        </Dashboard>
      </Segment>
    </ContainerCenter>
  ) : (
    <div>
      <Loading />
    </div>
  );
};

export default FinishedFlowsPage;
