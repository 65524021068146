import React from "react";
import { Container, Title, Draggable, EventNode, IconDiv } from "./styles";
import StartIcon from "../../../../assets/SideBar/StartIcon";
import EndIcon from "../../../../assets/SideBar/EndIcon";
import TimerIcon from "../../../../assets/SideBar/TimerIcon";
import TextIcon from "../../../../assets/SideBar/TextIcon";
const Events = ({ Label, onDrag }) => {
  return (
    <Container>
      <Title>{Label}</Title>
      <Draggable>
        <EventNode
          style={{ marginRight: "0.125rem", marginBottom: "0.125rem" }}
        >
          <IconDiv
            draggable
            onDragStart={(event) => onDrag(event, "eventStart")}
          >
            <StartIcon />
          </IconDiv>
          <span>Início</span>
        </EventNode>
        <EventNode style={{ marginBottom: "0.125rem" }}>
          <IconDiv draggable onDragStart={(event) => onDrag(event, "eventEnd")}>
            <EndIcon />
          </IconDiv>
          <span>Fim</span>
        </EventNode>
        <EventNode style={{ marginRight: "0.125rem" }}>
          <IconDiv
            draggable
            onDragStart={(event) => onDrag(event, "timerEvent")}
          >
            <TimerIcon />
          </IconDiv>
          <span>Temporizador</span>
        </EventNode>
        <EventNode>
          <IconDiv
            text
            draggable
            onDragStart={(event) => onDrag(event, "customText")}
          >
            <TextIcon />
          </IconDiv>
          <span>Texto</span>
        </EventNode>
      </Draggable>
    </Container>
  );
};

export default Events;
