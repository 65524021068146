import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.625rem;
  font-size: 0.70625rem;
  line-height: 0.875rem;
`;

export const Wrapper = styled.div`
  position: relative;
  width: 6.529375rem;
  margin-top: 0.4rem;

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  ::after {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;

    font-family: Sora;
    font-size: 0.70625rem;
    content: attr(content);

    @media (min-width: 1920px) {
      top: 0.76rem;
    }
  }
`;

export const Input = styled.input`
  width: 6.529375rem !important;
  height: 2.4025rem;
  outline: none;
  border: 0.941243px solid #d4d6da;
  border-radius: 0.35296625rem;
  padding: 0.73125rem 2.3875rem 0.7875rem 0.921875rem;

  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.87);
  }

  &:focus {
    border: 1px solid #40e5a0;
  }
`;

export const Label = styled.span`
  font-family: Sora;
  font-size: 0.70625rem;
  line-height: 0.875rem;
  color: #585e66;
  font-weight: 600;
`;
